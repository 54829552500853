
import { addLeadingZero, getFallbackValue, formatDate } from "../../../utilities";
import { getSensorSummaryData } from "../../../data/getsensordata";
import { openSelectSensorDiagramDateForm } from "./sensorvaluesdiagram";
import { openNotificationsForm, openValuesOutOfRangeForm } from "./wheelsdiagram";
import { createRepeaterDiagram  } from "./repeaterdiagram";
import { exportSensorValues } from "../../excelexports/excelexport";
import { getToday, formatTime, formatDateTime, convertUnixTime, convertToUnixTimeStamp, convertToStartOfDayUnixTimeStamp, convertToEndOfDayUnixTimeStamp, isDateInArray, removeSelectOptions } from "../../../utilities.js";
import { openSensorValuesChartReport, createReportsAxleElements } from '../../../reports';
import { getWheelProperties, getTyreArrayIndex } from "../units";
import {
    openTyreContextMenu, createSensorNoSignalElement, createUnitElement, createUnitElementTest, createTyreIdElement, createTreadDepthElement,
    createSensorIdElement, createSensorPressureElement, createSensorTemperatureElement,
    createSensorVoltageElement
} from "./unitdiagrams";
import { getResourceNotifications, setUnitValuesOutOfRangeNotifications } from "../../../notifications";

import { SPARE_AXLE } from '../../../global';

var gSensorIdCheck;
var gTyreIdCheck;
var gTreadDepthCheck;
var gPressureCheck;
var gVoltageCheck;
var gTemperatureCheck;

function createDiagramView(unitdata, diagramView, container) {

    const diagram = document.getElementById(container);
    while (diagram.hasChildNodes()) {
        diagram.removeChild(diagram.lastChild);
    }
    const diagramFragment = document.createDocumentFragment();

    let diagramDiv = document.createElement('div');
    diagramDiv.id = `${container}diagram`;
    diagramDiv.className = 'unitdiagram';

    diagramFragment.appendChild(diagramDiv);
    diagram.appendChild(diagramFragment);

    if (diagramView === 'SENSORVALUESDIAGRAM') {

        const sensorValuesDiagramButtons = createSensorDiagramButtonsElement(unitdata);
        diagramDiv.appendChild(sensorValuesDiagramButtons);

        const sensorDiagramMetricOptionsDiv = createSensorDiagramOptionsElement(unitdata);
        diagramDiv.appendChild(sensorDiagramMetricOptionsDiv);

        //const sensorValuesDiagramDiv = createUnitDiagramDiv(diagramView.toLowerCase()); //*unitschematicwheeldiagram
        //diagramDiv.appendChild(sensorValuesDiagramDiv);
    }
    if (diagramView === 'WHEELSDIAGRAM') {
        const wheelsDiagramButtons = createWheelsDiagramButtonsElement(unitdata);
        diagramDiv.appendChild(wheelsDiagramButtons);
    }
    getDiagramConfiguration(unitdata, diagramView, diagramDiv);
}

function createWheelsDiagramButtonsElement(unit) {

    let schematicDiagramButtonDivClasses = ['row', 'pane2buttons'];
    let schematicDiagramButtonDiv = document.createElement('div');
    schematicDiagramButtonDiv.classList.add(...schematicDiagramButtonDivClasses);

    let schematicDiagramButtonClasses = ['btn', 'btn-primary', 'panebutton', 'flex-child'];

    let notificationsButton = document.createElement('button');
    notificationsButton.classList.add(...schematicDiagramButtonClasses);
    notificationsButton.type = 'button';
    notificationsButton.title = 'SET NOTIFICATIONS';

    const notificationsIcon = document.createElement('svg');
    notificationsIcon.className = 'buttonicon notificationbluesvg';

    const notificationsButtonText = document.createElement('span');
    //wheelsDiagramButtonText.className = 'buttontext'; //class="buttontext buttontext-disabled"
    notificationsButtonText.innerText = 'Notifications'; //'\xa0Unit'

    //let notificationsImg = document.createElement('img');
    //notificationsImg.src = "images/icons8-notification-24.png";
    //notificationsImg.src = "/src/components/unitDiagrams/images/icons8-notification-24.png";

    notificationsButton.appendChild(notificationsIcon);
    notificationsButton.appendChild(notificationsButtonText);

    notificationsButton.onclick = function (e) {
        openNotificationsForm(unit.id);
        e.stopImmediatePropagation();
    };

    schematicDiagramButtonDiv.appendChild(notificationsButton);

    let wheelsDiagramButton = document.createElement('button');
    wheelsDiagramButton.classList.add(...schematicDiagramButtonClasses);
    wheelsDiagramButton.type = 'button';
    wheelsDiagramButton.title = 'VIEW REPEATER DIAGRAM';

    const sensorIcon = document.createElement('svg');
    sensorIcon.className = 'buttonicon sensorbluesvg';

    const wheelsDiagramButtonText = document.createElement('span');
    wheelsDiagramButtonText.innerText = 'Repeater Diagram';
    wheelsDiagramButton.appendChild(sensorIcon);
    wheelsDiagramButton.appendChild(wheelsDiagramButtonText);

    wheelsDiagramButton.onclick = function (e) {
        e.stopImmediatePropagation();

        createRepeaterDiagram(unit);
    };

    //Marius
    //wheelsDiagramButton.onclick = async function () {
    //    await loadAllSensorValuesMessages(unit);
    //};

    schematicDiagramButtonDiv.appendChild(wheelsDiagramButton);

    return schematicDiagramButtonDiv;
}

//function createUnitDiagramDiv(diagram) {

//    let diagramDiv = document.createElement('div');
//    diagramDiv.id = diagram;
//    diagramDiv.className = 'unitdiagram'; //*unitschematicwheeldiagram

//    return diagramDiv;
//}

async function getDiagramConfiguration(unit, diagramView, diagramDiv) {

    const resourceNotifications = await getResourceNotifications();

    //unit = getWheelPropertiesTest(unit);
    unit.wheelSensors = getWheelProperties(unit.wheelSensors);
    unit.spareSensors = getWheelProperties(unit.spareSensors);
    
    //const unitNumbers = [...new Set(Object.keys(unit.wheelSensors).map(item => unit.wheelSensors[item].sensorName.slice(3, 4)))].sort();

    const unitConfig = unit.unitConfig;
    for (let un = 0; un < unitConfig.length; un++) {

        const unitDiv = createUnitElement(diagramView, un, unit);
        diagramDiv.appendChild(unitDiv);

        const axles = unitConfig[un].axles;
        for (let a = 0; a < axles.length; a++) {
            if (diagramView === 'REPORTSDIAGRAM') {
                const wheelConfig = unit.unitConfig[un].axles[a].wheelConfig;
                const reportsAxleDiv = createReportsAxleElements(unit, un, a + +1, wheelConfig);
                unitDiv.appendChild(reportsAxleDiv);
            } else {
                const axleDiv = createAxleElements(diagramView, unit, un, a + +1, resourceNotifications);
                //createReportsAxleElements(unit, unitNumber, axle)
                unitDiv.appendChild(axleDiv);
            }
         }

    }
    if (unit.spareSensors.length) {
        const unitSpareDiv = createUnitElement(diagramView, SPARE_AXLE, unit);
        diagramDiv.appendChild(unitSpareDiv);

        if (diagramView === 'REPORTSDIAGRAM') {
            const reportsSpareAxleDiv = createReportsAxleElements(unit, 0, 'SPARE', null);
            unitSpareDiv.appendChild(reportsSpareAxleDiv);
        } else {
            const spareAxleDiv = createSpareAxleElements(diagramView, unit, resourceNotifications);
            unitSpareDiv.appendChild(spareAxleDiv);
        }
    }
}

function createAxleElements(diagramView, unit, unitNumber, axle, resourceNotifications) {

    const tyreAxleDiv = document.createElement('div');
    tyreAxleDiv.className = 'diagramaxle'; //*wheelaxlediagram
    let isAxleActive = getAxleActiveStatus(resourceNotifications, unit, unitNumber, axle);
    //console.log(isAxleActive);

    const axleSensors = unit.wheelSensors.filter(s => parseInt(s.sensorName.slice(1, 3)) === axle && parseInt(s.sensorName.slice(3)) === unitNumber);
    const wheelConfig = unit.unitConfig[unitNumber].axles[axle - 1].wheelConfig;
    let wheel;
    
    if (wheelConfig === 0) { 
        wheel = `1${addLeadingZero(axle)}${unitNumber}`;

        let tyre1Div = createTyreElement(diagramView, unit, axle, 1, wheel, axleSensors);
            tyreAxleDiv.appendChild(tyre1Div);

            let axledivclasses = [];
            let axleStatusClass = '';
            isAxleActive ? axleStatusClass = 'axleactive' : axleStatusClass = 'axleinactive';
            console.log(isAxleActive);
            axledivclasses = ['shortaxle', axleStatusClass]; //'axleinfo',

            //axle
            if (diagramView === "WHEELSDIAGRAM") {
                let axleDiv = createAxleElement(unit, axle, axleSensors, axledivclasses);
                tyreAxleDiv.appendChild(axleDiv);

                if (axleSensors.length) {
                    let axleInfoDiv = createAxleInfoElement(axleSensors);
                    tyreAxleDiv.appendChild(axleInfoDiv);
                }
            } else {
                let axleDiv = document.createElement('div');
                axleDiv.className = 'sensorvaluesaxle';
                tyreAxleDiv.appendChild(axleDiv);
            }

        wheel = `4${addLeadingZero(axle)}${unitNumber}`;
            let tyre4Div = createTyreElement(diagramView, unit, axle, 4, wheel, axleSensors);
            tyreAxleDiv.appendChild(tyre4Div);

        } else {

            for (let s = 1; s <= 2; s++) {
                wheel = `${s}${addLeadingZero(axle)}${unitNumber}`;
                const tyreDiv = createTyreElement(diagramView, unit, axle, s, wheel, axleSensors);
                tyreAxleDiv.appendChild(tyreDiv);
            }

            let axledivclasses = [];
            let axleStatusClass = '';
            isAxleActive ? axleStatusClass = 'axleactive' : axleStatusClass = 'axleinactive';
            //console.log(isAxleActive)
            axledivclasses = ['shortaxle', axleStatusClass]; //'axleinfo', 

            if (diagramView === "WHEELSDIAGRAM") {
                let axleDiv = createAxleElement(unit, axle, axleSensors, axledivclasses);
                tyreAxleDiv.appendChild(axleDiv);

                if (axleSensors.length) {
                    let axleInfoDiv = createAxleInfoElement(axleSensors);
                    tyreAxleDiv.appendChild(axleInfoDiv);
                }
            } else {
                let axleDiv = document.createElement('div');
                axleDiv.className = 'sensorvaluesaxle';
                tyreAxleDiv.appendChild(axleDiv);
            }

        for (let s = 3; s <= 4; s++) {
            wheel = `${s}${addLeadingZero(axle)}${unitNumber}`;
                let tyreDiv = createTyreElement(diagramView, unit, axle, s, wheel, axleSensors);
                tyreAxleDiv.appendChild(tyreDiv);
            }
        }

    return tyreAxleDiv;
}

function createSpareAxleElements(diagramView, unit, resourceNotifications) {    

    const tyreSpareAxleDiv = document.createElement('div');
    tyreSpareAxleDiv.className = 'diagramaxle';

    const axleSensors = unit.spareSensors;

    for (let s = 0; s < axleSensors.length; s++) {
        const tyreDiv = createSpareTyreElement(diagramView, unit, parseInt(axleSensors[s].sensorName.slice(0,1)), axleSensors);
        tyreSpareAxleDiv.appendChild(tyreDiv);
    }

    //Spare Axle
    if (diagramView === "WHEELSDIAGRAM") {        
        let axledivclasses = [];
        let axleStatusClass = '';
        const isAxleActive = getAxleActiveStatus(resourceNotifications, unit, 0, SPARE_AXLE);
        isAxleActive ? axleStatusClass = 'spareaxleactive' : axleStatusClass = 'spareaxleinactive';
        axledivclasses = ['spareaxle', 'axleinfo', axleStatusClass]; //'spareaxle'
        const spareAxle = createSpareAxleElement(unit, axleSensors, axledivclasses); //'spareaxle');
        tyreSpareAxleDiv.appendChild(spareAxle);

        const axleInfoDiv = createAxleInfoElement(axleSensors);
        tyreSpareAxleDiv.appendChild(axleInfoDiv);
    }

    return tyreSpareAxleDiv;
}

function createSpareTyreElement(diagramView, unit, tyre, axleSensors) {

    const wheel = document.createElement('div');

    const tyreNameDiv = createTyreNameElement(SPARE_AXLE, tyre);

    const tyreDiv = document.createElement('div');
    //let tyreArrayIndex = 0;
    if (axleSensors.length > 0) {
        //tyreArrayIndex = getTyreArrayIndexTest(tyre, axleSensors);
        

        let tyredivclasses = ['wheeldefault'];
        let sensorType = 'wheelcorrect';

        if (axleSensors.filter(as => parseInt(as.sensorName.slice(0, 1)) === tyre)[0]?.hasOwnProperty('sensorType')
            && axleSensors.filter(as => parseInt(as.sensorName.slice(0, 1)) === tyre)[0]?.sensorType !== "") {
            sensorType = axleSensors.filter(as => parseInt(as.sensorName.slice(0, 1)) === tyre)[0]?.sensorType || 'wheelcorrect';
        }


        if (diagramView === "SENSORVALUESDIAGRAM") {

            wheel.className = 'sensorvaluesinfo';

            tyredivclasses = ['sensorvaluesdefault'];

            tyreNameDiv.className = 'sensorvaluestyrename';

            const sensorId = axleSensors.filter(as => parseInt(as.sensorName.slice(0, 1)) === tyre)[0]?.sensorId || 0;            
            const sensorIdDiv = createSensorIdElement(sensorId);
            if (gSensorIdCheck)
                tyreDiv.appendChild(sensorIdDiv);

            const tyreArrayIndex = getTyreArrayIndex(tyre, axleSensors);
            const tyreIdDiv = createTyreIdElement(tyreArrayIndex, axleSensors);
            if (gTyreIdCheck)
                tyreDiv.appendChild(tyreIdDiv);

            
            const treadDepthDiv = createTreadDepthElement(tyreArrayIndex, axleSensors);
            if (gTreadDepthCheck) {
                tyreDiv.appendChild(treadDepthDiv);
            }
            
            const sensorPressureDiv = createSensorPressureElement(tyreArrayIndex, axleSensors);
            const sensorTemperatureDiv = createSensorTemperatureElement(tyreArrayIndex, axleSensors);
            const sensorVoltageDiv = createSensorVoltageElement(tyreArrayIndex, axleSensors);

            if (gPressureCheck || gPressureCheck == null)
                tyreDiv.appendChild(sensorPressureDiv);

            if (gTemperatureCheck)
                tyreDiv.appendChild(sensorTemperatureDiv);

            if (gVoltageCheck)
                tyreDiv.appendChild(sensorVoltageDiv);

            
        } else {
            tyreDiv.id = 'wheel' + tyre + axleSensors[0].sensorName.slice(1);
            tyredivclasses = ['wheeldefault', sensorType];
        }

        tyreDiv.classList.add(...tyredivclasses);

        wheel.appendChild(tyreNameDiv);
        wheel.appendChild(tyreDiv);
    }

    const tyreArrayIndex = getTyreArrayIndexTest(tyre, axleSensors);

    if (wheel.classList.contains('wheelinactive')) {
        wheel.onclick = function (e) {
            e.stopImmediatePropagation();
            openNewSensorForm(unit, SPARE_AXLE + tyre);
        };
    } else {
        wheel.onclick = function (e) {
            e.stopImmediatePropagation();
            openTyreContextMenu(unit, axleSensors[tyreArrayIndex]);
        };
    }

    return wheel;
}

function createAxleElement(unit, axle, axleSensors, axledivclasses) {

    const axleDiv = document.createElement('div');
    axleDiv.className = 'axle'; // axleinfo
    const axleSvg = document.createElement('svg');

    axleSvg.classList.add(...axledivclasses);

    axleDiv.appendChild(axleSvg);

    axleDiv.onclick = function (e) {
        e.stopImmediatePropagation();
        if (axle >= 4) {
            if (unit.hasOwnProperty('trailers')) {
                if (unit.unitTrailers[0] != null) unit = unit.unitTrailers[0];
            }
        } //set trailer's axle properties
        openValuesOutOfRangeForm(unit, axleSensors);
    };

    return axleDiv;
}


function createSpareAxleElement(unit, axleSensors, axledivclasses) {

    const axleDiv = document.createElement('div');
    //axleDiv.className = 'axle'; // axleinfo

    axleDiv.classList.add(...axledivclasses);

    axleDiv.onclick = function (e) {
        e.stopImmediatePropagation();
        openValuesOutOfRangeForm(unit, axleSensors);
    };

    return axleDiv;
}

function getAxleActiveStatus(axleStatusNotifications, unit, unitNumber, axle) {

    let notifications = axleStatusNotifications.notifications;
    for (let key in notifications) {
        if (!notifications.hasOwnProperty(key)) continue;
        let obj = notifications[key]; //|| obj.n.slice(-3) === "170"
        if (obj.n.slice(-3) === addLeadingZero(axle) + unitNumber ) {
            return true;
        }
    }
    return false;
}

function createTyreNameElement(axle, tyre) {

    const tyreNameDiv = document.createElement('div');
    tyreNameDiv.className = 'tyrename';
    if (axle === SPARE_AXLE) tyreNameDiv.innerText = `S-${tyre}`;
    else tyreNameDiv.innerText = `A${axle}-T${tyre}`;

    return tyreNameDiv;
}

export function getTyreArrayIndexTest(tyre, axleSensors) {
    let tyreArrayIndex = -1;
    for (let s = 0; s < axleSensors.length; s++) {
        if (parseInt(axleSensors[s].sensorName.slice(0, 1)) === tyre) {
            //tyreArrayIndex = tyre;
            return s;
        }
    }
    return tyreArrayIndex;
}

function createTyreElement(diagramView, unit, axle, tyre, wheel, axleSensors) {

    const wheelDiv = document.createElement('div');

    const tyreNameDiv = createTyreNameElement(axle, tyre);

    const tyreDiv = document.createElement('div');
    let tyreArrayIndex = 0;
    if (axleSensors.length > 0) {
        tyreArrayIndex = getTyreArrayIndexTest(tyre, axleSensors);      


        let tyredivclasses = ['wheeldefault'];
        let sensorType = 'wheelcorrect';

        if (tyreArrayIndex === -1) {
            sensorType = 'wheelblue';
        } else {
            if (axleSensors.filter(as => parseInt(as.sensorName.slice(0, 1)) === tyre)[0].hasOwnProperty('sensorType')
                && axleSensors.filter(as => parseInt(as.sensorName.slice(0, 1)) === tyre)[0].sensorType !== "") {
                sensorType = axleSensors.filter(as => parseInt(as.sensorName.slice(0, 1)) === tyre)[0].sensorType;
            }
        }

        if (diagramView === "SENSORVALUESDIAGRAM") {

            wheelDiv.className = 'sensorvaluesinfo';

            tyredivclasses = ['sensorvaluesdefault'];

            tyreNameDiv.className = 'sensorvaluestyrename';

            if (tyreArrayIndex !== -1) {
                let sensorId = axleSensors[tyreArrayIndex].sensorId;
                let sensorIdDiv = createSensorIdElement(sensorId);
                if (gSensorIdCheck)
                    tyreDiv.appendChild(sensorIdDiv);

                const tyreIdDiv = createTyreIdElement(tyreArrayIndex, axleSensors);
                if (gTyreIdCheck)
                    tyreDiv.appendChild(tyreIdDiv);

                const treadDepthDiv = createTreadDepthElement(tyreArrayIndex, axleSensors);
                if (gTreadDepthCheck) {
                    tyreDiv.appendChild(treadDepthDiv);
                }
            }

            let sensorPressureDiv = {};
            let sensorTemperatureDiv = {};
            let sensorVoltageDiv = {};

            if (sensorType !== "wheelblue") {
                sensorPressureDiv = createSensorPressureElement(tyreArrayIndex, axleSensors);
                sensorTemperatureDiv = createSensorTemperatureElement(tyreArrayIndex, axleSensors);
                sensorVoltageDiv = createSensorVoltageElement(tyreArrayIndex, axleSensors);

                if (gPressureCheck || gPressureCheck == null)
                    tyreDiv.appendChild(sensorPressureDiv);

                if (gTemperatureCheck)
                    tyreDiv.appendChild(sensorTemperatureDiv);

                if (gVoltageCheck)
                    tyreDiv.appendChild(sensorVoltageDiv);

            } else {
                let sensorNoSignalDiv = createSensorNoSignalElement();
                tyreDiv.appendChild(sensorNoSignalDiv);
            }
        } else {
            tyreDiv.id = 'wheel' + tyre + axleSensors[0].sensorName.slice(1);
            tyredivclasses = ['wheeldefault', sensorType];

        }

        tyreDiv.classList.add(...tyredivclasses);

        wheelDiv.appendChild(tyreNameDiv);
        wheelDiv.appendChild(tyreDiv);
    } else {
        
        tyreDiv.id = 'wheelnosignal' + tyre;

        let tyredivclasses = ['wheeldefault'];
        const sensorType = 'wheelblue';

        if (diagramView === "SENSORVALUESDIAGRAM") {

            wheelDiv.className = 'sensorvaluesinfo';

            tyredivclasses = ['sensorvaluesdefault'];

            tyreNameDiv.className = 'sensorvaluestyrename';

            let sensorNoSignalDiv = createSensorNoSignalElement();
            tyreDiv.appendChild(sensorNoSignalDiv);
            
        } else {
            tyredivclasses = ['wheeldefault', sensorType];

        }

        tyreDiv.classList.add(...tyredivclasses);

        wheelDiv.appendChild(tyreNameDiv);
        wheelDiv.appendChild(tyreDiv);

    }

    if (wheelDiv.classList.contains('wheelinactive')) {
        wheelDiv.onclick = function (e) {
            e.stopImmediatePropagation();
            openNewSensorForm(unit, "9" + tyre);
        };
    } else {
        wheelDiv.onclick = function (e) {
            e.stopImmediatePropagation();
            const unitAxleWheel = processWheelData(wheel, axleSensors);
            openTyreContextMenu(unit, unitAxleWheel);
        };
    }

    return wheelDiv;
}

function processWheelData(wheel, axleSensors) {
    // Check if wheel matches any SensorName in the objects array
    let axleSensor = axleSensors.find(s => s.SensorName === wheel);

    // If a match is found, return the existing array unchanged
    if (axleSensor) {
        return axleSensor;
    }

    // Find an object to copy certain properties from (for this example, we copy from the first object)
    const sourceObject = axleSensors[0];
    const sensor = {};

    // Assign all properties of the sourceObject to the newObject with specified exceptions
    for (let key in sourceObject) {
        if (sourceObject.hasOwnProperty(key)) {
            if (key === 'unitNumber' || key === 'axle' || key === 'manufacturersRecommendedPressure' || key === 'percentageDeviationAllowed' || 
                key === 'maxTemperatureValue' || key === 'minVoltageValue' || key === 'maxPressureValue' || key === 'minPressureValue') {
                sensor[key] = sourceObject[key];
            } else {
                sensor[key] = null;
            }
        }
    }

    sensor.sensorName = wheel;
    sensor.tyreName = `A${parseInt(wheel.slice(1, 3))}-T${wheel.slice(0, 1)}`;
    sensor.sensorType = 'noSignal';
    sensor.wheelType = 'wheelinactive';

    return sensor;
}

function createTyreElementOld(diagramView, unit, axle, tyre, axleSensors) {

    const wheel = document.createElement('div');

    const tyreNameDiv = createTyreNameElement(axle, tyre);

    let tyreDiv = document.createElement('div');
    let tyreArrayIndex = 0;
    if (axleSensors.length > 0) {
        tyreArrayIndex = getTyreArrayIndex(tyre, axleSensors);
        tyreDiv.id = 'wheel' + axleSensors[0].sensorName.slice(0, 1) + tyre;
        //tyreDiv.id = 'wheel' + tyre + axleSensors[0].sensorName.slice(1, 4); --Test Wialon IPS 2

        let tyredivclasses = ['wheeldefault'];

        let sensorType = 'wheelcorrect';
        //if (unit.name !== "Test Wialon IPS 2" || unit.name !== "GalileoSky7x") {
        //    if (axleSensors.filter(as => parseInt(as.sensorName.slice(1, 2)) === tyre)[0].hasOwnProperty('sensorType')
        //        && axleSensors.filter(as => parseInt(as.sensorName.slice(1, 2)) === tyre)[0].sensorType !== "") {
        //        sensorType = axleSensors.filter(as => parseInt(as.sensorName.slice(1, 2)) === tyre)[0].sensorType;
        //    }
        //}

        //if (unit.name === "Test Wialon IPS 2" || unit.name === "GalileoSky7x") {
        if (axleSensors.filter(as => parseInt(as.sensorName.slice(0,1)) === tyre)[0].hasOwnProperty('sensorType')
            && axleSensors.filter(as => parseInt(as.sensorName.slice(0, 1)) === tyre)[0].sensorType !== "") {
            sensorType = axleSensors.filter(as => parseInt(as.sensorName.slice(0, 1)) === tyre)[0].sensorType;
        }
        //}

        if (diagramView === "SENSORVALUESDIAGRAM") {

            wheel.className = 'sensorvaluesinfo';

            tyredivclasses = ['sensorvaluesdefault'];

            tyreNameDiv.className = 'sensorvaluestyrename';

            const tyreIdDiv = createTyreIdElement(tyreArrayIndex, axleSensors);
            if (gTyreIdCheck)
                tyreDiv.appendChild(tyreIdDiv);

            const treadDepthDiv = createTreadDepthElement(tyreArrayIndex, axleSensors);
            if (gTreadDepthCheck)
                tyreDiv.appendChild(treadDepthDiv);

            const sensorId = axleSensors[tyreArrayIndex].sensorName.slice(2);
            const sensorIdDiv = createSensorIdElement(sensorId);
            if (gSensorIdCheck)
                tyreDiv.appendChild(sensorIdDiv);

            const sensorPressureDiv = {};
            const sensorTemperatureDiv = {};
            const sensorVoltageDiv = {};

            if (sensorType !== "wheelblue") {
                sensorPressureDiv = createSensorPressureElement(tyreArrayIndex, axleSensors);
                sensorTemperatureDiv = createSensorTemperatureElement(tyreArrayIndex, axleSensors);
                sensorVoltageDiv = createSensorVoltageElement(tyreArrayIndex, axleSensors);

                if (gPressureCheck || gPressureCheck == null)
                    tyreDiv.appendChild(sensorPressureDiv);

                if (gTemperatureCheck)
                    tyreDiv.appendChild(sensorTemperatureDiv);

                if (gVoltageCheck)
                    tyreDiv.appendChild(sensorVoltageDiv);

            } else {
                let sensorNoSignalDiv = createSensorNoSignalElement();
                tyreDiv.appendChild(sensorNoSignalDiv);
            }
        } else {
            tyredivclasses = ['wheeldefault', sensorType];
        }

        tyreDiv.classList.add(...tyredivclasses);

        wheel.appendChild(tyreNameDiv);
        wheel.appendChild(tyreDiv);
    }

    if (wheel.classList.contains('wheelinactive')) {
        wheel.onclick = function (e) {
            e.stopImmediatePropagation();
            openNewSensorForm(unit, "9" + tyre);
        };
    } else {
        wheel.onclick = function (e) {
            e.stopImmediatePropagation();
            openTyreContextMenu(unit, axleSensors[tyreArrayIndex]);
        };
    }

    return wheel;
}

//function createAxleInfoElement(axleSensors) {

//    //let mrpValue = 0;
//    //let pdaValue = 0;
//    //let minPressureValue = 0;
//    //let maxPressureValue = 0;
//    //let maxTempValue = 0;
//    //let minVoltageValue = 0;

//    //for (let s = 0; s <= unit.wheelSensors.length; s++) {
//    //    if (axleSensors[0].sensorName === unit.wheelSensors[s].sensorName) {
//    //        mrpValue = unit.wheelSensors[s]?.manufacturersRecommendedPressure;
//    //         pdaValue = unit.wheelSensors[s]?.percentageDeviationAllowed;
//    //         minPressureValue = unit.wheelSensors[s]?.minPressureValue;
//    //         maxPressureValue = unit.wheelSensors[s]?.maxPressureValue;
//    //         maxTempValue = unit.wheelSensors[s]?.maxTemperatureValue;
//    //         minVoltageValue = unit.wheelSensors[s]?.minVoltageValue;
//    //        break;
//    //    }
//    //}

//    const mrpValue = axleSensors[0]?.manufacturersRecommendedPressure || 0;
//    const pdaValue = axleSensors[0]?.percentageDeviationAllowed || 0;
//    const minPressureValue = axleSensors[0]?.minPressureValue || 0;
//    const maxPressureValue = axleSensors[0]?.maxPressureValue || 0;
//    const maxTempValue = axleSensors[0]?.maxTemperatureValue || 0;
//    const minVoltageValue = axleSensors[0]?.minVoltageValue || 0;

//    let axleinfodivclasses = ['axleinfo', 'axleinfohide'];
//    let axleInfoDiv = document.createElement('div');
//    axleInfoDiv.classList.add(...axleinfodivclasses);

//    let mrpDiv = createManufacturersRecommendedPressureElement(mrpValue);
//    axleInfoDiv.appendChild(mrpDiv);

//    let pdaDiv = createPercentageDeviationAllowedElement(pdaValue);
//    axleInfoDiv.appendChild(pdaDiv);

//    let minPressureDiv = createMinPressureValueElement(minPressureValue);
//    axleInfoDiv.appendChild(minPressureDiv);

//    let maxPressureDiv = createMaxPressureValueElement(maxPressureValue);
//    axleInfoDiv.appendChild(maxPressureDiv);

//    let maxTempDiv = createMaxTemperatureValueElement(maxTempValue);
//    axleInfoDiv.appendChild(maxTempDiv);

//    let minVoltageDiv = createMinVoltageValueElement(minVoltageValue);
//    axleInfoDiv.appendChild(minVoltageDiv);

//    return axleInfoDiv;
//}

function createAxleInfoElement(axleSensors) {

    const mrpValue = getFallbackValue(axleSensors, 'manufacturersRecommendedPressure', "");
    const pdaValue = getFallbackValue(axleSensors, 'percentageDeviationAllowed', "");
    const minPressureValue = getFallbackValue(axleSensors, 'minPressureValue', "");
    const maxPressureValue = getFallbackValue(axleSensors, 'maxPressureValue', "");
    const maxTempValue = getFallbackValue(axleSensors, 'maxTemperatureValue', "");
    const minVoltageValue = getFallbackValue(axleSensors, 'minVoltageValue', "");

    const axleinfodivclasses = ['axleinfo', 'axleinfohide'];
    const axleInfoDiv = document.createElement('div');
    axleInfoDiv.classList.add(...axleinfodivclasses);

    const axleUnit = axleSensors[0].sensorName.slice(1);
    const mrpDiv = createManufacturersRecommendedPressureElement(axleUnit, mrpValue);
    axleInfoDiv.appendChild(mrpDiv);

    const pdaDiv = createPercentageDeviationAllowedElement(axleUnit, pdaValue);
    axleInfoDiv.appendChild(pdaDiv);

    const minPressureDiv = createMinPressureValueElement(axleUnit, minPressureValue);
    axleInfoDiv.appendChild(minPressureDiv);

    const maxPressureDiv = createMaxPressureValueElement(axleUnit, maxPressureValue);
    axleInfoDiv.appendChild(maxPressureDiv);

    const maxTempDiv = createMaxTemperatureValueElement(axleUnit, maxTempValue);
    axleInfoDiv.appendChild(maxTempDiv);

    const minVoltageDiv = createMinVoltageValueElement(axleUnit, minVoltageValue);
    axleInfoDiv.appendChild(minVoltageDiv);

    return axleInfoDiv;
}

function createManufacturersRecommendedPressureElement(axleUnit, mrpValue) {

    if (mrpValue == null || mrpValue == "null" || mrpValue == "") mrpValue = "--";

    let mrpDiv = document.createElement('div');

    let labelSpan = document.createElement('span');
    let labelSpanText = document.createTextNode("Rec Pressure (bar): ");
    labelSpan.appendChild(labelSpanText);

    let valueSpan = document.createElement('span');
    valueSpan.className = 'boldtextmaxminvalue';
    valueSpan.id = `a${axleUnit}mrp`; // Assign an id here
    valueSpan.textContent = mrpValue; // Use textContent here

    let mrpSpan = document.createElement('span');
    mrpSpan.appendChild(labelSpan);
    mrpSpan.appendChild(valueSpan);

    mrpDiv.appendChild(mrpSpan);

    return mrpDiv;
}

function createPercentageDeviationAllowedElement(axleUnit, pdaValue) {

    if (pdaValue == null || pdaValue == "null" || pdaValue == "") pdaValue = "--";

    let pdaDiv = document.createElement('div');

    let labelSpan = document.createElement('span');
    let labelSpanText = document.createTextNode("Deviation (%): ");
    labelSpan.appendChild(labelSpanText);

    let valueSpan = document.createElement('span');
    valueSpan.className = 'boldtextmaxminvalue';
    valueSpan.id = `a${axleUnit}ppd`; // Assign an id here
    valueSpan.textContent = pdaValue; // Use textContent here

    let pdaSpan = document.createElement('span');
    pdaSpan.appendChild(labelSpan);
    pdaSpan.appendChild(valueSpan);

    pdaDiv.appendChild(pdaSpan);

    return pdaDiv;
}

function createMinPressureValueElement(axleUnit, minPressurevalue) {

    if (minPressurevalue == null || minPressurevalue == "null" || minPressurevalue == "") minPressurevalue = "--";

    let minPressureDiv = document.createElement('div');

    let labelSpan = document.createElement('span');
    let labelSpanText = document.createTextNode("Min Pressure (bar): ");
    labelSpan.appendChild(labelSpanText);

    //let valueSpan = document.createElement('span');
    //let valueSpanText = document.createTextNode(minPressurevalue);
    //valueSpan.className = 'boldtextmaxminvalue pressurered';
    //valueSpan.appendChild(valueSpanText);

    let valueSpan = document.createElement('span');
    valueSpan.className = 'boldtextmaxminvalue pressurered';
    valueSpan.id = `a${axleUnit}minpressure`; 
    valueSpan.textContent = minPressurevalue; 

    let minPressureSpan = document.createElement('span');
    minPressureSpan.appendChild(labelSpan);
    minPressureSpan.appendChild(valueSpan);

    minPressureDiv.appendChild(minPressureSpan);

    return minPressureDiv;
}

function createMaxPressureValueElement(axleUnit, maxPressureValue) {

    if (maxPressureValue == null || maxPressureValue == "null" || maxPressureValue == "") maxPressureValue = "--";

    let maxPressureDiv = document.createElement('div');

    let labelSpan = document.createElement('span');
    let labelSpanText = document.createTextNode("Max Pressure (bar): ");
    labelSpan.appendChild(labelSpanText);

    let valueSpan = document.createElement('span');
    valueSpan.className = 'boldtextmaxminvalue pressurepurple';
    valueSpan.id = `a${axleUnit}maxpressure`;
    valueSpan.textContent = maxPressureValue;

    let maxPressureSpan = document.createElement('span');
    maxPressureSpan.appendChild(labelSpan);
    maxPressureSpan.appendChild(valueSpan);

    maxPressureDiv.appendChild(maxPressureSpan);

    return maxPressureDiv;
}

function createMaxTemperatureValueElement(axleUnit, maxTemperatureValue) {

    if (maxTemperatureValue == null || maxTemperatureValue == "null" || maxTemperatureValue == "") maxTemperatureValue = "--";

    let maxTemperatureDiv = document.createElement('div');

    let labelSpan = document.createElement('span');
    let labelSpanText = document.createTextNode("Max Temp (°C): ");
    labelSpan.appendChild(labelSpanText);

    let valueSpan = document.createElement('span');
    valueSpan.className = 'boldtextmaxminvalue temperatureorange';
    valueSpan.id = `a${axleUnit}maxtemperature`;
    valueSpan.textContent = maxTemperatureValue;

    let maxTemperatureSpan = document.createElement('span');
    maxTemperatureSpan.appendChild(labelSpan);
    maxTemperatureSpan.appendChild(valueSpan);

    maxTemperatureDiv.appendChild(maxTemperatureSpan);

    return maxTemperatureDiv;
}

function createMinVoltageValueElement(axleUnit, minVoltageValue) {

    if (minVoltageValue == null || minVoltageValue == "null" || minVoltageValue == "") minVoltageValue = "--";

    let minVoltageDiv = document.createElement('div');

    let labelSpan = document.createElement('span');
    let labelSpanText = document.createTextNode("Min Voltage (V): ");
    labelSpan.appendChild(labelSpanText);

    let valueSpan = document.createElement('span');
    valueSpan.className = 'boldtextmaxminvalue voltageyellow';
    valueSpan.id = `a${axleUnit}minvoltage`;
    valueSpan.textContent = minVoltageValue;

    let minVoltageSpan = document.createElement('span');
    minVoltageSpan.appendChild(labelSpan);
    minVoltageSpan.appendChild(valueSpan);

    minVoltageDiv.appendChild(minVoltageSpan);

    return minVoltageDiv;
}

function calculateMinMaxPressure(textbox) {

    let manufacturersRecommendedPressure = parseFloat(document.getElementById('manufacturersRecommendedPressure').value);
    let percentageDeviationAllowed = parseFloat(document.getElementById('percentageDeviationAllowed').value);
    if (manufacturersRecommendedPressure && percentageDeviationAllowed) {
        document.getElementById("minPressure").value = calculateMinPressure(parseFloat(manufacturersRecommendedPressure), parseFloat(percentageDeviationAllowed));
        document.getElementById("maxPressure").value = calculateMaxPressure(parseFloat(manufacturersRecommendedPressure), parseFloat(percentageDeviationAllowed));
    }

    return true;
}

function calculateMinPressure(manufacturersRecommendedPressure, percentageDeviationAllowed) {

    let minPressure = (manufacturersRecommendedPressure - (percentageDeviationAllowed / 100 * manufacturersRecommendedPressure));
    return Math.round(minPressure * 100) / 100;
}

function calculateMaxPressure(manufacturersRecommendedPressure, percentageDeviationAllowed) {

    let maxPressure = (manufacturersRecommendedPressure + (percentageDeviationAllowed / 100 * manufacturersRecommendedPressure));
    return Math.round(maxPressure * 100) / 100;
}

//async function submitValuesOutOfRangeForm() {

//    manufacturersRecommendedPressureValidation();
//    percentageDeviationAllowedValidation();

//    document.body.style.cursor = 'wait';
//    //$('#container').overlayMask();
//    document.getElementById("loadingBoxText").innerText = "Updating 'sensor values out of range' data... ";
//    $("#loadingBox").modal({
//        backdrop: "static", //remove ability to close modal with click
//        keyboard: false, //remove option to close with keyboard
//        show: true //Display loader!
//    });

//    let valuesOutOfRangeParams = {};
//    valuesOutOfRangeParams.notificationId = document.getElementById('notificationId').value;
//    valuesOutOfRangeParams.id = document.getElementById('unitId').value;
//    if (document.getElementById('unitLinkedToId').value)
//        valuesOutOfRangeParams.unitLinkedToId = document.getElementById('unitLinkedToId').value;
//    valuesOutOfRangeParams.name = document.getElementById("vormUnitName").value;
//    let axleName = document.getElementById('axle').value;
//    let axle = document.getElementById('axle').value.slice(-1);
//    if (axleName === "Spare Axle")
//        axle = "9";

//    let axleSensorCount = document.getElementById('axleSensorCount').value;
//    valuesOutOfRangeParams.axleSensorCount = axleSensorCount;

//    let wheels = [];
//    if (axleSensorCount === "1") { //only one spare tyre
//        valuesOutOfRangeParams.wheel1 = document.getElementById('axleSensorId' + axle + '1').value;
//        wheels.push(valuesOutOfRangeParams.wheel1);
//    }
//    if (axleSensorCount === "2") {
//        if (axle === "9") {
//            valuesOutOfRangeParams.wheel1 = document.getElementById('axleSensorId' + axle + '1').value;
//            wheels.push(valuesOutOfRangeParams.wheel1);
//            valuesOutOfRangeParams.wheel4 = document.getElementById('axleSensorId' + axle + '2').value;
//            wheels.push(valuesOutOfRangeParams.wheel4);
//        } else {
//            valuesOutOfRangeParams.wheel1 = document.getElementById('axleSensorId' + axle + '1').value;
//            wheels.push(valuesOutOfRangeParams.wheel1);
//            valuesOutOfRangeParams.wheel4 = document.getElementById('axleSensorId' + axle + '4').value;
//            wheels.push(valuesOutOfRangeParams.wheel4);
//        }
//    }
//    if (axleSensorCount === "4") {
//        valuesOutOfRangeParams.wheel1 = document.getElementById('axleSensorId' + axle + '1').value;
//        wheels.push(valuesOutOfRangeParams.wheel1);
//        valuesOutOfRangeParams.wheel2 = document.getElementById('axleSensorId' + axle + '2').value;
//        wheels.push(valuesOutOfRangeParams.wheel2);
//        valuesOutOfRangeParams.wheel3 = document.getElementById('axleSensorId' + axle + '3').value;
//        wheels.push(valuesOutOfRangeParams.wheel3);
//        valuesOutOfRangeParams.wheel4 = document.getElementById('axleSensorId' + axle + '4').value;
//        wheels.push(valuesOutOfRangeParams.wheel4);
//    }
//    valuesOutOfRangeParams.wheels = wheels;
//    valuesOutOfRangeParams.axle = axle;

//    valuesOutOfRangeParams.manufacturersRecommendedPressure = parseFloat(document.getElementById("manufacturersRecommendedPressure").value);
//    valuesOutOfRangeParams.percentageDeviationAllowed = parseFloat(document.getElementById("percentageDeviationAllowed").value);

//    let minPressure = parseFloat(document.getElementById("minPressure").value);
//    let maxPressure = parseFloat(document.getElementById("maxPressure").value);

//    if (minPressure == null && maxPressure == null) {
//        minPressure = 9;
//        maxPressure = 12;
//    }

//    if (minPressure == null && maxPressure) {
//        minPressure = maxPressure - 3;
//    }

//    if (minPressure && maxPressure == null) {
//        maxPressure = minPressure + 3;
//    }

//    valuesOutOfRangeParams.minPressure = minPressure;
//    valuesOutOfRangeParams.maxPressure = maxPressure;

//    valuesOutOfRangeParams.minTemperature = -18;
//    if (document.getElementById('maxTemperature').value)
//        valuesOutOfRangeParams.maxTemperature = parseInt(document.getElementById('maxTemperature').value);
//    else
//        valuesOutOfRangeParams.maxTemperature = 65;

//    if (document.getElementById('minVoltage').value)
//        valuesOutOfRangeParams.minVoltage = parseFloat(document.getElementById('minVoltage').value);
//    else
//        valuesOutOfRangeParams.minVoltage = 3.000;
//    valuesOutOfRangeParams.maxVoltage = 5;

//    await setAxleSensorPressureMetricAttributes(valuesOutOfRangeParams);
//    setUnitValuesOutOfRangeNotifications(valuesOutOfRangeParams);

//    //document.getElementById("loadingBox").modal("hide");
//    //document.body.style.cursor = 'default';
//    //$("#loadingBox").modal("hide");
//    document.getElementById("valuesoutofrangemodal").style.display = "none"; //valuesoutofrangemodal

//    return false;
//}

//function manufacturersRecommendedPressureValidation() {

//    if (parseFloat(document.getElementById('manufacturersRecommendedPressure').value) < 1 || parseFloat(document.getElementById('manufacturersRecommendedPressure').value) > 9)
//        document.getElementById('manufacturersRecommendedPressure').setCustomValidity("Please enter a value between 1.0 and 12.0 bar");

//    return;
//}

//function percentageDeviationAllowedValidation() {
//    if (parseFloat(document.getElementById('percentageDeviationAllowed').value) < 20 || parseFloat(document.getElementById('percentageDeviationAllowed').value) > 35)
//        document.getElementById('percentageDeviationAllowed').setCustomValidity("Please enter a value not less than 20%");

//    return;
//}

//async function setAxleSensorPressureMetricAttributes(valuesOutOfRangeParams) {

//    try {

//        global.pressureMetricAttributesUpdated = false;

//        let session = global.session;
//        session.loadLibrary("itemCustomFields");

//        let flags = wialon.util.Number.or(wialon.item.Item.dataFlag.base, wialon.item.Item.dataFlag.customFields, wialon.item.Item.dataFlag.adminFields);

//        let axleMRPValue = false;
//        let axlePPDValue = false;

//        return await new Promise((resolve, reject) => {
//            session.updateDataFlags(
//                [{ type: "type", data: "avl_unit", flags: flags, mode: 0 }],
//                (error) => {
//                    if (error) {
//                        console.log("wheelsdiagramsectionJS: setAxleSensorPressureMetricAttributes - ERROR " + error + " #" + wialon.core.Errors.getErrorText(error));
//                        handleError(error, globals.token, "setAxleSensorPressureMetricAttributes");
//                    }

//                    let unit = session.getItem(valuesOutOfRangeParams.id);
//                    let customFields = unit.getCustomFields();
//                    //cur_unit.updateCustomField({ id: prop_id, n: name, v: value });
//                    for (let cf in customFields) {  // construct select list

//                        if (customFields[cf].n.trim() === "A" + valuesOutOfRangeParams.axle + "MRP".trim()) {
//                            unit.updateCustomField({ id: customFields[cf].id, n: customFields[cf].n, v: valuesOutOfRangeParams.manufacturersRecommendedPressure });
//                            //axleSensorPressureMetricAttributes.manufacturersRecommendedPressure = customFields[cf].v;
//                            axleMRPValue = true;
//                        }

//                        if (customFields[cf].n.trim() === "A" + valuesOutOfRangeParams.axle + "PPD".trim()) {
//                            unit.updateCustomField({ id: customFields[cf].id, n: customFields[cf].n, v: valuesOutOfRangeParams.percentageDeviationAllowed });
//                            axlePPDValue = true;
//                        }
//                    }

//                    if (axleMRPValue && axlePPDValue)
//                        resolve(true);
//                }
//            );
//        });
//    } catch (e) {
//        console.error('wheelsdiagramsectionJS: setAxleSensorPressureMetricAttributes - ERROR: ', e);
//        handleError(e, globals.token, "setAxleSensorPressureMetricAttributes");
//    }
//}

function createSensorDiagramButtonsElement(unit) {

    let sensorDiagramButtonDivClasses = ['row', 'pane2buttons'];
    let sensorDiagramButtonDiv = document.createElement('div');
    sensorDiagramButtonDiv.classList.add(...sensorDiagramButtonDivClasses);
    let sensorDiagramButtonClasses = ['btn', 'btn-primary', 'panebutton', 'flex-child'];

    let sensorDiagramPrintButton = document.createElement('button');
    sensorDiagramPrintButton.classList.add(...sensorDiagramButtonClasses);
    sensorDiagramPrintButton.type = 'button';
    sensorDiagramPrintButton.title = 'PRINT SENSOR VALUES DIAGRAM (EXCEL EXPORT)';

    //let printImg = document.createElement('img');
    //printImg.src = "images/whiteprinter-24.png";
    ////printImg.src = "/src/components/unitDiagrams/images/whiteprinter-24.png";
    //sensorDiagramPrintButton.appendChild(printImg);

    const printIcon = document.createElement('svg');
    printIcon.className = 'buttonicon printbluesvg'; //class="button imgbutton buttontyre-disabled"

    const printButtonText = document.createElement('span');
    printButtonText.innerText = 'Print Diagram';

    sensorDiagramPrintButton.appendChild(printIcon);
    sensorDiagramPrintButton.appendChild(printButtonText);

    sensorDiagramPrintButton.onclick = function () {
        let sensorValuesDiagramOptions = {};
        gTyreIdCheck == null ? sensorValuesDiagramOptions.tyreIdCheck = false : sensorValuesDiagramOptions.tyreIdCheck = gTyreIdCheck;
        gTreadDepthCheck == null ? sensorValuesDiagramOptions.treadDepthCheck = false : sensorValuesDiagramOptions.treadDepthCheck = gTreadDepthCheck;
        //gSensorIdCheck == null ? sensorValuesDiagramOptions.sensorIdCheck = false : sensorValuesDiagramOptions.sensorIdCheck = gSensorIdCheck;
        gPressureCheck == null ? sensorValuesDiagramOptions.pressureCheck = true : sensorValuesDiagramOptions.pressureCheck = gPressureCheck;
        gTemperatureCheck == null ? sensorValuesDiagramOptions.temperatureCheck = false : sensorValuesDiagramOptions.temperatureCheck = gTemperatureCheck;
        gVoltageCheck == null ? sensorValuesDiagramOptions.voltageCheck = false : sensorValuesDiagramOptions.voltageCheck = gVoltageCheck;

        exportSensorValues(unit, sensorValuesDiagramOptions);
    };

    sensorDiagramButtonDiv.appendChild(sensorDiagramPrintButton);

    let sensorValuesDiagramSelectDateButton = document.createElement('button');
    sensorValuesDiagramSelectDateButton.classList.add(...sensorDiagramButtonClasses);
    sensorValuesDiagramSelectDateButton.type = 'button';
    sensorValuesDiagramSelectDateButton.title = 'SELECT HISTORY DATE';

    //let calendarImg = document.createElement('img');
    //calendarImg.src = "images/calendar-24.png";
    ////calendarImg.src = "/src/components/unitDiagrams/images/calendar-24.png";
    //sensorValuesDiagramSelectDateButton.appendChild(calendarImg);

    const calendarIcon = document.createElement('svg');
    calendarIcon.className = 'buttonicon calendarbluesvg'; //class="button imgbutton buttontyre-disabled"

    const selectDateButtonText = document.createElement('span');
    selectDateButtonText.innerText = 'History';

    sensorValuesDiagramSelectDateButton.appendChild(calendarIcon);
    sensorValuesDiagramSelectDateButton.appendChild(selectDateButtonText);

    sensorValuesDiagramSelectDateButton.onclick = function (e) {
        e.stopImmediatePropagation();
        openSelectSensorDiagramDateForm(unit);
    };

    sensorDiagramButtonDiv.appendChild(sensorValuesDiagramSelectDateButton);

    let sensorValuesDiagramReportButton = document.createElement('button');
    sensorValuesDiagramReportButton.classList.add(...sensorDiagramButtonClasses);
    sensorValuesDiagramReportButton.type = 'button';
    sensorValuesDiagramReportButton.title = 'VIEW CHART REPORTS';

    //let chartImg = document.createElement('img');
    //chartImg.src = "images/chart-24.png";
    //sensorValuesDiagramReportButton.appendChild(chartImg);

    const chartIcon = document.createElement('svg');
    chartIcon.className = 'buttonicon chartbluesvg'; //class="button imgbutton buttontyre-disabled"

    const reportButtonText = document.createElement('span');
    reportButtonText.innerText = 'Reports';

    sensorValuesDiagramReportButton.appendChild(chartIcon);
    sensorValuesDiagramReportButton.appendChild(reportButtonText);

    sensorValuesDiagramReportButton.onclick = async function (e) {
        e.stopImmediatePropagation();
        //document.body.style.cursor = 'wait';

        document.body.style.cursor = 'none';
        document.getElementById("loadingBoxText").innerText = "Collecting Report Data... ";
        $("#loadingBox").modal({
            backdrop: "static", //remove ability to close modal with click
            keyboard: false, //remove option to close with keyboard
            show: true //Display loader!
        });

        //let sensorValuesData = [];
        //sensorValuesData = await loadSensorValuesChartMessages(unit);
        const date = new Date();
        date.setDate(date.getDate() - 30); //week ago - subtract 7 days from the current date

        createDiagramView(unit, 'REPORTSDIAGRAM', 'chartunit');
        const sensorSummaryData = await getSensorSummaryData(unit, formatDate(date));
        
        console.log('function openSensorValuesChartReport [' + Date.now() + '] ');
        $("#loadingBox").modal("hide");
        openSensorValuesChartReport(unit, sensorSummaryData);
        return false;
    };

    sensorDiagramButtonDiv.appendChild(sensorValuesDiagramReportButton);

    return sensorDiagramButtonDiv;
}

function createSensorDiagramOptionsElement(unit) {

    let sensorDiagramOptionsDivClasses = ['form-check', 'form-check-inline'];
    let sensorDiagramOptionsInputClasses = ['form-check-input', 'custom-checkbox'];
    let sensorDiagramOptionsLabelClasses = ['form-check-label'];

    let sensorDiagramOptionsDiv = document.createElement('div');
    sensorDiagramOptionsDiv.className = 'sensordiagramoptions';

    let sensorDiagramIdsOptionsDiv = document.createElement('div');

    const sensorValuesDiagramSensorIdDiv = createSensorValuesDiagramSensorIdInfo(sensorDiagramOptionsDivClasses, sensorDiagramOptionsInputClasses, sensorDiagramOptionsLabelClasses);
    const sensorValuesDiagramTyreIdDiv = createSensorValuesDiagramTyreIdInfo(sensorDiagramOptionsDivClasses, sensorDiagramOptionsInputClasses, sensorDiagramOptionsLabelClasses);
    const sensorValuesDiagramTreadDepthDiv = createSensorValuesDiagramTreadDepthInfo(sensorDiagramOptionsDivClasses, sensorDiagramOptionsInputClasses, sensorDiagramOptionsLabelClasses);

    sensorDiagramIdsOptionsDiv.appendChild(sensorValuesDiagramSensorIdDiv);
    sensorDiagramIdsOptionsDiv.appendChild(sensorValuesDiagramTyreIdDiv);
    sensorDiagramIdsOptionsDiv.appendChild(sensorValuesDiagramTreadDepthDiv);

    const sensorDiagramMetricOptionsDiv = document.createElement('div');
    sensorDiagramMetricOptionsDiv.className = 'sensordiagrammetricoptions';

    const sensorValuesDiagramPressureMetricDiv = createSensorValuesDiagramPressureMetric(sensorDiagramOptionsDivClasses, sensorDiagramOptionsInputClasses, sensorDiagramOptionsLabelClasses);
    const sensorValuesDiagramTemperatureMetricDiv = createSensorValuesDiagramTemperatureMetric(sensorDiagramOptionsDivClasses, sensorDiagramOptionsInputClasses, sensorDiagramOptionsLabelClasses);
    const sensorValuesDiagramVoltageMetricDiv = createSensorValuesDiagramVoltageMetric(sensorDiagramOptionsDivClasses, sensorDiagramOptionsInputClasses, sensorDiagramOptionsLabelClasses);

    sensorDiagramMetricOptionsDiv.appendChild(sensorValuesDiagramPressureMetricDiv);
    sensorDiagramMetricOptionsDiv.appendChild(sensorValuesDiagramTemperatureMetricDiv);
    sensorDiagramMetricOptionsDiv.appendChild(sensorValuesDiagramVoltageMetricDiv);

    const sensorDiagramSelectedDateTimeDiv = document.createElement('div');    
    sensorDiagramSelectedDateTimeDiv.className = 'selecteddatetime';

    const sensorValuesDiagramSelectDateTimePar = document.createElement('div');
    sensorValuesDiagramSelectDateTimePar.id = 'sensorvaluesdiagramdate';
    if (global.sensorValuesDiagramDateSelected) {

        //sensorDiagramDateOptionDiv.className = 'sensordiagramdateoption'        
        sensorValuesDiagramSelectDateTimePar.innerText = formatDateTime(global.selectedDateTime);

        const resetButtonClasses = ['btn', 'btn-light', 'btn-sm'];

        const resetButton = document.createElement('button');
        resetButton.innerText = "x";
        resetButton.classList.add(...resetButtonClasses);

        resetButton.onclick = function () {
            resetButton;
            global.sensorValuesDiagramDateSelected = false;
            global.selectedDateTime = "";

            //loadSensorValuesDiagram(unit);
            createDiagramView(unit, 'SENSORVALUESDIAGRAM', 'middlepane');

        };

        sensorDiagramSelectedDateTimeDiv.appendChild(sensorValuesDiagramSelectDateTimePar);
        sensorDiagramSelectedDateTimeDiv.appendChild(resetButton);
    } else {
        const historyIcon = document.createElement('svg');
        historyIcon.className = 'historyicon';
        const lastUpdatedSpan = document.createElement('span');
        lastUpdatedSpan.innerText = "Last updated: ";
        lastUpdatedSpan.className = 'boldtext';
        sensorValuesDiagramSelectDateTimePar.innerText = unit.lastMessage;
        sensorDiagramSelectedDateTimeDiv.appendChild(historyIcon);
        sensorDiagramSelectedDateTimeDiv.appendChild(lastUpdatedSpan);
        sensorDiagramSelectedDateTimeDiv.appendChild(sensorValuesDiagramSelectDateTimePar);
    }

    sensorDiagramOptionsDiv.appendChild(sensorDiagramIdsOptionsDiv);
    sensorDiagramOptionsDiv.appendChild(sensorDiagramMetricOptionsDiv);

    sensorDiagramOptionsDiv.appendChild(sensorDiagramSelectedDateTimeDiv);

    sensorDiagramOptionsDiv.onclick = function () {
        gSensorIdCheck = sensorValuesDiagramSensorIdDiv.childNodes[0].checked;
        gTyreIdCheck = sensorValuesDiagramTyreIdDiv.childNodes[0].checked;
        gTreadDepthCheck = sensorValuesDiagramTreadDepthDiv.childNodes[0].checked;
        if (gTreadDepthCheck) gTyreIdCheck = true;
        gPressureCheck = sensorValuesDiagramPressureMetricDiv.childNodes[0].checked;
        gTemperatureCheck = sensorValuesDiagramTemperatureMetricDiv.childNodes[0].checked;
        gVoltageCheck = sensorValuesDiagramVoltageMetricDiv.childNodes[0].checked;
        createDiagramView(unit, 'SENSORVALUESDIAGRAM', 'middlepane');
    };

    return sensorDiagramOptionsDiv;
}

function createSensorValuesDiagramTyreIdInfo(sensorDiagramOptionsDivClasses, sensorDiagramOptionsInputClasses, sensorDiagramOptionsLabelClasses) {

    const sensorValuesDiagramTyreIdDiv = document.createElement('div');
    sensorValuesDiagramTyreIdDiv.classList.add(...sensorDiagramOptionsDivClasses);

    let sensorValuesDiagramTyreIdInput = document.createElement('input');
    sensorValuesDiagramTyreIdInput.id = 'sdTyreIdCheck';
    sensorValuesDiagramTyreIdInput.type = 'checkbox';

    if (!gTyreIdCheck || gTyreIdCheck == null)
        sensorValuesDiagramTyreIdInput.checked = false;
    else
        sensorValuesDiagramTyreIdInput.checked = true;

    sensorValuesDiagramTyreIdInput.classList.add(...sensorDiagramOptionsInputClasses);

    let sensorValuesDiagramTyreIdLabel = document.createElement('label');
    sensorValuesDiagramTyreIdLabel.id = 'sdTyreIdLabel';
    sensorValuesDiagramTyreIdLabel.innerText = 'Tyre ID';
    sensorValuesDiagramTyreIdLabel.classList.add(...sensorDiagramOptionsLabelClasses);

    sensorValuesDiagramTyreIdDiv.appendChild(sensorValuesDiagramTyreIdInput);
    sensorValuesDiagramTyreIdDiv.appendChild(sensorValuesDiagramTyreIdLabel);

    return sensorValuesDiagramTyreIdDiv;
}

function createSensorValuesDiagramTreadDepthInfo(sensorDiagramOptionsDivClasses, sensorDiagramOptionsInputClasses, sensorDiagramOptionsLabelClasses) {

    const sensorValuesDiagramTreadDepthDiv = document.createElement('div');
    sensorValuesDiagramTreadDepthDiv.classList.add(...sensorDiagramOptionsDivClasses);

    const sensorValuesDiagramTreadDepthInput = document.createElement('input');
    sensorValuesDiagramTreadDepthInput.id = 'sdTreadDepthCheck';
    sensorValuesDiagramTreadDepthInput.type = 'checkbox';

    if (!gTreadDepthCheck || gTreadDepthCheck == null)
        sensorValuesDiagramTreadDepthInput.checked = false;
    else {
        sensorValuesDiagramTreadDepthInput.checked = true;
    }

    sensorValuesDiagramTreadDepthInput.classList.add(...sensorDiagramOptionsInputClasses);

    const sensorValuesDiagramTreadDepthLabel = document.createElement('label');
    sensorValuesDiagramTreadDepthLabel.id = 'sdTreadDepthLabel';
    sensorValuesDiagramTreadDepthLabel.innerText = 'Tread Depth';
    sensorValuesDiagramTreadDepthLabel.classList.add(...sensorDiagramOptionsLabelClasses);

    sensorValuesDiagramTreadDepthDiv.appendChild(sensorValuesDiagramTreadDepthInput);
    sensorValuesDiagramTreadDepthDiv.appendChild(sensorValuesDiagramTreadDepthLabel);

    return sensorValuesDiagramTreadDepthDiv;
}

function createSensorValuesDiagramSensorIdInfo(sensorDiagramOptionsDivClasses, sensorDiagramOptionsInputClasses, sensorDiagramOptionsLabelClasses) {

    let sensorValuesDiagramSensorIdDiv = document.createElement('div');
    sensorValuesDiagramSensorIdDiv.classList.add(...sensorDiagramOptionsDivClasses);

    let sensorDiagramSensorIdInput = document.createElement('input');
    sensorDiagramSensorIdInput.id = 'sdSensorIdCheck';
    sensorDiagramSensorIdInput.type = 'checkbox';
    if (!gSensorIdCheck || gSensorIdCheck == null)
        sensorDiagramSensorIdInput.checked = false;
    else
        sensorDiagramSensorIdInput.checked = true;

    sensorDiagramSensorIdInput.classList.add(...sensorDiagramOptionsInputClasses);

    let sensorDiagramSensorIdLabel = document.createElement('label');
    sensorDiagramSensorIdLabel.id = 'sdSensorIdLabel';
    sensorDiagramSensorIdLabel.innerText = 'Sensor ID';
    sensorDiagramSensorIdLabel.classList.add(...sensorDiagramOptionsLabelClasses);

    sensorValuesDiagramSensorIdDiv.appendChild(sensorDiagramSensorIdInput);
    sensorValuesDiagramSensorIdDiv.appendChild(sensorDiagramSensorIdLabel);

    return sensorValuesDiagramSensorIdDiv;
}

function createSensorValuesDiagramPressureMetric(sensorDiagramOptionsDivClasses, sensorDiagramOptionsInputClasses, sensorDiagramOptionsLabelClasses) {

    let sensorValuesDiagramPressureMetricDiv = document.createElement('div');
    sensorValuesDiagramPressureMetricDiv.classList.add(...sensorDiagramOptionsDivClasses);

    let sensorValuesDiagramPressureMetricInput = document.createElement('input');
    sensorValuesDiagramPressureMetricInput.id = 'sdPressureCheck';
    sensorValuesDiagramPressureMetricInput.type = 'checkbox';

    if (gPressureCheck || gPressureCheck == null)
        sensorValuesDiagramPressureMetricInput.checked = true;
    else
        sensorValuesDiagramPressureMetricInput.checked = false;
    sensorValuesDiagramPressureMetricInput.classList.add(...sensorDiagramOptionsInputClasses);

    let sensorValuesDiagramPressureMetricLabel = document.createElement('label');
    sensorValuesDiagramPressureMetricLabel.id = 'sdPressureLabel';
    sensorValuesDiagramPressureMetricLabel.innerHTML = 'Pressure';
    sensorValuesDiagramPressureMetricLabel.classList.add(...sensorDiagramOptionsLabelClasses);

    sensorValuesDiagramPressureMetricDiv.appendChild(sensorValuesDiagramPressureMetricInput);
    sensorValuesDiagramPressureMetricDiv.appendChild(sensorValuesDiagramPressureMetricLabel);

    return sensorValuesDiagramPressureMetricDiv;
}

function createSensorValuesDiagramTemperatureMetric(sensorDiagramOptionsDivClasses, sensorDiagramOptionsInputClasses, sensorDiagramOptionsLabelClasses) {

    let sensorValuesDiagramTemperatureMetricDiv = document.createElement('div');
    sensorValuesDiagramTemperatureMetricDiv.classList.add(...sensorDiagramOptionsDivClasses);

    let sensorDiagramTemperatureMetricInput = document.createElement('input');
    sensorDiagramTemperatureMetricInput.id = 'sdTemperatureCheck';
    sensorDiagramTemperatureMetricInput.type = 'checkbox';
    if (!gTemperatureCheck || gTemperatureCheck == null)
        sensorDiagramTemperatureMetricInput.checked = false;
    else
        sensorDiagramTemperatureMetricInput.checked = true;

    sensorDiagramTemperatureMetricInput.classList.add(...sensorDiagramOptionsInputClasses);

    let sensorDiagramTemperatureMetricLabel = document.createElement('label');
    sensorDiagramTemperatureMetricLabel.id = 'sdTemperatureLabel';
    sensorDiagramTemperatureMetricLabel.innerHTML = 'Temperature';
    sensorDiagramTemperatureMetricLabel.classList.add(...sensorDiagramOptionsLabelClasses);

    sensorValuesDiagramTemperatureMetricDiv.appendChild(sensorDiagramTemperatureMetricInput);
    sensorValuesDiagramTemperatureMetricDiv.appendChild(sensorDiagramTemperatureMetricLabel);

    return sensorValuesDiagramTemperatureMetricDiv;
}

function createSensorValuesDiagramVoltageMetric(sensorDiagramOptionsDivClasses, sensorDiagramOptionsInputClasses, sensorDiagramOptionsLabelClasses) {

    let sensorValuesDiagramVoltageMetricDiv = document.createElement('div');
    sensorValuesDiagramVoltageMetricDiv.classList.add(...sensorDiagramOptionsDivClasses);

    let sensorValuesDiagramVoltageMetricInput = document.createElement('input');
    sensorValuesDiagramVoltageMetricInput.id = 'sdVoltageCheck';
    sensorValuesDiagramVoltageMetricInput.type = 'checkbox';

    if (!gVoltageCheck || gVoltageCheck == null)
        sensorValuesDiagramVoltageMetricInput.checked = false;
    else
        sensorValuesDiagramVoltageMetricInput.checked = true;

    sensorValuesDiagramVoltageMetricInput.classList.add(...sensorDiagramOptionsInputClasses);

    let sensorValuesDiagramVoltageMetricLabel = document.createElement('label');
    sensorValuesDiagramVoltageMetricLabel.id = 'sdVoltageLabel';
    sensorValuesDiagramVoltageMetricLabel.innerHTML = 'Voltage';
    sensorValuesDiagramVoltageMetricLabel.classList.add(...sensorDiagramOptionsLabelClasses);

    sensorValuesDiagramVoltageMetricDiv.appendChild(sensorValuesDiagramVoltageMetricInput);
    sensorValuesDiagramVoltageMetricDiv.appendChild(sensorValuesDiagramVoltageMetricLabel);

    return sensorValuesDiagramVoltageMetricDiv;
}

export { createDiagramView };