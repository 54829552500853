import { SPARE_AXLE } from "../../global";

import { addLeadingZero, formatDateTime, convertUnixTime } from "../../utilities";

import { addTrailer } from "../../data/unitdata";
import { getUnitData } from "../../data/getunitdata"
import { displayUnitData, displayDiagramViews } from "../detailpage";
import { displayUnitInfoView } from "./unitinfo";

import { getUnitGroupName, registerUnitsGroupsEvents, displayMainUnitsGroupsList } from "./unitgroups";
import { displayStatusSummary, loadUnitStatusDiagram } from "./unitdiagrams/unitstatusesdiagrams";

export async function getUnits() {

    let units = await getUnitData("*");
    global.units = units;

    units = getUnitConfigs(units);
    units = getSensorValueFlags(units);

    setMainInterval(units);
}

export async function setMainInterval(units) {
    
    main(units).catch((error) => {
        if (error.type === 'API_ERROR') {
            let reason = error.fullError && error.fullError.reason;
            logmsg(
                wialon.core.Errors.getErrorText(error.code) +
                (reason ? ' ' + reason : '')
            );
            console.log("pressuretrackJS: getUnits(main) - API ERROR " + error + " #" + wialon.core.Errors.getErrorText(error)) + ")";
        } else {
            logmsg('Detailed error in console: ' + error);
            console.log("pressuretrackJS: getUnits(main) - ERROR " + error + ")");
            console.error(error);
        }
    });

    if (global.mainIntervalId) {
        clearInterval(global.mainIntervalId);
    }

    global.mainIntervalId = setInterval(async () => {
        units = await getUnitData("*");

        units = await getUnitConfigs(units);
        units = getSensorValueFlags(units);

        global.units = units;

        main(units).catch((error) => {
            if (error.type === 'API_ERROR') {
                let reason = error.fullError && error.fullError.reason;
                logmsg(
                    wialon.core.Errors.getErrorText(error.code) +
                    (reason ? ' ' + reason : '')
                );
                console.log("pressuretrackJS: getUnits(main) - API ERROR " + error + " #" + wialon.core.Errors.getErrorText(error)) + ")";
            } else {
                logmsg('Detailed error in console: ' + error);
                console.log("pressuretrackJS: getUnits(main) - ERROR " + error + ")");
                console.error(error);
            }
        });
    }, 45000);
}

async function main(units) {    

    try {        
        if (global.showMainPane) {
            const resource = global.resource;
            for (let u = 0; u < units.length; u++) {
                units[u].trailers = await getUnitTrailers(resource, units[u]);
            }
            displayMainPane(units);
        } else {
            if (global.mainIntervalId) {
                clearInterval(global.mainIntervalId);
                //console.log(`Clear MAIN Interval Id: ${global.mainIntervalId}`);
            }
            displayUnitData(global.selectedUnitId);
        }
        global.units = units;
    } catch (e) {
        console.error('unitsJS: main - ERROR: ', e);
        setMainInterval(global.units);
    }
}

async function getPressureMetricAttributes(units) {

    for (let u = 0; u < units.length; u++) {

        getUnitPressureMetricAttributes(units[u]);

        ////const wUnit = global.session.getItem(units[u].id);
        //const axlesUnit = [...new Set(Object.keys(units[u].wheelSensors).map(item => units[u].wheelSensors[item].sensorName.slice(1)))];
        ////let pressureMetricAttributes = await getAxleSensorPressureMetricAttributes(wUnit, axlesUnit);
        //let pressureMetricAttributes = await getAxleSensorPressureMetricAttributes(units[u].id, axlesUnit);

        //for (let s in units[u].wheelSensors) {
        //    //wheelSensor.minPressureValue = 1;
        //    //wheelSensor.maxPressureValue = 11;
        //    //wheelSensor.maxTemperatureValue = 85;
        //    //wheelSensor.minVoltageValue = 3.000;
        //    if (units[u].wheelSensors[s]) {

        //        //wheelSensor.axleSensorPressureMetricAttributes  = await getAxleSensorPressureMetricAttributes(unit.id, sensors[s].n.slice(1, 2));
        //        //axleSensorPressureMetricAttributes.percentageDeviationAllowed = await getAxleSensorPPDAttribute();

        //        let axleUnit = units[u].wheelSensors[s].sensorName.slice(1);

        //        for (let pma = 0; pma < pressureMetricAttributes.length; pma++) {
        //            if (pressureMetricAttributes[pma].axleUnit === axleUnit) {
        //                units[u].wheelSensors[s].manufacturersRecommendedPressure = pressureMetricAttributes[pma].manufacturersRecommendedPressure;
        //                units[u].wheelSensors[s].percentageDeviationAllowed = pressureMetricAttributes[pma].percentageDeviationAllowed;
        //                break;
        //            }
        //        }
        //    }
        //}

        //for (let s in units[u].spareSensors) {
        //    if (units[u].spareSensors[s]) {

        //        //wheelSensor.axleSensorPressureMetricAttributes  = await getAxleSensorPressureMetricAttributes(unit.id, sensors[s].n.slice(1, 2));
        //        //axleSensorPressureMetricAttributes.percentageDeviationAllowed = await getAxleSensorPPDAttribute();

        //        let axleUnit = units[u].spareSensors[s].sensorName.slice(1);

        //        for (let pma = 0; pma < pressureMetricAttributes.length; pma++) {
        //            if (pressureMetricAttributes[pma].axleUnit === axleUnit) {
        //                units[u].spareSensors[s].manufacturersRecommendedPressure = pressureMetricAttributes[pma].manufacturersRecommendedPressure;
        //                units[u].spareSensors[s].percentageDeviationAllowed = pressureMetricAttributes[pma].percentageDeviationAllowed;
        //                break;
        //            }
        //        }
        //    }
        //}
    }
    global.pressureMetricAttributesUpdated = true;
}

export async function getUnitPressureMetricAttributes(unit) {

        //const wUnit = global.session.getItem(unit.id);
        const axlesUnit = [...new Set(Object.keys(unit.wheelSensors).map(item => unit.wheelSensors[item].sensorName.slice(1)))];
        //let pressureMetricAttributes = await getAxleSensorPressureMetricAttributes(wUnit, axlesUnit);
        let pressureMetricAttributes = await getAxleSensorPressureMetricAttributes(unit.id, axlesUnit);

        for (let s in unit.wheelSensors) {
            //wheelSensor.minPressureValue = 1;
            //wheelSensor.maxPressureValue = 11;
            //wheelSensor.maxTemperatureValue = 85;
            //wheelSensor.minVoltageValue = 3.000;
            if (unit.wheelSensors[s]) {

                //wheelSensor.axleSensorPressureMetricAttributes  = await getAxleSensorPressureMetricAttributes(unit.id, sensors[s].n.slice(1, 2));
                //axleSensorPressureMetricAttributes.percentageDeviationAllowed = await getAxleSensorPPDAttribute();

                const axleUnit = unit.wheelSensors[s].sensorName.slice(1);

                for (let pma = 0; pma < pressureMetricAttributes.length; pma++) {
                    if (pressureMetricAttributes[pma].axleUnit === axleUnit) {
                        unit.wheelSensors[s].manufacturersRecommendedPressure = pressureMetricAttributes[pma].manufacturersRecommendedPressure;
                        unit.wheelSensors[s].percentageDeviationAllowed = pressureMetricAttributes[pma].percentageDeviationAllowed;
                        break;
                    }
                }
            }
        }

        for (let s in unit.spareSensors) {
            if (unit.spareSensors[s]) {

                //wheelSensor.axleSensorPressureMetricAttributes  = await getAxleSensorPressureMetricAttributes(unit.id, sensors[s].n.slice(1, 2));
                //axleSensorPressureMetricAttributes.percentageDeviationAllowed = await getAxleSensorPPDAttribute();

                const axleUnit = unit.spareSensors[s].sensorName.slice(1);

                for (let pma = 0; pma < pressureMetricAttributes.length; pma++) {
                    if (pressureMetricAttributes[pma].axleUnit === axleUnit) {
                        unit.spareSensors[s].manufacturersRecommendedPressure = pressureMetricAttributes[pma].manufacturersRecommendedPressure;
                        unit.spareSensors[s].percentageDeviationAllowed = pressureMetricAttributes[pma].percentageDeviationAllowed;
                        break;
                    }
                }
            }
        }
}

async function getAxleSensorPressureMetricAttributes(unitId, axlesUnit) {
    try {

        axlesUnit.push(SPARE_AXLE + "0");

        let session = global.session;
        if (!session) session = wialon.core.Session.getInstance();
        session.loadLibrary("itemCustomFields");
        var flags = wialon.util.Number.or(wialon.item.Item.dataFlag.base, wialon.item.Item.dataFlag.customFields, wialon.item.Item.dataFlag.adminFields);

        return new Promise((resolve, reject) => {
            session.updateDataFlags(
                [{ type: "type", data: "avl_unit", flags: flags, mode: 0 }],
                (error) => {

                    if (error) {
                        console.log("unitsJS: getAxleSensorPressureMetricAttributes - API ERROR " + error + " (" + wialon.core.Errors.getErrorText(error)) + ")";
                        reject({ type: 'API_ERROR', error: error });
                        return;
                    }

                    const wUnit = session.getItem(unitId);
                    let customFields = wUnit.getCustomFields();

                    let axlesPressureMetricAttributes = [];

                    for (let a = 0; a < axlesUnit.length; a++) {

                        let axlePressureMetricAttributes = {};
                        axlePressureMetricAttributes.axleUnit = axlesUnit[a];

                        let axleMRPValue = false;
                        let axlePPDValue = false;

                        for (let cf in customFields) {

                            if (customFields[cf].n.trim() === `${axlesUnit[a]}MRP`) {
                                
                                axlePressureMetricAttributes.manufacturersRecommendedPressure = customFields[cf].v;
                                axleMRPValue = true;
                            }

                            if (customFields[cf].n.trim() === `${axlesUnit[a]}PPD`) {
                                axlePressureMetricAttributes.percentageDeviationAllowed = customFields[cf].v;
                                axlePPDValue = true;
                            }

                            if (axleMRPValue && axlePPDValue) break;
                            //resolve(axleSensorPressureMetricAttributes);
                        }

                        if (!axleMRPValue && !axlePPDValue) {
                            wUnit.createCustomField({ n: `${axlesUnit[a]}MRP`, v: "" });
                            axlePressureMetricAttributes.manufacturersRecommendedPressure = "";
                            wUnit.createCustomField({ n: `${axlesUnit[a]}PPD`, v: "" });
                            axlePressureMetricAttributes.percentageDeviationAllowed = "";
                        }

                        axlesPressureMetricAttributes.push(axlePressureMetricAttributes);
                        //resolve(axleSensorPressureMetricAttributes);
                    }

                    resolve(axlesPressureMetricAttributes);
                }
            );
        });
    } catch (e) {
        console.error('unitsJS: getAxleSensorPressureMetricAttributes - ERROR: ', e);
        setMainInterval(global.units);
    }
}

export async function getCurrentAxleSensorPressureMetricAttributes(unitId, axleUnit) {
    try {

        const session = global.session;
        if (!session) session = wialon.core.Session.getInstance();
        session.loadLibrary("itemCustomFields");
        var flags = wialon.util.Number.or(wialon.item.Item.dataFlag.base, wialon.item.Item.dataFlag.customFields, wialon.item.Item.dataFlag.adminFields);

        return new Promise((resolve, reject) => {
            session.updateDataFlags(
                [{ type: "type", data: "avl_unit", flags: flags, mode: 0 }],
                (error) => {

                    if (error) {
                        console.log("unitsJS: getCurrentAxleSensorPressureMetricAttributes - API ERROR " + error + " (" + wialon.core.Errors.getErrorText(error)) + ")";
                        reject({ type: 'API_ERROR', error: error });
                        return;
                    }

                    const wUnit = session.getItem(unitId);
                    let customFields = wUnit.getCustomFields();

                    let axlePressureMetricAttributes = {};
                    axlePressureMetricAttributes.axleUnit = axleUnit;

                    let axleMRPValue = false;
                    let axlePPDValue = false;

                    for (let cf in customFields) {

                        if (customFields[cf].n.trim() === `${axleUnit}MRP`) {
                            axlePressureMetricAttributes.manufacturersRecommendedPressure = customFields[cf].v;
                            axleMRPValue = true;
                        }

                        if (customFields[cf].n.trim() === `${axleUnit}PPD`) {
                            axlePressureMetricAttributes.percentageDeviationAllowed = customFields[cf].v;
                            axlePPDValue = true;
                        }

                        if (axleMRPValue && axlePPDValue) break;
                        //resolve(axleSensorPressureMetricAttributes);
                    }

                    if (!axleMRPValue && !axlePPDValue) {
                        axlePressureMetricAttributes.manufacturersRecommendedPressure = "";
                        axlePressureMetricAttributes.percentageDeviationAllowed = "";
                    }

                    resolve(axlePressureMetricAttributes);
                }
            );
        });
    } catch (e) {
        console.error('unitsJS: getAxleSensorPressureMetricAttributes - ERROR: ', e);
        setMainInterval(global.units);
    }
}

function getSensorValueFlags(units) {

    for (let u = 0; u < units.length; u++) {
        units[u] = getUnitSensorValueFlags(units[u]);

        //let purpleflags = 0, blinkflags = 0, redflags = 0, orangeflags = 0, yellowflags = 0, blueflags = 0;
        //const sensors = [...units[u].wheelSensors, ...units[u].spareSensors]
        //for (let s = 0; s < sensors.length; s++) {
        //    if (parseFloat(sensors[s].pressureValue) > parseFloat(sensors[s].maxPressureValue))
        //        purpleflags++;
        //    if (sensors[s].sensorName.slice(1, 3) !== SPARE_AXLE.toString() && parseFloat(sensors[s].pressureValue) < parseFloat(sensors[s].manufacturersRecommendedPressure) * 20 / 100)
        //        blinkflags++;
        //    if (parseFloat(sensors[s].pressureValue) < parseFloat(sensors[s].minPressureValue))
        //        redflags++;
        //    if (parseFloat(sensors[s].temperatureValue) > parseFloat(sensors[s].maxTemperatureValue))
        //        orangeflags++;
        //    if (parseFloat(sensors[s].voltageValue) < parseFloat(sensors[s].minVoltageValue))
        //        yellowflags++;
        //    if (sensors[s].pressureValue == null && sensors[s].temperatureValue == null && sensors[s].voltageValue == null)
        //        blueflags++;
        //    if (sensors[s].pressureValue == null && sensors[s].temperatureValue == null && sensors[s].voltageValue == null)
        //        blueflags++;
        //}
        //blueflags = sensors.length < units[u].sensorCount
        //    ? units[u].sensorCount - sensors.length
        //    : blueflags; //: 0

        //units[u].grayFlags = blueflags;
        //units[u].purpleFlags = purpleflags;
        //units[u].blinkFlags = blinkflags;
        //units[u].redFlags = redflags;
        //units[u].orangeFlags = orangeflags;
        //units[u].yellowFlags = yellowflags;
    }

    return units;
}

function getUnitSensorValueFlags(unit) {

        let purpleflags = 0, blinkflags = 0, redflags = 0, orangeflags = 0, yellowflags = 0, blueflags = 0;
        const sensors = [...unit.wheelSensors, ...unit.spareSensors];
        for (let s = 0; s < sensors.length; s++) {
            if (parseFloat(sensors[s].pressureValue) > parseFloat(sensors[s].maxPressureValue))
                purpleflags++;
            if (sensors[s].sensorName.slice(1, 3) !== SPARE_AXLE.toString() && parseFloat(sensors[s].pressureValue) < parseFloat(sensors[s].manufacturersRecommendedPressure) * 20 / 100)
                blinkflags++;
            if (parseFloat(sensors[s].pressureValue) < parseFloat(sensors[s].minPressureValue))
                redflags++;
            if (parseFloat(sensors[s].temperatureValue) > parseFloat(sensors[s].maxTemperatureValue))
                orangeflags++;
            if (parseFloat(sensors[s].voltageValue) < parseFloat(sensors[s].minVoltageValue))
                yellowflags++;
            if (sensors[s].pressureValue == null && sensors[s].temperatureValue == null && sensors[s].voltageValue == null)
                blueflags++;
            if (sensors[s].pressureValue == null && sensors[s].temperatureValue == null && sensors[s].voltageValue == null)
                blueflags++;
        }
        blueflags = sensors.length < unit.sensorCount
            ? unit.sensorCount - sensors.length
            : blueflags; //: 0

        unit.grayFlags = blueflags;
        unit.purpleFlags = purpleflags;
        unit.blinkFlags = blinkflags;
        unit.redFlags = redflags;
        unit.orangeFlags = orangeflags;
        unit.yellowFlags = yellowflags;

    return unit;
}

function getUnitConfigs(units) {
    for (let u = 0; u < units.length; u++) {
        //if (units[u].name === "Test Wialon IPS 2" || units[u].name === "GalileoSky7x") {
            const unitConfig = getUnitConfig(units[u]);
            units[u].sensorCount = countUnitSensors(unitConfig);
            units[u].unitConfig = unitConfig;
        //}
    }
    return units;
}

function countUnitSensors(unitConfig) {
    let sensorCount = 0;
    for (let u = 0; u < unitConfig.length; u++) {
        sensorCount = sensorCount + unitConfig[u].sensorCount;
    }
    return sensorCount;
}

function getUnitConfig(unit) {

    let unitConfig = [];

    const wheelSensorUnitNumbers = [...new Set(unit.wheelSensors.map(item => parseInt(item.sensorName.slice(-1))))];
    let maxUnitNumber = Math.max(...wheelSensorUnitNumbers.map(Number));
    const maxLocationSensorUnitNumber = Math.max(...[...new Set(unit.locationSensors.map(item => item.sensorName.slice(-1)))].map(Number));

    if (maxUnitNumber < maxLocationSensorUnitNumber) maxUnitNumber = maxLocationSensorUnitNumber;
    const unitNumbers = Array.from({ length: maxUnitNumber + 1 }, (_, index) => index.toString()).sort();

    for (let un = 0; un < unitNumbers.length; un++) {
        unitConfig.push(getUnitNumberConfig(unit, unitNumbers[un]));
    }

    return unitConfig;

}

function getUnitNumberConfig(unit, unitNumber) {
    const unitNumberConfig = {};
    unitNumberConfig.unitNumber = unitNumber;
    unitNumberConfig.name = `unit ${unitNumber}`;
    const axles = getAxles(unit, unitNumber);
    unitNumberConfig.axles = axles;
    
    unitNumberConfig.axleCount = axles.length;
    unitNumberConfig.sensorCount = countAxleSensors(axles);
    return unitNumberConfig;
}

function countAxleSensors(axles) {
    let sensorCount = 0;
    for (let a = 0; a < axles.length; a++) {
        if (axles[a].wheelConfig === 0) sensorCount = sensorCount + 2;
        else sensorCount = sensorCount + 4;
    }
    return sensorCount;
}

function getAxles(unit, unitNumber) {
    const wheelSensorAxles = [...new Set(unit.wheelSensors
        .filter(item => item.sensorName.slice(-1) === unitNumber.toString())
        .map(item => item.sensorName.slice(1, 3)))];
    let maxAxles = Math.max(...wheelSensorAxles.map(Number));
    // && item.sensorName.slice(1, 3) !== SPARE_AXLE.toString()
    const maxLocationSensorAxles = Math.max(...[...new Set(unit.locationSensors
        .filter(item => item.sensorName.slice(-1) === unitNumber.toString())
        .map(item => item.sensorName.slice(1, 3)))].map(Number));

    if (maxAxles < maxLocationSensorAxles) maxAxles = maxLocationSensorAxles;
    const axleNumbers = Array.from({ length: maxAxles }, (_, index) => addLeadingZero(index + 1)).sort();
    const axles = [];
    for (let a = 0; a < axleNumbers.length; a++) {
        axles.push(getAxleWheelConfig(unit, unitNumber, axleNumbers[a]));
    }

    return axles;
}

function getAxleWheelConfig(unit, unitNumber, axle) {
    const axleWheelConfig = {};
    axleWheelConfig.axle = axle;
    axleWheelConfig.wheelConfig = getWheelConfig(unit, unitNumber, axle);
    return axleWheelConfig;
}

function getWheelConfig(unit, unitNumber, axle) {

    let wheelConfig = unit.locationSensors.filter(s => s.sensorName === `l${axle}${unitNumber}`)[0]?.wheelConfig || unit.locationSensors.filter(s => s.sensorName === `r${axle}${unitNumber}`)[0]?.wheelConfig;

    if (wheelConfig == null) {
        wheelConfig = 0;
        const wheels = [...new Set(unit.wheelSensors
            .filter(item => item.sensorName.slice(-1) === unitNumber && item.sensorName.slice(1, 3) === axle)
            .map(item => item.sensorName.slice(0, 1)))];

        if (wheels.length > 2) {
            wheelConfig = 1;
        } else {
            const wheelsToCheck = ["2", "3"];
            if (wheelsToCheck.some(wheel => wheels.includes(wheel))) {
                wheelConfig = 1;
            }
        }
    }

    return wheelConfig;
}

//export function getUnitNumbers(unit) {
//    const unitNumbers = [...new Set(Object.keys(unit.wheelSensors).map(item => unit.wheelSensors[item].sensorName.slice(3, 4)))].sort();

//    return unitNumbers;
//}

export function getUnitAxles(unitNumber, wheelSensors) {

    let unitAxles = [];
    for (let ws = 0; ws < wheelSensors.length; ws++) {
        if (wheelSensors[ws].sensorName.slice(3, 4) === unitNumber.toString())
            unitAxles.push(wheelSensors[ws].sensorName.slice(1, 3));
    }
    // && wheelSensors[ws].sensorName.slice(1, 3) !== SPARE_AXLE.toString()
    return [...new Set(unitAxles)].sort();
}


export function getUnitAxleSensors(wheelSensors, axleUnit) {

    return wheelSensors.filter(s => s.sensorName.slice(-3) === axleUnit);
    
}

//export function getAxleWheelConfig(locationSensors, axleSensors) {
//    return locationSensors.filter(s => s.sensorName.slice(1) === axleSensors[0].sensorName.slice(1))[0]?.wheelConfig || -1;
//}

export function getUnitSpareSensors(wheelSensors) {
    return wheelSensors.filter(s => parseInt(s.sensorName.slice(-3)) === "170");
}

async function getUnitTrailers(resource, unit) {

    const resourceTrailers = resource.getTrailers();
    let trailerCodes = ["011", "012", "013", "014", "015"];
    let newTrailers = [];
    for (let l = 0; l < unit.locationSensors.length; l++) {
        let repeaterFound = false;
        if (!repeaterFound && trailerCodes.includes(unit?.locationSensors[l].sensorName.slice(1))) {
            repeaterFound = Object.keys(resourceTrailers).some(trailer => {
                const unitNameTrailerNo = resourceTrailers[trailer].ds;
                return unitNameTrailerNo === `${unit.name}-${unit.locationSensors[l].sensorName.slice(-1)}`;
            });
            //Add trailer
            if (!repeaterFound) {
                repeaterFound = true;
                let index = trailerCodes.findIndex(code => code.slice(-1) === unit.locationSensors[l].sensorName.slice(-1));
                if (index !== -1) {
                    // Remove the matched trailer code from the array
                    trailerCodes.splice(index, 1);
                }
                const trailer = {};
                trailer.name = `Trailer ${unit.locationSensors[l].sensorName.slice(-1)}`;
                trailer.order = parseInt(unit.locationSensors[l].sensorName.slice(-1));
                trailer.sensorId = unit.locationSensors[l].sensorId;
                trailer.unitLinkedTo = unit.name;
                const addedTrailer = await addTrailer(trailer);
                trailer.id = addedTrailer[0];
                newTrailers.push(trailer);
            }
        }
    }

    //if (uId === trl.bu) {
    //    const unitTrailer = {};
    //    unitTrailer.unitLinkedToId = uId;
    //    unitTrailer.trailerId = trl.id;
    //    unitTrailer.name = trl.n;
    //    unitTrailers.push(unitTrailer);
    //    break;
    //}

    const rTrailersUpdated = resource.getTrailers();
    let trailers = [];
    for (let t in rTrailersUpdated) {
        const trl = rTrailersUpdated[t];

        if (unit.name === trl.ds.slice(0, -2)) {
            const trailer = {};
            trailer.id = trl.id;
            trailer.name = trl.n;
            trailer.sensorId = trl.c;
            trailer.unitLinkedTo = unit.name;
            trailer.order = parseInt(trl.ds.slice(-1));
            trailers.push(trailer);
        }
    }

    return trailers;
    //    trailers = mergeTrailers(trailers, newTrailers);
    //    units[un].trailers = trailers;
    //    units[un].unitTrailers = unitTrailers;
    //}

    //for (let i = 0; i < units.length; i++) {
    //    let unitTrailersFound = [];
    //    for (let t = 0; t < unitTrailers.length; t++) {
    //        let uTrailer = unitTrailers[t];
    //        if (uTrailer.name.trim() === units[i].name.trim()) {
    //            uTrailer.id = units[i].id;
    //            uTrailer.unitIcon = units[i].unitIcon;
    //            uTrailer.mobileNo = units[i].mobileNo;
    //            uTrailer.sensors = units[i].sensors;
    //        }
    //        if (uTrailer.unitLinkedToId === units[i].id) {
    //            unitTrailersFound.push(uTrailer);
    //        }
    //    }

    //    units[i].unitTrailers = unitTrailersFound;
    //}

}

function getWheelProperties(sensors) {

    try { 
        if (sensors.length > 0) {
            for (let s = 0; s < sensors.length; s++) {
                sensors[s].wheelType = "wheelactive";
                sensors[s].sensorType = "";
                if (parseInt(sensors[s].sensorName.axle) !== SPARE_AXLE)
                    sensors[s].tyreName = "A" + sensors[s].sensorName.slice(1, 3).replace(/^0+/, "") + "-T" + sensors[s].sensorName.slice(0, 1);
                else
                    sensors[s].tyreName = `S-${sensors[s].sensorName.slice(0, 1)}`;
                sensors[s].pressureLabelColour = "green";
                sensors[s].temperatureLabelColour = "green";
                sensors[s].voltageLabelColour = "green";
                sensors[s].noSignalLabelColour = "green";

                if (sensors[s].pressureValue == null && sensors[s].temperatureValue == null && sensors[s].voltageValue == null) {
                    sensors[s].sensorType = "wheelblue";
                    sensors[s].noSignalLabelColour = "blue";
                }

                if (parseFloat(sensors[s].pressureValue) > parseFloat(sensors[s].maxPressureValue)) {
                    sensors[s].sensorType = "wheelpurple";
                    sensors[s].pressureLabelColour = "purple";
                }

                if (parseFloat(sensors[s].pressureValue) < parseFloat(sensors[s].minPressureValue)) {
                    sensors[s].sensorType = "wheelred";
                    if (parseInt(sensors[s].sensorName.slice(1, 3)) !== SPARE_AXLE && (parseFloat(sensors[s].pressureValue) < parseFloat(sensors[s].manufacturersRecommendedPressure) * 20/100)) {
                        sensors[s].sensorType = "wheelblink"; 
                    }
                    sensors[s].pressureLabelColour = "red";
                }

                if (parseInt(sensors[s].temperatureValue) > parseInt(sensors[s].maxTemperatureValue)) {
                    if (sensors[s].sensorType !== "wheelpurple" && sensors[s].sensorType !== "wheelred") {
                        sensors[s].sensorType = "wheelorange";
                    }
                    sensors[s].temperatureLabelColour = "orange";
                }

                if (parseFloat(sensors[s].voltageValue) < parseFloat(sensors[s].minVoltageValue)) {
                    if (sensors[s].sensorType !== "wheelpurple" && sensors[s].sensorType !== "wheelred" && sensors[s].sensorType !== "wheelorange") {
                        sensors[s].sensorType = "wheelyellow";
                    }
                    sensors[s].voltageLabelColour = "yellow";
                }
            }
        }

        return sensors;

    } catch (e) {        
        console.error('unitsJS: getWheelProperties - ERROR: ', e);
        setMainInterval(global.units);
    }
}

function getTyreArrayIndex(tyre, axleSensors) {

    let tyreArrayIndex = 0;
    for (let s = 0; s < axleSensors.length; s++) {
        if (parseInt(axleSensors[s].sensorName.slice(0, 1)) === tyre) {
            tyreArrayIndex = s;
            break;
        }
    }
    return tyreArrayIndex;
}

function loadSensorValuesBatch(prms) {

    let remote = wialon.core.Remote.getInstance();

    return new Promise((resolve, reject) => {
        remote.remoteCall('core/batch', prms,
            (error, result) => {
                if (error) {
                    reject({ type: 'API_ERROR', code: error, fullError: result });
                    return;
                }
                resolve(result);
            },
        );
    });
}

function displayMainUnitsList(units) {

    try {

        let unitsListMain = document.getElementById('unitslistmain');
        while (unitsListMain.hasChildNodes()) {
            unitsListMain.removeChild(unitsListMain.lastChild);
        }

        let unitsListFragment = document.createDocumentFragment();
        let unitsListContainerDiv = createUnitsListContainer();
        unitsListFragment.appendChild(unitsListContainerDiv);

        unitsListMain.appendChild(unitsListFragment);

        global.units = units;
        for (let i = 0; i < units.length; i++) {

            let u = units[i];

            const isGroupUnit = false;
            let unitDiv = createMainUnitContainer(u, isGroupUnit);
            unitsListContainerDiv.appendChild(unitDiv);

            unitDiv.onclick = function (e) {
                e.stopImmediatePropagation();

                global.selectedUnitId = u.id;
                global.selectedGroupId = 0;
                global.sensorValuesDiagramDateSelected = false;
                global.selectedDateTime = "";
                displayUnitData(u);
                //displayDetailPage(u.id);
            };
        }

        displayStatusSummary(units);

        $("#loadingBox").modal('hide');
        document.body.style.cursor = 'default';
        document.getElementById('splashcontainer').style.display = 'none';
    }
    catch (e) {
        console.error("unitsJS - displayMainUnitsList: " + e.message);
        setMainInterval(global.units);
    }
}

export function displayUnitsList(units, unitId) {

    try {
        let unitsListDiv = document.getElementById('unitslist');
        console.log(`unitsListDiv`)
        while (unitsListDiv.hasChildNodes()) {
            unitsListDiv.removeChild(unitsListDiv.lastChild);
        }

        let unitsListFragment = document.createDocumentFragment();

        let displayUnits = units;
        if (!global.mainUnitsListSelected) { 
            displayUnits = global.selectedGroupUnits;
            let groupNameDiv = document.createElement('div');
            let selectedGroupName = getUnitGroupName();
            groupNameDiv.id = selectedGroupName;
            let groupNameDivClasses = ['unitgroup', 'unitgroupcenter'];
            groupNameDiv.classList.add(...groupNameDivClasses);

            groupNameDiv.innerHTML = selectedGroupName;
            unitsListFragment.appendChild(groupNameDiv);

            groupNameDiv.onclick = function () {
                global.mainUnitsListSelected = false;
                displayMainPane(units);
            };
        }

        let unitsListContainerDiv = createUnitsListContainer();
        unitsListFragment.appendChild(unitsListContainerDiv);
        unitsListDiv.appendChild(unitsListFragment);

        let selectedUnitInfoDisplayed = true;
        for (let i = 0; i < displayUnits.length; i++) {

            let u = displayUnits[i];

            //const isGroupUnit = false;
            //let unitDiv = createUnitContainer(u, uwt, isGroupUnit);
            let unitDiv = createUnitContainer(u, displayUnits);
            unitsListContainerDiv.appendChild(unitDiv);
            if (parseInt(unitId) === parseInt(u.id)) {
                unitDiv.classList.add('selectedunit');
            }
            unitDiv.onclick = function (e) {
                e.stopImmediatePropagation();
                let prevSelectedUnit = global.selectedUnitId;
                let unitInfoPaneDiv = document.getElementById('unitinfopane');

                if (unitInfoPaneDiv == null) {
                    //displayDiagramViews(u);                    
                } else {

                    //if (global.selectedUnitInfoCanClose && unitInfoPaneDiv.hasChildNodes()) {                        
                    if (unitInfoPaneDiv.hasChildNodes()) {
                        while (unitInfoPaneDiv.hasChildNodes()) {
                            unitInfoPaneDiv.removeChild(unitInfoPaneDiv.lastChild);
                        }
                        selectedUnitInfoDisplayed = false;
                    } else {
                        displayUnitInfoView(displayUnits, parseInt(u.id));
                        selectedUnitInfoDisplayed = true;
                    }

                    if (unitInfoPaneDiv.canClose) {
                    }
                }
                unitDiv.selected = true;
                global.sensorValuesDiagramDateSelected = false;
                global.selectedDateTime = "";
                const selectedUnitId = u.id;
                global.selectedUnitId = selectedUnitId;

                if (!selectedUnitInfoDisplayed && prevSelectedUnit != selectedUnitId) {
                    displayUnitInfoView(displayUnits, parseInt(u.id));
                    selectedUnitInfoDisplayed = true;
                }

                //alert(`unitGroups -> displayUnitsList -> displayDiagramViews(u): ${u}`);
                displayDiagramViews(u);

                let selectedUnitContainer = document.getElementsByClassName('selectedunit');
                if (selectedUnitContainer.length > 0) {
                    selectedUnitContainer[0].classList.remove('selectedunit');
                }
                unitDiv.classList.add('selectedunit');
            };
        }
    }
    catch (e) {
        console.error("unitsJS - displayUnitsList: " + e.error);
        setMainInterval(global.units);
    }
    $("#loadingBox").modal('hide');
    document.body.style.cursor = 'default';

}

function createUnitContainer(unit, units) {

    try {

        let unitDiv = document.createElement('div');
        //if (isGroupUnit) unitDiv.id = `groupunit${unit.id}`
        //else unitDiv.id = unit.id;
        unitDiv.id = unit.id;
        unitDiv.className = 'unit';
        unitDiv.selected = false;
        //unitDiv.classList.add('selectunit');

        let unitHeaderDiv = document.createElement('div');
        unitHeaderDiv.className = 'unitheader';
        unitDiv.appendChild(unitHeaderDiv);

        let listIcon = document.createElement('svg');
        listIcon.className = 'trucktrailer-listicon';
        unitHeaderDiv.appendChild(listIcon);

        let text = " " + unit.name;
        let t = document.createTextNode(text);
        unitHeaderDiv.appendChild(t);

        //Metric flags
        let metricFlagsSpan = document.createElement("span");
        metricFlagsSpan.className = "listspanicons";
        unitHeaderDiv.appendChild(metricFlagsSpan);

        if (unit.yellowFlags > 0) {
            let vspan = document.createElement("span");
            let vtxt = document.createTextNode("V");
            vspan.className += "yellowflag";
            vspan.appendChild(vtxt);
            metricFlagsSpan.appendChild(vspan);
        }
        if (unit.orangeFlags > 0) {
            let tspan = document.createElement("span");
            let ttxt = document.createTextNode("T");
            tspan.className += "orangeflag";
            tspan.appendChild(ttxt);
            metricFlagsSpan.appendChild(tspan);
        }
        if (unit.blinkFlags > 0) {
            let pspan = document.createElement("span");
            let ptxt = document.createTextNode("P");
            pspan.className += "blinkflag";
            pspan.appendChild(ptxt);
            metricFlagsSpan.appendChild(pspan);
        } else {
            if (unit.redFlags > 0) {
                let pspan = document.createElement("span");
                let ptxt = document.createTextNode("P");
                pspan.className += "redflag";
                pspan.appendChild(ptxt);
                metricFlagsSpan.appendChild(pspan);
            }
        }
        if (unit.purpleFlags > 0) {
            let pspan = document.createElement("span");
            let ptxt = document.createTextNode("P");
            pspan.className += "purpleflag";
            pspan.appendChild(ptxt);
            metricFlagsSpan.appendChild(pspan);
        }
        if (unit.grayFlags > 0) {
            let ospan = document.createElement("span");
            let otxt = document.createTextNode("S");
            ospan.className += "blueflag";
            ospan.appendChild(otxt);
            metricFlagsSpan.appendChild(ospan);
        }

        //if (unit.unitTrailers.length > 0) {

        //    //unit.unitTrailers.sort(function (trl1, trl2) {
        //    //    if (parseInt(trl1.linkedOrder) > parseInt(trl2.linkedOrder)) return 1;
        //    //    if (parseInt(trl1.linkedOrder) < parseInt(trl2.linkedOrder)) return -1;
        //    //    return 0;
        //    //});

        //    for (let t = 0; t < unit.unitTrailers.length; t++) {

        //        let unitLinkedDiv = document.createElement('div');
        //        unitLinkedDiv.className = 'unitheader';
        //        unitDiv.appendChild(unitLinkedDiv);

        //        let stringlink = '\xa0 \xa0 \xa0 \xa0 -o- \xa0 '; //1f517 \u{156e}\u{156d} \u{2ad8} \u{2d3c}  \u{2ad8}
        //        let link = document.createTextNode(stringlink);
        //        unitLinkedDiv.appendChild(link);

        //        let trailericon = unit.unitTrailers[t].unitIcon; // get unit Icon url
        //        let trailerimg = document.createElement('img');
        //        trailerimg.src = trailericon;
        //        unitLinkedDiv.appendChild(trailerimg);

        //        let trailertext = " " + unit.unitTrailers[t].name;
        //        let trailert = document.createTextNode(trailertext);
        //        unitLinkedDiv.appendChild(trailert);
        //    }
        //}        
        return unitDiv;
    } catch (e) {
        console.log("unitsJS - createUnitContainer: " + e.error);
        setMainInterval(global.units);
    }
}

export function displayMainPane(units) {

    //units = global.units;
    if (global.mainUnitsListSelected) {       
        displayMainUnitsList(units);
        getUnits();
    } else {
        displayMainUnitsGroupsList(units);
    }

    removeDetailPanes();
    document.getElementById('mainpane').style.display = 'block';
    document.getElementById('unitslistmain').style.display = 'block';
    switch (global.selectedMetric) {
        case 'PRESSURE':
            document.getElementById('rbtnPressure').checked = true;
            break;
        case 'TEMPERATURE':
            document.getElementById('rbtnTemperature').checked = true;
            break;
        case 'VOLTAGE':
            document.getElementById('rbtnVoltage').checked = true;
            break;
    }

    const statusRadioButtons = document.getElementById('statusradiobtns');
    statusRadioButtons.onclick = (e) => {

        if (global.mainUnitsListSelected)
            displayStatusSummary(units);
        else
            if (global.selectedGroupId === 0)
                displayStatusSummary([]);
            else
                displayStatusSummary(global.selectedGroupUnits);

        e.stopImmediatePropagation();
    };

    if (!global.unitsGroupsEventsRegistered) {
        global.unitsGroupsEventsRegistered = true;
        registerUnitsGroupsEvents(units);
    }

    global.showMainPane = true;
}

function removeDetailPanes() {

    let leftPane = document.getElementById('leftpane');
    let unitsList = document.getElementById('unitslist');
    while (unitsList.hasChildNodes()) {
        unitsList.removeChild(unitsList.lastChild);
    }
    //leftPane.style.display = "none";

    let middlepane = document.getElementById('middlepane');
    while (middlepane.hasChildNodes()) {
        middlepane.removeChild(middlepane.lastChild);
    }
    //middlepane.style.display = "none";

    let rightpane = document.getElementById('rightpane');
    while (rightpane.hasChildNodes()) {
        rightpane.removeChild(rightpane.lastChild);
    }
    //rightpane.style.display = "none";

    let detailsPane = document.getElementById('detailspane');
    detailsPane.style.display = "none";
}

//function displayStatusSummary(units) {

//    let unitTotal = units.length;
//    if (parseInt(unitTotal) === 0) {
//        let totalUnitsSpan = document.getElementById('totalunits');
//        totalUnitsSpan.innerText = '-';

//        document.getElementById('lowvoltagemetrics').style.display = 'none';
//        document.getElementById('underinflatedmetrics').style.display = 'inherit';
//        document.getElementById('overinflatedmetrics').style.display = 'inherit';
//        document.getElementById('hightemperaturemetrics').style.display = 'none';

//        let underInflatedSpan = document.getElementById('underInflated');
//        underInflatedSpan.innerText = '-';
//        let percUnderInflatedSpan = document.getElementById('percentageUnderInflated');
//        percUnderInflatedSpan.innerText = '- ';
//        let overInflatedSpan = document.getElementById('overInflated');
//        overInflatedSpan.innerText = '-';
//        let percOverInflatedSpan = document.getElementById('percentageOverInflated');
//        percOverInflatedSpan.innerText = '- ';
//        document.getElementById('normalvoltagelabel').style.display = 'none';
//        document.getElementById('normalpressurelabel').style.display = 'inherit';
//        document.getElementById('normaltemperaturelabel').style.display = 'none';
//        let normalSpan = document.getElementById('normal');
//        normalSpan.innerText = '-';
//        let percNormalSpan = document.getElementById('percentagenormal');
//        percNormalSpan.innerText = '- ';

//        let noSignalSpan = document.getElementById('noSignal');
//        noSignalSpan.innerText = '-';
//        let percNoSignalSpan = document.getElementById('percentagenosignal');
//        percNoSignalSpan.innerText = '- ';
//        let totalSensorValuesSpan = document.getElementById('totalSensorValues');
//        totalSensorValuesSpan.innerText = '-';
//    } else {
//        //for (let u = 0; u < units.length; u++) {
//        //    if (units[u].unitTrailers.length) unitTotal = unitTotal + units[u].unitTrailers.length;
//        //}
//        let totalUnitsSpan = document.getElementById('totalunits');
//        totalUnitsSpan.innerText = unitTotal;

//        let totalSensorValues = 0;
//        let yellowStatus = 0;
//        let blinkStatus = 0;
//        let redStatus = 0;
//        let greenStatus = 0;
//        let purpleStatus = 0;
//        let orangeStatus = 0;
//        let blueStatus = 0;

//        for (let unit = 0; unit < units.length; unit++) {
//            totalSensorValues = parseInt(totalSensorValues) + parseInt(units[unit].wheelSensors.length) + parseInt(units[unit].spareSensors.length) + parseInt(units[unit].linkedblueFlags);

//            if (units[unit].linkedyellowFlags > 0) {
//                yellowStatus = parseInt(yellowStatus) + parseInt(units[unit].linkedyellowFlags);
//            }
//            if (units[unit].linkedblinkFlags > 0) {
//                blinkStatus = parseInt(blinkStatus) + parseInt(units[unit].linkedblinkFlags);
//            }
//            if (units[unit].linkedredFlags > 0) {
//                redStatus = parseInt(redStatus) + parseInt(units[unit].linkedredFlags);
//            }
//            if (units[unit].linkedpurpleFlags > 0) {
//                purpleStatus = parseInt(purpleStatus) + parseInt(units[unit].linkedpurpleFlags);
//            }
//            if (units[unit].linkedorangeFlags > 0) {
//                orangeStatus = parseInt(orangeStatus) + parseInt(units[unit].linkedorangeFlags);
//            }
//            if (units[unit].linkedblueFlags > 0) {
//                blueStatus = parseInt(blueStatus) + parseInt(units[unit].linkedblueFlags);
//            }

//            //if (units[unit].unitTrailers.length > 0) {
//            //    for (let t = 0; t < units[unit].unitTrailers.length; t++) {
//            //        totalSensorValues = parseInt(totalSensorValues) + parseInt(units[unit].unitTrailers[t].wheelSensors.length);
//            //    }
//            //}
//        }

//        if (document.getElementById('rbtnPressure').checked === true) {

//            global.selectedMetric = "PRESSURE";

//            document.getElementById('lowvoltagemetrics').style.display = 'none';
//            document.getElementById('underinflatedmetrics').style.display = 'inherit';
//            document.getElementById('overinflatedmetrics').style.display = 'inherit';
//            document.getElementById('hightemperaturemetrics').style.display = 'none';

//            let underInflatedSpan = document.getElementById('underInflated');
//            underInflatedSpan.innerText = redStatus;
//            let percUnderInflatedSpan = document.getElementById('percentageUnderInflated');
//            percUnderInflatedSpan.innerText = Math.round(redStatus / totalSensorValues * 100);
//            let overInflatedSpan = document.getElementById('overInflated');
//            overInflatedSpan.innerText = purpleStatus;
//            let percOverInflatedSpan = document.getElementById('percentageOverInflated');
//            percOverInflatedSpan.innerText = Math.round(purpleStatus / totalSensorValues * 100);
//            document.getElementById('normalvoltagelabel').style.display = 'none';
//            document.getElementById('normalpressurelabel').style.display = 'inherit';
//            document.getElementById('normaltemperaturelabel').style.display = 'none';
//            let normalSpan = document.getElementById('normal');
//            greenStatus = parseInt(greenStatus) + (parseInt(totalSensorValues) - parseInt((parseInt(redStatus) + parseInt(purpleStatus) + parseInt(blueStatus))));
//            normalSpan.innerText = greenStatus;
//            let percNormalSpan = document.getElementById('percentagenormal');
//            percNormalSpan.innerText = Math.round(greenStatus / totalSensorValues * 100);
//        }

//        if (document.getElementById('rbtnTemperature').checked === true) {

//            global.selectedMetric = "TEMPERATURE";

//            document.getElementById('lowvoltagemetrics').style.display = 'none';
//            document.getElementById('underinflatedmetrics').style.display = 'none';
//            document.getElementById('overinflatedmetrics').style.display = 'none';
//            document.getElementById('hightemperaturemetrics').style.display = 'inherit';

//            document.getElementById('normalvoltagelabel').style.display = 'none';
//            document.getElementById('normalpressurelabel').style.display = 'none';
//            document.getElementById('normaltemperaturelabel').style.display = 'inherit';
//            let normalSpan = document.getElementById('normal');
//            greenStatus = parseInt(greenStatus) + (parseInt(totalSensorValues) - parseInt((parseInt(orangeStatus) + parseInt(blueStatus))));
//            normalSpan.innerText = greenStatus;
//            let percNormalSpan = document.getElementById('percentagenormal');
//            percNormalSpan.innerText = Math.round(greenStatus / totalSensorValues * 100);
//            let highTemperatureSpan = document.getElementById('hightemperature');
//            highTemperatureSpan.innerText = orangeStatus;
//            let percentageHighTemperatureSpan = document.getElementById('percentagehightemperature');
//            percentageHighTemperatureSpan.innerText = Math.round(orangeStatus / totalSensorValues * 100);
//        }

//        if (document.getElementById('rbtnVoltage').checked === true) {

//            global.selectedMetric = "VOLTAGE";

//            document.getElementById('lowvoltagemetrics').style.display = 'inherit';
//            document.getElementById('underinflatedmetrics').style.display = 'none';
//            document.getElementById('overinflatedmetrics').style.display = 'none';
//            document.getElementById('hightemperaturemetrics').style.display = 'none';

//            let lowVoltageSpan = document.getElementById('lowvoltage');
//            lowVoltageSpan.innerText = yellowStatus;
//            let percentageLowVoltageSpan = document.getElementById('percentagelowvoltage');
//            percentageLowVoltageSpan.innerText = Math.round(yellowStatus / totalSensorValues * 100);
//            document.getElementById('normalvoltagelabel').style.display = 'inherit';
//            document.getElementById('normalpressurelabel').style.display = 'none';
//            document.getElementById('normaltemperaturelabel').style.display = 'none';
//            let normalSpan = document.getElementById('normal');
//            greenStatus = parseInt(greenStatus) + (parseInt(totalSensorValues) - parseInt((parseInt(yellowStatus) + parseInt(blueStatus))));
//            normalSpan.innerText = greenStatus;
//            let percNormalSpan = document.getElementById('percentagenormal');
//            percNormalSpan.innerText = Math.round(greenStatus / totalSensorValues * 100);
//        }

//        let noSignalSpan = document.getElementById('noSignal');
//        noSignalSpan.innerText = blueStatus;
//        let percNoSignalSpan = document.getElementById('percentagenosignal');
//        percNoSignalSpan.innerText = Math.round(blueStatus / totalSensorValues * 100);
//        let totalSensorValuesSpan = document.getElementById('totalSensorValues');
//        totalSensorValuesSpan.innerText = totalSensorValues;
//    }

//    const lastUpdatedMain = document.getElementById('lastupdatedmain');
//    const unit = units.find(u => u.lastMessage);
//    if (unit) {
//        lastUpdatedMain.innerText = " " + unit.lastMessage;
//    } else {
//        lastUpdatedMain.innerText = "Not available";
//    }
//}

export function createMainUnitContainer(unit, isGroupUnit) {

    try {
        let unitDiv = document.createElement('div');
        if (isGroupUnit) unitDiv.id = `maingroupunit${unit.id}`;
        else unitDiv.id = `mainunit${unit.id}`;;
        unitDiv.className = 'unit';

        let unitHeaderDiv = document.createElement('div');
        unitHeaderDiv.className = 'unitheader';
        unitDiv.appendChild(unitHeaderDiv);

        //let icon = unit.unitIcon;
        //let img = document.createElement('img');
        //img.src = icon;
        //unitHeaderDiv.appendChild(img);

        let text = '\xa0 \xa0' + unit.name;
        let t = document.createTextNode(text);
        unitHeaderDiv.appendChild(t);

        let unitStatusDiv = document.createElement('div');
        unitStatusDiv.className = 'unitstatus';
        let unitStatusDiagramDiv = loadUnitStatusDiagram(unit);
        unitStatusDiagramDiv.className = 'statusdiagram';
        unitStatusDiv.appendChild(unitStatusDiagramDiv);
        unitHeaderDiv.appendChild(unitStatusDiv);

        //if (unit.unitTrailers.length > 0) {

        //    for (let t = 0; t < unit.unitTrailers.length; t++) {

        //        let unitLinkedDiv = document.createElement('div');
        //        unitLinkedDiv.className = 'unitheader';
        //        unitDiv.appendChild(unitLinkedDiv);

        //        let stringlink = '\xa0 \xa0 \xa0 \xa0 -o- \xa0 '; //1f517 \u{156e}\u{156d} \u{2ad8} \u{2d3c}  \u{2ad8}
        //        let link = document.createTextNode(stringlink);
        //        unitLinkedDiv.appendChild(link);

        //        let trailericon = unit.unitTrailers[t].unitIcon; // get unit Icon url
        //        let trailerimg = document.createElement('img');
        //        trailerimg.src = trailericon;
        //        unitLinkedDiv.appendChild(trailerimg);

        //        let trailertext = " " + unit.unitTrailers[t].name;
        //        let trailert = document.createTextNode(trailertext);
        //        unitLinkedDiv.appendChild(trailert);

        //        let unitLinkedStatusDiv = document.createElement('div');
        //        unitLinkedStatusDiv.className = 'unitstatus';
        //        let unitLinkedStatusDiagramDiv = loadUnitStatusDiagram(unit.unitTrailers[t]);
        //        unitLinkedStatusDiagramDiv.className = 'statusdiagram';
        //        unitLinkedStatusDiv.appendChild(unitLinkedStatusDiagramDiv);
        //        unitLinkedDiv.appendChild(unitLinkedStatusDiv);
        //    }
        //}
        return unitDiv;
    } catch (e) {
        console.log("unitgroupJS - createMainUnitContainer: " + e.error);
        setMainInterval(global.units);
    }
}

function createUnitsListContainer() {

    let unitsListContainerDiv = document.createElement('div');
    unitsListContainerDiv.id = 'unitslistcontainer';
    unitsListContainerDiv.className = 'unitslistcontainer';
    if (!global.mainUnitsListSelected) {
        unitsListContainerDiv.classList.add('unitsgrouplistcontainer');
    }

    return unitsListContainerDiv;
}

export { getUnitConfigs, getUnitConfig, loadSensorValuesBatch, getWheelProperties, getTyreArrayIndex };



