
/* Psalm 127:1-2
As die HERE die huis nie bou nie, tevergeefs werk die wat daaraan bou;
as die HERE die stad nie bewaar nie, tevergeefs waak die wagter.
Tevergeefs dat julle vroeg opstaan, laat opbly, brood van smarte eet —
net so goed gee Hy dit aan sy beminde in die slaap! */

/*“Walking on water and developing software from a specification are easy if both are frozen”*/

import regeneratorRuntime from "regenerator-runtime"; //webpack

import { SPARE_AXLE } from './global';
import { getResource } from "./global";
import { getResourceNotifications } from "./notifications";
import { logmsg } from "./components/views/unitgroups";
import { getUnits, displayMainPane } from "./components/views/units";
import { loadSensorValuesBatch, getUnitConfigs } from "./components/views/units";

import { getUnitData } from "./data/getunitdata";
import { addTrailer } from "./data/unitdata";

global.session = wialon.core.Session.getInstance();
global.resource = {};

global.resourceNotifications = [];
global.unitsGroupsEventsRegistered = false

global.showMainPane = true;
global.mainUnitsListSelected = true;

global.selectedUnitId = 0;
global.units = [];
global.unitGroups = [];
global.selectedGroupUnits = [];
global.selectedGroupId = 0;
global.selectedMetric = "PRESSURE";

global.pressureMetricAttributesUpdated = false;

global.sensorValuesChartMessages = [];
global.isProcessingChartMessages = false;

global.sensorValuesDiagramDateSelected = false;
global.selectedDateTime = "";

global.mainIntervalId = null;
global.detailIntervalId = null;

$(document).ready(function () {
    document.getElementById('container').style.display = 'none';
    document.getElementById('mainpane').style.display = 'none';
    document.getElementById('detailspane').style.display = 'none';
    
    getToken();
});

function getToken() {        
    try {
        const dns = "https://hosting.wialon.com";
        const wialonUrl = `${dns}/login.html?client_id=PressureTrack&access_type=-1&activation_time=0&duration=2592000&flags=0x1&redirect_uri=${dns}/post_token.html`;
        const wialonLogin = document.getElementById('wialon-login');
        wialonLogin.src = wialonUrl;
        window.onmessage = function (e) {
            tokenReceived(e);
        }
    } catch (e) {
        console.error('pressuretrackJS: getToken - ERROR: ', e);
    }
}

async function tokenReceived(e) {    
    try {
        const version = updateApp();
        const msg = e.data;
        if (typeof msg == "string" && msg.indexOf("access_token=") >= 0) {
            const token = msg.replace("access_token=", "");
            const session = global.session;
            if (!session) session = wialon.core.Session.getInstance();
            session.initSession("https://hst-api.wialon.com");
            session.loginToken(token, "", async function (error) {
                if (error) {
                    logmsg(wialon.core.Errors.getErrorText(error));
                    console.log("pressuretrackJS: tokenReceived - ERROR " + error + " (" + wialon.core.Errors.getErrorText(error)) + ")";
                    return;
                }
                                
                global.resource = await getResource();
                const id = global.resource.getId;
                
                getUnits().catch((error) => {
                    if (error.type === 'API_ERROR') {
                        let reason = error.fullError && error.fullError.reason;
                        logmsg(
                            wialon.core.Errors.getErrorText(error.code) +
                            (reason ? ' ' + reason : '')
                        );
                        console.log("pressuretrackJS: tokenReceived (getUnits) - API ERROR " + error + " #" + wialon.core.Errors.getErrorText(error)) + ")";
                    } else {
                        logmsg('Detailed error in console: ' + error);
                        console.log("pressuretrackJS: tokenReceived (getUnits) - ERROR " + error + ")");
                        console.error(error);
                    }
                });

                //if (global.mainIntervalId) {
                //    clearInterval(global.mainIntervalId);
                //    console.log(`CLEAR Interval Id: ${global.mainIntervalId}`);
                //}

                //global.mainIntervalId = setInterval(() => getUnits().catch((error) => {
                //    if (error.type === 'API_ERROR') {
                //        let reason = error.fullError && error.fullError.reason;
                //        logmsg(
                //            wialon.core.Errors.getErrorText(error.code) +
                //            (reason ? ' ' + reason : '')
                //        );
                //        console.log("pressuretrackJS: tokenReceived(getUnits) - API ERROR " + error + " #" + wialon.core.Errors.getErrorText(error)) + ")";
                //    } else {
                //        logmsg('Detailed error in console: ' + error);
                //        console.log("pressuretrackJS: tokenReceived(getUnits) - ERROR " + error + ")");
                //        console.error(error);
                //    }
                //}), 145000);

                const userName = session.getCurrUser().getName();
                logmsg(userName);

                showSplash(userName, version);

                let container = document.getElementById('container');
                container.style.display = 'block';
            });
        }
    } catch (e) {
            console.error('pressuretrackJS: tokenReceived - ERROR: ', e);
    }
}

const showSplash = (userName, version) => {

    const splashContainer = document.getElementById('splashcontainer');
    splashContainer.className = 'container-splash';
    while (splashContainer.hasChildNodes()) {
        splashContainer.removeChild(splashContainer.lastChild);
    }
    const splashMainFragment = document.createDocumentFragment();

    const splash = document.createElement('div');
    splash.id = 'splash';

    const splashIcon = document.createElement('div');
    splashIcon.id = 'splashicon';
    splashIcon.className = 'splashicon';
    splash.appendChild(splashIcon);

    const splashLogo = document.createElement('div');
    splashLogo.id = 'splashlogo';
    splashLogo.className = 'splashlogo';
    splash.appendChild(splashLogo);

    const splashLabel = document.createElement('div');
    splashLabel.id = 'splashlabel';
    splashLabel.className = 'splashlabel';
    splashLabel.innerText = `If you can't measure it, you can't manage it.`;
    splash.appendChild(splashLabel);

    const userNameSplash = document.createElement('div');
    userNameSplash.id = 'username';
    userNameSplash.className = 'username';
    userNameSplash.innerText = userName;
    splash.appendChild(userNameSplash);

    const versionSplash = document.createElement('div');
    versionSplash.id = 'splashversion';
    versionSplash.className = 'version';
    versionSplash.innerText = `version ${version}`;
    splash.appendChild(versionSplash);

    splashMainFragment.appendChild(splash);
    splashContainer.appendChild(splashMainFragment);
}

function updateApp() {

    const currentVersion = "1.75.95"; //"1.75.93" (live)
    const previousVersion = localStorage.getItem("version");

    if (previousVersion) {
        if (`PressureTrack v${currentVersion}` !== previousVersion) {
            localStorage.setItem("version", `PressureTrack v${currentVersion}`);
            location.reload(true);
        }
    } else {
        localStorage.setItem("version", `PressureTrack v${currentVersion}`);
            location.reload(true);
    }

    document.getElementById('version').innerText = `version ${currentVersion}`;

    return currentVersion;
}
  
async function initialiseUnitsOldFaithful() {   

    //let unitProfile_flags = 1 + 8388608;
    //wialon.item.Item.dataFlag.profileFields |
    //wialon.item.Unit.dataFlag.restricted |
    //session.loadLibrary("itemProfileFields");


    const resource = await getResource();

    let wTrailers = resource.getTrailers();
    const t = 1;

    const getAllUnits = await getUnitData("*");
   

    let session = global.session;
    if (!session) session = wialon.core.Session.getInstance();    


    //flags to specify what kind of data should be returned
    let unitFlags = wialon.util.Number.or(wialon.item.Item.dataFlag.base |
        wialon.item.Unit.dataFlag.sensors |
        wialon.item.Unit.dataFlag.lastMessage);

    let resourceFlags = wialon.util.Number.or(wialon.item.Item.dataFlag.base, wialon.item.Resource.dataFlag.trailers);

    session.loadLibrary("itemIcon");
    session.loadLibrary("unitSensors");
    session.loadLibrary("resourceTrailers");

    session.updateDataFlags(
        [{ type: "type", data: "avl_unit", flags: unitFlags, mode: 0 },
        { type: "type", data: "avl_resource", flags: resourceFlags, mode: 0 }],
        async function (error) {
            if (error) {
                logmsg(wialon.core.Errors.getErrorText(error));
                console.log("pressuretrackJS: getUnits - API ERROR " + error + " #" + wialon.core.Errors.getErrorText(error));
                return;
            }

            if (!global.resource) global.resource = await getResource();

            //console.log('timeMain1: [' + Date.now() + '] ');
            let resourceNotifications = await getResourceNotifications();
            global.resourceNotifications = resourceNotifications;
            //console.log('timeMain2: [' + Date.now() + '] ');
            let sensorValueRanges = resourceNotifications.notifications;

            let wUnits = session.getItems("avl_unit");
            let wTrailers = global.resource.getTrailers();

            let unitId = 0;
            let units = [];
            //Create array of Units with trailers and properties
            for (let i = 0; i < wUnits.length; i++) {
                let unit = {};
                let wUnit = wUnits[i];
                unitId = wUnit.getId();
                unit.id = unitId;
                //unit.type = wUnit.getType();
                unit.name = wUnit.getName();
                unit.mobileNo = wUnit.getPhoneNumber();
                unit.position = wUnit.getPosition();
                //unit.unitLocation = (await getUnitLocation(unit.position))[0];
                //if (typeof wUnit.getProfileFields === "function") {
                //    let fields = Object.values(wUnit.getProfileFields());
                //    for (let f = 0; f < fields.length; f++) {
                //        if (fields[f].n === "vehicle_class")
                //            unit.vehicleClass = fields[f].v;
                //        if (fields[f].n === "axles")
                //            unit.axleCount = parseInt(fields[f].v);
                //    }
                //}

                //unit.isTrailer = false;
                //for (let t in wTrailers) {
                //    const wTrailer = wTrailers[t];
                //    if (unit.name.trim() === wTrailer.n.trim()) {
                //        unit.isTrailer = true;
                //        unit.trailerId = wTrailer.id;
                //        //delete wTrailers[trl];
                //        break;
                //    }
                //}

                let sensors = wUnit.getSensors();
                let unitSensors = [];

                let wheelSensors = [];

                for (let s in sensors) {

                    let wheelSensor = {};
                    if (sensors[s].n.slice(0, 1) === 'P') {
                        wheelSensor.sensorName = sensors[s].n.slice(1);

                        const tyreIdMatch = sensors[s].d.match(/Tyre Id: ([\w\d]+);/);
                        wheelSensor.tyreId = tyreIdMatch ? tyreIdMatch[1] : sensors[s].d;

                        const treadDepthMatch = sensors[s].d.match(/Tread Depth: ([\d.]+ mm)/);
                        wheelSensor.treadDepth = treadDepthMatch ? treadDepthMatch[1] : "undefined";

                        let unitNumber = sensors[s].n.slice(-1);
                        wheelSensor.unitNumber = unitNumber;
                        let axle = sensors[s].n.slice(2, 4);

                        for (let valueRange in sensorValueRanges) {
                            if (sensorValueRanges[valueRange].un[0] === unitId) {
                                if (axle === sensorValueRanges[valueRange].n.slice(sensorValueRanges[valueRange].n.length - 3).slice(0, 2) && unitNumber === sensorValueRanges[valueRange].n.slice(-1)) {
                                    let sensorMetric = sensorValueRanges[valueRange].n.slice(sensorValueRanges[valueRange].n.length - 5).slice(0, 1);
                                    switch (sensorMetric) {
                                        case 'P':
                                            wheelSensor.minPressureValue = sensorValueRanges[valueRange].trg_p.lower_bound;
                                            wheelSensor.maxPressureValue = sensorValueRanges[valueRange].trg_p.upper_bound;
                                            //delete sensorValueRanges[valueRange];
                                            break;
                                        case 'T':
                                            wheelSensor.maxTemperatureValue = sensorValueRanges[valueRange].trg_p.upper_bound;
                                            //delete sensorValueRanges[valueRange];
                                            break;
                                        case 'V':
                                            wheelSensor.minVoltageValue = sensorValueRanges[valueRange].trg_p.lower_bound;
                                            //delete sensorValueRanges[valueRange];
                                            break;
                                    }
                                }
                            }
                        }
                        unitSensors.push(wheelSensor);
                        wheelSensors.push(wheelSensor);
                    }
                }

                unit.sensors = unitSensors;
                unit.wheelSensors = wheelSensors;
                //unit.locationSensors = locationSensors;

                unit.grayFlags = 0;
                unit.blinkFlags = 0;
                unit.redFlags = 0;
                unit.purpleFlags = 0;
                unit.orangeFlags = 0;
                unit.yellowFlags = 0;

                units.push(unit);
            }

            let timeTo = session.getServerTime();

            let timeFrom = timeTo - 1200; //2520 = elke 42; 1200 = 20 min

            let prms = [];
            for (let i = 0; i < units.length; i++) {
                let unitId = units[i].id;

                let prmsObj = {
                    "svc": "messages/load_interval",
                    "params": {
                        "itemId": unitId,
                        "timeFrom": timeFrom,
                        "timeTo": timeTo, "flags": 32, "flagsMask": 0, "loadCount": 100
                    }
                };
                //0xffffffff
                prms.push(prmsObj);
            }

            let sensorValues = await loadSensorValuesBatch(prms);
            let newTrailers = [];
            for (let u = 0; u < units.length; u++) {
                let locationSensors = [];
                
                let trailerCodes = ["011", "012", "013", "014", "015"];
                for (let m = 0; m < sensorValues[u].messages.length; m++) {
                    let repeaterFound = false;
                    for (let property in sensorValues[u].messages[m].p) {                        
                        
                        if (property.slice(0, 2) === "wl") {
                            let leftSensorFound = false;

                            for (let lsl = 0; lsl < locationSensors.length; lsl++) {
                                if (locationSensors[lsl].sensorName === property.slice(1)) {
                                    leftSensorFound = true;
                                }
                            }

                            if (!leftSensorFound) {                                
                                
                                if (!repeaterFound && trailerCodes.includes(property.slice(2))) {
                                    repeaterFound = Object.keys(wTrailers).some(trailer => {
                                        const unitNameTrailerNo = wTrailers[trailer].ds;
                                        //const sensorId = wTrailers[trailer].c;
                                        //const sId = sensorValues[u].messages[m]?.p["i" + property.slice(1)].toString() || "";
                                        return unitNameTrailerNo === `${units[u].name}-${property.slice(-1)}`;   // && sensorId === sId
                                    });
                                    //Add trailer
                                    if (!repeaterFound) {
                                        repeaterFound = true;
                                        
                                        let index = trailerCodes.findIndex(code => code.slice(-1) === property.slice(-1));
                                        if (index !== -1) {
                                            // Remove the matched trailer code from the array
                                            trailerCodes.splice(index, 1);
                                        }

                                        const trailer = {};                                        
                                        //trailer.name = property.slice(1);
                                        trailer.name = `Trailer ${property.slice(-1)}`;
                                        trailer.order = parseInt(property.slice(-1));
                                        trailer.sensorId = sensorValues[u].messages[m]?.p["i" + property.slice(1)];
                                        trailer.unitLinkedTo = units[u].name;
                                        const addedTrailer = await addTrailer(trailer);
                                        trailer.id = addedTrailer[0];
                                        newTrailers.push(trailer);                                        
                                    }
                                }

                                const locationSensorLeft = {};
                                locationSensorLeft.sensorName = property.slice(1);
                                locationSensorLeft.sensorId = sensorValues[u].messages[m]?.p["i" + property.slice(1)] || 0;
                                locationSensorLeft.unitNumber = property.slice(-1);
                                locationSensorLeft.axle = property.slice(2, 4);
                                locationSensorLeft.axleUnit = property.slice(2);
                                locationSensorLeft.wheelConfig = parseInt(sensorValues[u].messages[m]?.p["w" + property.slice(1)]);
                                locationSensorLeft.voltage = Math.round(sensorValues[u].messages[m]?.p["v" + property.slice(1)] / 1000 * 100) / 100 || 0;
                                locationSensors.push(locationSensorLeft);
                            }  
                        }

                        if (property.slice(0, 2) === "wr") {
                            let rightSensorFound = false;
                            for (let lsr = 0; lsr < locationSensors.length; lsr++) {
                                if (locationSensors[lsr].sensorName === property.slice(1)) {
                                    rightSensorFound = true;
                                }
                            }

                            if (!rightSensorFound) {                                
                                    if (!repeaterFound && trailerCodes.includes(property.slice(2))) {
                                        repeaterFound = Object.keys(wTrailers).some(trailer => {
                                            const unitNameTrailerNo = wTrailers[trailer].ds;
                                            //const sensorId = wTrailers[trailer].c;
                                            //const sId = sensorValues[u].messages[m]?.p["i" + property.slice(1)].toString() || "";
                                            return unitNameTrailerNo === `${units[u].name}-${property.slice(-1)}`; //&& sensorId === sId
                                        });
                                        //Add trailer
                                        if (!repeaterFound) {
                                            repeaterFound = true;

                                            let index = trailerCodes.findIndex(code => code.slice(-1) === property.slice(-1));
                                            if (index !== -1) {
                                                // Remove the matched trailer code from the array
                                                trailerCodes.splice(index, 1);
                                            }

                                            const trailer = {};
                                            //trailer.name = property.slice(1);
                                            trailer.name = `Trailer ${property.slice(-1)}`;
                                            trailer.order = parseInt(property.slice(-1));
                                            trailer.sensorId = sensorValues[u].messages[m]?.p["i" + property.slice(1)];
                                            trailer.unitLinkedTo = units[u].name;
                                            const addedTrailer = await addTrailer(trailer);
                                            trailer.id = addedTrailer[0];
                                            newTrailers.push(trailer);                                            
                                        }
                                    }                             

                                const locationSensorRight = {};
                                locationSensorRight.sensorName = property.slice(1);
                                locationSensorRight.sensorId = sensorValues[u].messages[m]?.p["i" + property.slice(1)] || 0;
                                locationSensorRight.unitNumber = property.slice(-1);
                                locationSensorRight.axle = property.slice(2, 4);
                                locationSensorRight.axleUnit = property.slice(2);
                                locationSensorRight.wheelConfig = parseInt(sensorValues[u].messages[m]?.p["w" + property.slice(1)]);
                                locationSensorRight.voltage = Math.round(sensorValues[u].messages[m]?.p["v" + property.slice(1)]/1000 * 100)/100 || 0;
                                locationSensors.push(locationSensorRight);
                            }                                     
                        }

                        for (let k = 0; k < units[u].wheelSensors.length; k++) {
                            if (property.substring(1) === units[u].wheelSensors[k].sensorName) {

                                if (property.includes("i")) {                                        
                                    units[u].wheelSensors[k].sensorId = parseFloat(sensorValues[u].messages[m].p[property]);
                                }

                                if (property.includes("p"))
                                    units[u].wheelSensors[k].pressureValue = (parseFloat(sensorValues[u].messages[m].p[property])).toFixed(2); //* 0.0689475729

                                if (property.includes("t")) 
                                    units[u].wheelSensors[k].temperatureValue = parseInt(sensorValues[u].messages[m].p[property]);

                                if (property.includes("v")) 
                                    units[u].wheelSensors[k].voltageValue = sensorValues[u].messages[m].p[property];
                                    
                                if (property.includes("r")) 
                                    units[u].wheelSensors[k].rotationValue = parseFloat(sensorValues[u].messages[m].p[property]);
                            }
                        }
                    }
                }

                const hasPressureValue = units[u].wheelSensors.filter(item => {
                    return 'pressureValue' in item;
                });
                const hasTemperatureValue = units[u].wheelSensors.filter(item => {
                    return 'temperatureValue' in item;
                });
                const hasVoltageValue = units[u].wheelSensors.filter(item => {
                    return 'voltageValue' in item;
                });
                const filteredSensors = Object.values({ ...hasPressureValue, ...hasTemperatureValue, ...hasVoltageValue });
                units[u].wheelSensors = filteredSensors.filter(item => item.sensorName.slice(1, 3) !== SPARE_AXLE.toString());
                units[u].spareSensors = filteredSensors.filter(item => item.sensorName.slice(1, 3) === SPARE_AXLE.toString());
                units[u].locationSensors = locationSensors;       
            }

            units = getUnitConfigs(units);
            units = getSensorValueFlags(units);
            
            let unitTrailers = [];
            let trailers = [];
            const wTrailersUpdated = global.resource.getTrailers();
            for (let un = 0; un < units.length; un++) {
                const uId = units[un].id;
                //All trailers that are linked/binded
                for (let wTrl in wTrailersUpdated) {
                    let trl = wTrailersUpdated[wTrl];
                    //if (trailer.id !== gSelectedUnit.trailerId ) { //Delink workaround - trailer.bu not updated yet in callback
                    if (uId === trl.bu) {
                        const unitTrailer = {};
                        unitTrailer.unitLinkedToId = uId;
                        unitTrailer.trailerId = trl.id;
                        unitTrailer.name = trl.n;
                        //unitTrailer.linkedOrder = parseInt(trailer.c);
                        unitTrailers.push(unitTrailer);
                        break;
                    } else {
                        if (units[un].name === trl.ds.slice(0, -2)) {
                            const trailer = {};
                            trailer.id = trl.id;
                            trailer.name = trl.n;
                            trailer.sensorId = trl.c;
                            trailer.unitLinkedTo = units[un].name;
                            trailer.order = parseInt(trl.ds.slice(-1));
                            trailers.push(trailer);

                        }
                    }
                }

                trailers = mergeTrailers(trailers, newTrailers)
                units[un].trailers = trailers;
                units[un].unitTrailers = unitTrailers;
            }

            for (let i = 0; i < units.length; i++) {
                let unitTrailersFound = [];
                for (let t = 0; t < unitTrailers.length; t++) {
                    let uTrailer = unitTrailers[t];
                    if (uTrailer.name.trim() === units[i].name.trim()) {
                        uTrailer.id = units[i].id;
                        uTrailer.mobileNo = units[i].mobileNo;
                        uTrailer.sensors = units[i].sensors;
                    }
                    if (uTrailer.unitLinkedToId === units[i].id) {
                        unitTrailersFound.push(uTrailer);
                    }
                }

                units[i].unitTrailers = unitTrailersFound;
            }
            //units = joinTruckAndTrailers(units);

            global.units = units;
            //if (!global.showMainPane) {
            //    displayDetailPage();
            //}

            main(units).catch((error) => {
                if (error.type === 'API_ERROR') {
                    let reason = error.fullError && error.fullError.reason;
                    logmsg(
                        wialon.core.Errors.getErrorText(error.code) +
                        (reason ? ' ' + reason : '')
                    );
                    console.log("pressuretrackJS: getUnits(main) - API ERROR " + error + " #" + wialon.core.Errors.getErrorText(error)) + ")";
                } else {
                    logmsg('Detailed error in console: ' + error);
                    console.log("pressuretrackJS: getUnits(main) - ERROR " + error + ")");
                    console.error(error);
                }
            });   
        }
    );
}

function mergeTrailers(trailers, newTrailers) {
    // Combine both arrays
    const combinedTrailers = trailers.concat(newTrailers);

    // Create an object to store unique objects based on id
    const uniqueObjects = {};

    // Filter out duplicates based on id
    combinedTrailers.forEach(obj => {
        uniqueObjects[obj.id] = obj;
    });

    // Convert uniqueObjects back to an array
    const result = Object.values(uniqueObjects);

    return result;
}

//async function getAllUnitsSensorValues(units) {

//    global.units = units;
//    let session = global.session;
//    if (!session) session = wialon.core.Session.getInstance();

//    let timeTo = session.getServerTime();
//    let timeFrom = timeTo - 3600;

//    let prms = [];
//    for (let j = 0; j < units.length; j++) {
//        let unitId = units[j].id;

//        let prmsObj = {
//            "svc": "messages/load_interval",
//            "params": {
//                "itemId": unitId,
//                "timeFrom": timeFrom,
//                "timeTo": timeTo, "flags": 32, "flagsMask": 0, "loadCount": 100
//            }
//        };
//        prms.push(prmsObj);
//    }

//    let remote = wialon.core.Remote.getInstance();
//    remote.remoteCall('core/batch', prms,
//        function (callback, response) {
//            if (callback == 0) {
//                for (let u = 0; u < units.length; u++) {
//                    //let unitId = units[u].id;

//                    for (let i = 0; i < response[u].messages.length; i++) { // get last received message with values
//                        if (response[u].messages[i].p.data_type === "E6") { // E6 = custom sensor values??

//                            for (let property in response[u].messages[i].p) {
//                                if (property === 'data_type' || property === 'real_count' || property === 'total_count') continue;
//                                for (let k = 0; k < units[u].sensors.length; k++) {
//                                    if (property.substring(1, 3) === units[u].sensors[k].sensorName.substring(0, 2)) {
//                                        if (property.includes("pressure")) {
//                                            units[u].sensors[k].pressureValue = (parseFloat(response[u].messages[i].p[property]) * 0.0689475729).toFixed(2);
//                                        } else {
//                                            units[u].sensors[k].temperatureValue = parseInt(response[u].messages[i].p[property]);
//                                        }
//                                        if (property.includes("volt")) {
//                                            units[u].sensors[k].voltageValue = parseFloat(response[u].messages[i].p[property]);
//                                        }
//                                    }
//                                }
//                            }
//                        }
//                    }
//                }
//                units = getSensorValueFlags(units);

//                if (global.showMainPane) displayMainPane(units);
//                else displayDetailPage();
//            } else {
//                console.log("Operation failed", callback);
//            }
//        }
//    );
//}

//async function getAllUnitsSensorValuesOld(units) {

//    global.units = units;
//    let session = global.session;
//    if (!session) session = wialon.core.Session.getInstance();

//    let timeTo = session.getServerTime();
//    let timeFrom = timeTo - 3600;

//    let prms = [];
//    for (let j = 0; j < units.length; j++) {
//        let unitId = units[j].id;

//        let prmsObj = {
//            "svc": "messages/load_interval",
//            "params": {
//                "itemId": unitId,
//                "timeFrom": timeFrom,
//                "timeTo": timeTo, "flags": 32, "flagsMask": 0, "loadCount": 100
//            }
//        };
//        prms.push(prmsObj);
//    }

//    let remote = wialon.core.Remote.getInstance();
//    remote.remoteCall('core/batch', prms,
//        function (callback, response) {
//            if (callback == 0) {
//                for (let u = 0; u < units.length; u++) {
//                    //let unitId = units[u].id;

//                    for (let i = 0; i < response[u].messages.length; i++) { // get last received message with values
//                        if (response[u].messages[i].p.data_type === "E6") { // E6 = custom sensor values??

//                            for (let property in response[u].messages[i].p) {
//                                if (property === 'data_type' || property === 'real_count' || property === 'total_count') continue;
//                                for (let k = 0; k < units[u].sensors.length; k++) {
//                                    if (property.substring(1, 3) === units[u].sensors[k].sensorName.substring(0, 2)) {
//                                        if (property.includes("pressure")) {
//                                            units[u].sensors[k].pressureValue = (parseFloat(response[u].messages[i].p[property]) * 0.0689475729).toFixed(2);
//                                        } else {
//                                            units[u].sensors[k].temperatureValue = parseInt(response[u].messages[i].p[property]);
//                                        }
//                                        if (property.includes("volt")) {
//                                            units[u].sensors[k].voltageValue = parseFloat(response[u].messages[i].p[property]);
//                                        }
//                                    }
//                                }
//                            }
//                        }
//                    }
//                }
//                units = getSensorValueFlags(units);

//                if (global.showMainPane) displayMainPane(units);
//                else displayDetailPage();
//            } else {
//                console.log("Operation failed", callback);
//            }
//        }
//    );
//}

function countProperties(obj) {
    var count = 0;

    for (var prop in obj) {
        if (obj.hasOwnProperty(prop))
            count++;
    }

    return count;
}

function unloadMessageLoader(messageLoader) {

    return new Promise((reject) => {
        messageLoader.unload(
            (error, result) => {
                if (error) {
                    reject({ type: 'API_ERROR', code: error, fullError: result });
                    return;
                }
                return;
            },
        );
    });
}

function loadIntervalAsync(messageLoader, options) {

    let {
        unitId,
        timeFrom,
        timeTo,
        flags = 32,
        flagsMask = 0,
        loadCount,
    } = options;

    return new Promise((resolve, reject) => {
        messageLoader.loadInterval(
            unitId,
            timeFrom,
            timeTo,
            flags,
            flagsMask,
            loadCount,
            (error, result) => {
                if (error) {
                    reject({ type: 'API_ERROR', code: error, fullError: result });
                    return;
                }
                resolve(result);
            },
        );
    });
}

//function createUnitsListButtonsElement() {

//    let unitsListButtonDivClasses = ['row', 'pane2buttons'];
//    let unitsListButtonDiv = document.createElement('div');
//    unitsListButtonDiv.classList.add(...unitsListButtonDivClasses);

//    let unitsListButtonClasses = ['btn', 'btn-primary', 'panebutton', 'flex-child'];

//    let unitsButton = document.createElement('button');
//    unitsButton.classList.add(...unitsListButtonClasses);
//    unitsButton.type = 'button';
//    unitsButton.title = 'ALL UNITS';

//    let unitsImg = document.createElement('img');
//    unitsImg.src = "images/truck-24.png";
//    unitsButton.appendChild(unitsImg);

//    unitsButton.onclick = function () {
//        //openNotificationsForm(unit.id);
//    };

//    unitsListButtonDiv.appendChild(unitsButton);

//    //let unitsGroupsButtonClasses = ['btn', 'btn-primary'];
//    //let unitGroupsButton = document.createElement('button');    
//    //unitGroupsButton.classList.add(...unitsGroupsButtonClasses);
//    //unitGroupsButton.type = 'button';
//    //unitGroupsButton.title = 'Unit Groups';

//    //let unitGroupsDropdownButtonClasses = ['btn', 'btn-primary', 'dropdown-toggle', 'dropdown-toggle-split'];
//    //let unitGroupsDropdownButton = document.createElement('button');
//    //unitGroupsDropdownButton.classList.add(...unitGroupsDropdownButtonClasses);
//    //unitGroupsDropdownButton.type = 'button';
//    //unitGroupsDropdownButton.setAttribute("data-toggle", "dropdown");
//    //unitGroupsDropdownButton.setAttribute("aria-haspopup", "true");
//    //unitGroupsDropdownButton.setAttribute("aria-expanded", "false");
//    //unitGroupsDropdownButton.title = 'Unit Groups';

//    //let dropdownSpan = document.createElement('span');
//    //dropdownSpan.className = 'sr-only';
//    //unitGroupsDropdownButton.appendChild(dropdownSpan);
//    ////let printImg = document.createElement('img');
//    ////printImg.src = "images/scissors-24.png";
//    ////unitGroupsButton.appendChild(printImg);

//    let unitGroupsButton = document.getElementById('unitgroupsbuttondiv');
//    unitGroupsButton.classList.remove("unitgroupsbuttonhide");

//    unitGroupsButton.onclick = function () {
//        //snipDiagram(unit, vehicleClass);
//    };

//    unitsListButtonDiv.appendChild(unitGroupsButton);

//    return unitsListButtonDiv;
//}

async function addNewSensorMetrics(sensor) {

    let newPressureSensor = false;
    let pressureSensorMetric = {};
    pressureSensorMetric.sensorName = "P" + sensor.wheelId;
    pressureSensorMetric.newSensorName = pressureSensorMetric.sensorName.slice(0, 3) + sensor.newSensorId;
    pressureSensorMetric.metricParameter = 't' + sensor.wheelId + '_pressure*const0.0689475729';
    pressureSensorMetric.metricUnit = 'bar';
    newPressureSensor = await addNewSensor(sensor.id, pressureSensorMetric);

    let newTemperatureSensor = false;
    let temperatureSensorMetric = {};
    temperatureSensorMetric.sensorName = "T" + sensor.wheelId;
    temperatureSensorMetric.newSensorName = temperatureSensorMetric.sensorName.slice(0, 3) + sensor.newSensorId;
    temperatureSensorMetric.metricParameter = 't' + sensor.wheelId + '_temp';
    temperatureSensorMetric.metricUnit = 'degreesC';
    newTemperatureSensor = await addNewSensor(sensor.id, temperatureSensorMetric);

    let newVoltageSensor = false;
    let voltageSensorMetric = {};
    voltageSensorMetric.sensorName = "V" + sensor.wheelId;
    voltageSensorMetric.newSensorName = voltageSensorMetric.sensorName.slice(0, 3) + sensor.newSensorId;
    voltageSensorMetric.metricParameter = 't' + sensor.wheelId + '_sensor_volt';
    voltageSensorMetric.metricUnit = 'V';
    newVoltageSensor = await addNewSensor(sensor.id, voltageSensorMetric);
}

async function addNewSensor(unitId, sensorMetric) {

    var newSensorObj = { n: sensorMetric.newSensorName, d: "", f: 0, c: "", vt: 1, vs: 0, tbl: [], m: sensorMetric.metricUnit, p: sensorMetric.metricParameter, t: "custom" };

    let session = global.session;
    if (!session) session = wialon.core.Session.getInstance();
    let unit = session.getItem(unitId);   

    unit.createSensor(newSensorObj,
        function (error, data) { // delete sensor callback
            if (error) {
                console.log("pressuretrackJS: addNewSensor - API ERROR " + error + " (" + wialon.core.Errors.getErrorText(error)) + ")";
                return false;
            }
            else {
                console.log("pressuretrackJS: addNewSensor - SUCCESS (" + data.n + " new sensor added.)"); //getSensors();
                return true;
            }
        }
    );
}

function loadTruckTemplate() {

    let unitDiagramsDiv = document.getElementById('rightpane');
    while (unitDiagramsDiv.hasChildNodes()) {
        unitDiagramsDiv.removeChild(unitDiagramsDiv.lastChild);
    }
    let unitSchematicDiagramFragment = document.createDocumentFragment();
    let unitSchematicDiagramDiv = document.createElement('div');
    unitSchematicDiagramDiv.id = 'rightpane';
    unitSchematicDiagramDiv.className = 'unitdiagram';

    unitSchematicDiagramFragment.appendChild(unitSchematicDiagramDiv);
    unitDiagramsDiv.appendChild(unitSchematicDiagramFragment);

    let schematicDiagramButtonDiv = createSchematicDiagramButtonsElement(); //unit, unitId, selectedUnitSensorIds, uwt
    unitSchematicDiagramDiv.appendChild(schematicDiagramButtonDiv);
   
    let wheelHTML = "";
    let axleIsActive = "";
    for (let axleNo = 2; axleNo < 8; axleNo++) {
        if (axleNo == 2 || axleNo == 3) { axleIsActive = "axleactive"; }
        else { axleIsActive = "axleinactive"; }
        wheelHTML += "<div class='axlewheels'>";
        wheelHTML += "<div class='axle " + axleIsActive + " truckaxle" + axleNo.toString() + "'></div>";
        for (let k = 1; k < 5; k++) {
            let wheelNo = k.toString();
            wheelHTML += "<div class='truckaxle" + axleNo.toString() + "wheel wheel wheelinactive wheel" + wheelNo + "'>" + "</div>";
        }
        wheelHTML += "</div>";
    }

    $("#rightpane").append(
        "<div class='tractor1'>"
        + "<div class='verticalaxle'></div>"
        + "<div class='axlewheels'>"
        + "<div class='axle truckaxle1 axleactive'></div >"        
        + "<div class='truckaxle1wheel supersinglewheel wheel1 wheelinactive'></div>"
        + "<div class='truckaxle1wheel supersinglewheel wheel4 wheelinactive'></div>"
        + "</div>"        
        + "</div>" +
        "<div class='tractor2'></div>" +
        "<div class='tractor2'></div>" +
        "<div class='tractor3'></div>" +
        "</div >" +
        "<div class='trailer'>" +   
        "<div class='spareaxlecenter truckspareaxle'>" +
        "<div class='chartsparewheel wheelinactive'></div>" +
        "<div class='chartsparewheel wheelinactive'></div>" +
        "</div >" +
        wheelHTML +
        "<div class='spareaxlecenter trailer4axlespareaxle'>" +
        "<div class='chartsparewheel wheelinactive'></div>" +
        "<div class='chartsparewheel wheelinactive'></div>" +
        "</div >" +
        "</div>"
    );
}

function getTrailerId(unitId, uwt) {

    let trailerId = 0;

    for (let i = 0; i < uwt.length; i++) {
        if (uwt[i].id === parseInt(unitId)) {

            //currently only ONE trailer per unit
            if (uwt[i].isTrailer) {
                //if (!uwt[i].isTrailer) {
                trailerId = uwt[i].trailerId;
                return trailerId;
            }
        }
    }
    return trailerId;
}

function getLinkedTrailerUnits(unitId, uwt) {

    let trailerUnits = [];    

    for (let i = 0; i < uwt.length; i++) {
        
        if (uwt[i].id === parseInt(unitId)) {

            //currently only ONE trailer per unit
            if (!uwt[i].isTrailer && uwt[i].unitTrailers.length > 0) {
                for (let t = 0; t < uwt[i].unitTrailers.length; t++) {
                    let trailerUnit = {};
                    //if (!uwt[i].isTrailer) {
                    trailerUnit = uwt[i].unitTrailers[t];
                    trailerUnits.push(trailerUnit);
                }                
            }
            return trailerUnits;
        }
    }
}

//async function displaySelectedTrailerInfo(unit, unitPosition) {

//    //var text = "<div class='unitinfo'><div class='unitinfoheader'><h6 id='trailerNameEdit' class='editunitinfo'>" + unit.getName() + "</h6>";
//    let text = "<div class='unitinfo'><div class='unitinfoheader'><h6>" + unit.getName() + "</h6>";
//    let icon = unit.getIconUrl(32);
//    if (icon) text = "<img class='icon' src='" + icon + "' alt='icon'/>" + text + "<br/></div >";

//    //let trailerEdit = document.getElementById("unitName");
//    //trailerEdit.onclick = function () {
//    //    openEditUnitForm(unit.getId(), unit.getName());
//    //}

//    if (unitPosition) {
//        text += "<b>Speed:</b> " + unitPosition.s + " km/h<br/>";
//        let unitLocation = await getUnitLocation(unitPosition);
//        text += "<b>Location:</b> " + unitLocation + "<br/>";
//        let time = wialon.util.DateTime.formatTime(unitPosition.t);
//        text += "<b>Last message:</b> " + time + "<br/>";
//        $("#trailerinfobox").append(text + "</div>");
        
//    } else
//        $("#trailerinfobox").append(text + "<b>Last Message: </b>No GPS Device</div>");

//    $('#trailerinfobox').append('<div class="panebutton"><button class="btn btn-primary btn-block delinkTrailerButton" type="button">Delink Trailer</button></div>');

//    return true;
//}



function checkMinMaxPressureDifference(textbox) {

    let minPressure = parseFloat(document.getElementById("minPressure").value);
    let maxPressure = parseFloat(document.getElementById("maxPressure").value);
    if (minPressure && maxPressure) {
        if (parseFloat(textbox.value) + 2 <= maxPressure) {
            textbox.setCustomValidity("");
        }
        else {
            textbox.setCustomValidity("The difference between the minimum and maximum pressure must be at least 2 bar.");
        }
    }
    return true;     
}

function checkMaxMinPressureDifference(textbox) {

    let minPressure = parseFloat(document.getElementById("minPressure").value);
    let maxPressure = parseFloat(document.getElementById("maxPressure").value);
    if (minPressure && maxPressure) {
        if (minPressure + 2 <= parseFloat(textbox.value)) {
            textbox.setCustomValidity("");
        }
        else {
            textbox.setCustomValidity("The difference between the minimum and maximum pressure must be at least 2 bar.");
        }
    }
    return true;
}

function acceptableMaxMinPressureDifference() {

    let minPressure = parseFloat(document.getElementById("minPressure").value);
    let maxPressure = parseFloat(document.getElementById("maxPressure").value);
        if (minPressure + 2 <= maxPressure) {
            return true;
        }
        else {
            return false;
        }
}

function logout() {
    var sess = wialon.core.Session.getInstance();
    if (sess && sess.getId()) {
        sess.logout(function () {

        });
    }
    logmsg("");
    $(".leftpane").empty();
    $(".middlepane").empty(); //rather deselect
    $('.rightpane').empty()
    //document.getElementById("logout").setAttribute("disabled", "");
    //document.getElementById("login").removeAttribute("disabled");
}

function newDriver(key, m) {
    openNewDriverForm();
}

function ekUpload() {

    function Init() {

        console.log("Upload Initialised");

        let fileSelect = document.getElementById('driverIcon'),
            fileDrag = document.getElementById('file-drag'),
            submitButton = document.getElementById('submit-button');

        document.getElementById('cameraImage').classList.remove("hidden");
        document.getElementById('file-image').classList.add("hidden");
        //document.getElementById('file-image').src = "#";
        //document.getElementById('response').classList.add("hidden");
        document.getElementById('messages').innerHTML = "";

        fileSelect.addEventListener('change', fileSelectHandler, false);

        // Is XHR2 available?
        let xhr = new XMLHttpRequest();
        if (xhr.upload) {
            // File Drop
            fileDrag.addEventListener('dragover', fileDragHover, false);
            fileDrag.addEventListener('dragleave', fileDragHover, false);
            fileDrag.addEventListener('drop', fileSelectHandler, false);
        }
    }

    function fileDragHover(e) {
        let fileDrag = document.getElementById('file-drag');

        e.stopPropagation();
        e.preventDefault();

        fileDrag.className = (e.type === 'dragover' ? 'hover' : 'modal-body driverIcon');
    }

    function fileSelectHandler(e) {
        // Fetch FileList object
        var files = e.target.files || e.dataTransfer.files;

        // Cancel event and hover styling
        fileDragHover(e);

        // Process all File objects
        for (var i = 0, f; f = files[i]; i++) {
            parseFile(f);
            uploadFile(f);
        }
    }

    // Output
    function output(msg) {
        // Response
        var m = document.getElementById('messages');
        m.innerHTML = msg;
    }

    function parseFile(file) {

        console.log(file.name);
        output(
            //'<strong>' + encodeURI(file.name) + '</strong>'
            encodeURI(file.name)
        );

        // var fileType = file.type;
        // console.log(fileType);
        let imageName = file.name;

        let isGood = (/\.(?=gif|jpg|png|jpeg)/gi).test(imageName);
        if (isGood) {
            document.getElementById('start').classList.add("hidden");
            document.getElementById('cameraImage').classList.add("hidden");
            
            document.getElementById('response').classList.remove("hidden");
            // Thumbnail Preview
            document.getElementById('file-image').classList.remove("hidden");
            document.getElementById('file-image').src = URL.createObjectURL(file);
        }
        else {
            document.getElementById('file-image').classList.add("hidden");
            document.getElementById('start').classList.remove("hidden");
            document.getElementById('response').classList.add("hidden");
            document.getElementById("file-upload-form").reset();
        }
    }

    function setProgressMaxValue(e) {
        //var pBar = document.getElementById('file-progress');

        //if (e.lengthComputable) {
        //    pBar.max = e.total;
        //}
    }

    function updateFileProgress(e) {
        //var pBar = document.getElementById('file-progress');

        //if (e.lengthComputable) {
        //    pBar.value = e.loaded;
        //}
    }

    function uploadFile(file) {

        var xhr = new XMLHttpRequest(),
            fileInput = document.getElementById('class-roster-file'),
            //pBar = document.getElementById('file-progress'),
            fileSizeLimit = 1024; // In MB
        if (xhr.upload) {
            // Check if file is less than x MB
            if (file.size <= fileSizeLimit * 1024 * 1024) {
                // Progress bar
                //pBar.style.display = 'inline';
                xhr.upload.addEventListener('loadstart', setProgressMaxValue, false);
                xhr.upload.addEventListener('progress', updateFileProgress, false);

                // File received / failed
                xhr.onreadystatechange = function (e) {
                    if (xhr.readyState == 4) {
                        // Everything is good!

                        // progress.className = (xhr.status == 200 ? "success" : "failure");
                        // document.location.reload(true);
                    }
                };

                // Start upload
                xhr.open('POST', document.getElementById('file-upload-form').action, true);
                xhr.setRequestHeader('X-File-Name', file.name);
                xhr.setRequestHeader('X-File-Size', file.size);
                xhr.setRequestHeader('Content-Type', 'multipart/form-data');
                //xhr.send(file);
            } else {
                output('Please upload a smaller file (< ' + fileSizeLimit + ' MB).');
            }
        }
    }

    // Check for the various File API support.
    if (window.File && window.FileList && window.FileReader) {
        Init();
    } else {
        document.getElementById('file-drag').style.display = 'none';
    }
}

(function ($) {
    'use strict';
    $.contextMenu({
        selector: '.context-menu-hover',
        trigger: 'hover',
        delay: 500,
        autoHide: true,
        callback: function (key, options) {
            if (key === 'edit') {
                let m = "clicked: " + key;
                editDriver(key, m);
            }
            if (key === 'new') {
                let m = "clicked: " + key;
                newDriver(key, m);
            }  
        },
        items: {
            "new": {
                name: "New Driver",
                icon: "add"
            },
            "edit": {
                name: "Edit Driver",
                icon: "edit"
            }
        }
    });
    //<p style="text-align:center" >
    $.fn.overlayMask = function (action) {
        var mask = this.find('.overlay-mask');
        // Create the required mask
        if (!mask.length) {
            this.css({
                position: 'relative'
            });
            mask = $('<div class="overlay-mask"></div>');
            mask.css({
                position: 'absolute',
                display: 'inline-block',
                width: '100%',
                height: '120%',
                top: '0px',
                left: '0px',
                background: 'rgb(0, 0, 0)',
                background: 'rgba(0, 0, 0, 0.5)',
                zIndex: 100
            }).appendTo(this);
        }
        
        // Act based on params
        if (!action || action === 'show') {
            mask.show();
        } else if (action === 'hide') {
            mask.hide();

            //gUser = wialon.core.Session.getInstance().getCurrUser();
            //let userName = wialon.core.Session.getInstance().getCurrUser().getName();
            //let userId = wialon.core.Session.getInstance().getCurrUser().getId();

            //logmsg(gUser.getName());


            //logmsg("Logged successfully")
        }
        return this;
    };
})(jQuery)

export { loadIntervalAsync, getUnits }
