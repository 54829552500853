/// <reference path="sensorchartreport.js" />
import { PRIMARY_COLOUR, SECONDARY_COLOUR, LOW_PRESSURE_COLOUR, HIGH_PRESSURE_COLOUR, HIGH_TEMPERATURE_COLOUR, LOW_VOLTAGE_COLOUR } from "./global";
import { getPressureChartData, getPressureChartAxesData } from "./sensorchartreport"
import { getCurrentLocation } from "./data/getunitdata";
import { getSensorSummaryData, getSensorHistoryData } from "./data/getsensordata";

import { convertUnixTime, formatDate } from "./utilities";
import { loadIntervalAsync } from "./pressuretrack";
import { createUnitMapContainerElement } from "./components/views/unitinfo";
import { loadSensorValuesBatch } from "./components/views/units";
import { DialogBox } from "../thirdparty/customdialog/draggable-resizable-dialog.js";

import { SPARE_AXLE } from "./global";

let map = null, marker = null, mapBox = null; // global variables for maps (leaflet)

var gCustomChartDates = [];

var gPressureChartCanvas = document.getElementById('pressurechartcanvas'); 
var gPressureCtx = gPressureChartCanvas.getContext('2d');
var gTemperatureChartCanvas = document.getElementById('temperaturechartcanvas');
var gTemperatureCtx = gTemperatureChartCanvas.getContext('2d');
var gVoltageChartCanvas = document.getElementById('voltagechartcanvas');
var gVoltageCtx = gVoltageChartCanvas.getContext('2d');
var gPressureChartCounter = 0;
var gTemperatureChartCounter = 0;
var gVoltageChartCounter = 0;
var gChartColourCounter = -1;
var gPressureChart, gTemperatureChart, gVoltageChart;
var gPressureChartMaxY = 0;
var gTemperatureChartMaxY = 0;
var gVoltageChartMaxY = 0;

var gSensorValuesDateRangeRadio = document.getElementById('sensorvaluesdaterange');
var gSensorValuesTodayRadio = document.getElementById('sensorValuesToday');
var gSensorValuesLast7DaysRadio = document.getElementById('sensorValuesLast7Days');
var gSensorValuesLast30DaysRadio = document.getElementById('sensorValuesLast30Days');

var gSensorValuesPressureCheck = document.getElementById('sensorValuesPressure');
var gSensorValuesTemperatureCheck = document.getElementById('sensorValuesTemperature');
var gSensorValuesVoltageCheck = document.getElementById('sensorValuesVoltage');

var gMonthSummaryData;

var gSensorValuesDataToday = [];
var gSensorValuesOutOfRangeDataToday = [];
var gOutOfRangeDataTodayPressure = [];
var gOutOfRangeDataTodayTemperature = [];
var gOutOfRangeDataTodayVoltage = [];
var gSensorValuesData7Days = [];
var gSensorValuesOutOfRangeDataWeek = [];
var gOutOfRangeDataWeekPressure = [];
var gOutOfRangeDataWeekTemperature = [];
var gOutOfRangeDataWeekVoltage = [];
var gSensorValuesData30Days = [];
var gSensorValuesOutOfRangeDataMonth = [];
var gOutOfRangeDataMonthPressure = [];
var gOutOfRangeDataMonthTemperature = [];
var gOutOfRangeDataMonthVoltage = [];
var gSensorValuesDataDateRange = [];
var gOutOfRangeDataDateRange = [];
var gOutOfRangeDataDateRangePressure = [];
var gOutOfRangeDataDateRangeTemperature = [];
var gOutOfRangeDataDateRangeVoltage = [];
var gPrevMinPressureValue, gPrevMaxTemperatureValue, gPrevMinVoltageValue;
var gPressureAnnotation = [];
var gTemperatureAnnotation = [];
var gVoltageAnnotation = [];

var gChartColours = [
    //{ hex: "#1b0e99", used: false }, //0 dark blue purple
    { hex: PRIMARY_COLOUR, used: false }, 
    { hex: "#00600a", used: false }, //1 green
    { hex: "#730000", used: false }, //2 dark red 0.1
    { hex: "#ffb200", used: false }, //3 dark yellow
    { hex: "#0075ff", used: false }, //4 check blue  #ab922e    
    { hex: "#50ae56", used: false }, //5 lighter green
    { hex: "#c8442e", used: false }, //6 red pinkish
    { hex: "#ffff00", used: false }, //7 yellow
    { hex: "#87cefa", used: false }, //8 light sky blue
    { hex: "#aeffac", used: false }, //9 darker lightgreen*
    { hex: "#ffa07a", used: false }, //10 salmonish
    { hex: "#ffffa2", used: false }, //11 darker lightyellow
    { hex: "#8f8fbc", used: false }, //12 purple
    { hex: "#20b2aa", used: false }, //13 lightseagreen
    { hex: "#e553bc", used: false }, //14 pink
    { hex: "#c8df00", used: false }, //15 yellowgreen
    { hex: "#3c3674", used: false }, //16 darkpurple
    { hex: "#306E54", used: false }, //17
    { hex: "#984F6A", used: false }, //18
    { hex: "#8E984F", used: false }, //19
    { hex: "#000025", used: false }, //20 blackblue
    { hex: "#075ea5", used: false }, //21
    { hex: "#e34300", used: false }, //22
    { hex: "#bca68f", used: false }, //23
    { hex: "#006699", used: false }, //24 slateblue
    { hex: "#1A9900", used: false }, //25 green
    { hex: "#7F0099", used: false }, //26 purple
    { hex: "#993300", used: false }, //27 brown
    { hex: "#579bd2", used: false }, //28 
    { hex: "#8fbc8f", used: false }, //29 softgreen
    { hex: "#cd5c5c", used: false }, //30 
    { hex: "#b9b072", used: false }, //31
    { hex: "#b3f2ff", used: false }, //32 
    { hex: "#8fbc8f", used: false }, //33
    { hex: "#FFC0B3", used: false }, //34
    { hex: "#fff0b3", used: false }, //35
    { hex: "#075ea5", used: false }, //35
];

function removePressureChart(wheelAxle) {

    let pressureAnnotation = gPressureAnnotation.filter((value, index, self) =>
        index === self.findIndex((t) => (
            t.minPressureValue === value.minPressureValue
        ))
    );

    if (gPressureChart && gPressureChart.data.datasets.length > 0) {
        if (gPressureChart.config.options.plugins.annotation.annotations.length) {
            if (pressureAnnotation.length === 1) {
                gPressureChart.config.options.plugins.annotation.annotations[0].yMax = pressureAnnotation[0].minPressureValue;
                gPressureChart.config.options.plugins.annotation.annotations[0].yMin = pressureAnnotation[0].minPressureValue;
                gPressureChart.config.options.plugins.annotation.annotations[0].label.content = "Min Press: " + pressureAnnotation[0].minPressureValue + " bar";
                gPressureChart.config.options.plugins.annotation.annotations[1].yMax = pressureAnnotation[0].maxPressureValue;
                gPressureChart.config.options.plugins.annotation.annotations[1].yMin = pressureAnnotation[0].maxPressureValue;
                gPressureChart.config.options.plugins.annotation.annotations[1].label.content = "Max Press: " + pressureAnnotation[0].maxPressureValue + " bar";
                gPressureChart.config.options.plugins.annotation.annotations[2].yMax = pressureAnnotation[0].manufacturersRecommendedPressure;
                gPressureChart.config.options.plugins.annotation.annotations[2].yMin = pressureAnnotation[0].manufacturersRecommendedPressure;
                gPressureChart.config.options.plugins.annotation.annotations[2].label.content = "Rec Press: " + pressureAnnotation[0].manufacturersRecommendedPressure + " bar";

                gPrevMinPressureValue = pressureAnnotation[0].minPressureValue;

                if (gPressureChartMaxY < pressureAnnotation[0].maxPressureValue) {
                    gPressureChartMaxY = parseFloat(pressureAnnotation[0].maxPressureValue) + 1;
                    gPressureChart.config.options.scales.y.max = gPressureChartMaxY;
                }
            }
        }

        for (let p = 0; p < gPressureChart.data.datasets.length; p++) {
            if (gPressureChart.data.datasets[p].label === 'A' + parseInt(wheelAxle.slice(1)) + '-T' + wheelAxle.slice(0, 1)) {
                gPressureChart.data.datasets.splice(p, 1);
                gPressureChart.update();
            }
        }
    }
}

function removeTemperatureChart(wheelAxle) {

    let temperatureAnnotation = [...new Set(gTemperatureAnnotation)];

    if (gTemperatureChart && gTemperatureChart.data.datasets.length > 0) {
        if (gTemperatureChart.config.options.plugins.annotation.annotations.length) {
            if (temperatureAnnotation.length === 1) {
                gTemperatureChart.config.options.plugins.annotation.annotations[0].yMax = temperatureAnnotation[0];
                gTemperatureChart.config.options.plugins.annotation.annotations[0].yMin = temperatureAnnotation[0];
                gTemperatureChart.config.options.plugins.annotation.annotations[0].label.content = "Max Temp: " + temperatureAnnotation[0] + "\u2103";

                gPrevMaxTemperatureValue = temperatureAnnotation[0];

                if (gTemperatureChartMaxY < temperatureAnnotation[0]) {
                    gTemperatureChartMaxY = parseInt(temperatureAnnotation[0]) + 10;
                    gTemperatureChart.config.options.scales.y.max = gTemperatureChartMaxY;
                }
            }
        }

        for (let p = 0; p < gTemperatureChart.data.datasets.length; p++) {
            if (gTemperatureChart.data.datasets[p].label === 'A' + parseInt(wheelAxle.slice(1)) + '-T' + wheelAxle.slice(0, 1)) {
                gTemperatureChart.data.datasets.splice(p, 1);
                gTemperatureChart.update();
            }
        }
    }
}

function removeVoltageChart(wheelAxle) {

    let voltageAnnotation = [...new Set(gVoltageAnnotation)];

    if (gVoltageChart && gVoltageChart.data.datasets.length > 0) {
        if (gVoltageChart.config.options.plugins.annotation.annotations.length) {
            if (voltageAnnotation.length === 1) {
                gVoltageChart.config.options.plugins.annotation.annotations[0].yMax = voltageAnnotation[0];
                gVoltageChart.config.options.plugins.annotation.annotations[0].yMin = voltageAnnotation[0];
                gVoltageChart.config.options.plugins.annotation.annotations[0].label.content = "Min Volt: " + voltageAnnotation[0] + "V";

                gPrevMinVoltageValue = voltageAnnotation[0];

                if (gVoltageChartMaxY < voltageAnnotation[0]) {
                    gVoltageChartMaxY = parseFloat(voltageAnnotation[0]) + 1;
                    gVoltageChart.config.options.scales.y.max = gVoltageChartMaxY;
                }
            }
        }

        for (let p = 0; p < gVoltageChart.data.datasets.length; p++) {
            if (gVoltageChart.data.datasets[p].label === 'A' + parseInt(wheelAxle.slice(1)) + '-T' + wheelAxle.slice(0, 1)) {
                gVoltageChart.data.datasets.splice(p, 1);
                gVoltageChart.update();
            }
        }
    }
}

function updatePressureChart(chartData, tyreInput, wheelAxle) {

    if (gPressureChart.config.options.plugins.annotation.annotations.length) {
        if (parseFloat(gPrevMinPressureValue) !== parseFloat(chartData[0].minPressureValue)) {
            gPressureChart.config.options.plugins.annotation.annotations[0].yMax = '99';
            gPressureChart.config.options.plugins.annotation.annotations[0].yMin = '99';
            gPressureChart.config.options.plugins.annotation.annotations[1].yMax = '99';
            gPressureChart.config.options.plugins.annotation.annotations[1].yMin = '99';
            gPressureChart.config.options.plugins.annotation.annotations[2].yMax = '99';
            gPressureChart.config.options.plugins.annotation.annotations[2].yMin = '99';
            gPrevMinPressureValue = chartData[0].minPressureValue;
        }
    }

    const chartAxesData = getPressureChartAxesData(chartData, tyreInput.chartColour);
    const label = wheelAxle.slice(1) === SPARE_AXLE.toString() ? `S-${wheelAxle.slice(0, 1)}` : 'A' + parseInt(wheelAxle.slice(1)) + '-T' + wheelAxle.slice(0, 1);
    let obj = {
        label: label,
        backgroundColor: chartAxesData.pointBackgroundColours,
        borderColor: chartAxesData.borderColours,
        pointBackgroundColor: chartAxesData.pointBackgroundColours, 
        pointBorderColor: chartAxesData.pointBorderColours,
        segment: chartAxesData.segmentColourConfig,
        data: chartAxesData.dataPoints,
        borderWidth: 0.5,
        radius: 0.85,
        hitRadius: 4.5,
        hoverRaius: 4.5,
        tension: 0.5,
        pointHoverRadius: 4.5
    };
    gPressureChart.data.datasets.push(obj);

    if (gPressureChart.config.options.plugins.annotation.annotations.length) {
        let maxY = Math.max(...chartAxesData.dataPoints.map(c => c.y));
        if (gPressureChart.config.options.plugins.annotation.annotations[0].yMax === '99') {
            if (gPressureChartMaxY <= maxY) gPressureChartMaxY = Math.round(maxY + 1);
        } else {
            const tempMaxY = Math.max(maxY, chartData[0].maxPressureValue);
            gPressureChartMaxY = Math.round(tempMaxY + 2);
        }
        gPressureChart.options.scales.y.max = gPressureChartMaxY;
    }

    gPressureChart.update();
}

function updateTemperatureChart(chartData, tyreInput, wheelAxle) {

    if (gTemperatureChart.config.options.plugins.annotation.annotations.length) {
        if (parseFloat(gPrevMaxTemperatureValue) !== parseFloat(chartData[0].maxTemperatureValue)) {
            gTemperatureChart.config.options.plugins.annotation.annotations[0].yMax = '999';
            gTemperatureChart.config.options.plugins.annotation.annotations[0].yMin = '999';
            gPrevMaxTemperatureValue = chartData[0].maxTemperatureValue;
        }
    }

    let chartAxesData = [];
    for (let i = 0; i < chartData.length; i++) {
        let xyData = {};
        xyData.y = chartData[i].temperature;
        xyData.x = chartData[i].time;
        xyData.sensorId = chartData[i].sensorId;
        xyData.pressure = chartData[i].pressure;
        xyData.voltage = chartData[i].voltage;
        //xyData.address = sensorValuesLocations[i];
        chartAxesData.push(xyData);
    }
    const label = wheelAxle.slice(1) === SPARE_AXLE.toString() ? `S-${wheelAxle.slice(0, 1)}` : 'A' + parseInt(wheelAxle.slice(1)) + '-T' + wheelAxle.slice(0, 1);
    let obj = {
        label: label,
        backgroundColor: tyreInput.chartColour,
        fill: false,
        borderColor: tyreInput.chartColour,
        data: chartAxesData, //yAxisLabels,
        borderWidth: 0.5,
        radius: 0.85,
        hitRadius: 4.5,
        hoverRaius: 4.5,
        tension: 0.5,
        pointHoverRadius: 4.5
    };
    gTemperatureChart.data.datasets.push(obj);

    if (gTemperatureChart.config.options.plugins.annotation.annotations.length) {
        let maxY = Math.max(...chartAxesData.map(c => c.y));
        if (gTemperatureChart.config.options.plugins.annotation.annotations[0].yMax === '999') {
            if (gTemperatureChartMaxY <= maxY) gTemperatureChartMaxY = Math.round(maxY + 5);
        } else {
            const tempMaxY = Math.max(maxY, tyreInput.maxTemperatureValue);
            gTemperatureChartMaxY = Math.round(tempMaxY + 5);
        }
        gTemperatureChart.options.scales.y.max = gTemperatureChartMaxY;
    }

    gTemperatureChart.update();
}

function updateVoltageChart(chartData, tyreInput, wheelAxle) {

    if (gVoltageChart.config.options.plugins.annotation.annotations.length) {
        if (parseFloat(gPrevMinVoltageValue) !== parseFloat(chartData[0].minVoltageValue)) {
            gVoltageChart.config.options.plugins.annotation.annotations[0].yMax = '99';
            gVoltageChart.config.options.plugins.annotation.annotations[0].yMin = '99';
            gPrevMinVoltageValue = chartData[0].minVoltageValue;
        }
    }

    let chartAxesData = [];
    for (let i = 0; i < chartData.length; i++) {
        let xyData = {};
        xyData.y = chartData[i].voltage;
        xyData.x = chartData[i].time;
        xyData.sensorId = chartData[i].sensorId;
        xyData.pressure = chartData[i].pressure;
        xyData.temperature = chartData[i].temperature;
        //xyData.address = sensorValuesLocations[i];
        chartAxesData.push(xyData);
    }

    //let chartColour = generateDarkColorRgb();
    const label = wheelAxle.slice(1) === SPARE_AXLE.toString() ? `S-${wheelAxle.slice(0, 1)}` : 'A' + parseInt(wheelAxle.slice(1)) + '-T' + wheelAxle.slice(0, 1);
    let obj = {
        label: label,
        backgroundColor: tyreInput.chartColour,
        fill: false,
        borderColor: tyreInput.chartColour,
        data: chartAxesData, //yAxisLabels,
        borderWidth: 0.5,
        radius: 0.85,
        hitRadius: 4.5,
        hoverRaius: 4.5,
        tension: 0.5,
        pointHoverRadius: 4.5
    };
    gVoltageChart.data.datasets.push(obj);

    if (gVoltageChart.config.options.plugins.annotation.annotations.length) {
        let maxY = Math.max(...chartAxesData.map(c => c.y));
        if (gVoltageChart.config.options.plugins.annotation.annotations[0].yMax === '99') {
            if (gVoltageChartMaxY <= maxY) gVoltageChartMaxY = Math.round(maxY + 1);
        } else {
            const tempMaxY = Math.max(maxY, tyreInput.minVoltageValue);
            gVoltageChartMaxY = Math.round(tempMaxY + 2);
        }
        gVoltageChart.options.scales.y.max = gVoltageChartMaxY;
    }

    gVoltageChart.update();
}

function random_rgba() {
    var o = Math.round, r = Math.random, s = 255;
    return 'rgba(' + o(r() * s) + ',' + o(r() * s) + ',' + o(r() * s) + ',' + r().toFixed(1) + ')';
}

function generateDarkColorRgb() {

    const red = Math.floor(Math.random() * 256);
    let green = 255;
    if (red < 225)
        green = Math.floor(Math.random() * 256);
    let blue = 255
    if (green < 225)
        blue = Math.floor(Math.random() * 256);
    return "rgb(" + red + ", " + green + ", " + blue + ")";
}

function createTyreAxleElements(unit, axle, axleSensors, wheelConfig) {

    let tyreAxleDiv = document.createElement('div');
    tyreAxleDiv.className = 'chartwheelaxle';
    let isAxleActive = getAxleActiveStatus(unit, axle);
    let axledivclasses = [];
    let axleStatusClass = '';
    isAxleActive ? axleStatusClass = 'chartaxleactive' : axleStatusClass = 'chartaxleinactive';

    if (wheelConfig === 0) {
        let tyredivclasses = ['chartsupersinglewheel', 'chartwheelblue'];
        let tyre1Div = createTyreElement(unit, 1, axleSensors, tyredivclasses); //unit, tyre, axleSensors, tyredivclasses
        tyreAxleDiv.appendChild(tyre1Div);

        axledivclasses = ['supersinglewheelaxle', 'axlehoverdisabled', axleStatusClass];
        let axleDiv = createAxleElement(unit, axle, axleSensors, axledivclasses);
        tyreAxleDiv.appendChild(axleDiv);

        let tyre4Div = createTyreElement(unit, 4, axleSensors, tyredivclasses);
        tyreAxleDiv.appendChild(tyre4Div);
    } else {
        for (let s = 1; s <= 2; s++) {

            let tyredivclasses = ['chartwheel', 'chartwheelblue'];
            let tyreDiv = createTyreElement(unit, s, axleSensors, tyredivclasses);
            tyreAxleDiv.appendChild(tyreDiv);
        }

        axledivclasses = ['chartaxle', 'axlehoverdisabled', axleStatusClass];
        let axleDiv = createAxleElement(unit, axle, axleSensors, axledivclasses);
        tyreAxleDiv.appendChild(axleDiv);

        for (let s = 3; s <= 4; s++) {

            let tyredivclasses = ['chartwheel', 'chartwheelblue'];
            let tyreDiv = createTyreElement(unit, s, axleSensors, tyredivclasses);
            tyreAxleDiv.appendChild(tyreDiv);

            //let tyreSensorValuesDiv = createTyreInfoElement(axle, s, axleSensors);
            //tyreAxleDiv.appendChild(tyreSensorValuesDiv);
        }
    }

    return tyreAxleDiv;
}

function createSpareTyreAxleElements(unit, axleSensors) {

    let isAxleActive = getAxleActiveStatus(unit, "9");

    let tyreSpareAxleDiv = document.createElement('div');
    tyreSpareAxleDiv.className = 'chartwheelaxle';

    let tyredivclasses = ['chartsparewheel', 'wheelinactive'];

    if (axleSensors.length > 0) {

        //Spare 1
        let tyredivclasses = ['chartsparewheel', 'chartwheelblue'];

        let tyreDiv1 = createTyreElement(unit, 1, axleSensors, tyredivclasses);
        tyreSpareAxleDiv.appendChild(tyreDiv1);

        //Spare Axle
        let axledivclasses = [];
        let axleStatusClass = '';
        isAxleActive ? axleStatusClass = 'axleactive' : axleStatusClass = 'axleinactive';
        axledivclasses = ['spareaxle', 'axlehoverdisabled', axleStatusClass]; //'spareaxle'
        let spareAxle = createAxleElement(unit, "S", axleSensors, axledivclasses); //'spareaxle');
        tyreSpareAxleDiv.appendChild(spareAxle);

        //Spare 2
        let tyreDiv2 = createTyreElement(unit, 2, axleSensors, tyredivclasses);
        tyreSpareAxleDiv.appendChild(tyreDiv2);

    } else {

        //Spare 1 Inactive
        let tyreDiv1 = createTyreElement(unit, 1, axleSensors, tyredivclasses);
        tyreSpareAxleDiv.appendChild(tyreDiv1);

        //Spare 2 Inactive
        let tyreDiv2 = createTyreElement(unit, 2, axleSensors, tyredivclasses);
        tyreSpareAxleDiv.appendChild(tyreDiv2);
    }

    return tyreSpareAxleDiv;
}

function createAxleElement(unit, axle, axleSensors, axledivclasses) {

    let axleDiv = document.createElement('div');
    axleDiv.classList.add(...axledivclasses);

    axleDiv.onclick = function (e) {
        e.stopImmediatePropagation();
        if (axle >= 4) {
            if (unit.hasOwnProperty('trailers')) {
                if (unit.unitTrailers[0] != null) unit = unit.unitTrailers[0];
            }
        } //set trailer's axle properties
        openValuesOutOfRangeForm(unit, axleSensors);
    };

    return axleDiv;
}

function getAxleActiveStatus(unit, axle) {

    let notifications = global.resourceNotifications.notifications;
    for (let key in notifications) {
        if (!notifications.hasOwnProperty(key)) continue;
        let obj = notifications[key];
        if (obj.n.substring(0, obj.n.length - 9) === unit.name + "-P" + axle) {
            return true;
        }
    }
    return false;
}

export function createReportsAxleElements(unit, unitNumber, axle, wheelConfig) {

    let tyreAxleDiv = document.createElement('div');
    tyreAxleDiv.className = 'chartwheelaxle';

    if (axle === 'SPARE' && unit.spareSensors.length) {
        for (let s = 0; s < unit.spareSensors.length; s++) {

            let tyredivclasses = ['chartwheel', 'chartwheelblue'];
            let tyreDiv = createTyreElement(unit, s + +1, unit.spareSensors, tyredivclasses);
            tyreAxleDiv.appendChild(tyreDiv);
        }
    } else {
        let isAxleActive = getAxleActiveStatus(unit, axle);
        let axledivclasses = [];
        let axleStatusClass = '';
        isAxleActive ? axleStatusClass = 'chartaxleactive' : axleStatusClass = 'chartaxleinactive';

        const axleSensors = unit.wheelSensors.filter(s => parseInt(s.sensorName.slice(1, 3)) === axle && parseInt(s.sensorName.slice(3)) === unitNumber);

        if (wheelConfig === 0) {
            let tyredivclasses = ['chartsupersinglewheel', 'chartwheelblue'];
            let tyre1Div = createTyreElement(unit, 1, axleSensors, tyredivclasses); //unit, tyre, axleSensors, tyredivclasses
            tyreAxleDiv.appendChild(tyre1Div);

            axledivclasses = ['supersinglewheelaxle', 'axlehoverdisabled', axleStatusClass];
            let axleDiv = createAxleElement(unit, axle, axleSensors, axledivclasses);
            tyreAxleDiv.appendChild(axleDiv);

            let tyre4Div = createTyreElement(unit, 4, axleSensors, tyredivclasses);
            tyreAxleDiv.appendChild(tyre4Div);
        } else {
            for (let s = 1; s <= 2; s++) {
                let tyredivclasses = ['chartwheel', 'chartwheelblue'];
                let tyreDiv = createTyreElement(unit, s, axleSensors, tyredivclasses);
                tyreAxleDiv.appendChild(tyreDiv);
            }

            axledivclasses = ['chartaxle', 'axlehoverdisabled', axleStatusClass];
            let axleDiv = createAxleElement(unit, axle, axleSensors, axledivclasses);
            tyreAxleDiv.appendChild(axleDiv);

            for (let s = 3; s <= 4; s++) {
                let tyredivclasses = ['chartwheel', 'chartwheelblue'];
                let tyreDiv = createTyreElement(unit, s, axleSensors, tyredivclasses);
                tyreAxleDiv.appendChild(tyreDiv);

                //let tyreSensorValuesDiv = createTyreInfoElement(axle, s, axleSensors);
                //tyreAxleDiv.appendChild(tyreSensorValuesDiv);
            }
        }
    }

    return tyreAxleDiv;
}

function createTyreElement(unit, wheel, axleSensors, tyredivclasses) {

    let tyreInput = document.createElement('input');
    tyreInput.type = 'checkbox';
    tyreInput.name = 'wheel';
    if (axleSensors.length)
        tyreInput.id = unit.id + 'wheelinput' + wheel + axleSensors[0].sensorName.slice(1); //nuwe config

    if (axleSensors.length > 0) {
        tyreInput.minPressureValue = axleSensors[0]?.minPressureValue || 0;
        tyreInput.maxPressureValue = axleSensors[0]?.maxPressureValue || 18;
        tyreInput.manufacturersRecommendedPressure = axleSensors[0]?.manufacturersRecommendedPressure || 5;
        tyreInput.maxTemperatureValue = axleSensors[0]?.maxTemperatureValue || 200;
        tyreInput.minVoltageValue = axleSensors[0]?.minVoltageValue || 0;
    }

    let tyreSlider = document.createElement('span');
    if (axleSensors.length > 0)
        tyreSlider.id = unit.id + 'wheelslider' + wheel + axleSensors[0].sensorName.slice(1);
    if (tyredivclasses.includes("chartsparewheel")) {
        if (!tyredivclasses.includes("wheelinactive"))
            tyreSlider.className = 'spareslider';
    }
    else
        tyreSlider.className = 'slider';

    let tyreSwitch = document.createElement('label');
    if (axleSensors.length)
            tyreSwitch.id = unit.id + 'tyreswitch' + wheel + axleSensors[0].sensorName.slice(1);

    if (!tyredivclasses.includes("wheelinactive")) {
        tyreSwitch.appendChild(tyreInput);
        tyreSwitch.appendChild(tyreSlider);
    }
    
    tyreSwitch.classList.add(...tyredivclasses);

    tyreInput.onclick = async (e) => {

        document.body.style.cursor = 'wait';

        if (tyreInput.checked == true) {
            gChartColourCounter++;
            for (let colour in gChartColours) {
                if (!gChartColours[colour].used) {
                    gChartColours[colour].used = true;
                    tyreInput.colourIndex = colour;
                    tyreInput.chartColour = gChartColours[colour].hex;
                    break;
                }
            }
            if (tyredivclasses.includes("chartsparewheel"))
                tyreSlider.className = 'spareslider + chart' + tyreInput.colourIndex;
            else
                tyreSlider.className = 'slider + chart' + tyreInput.colourIndex;

            if (tyreInput.minPressureValue != null) {  //'Out of Range Values' are defined
                const pressureAnnotation = {};
                pressureAnnotation.minPressureValue = tyreInput.minPressureValue;
                pressureAnnotation.manufacturersRecommendedPressure = tyreInput.manufacturersRecommendedPressure;
                pressureAnnotation.maxPressureValue = tyreInput.maxPressureValue;
                gPressureAnnotation.push(pressureAnnotation);
                gTemperatureAnnotation.push(tyreInput.maxTemperatureValue);
                gVoltageAnnotation.push(tyreInput.minVoltageValue);
            }

            if (gSensorValuesTodayRadio.checked) {
                const dateDay = new Date();
                dateDay.setDate(dateDay.getDate() - 1);
                const sensor = axleSensors.find(s => s.sensorName === `${wheel}${axleSensors[0].sensorName.slice(1)}`);
                const chartDataDay = await getSensorHistoryData(unit, sensor, formatDate(dateDay));

                //let sensorValuesLocations = await getUnitLocations(locations);

                if (gPressureChartCounter === 0) {
                    createPressureChart(unit.imei, chartDataDay, "TODAY");
                    //createPressureChart(chartDataDay, "TODAY", tyreInput, wheel + axleSensors[0].sensorName.slice(1, 3));
                } else {
                    updatePressureChart(chartDataDay, tyreInput, wheel + axleSensors[0].sensorName.slice(1, 3));
                }
                gPressureChartCounter++;

                if (gTemperatureChartCounter === 0) {
                    createTemperatureChart(unit.imei, chartDataDay, "TODAY");
                    //createTemperatureChart(chartDataDay, "TODAY", tyreInput, wheel + axleSensors[0].sensorName.slice(1, 3));
                } else {
                    updateTemperatureChart(chartDataDay, tyreInput, wheel + axleSensors[0].sensorName.slice(1, 3));
                }
                gTemperatureChartCounter++;

                if (gVoltageChartCounter === 0) {
                    createVoltageChart(unit.imei, chartDataDay, "TODAY");
                    //createVoltageChart(chartDataDay, "TODAY", tyreInput, wheel + axleSensors[0].sensorName.slice(1, 3));
                } else {
                    updateVoltageChart(chartDataDay, tyreInput, wheel + axleSensors[0].sensorName.slice(1, 3));
                }
                gVoltageChartCounter++;
            }

            if (gSensorValuesLast7DaysRadio.checked) {
                const dateWeek = new Date();
                dateWeek.setDate(dateWeek.getDate() - 7);
                const sensor = axleSensors.find(s => s.sensorName === `${wheel}${axleSensors[0].sensorName.slice(1)}`);
                const chartDataWeek = await getSensorHistoryData(unit, sensor, formatDate(dateWeek));

                //let locations = [];
                //for (let i = 0; i < chartData.length; i++) {
                //    let location = {};
                //    location.lon = chartData[i].posx;
                //    location.lat = chartData[i].posy;
                //    locations.push(location);
                //}
                //let sensorValuesLocations = await getUnitLocations(locations);

                if (gPressureChartCounter === 0) {
                    createPressureChart(unit.imei, chartDataWeek, "SEVEN DAYS", tyreInput, wheel + axleSensors[0].sensorName.slice(1, 3));
                } else {
                    updatePressureChart(chartDataWeek, tyreInput, wheel + axleSensors[0].sensorName.slice(1, 3));
                }
                gPressureChartCounter++;

                if (gTemperatureChartCounter === 0) {
                    createTemperatureChart(unit.imei, chartDataWeek, "SEVEN DAYS", tyreInput, wheel + axleSensors[0].sensorName.slice(1, 3));
                } else {
                    updateTemperatureChart(chartDataWeek, tyreInput, wheel + axleSensors[0].sensorName.slice(1, 3));
                }
                gTemperatureChartCounter++;

                if (gVoltageChartCounter === 0) {
                    createVoltageChart(unit.imei, chartDataWeek, "SEVEN DAYS", tyreInput, wheel + axleSensors[0].sensorName.slice(1, 3));
                } else {
                    updateVoltageChart(chartDataWeek, tyreInput, wheel + axleSensors[0].sensorName.slice(1, 3));
                }
                gVoltageChartCounter++;
            }

            if (gSensorValuesLast30DaysRadio.checked) {

                const dateMonth = new Date();
                dateMonth.setDate(dateMonth.getDate() - 30);
                const sensor = axleSensors.find(s => s.sensorName === `${wheel}${axleSensors[0].sensorName.slice(1)}`);
                const chartDataMonth = await getSensorHistoryData(unit, sensor, formatDate(dateMonth));

                //let locations = [];
                //for (let i = 0; i < chartData.length; i++) {
                //    let location = {};
                //    location.lon = chartData[i].posx;
                //    location.lat = chartData[i].posy;
                //    locations.push(location);
                //}
                //let sensorValuesLocations = await getUnitLocations(locations);

                if (gPressureChartCounter === 0) {
                    //createPressureChart(chartDataMonth, "THIRTY DAYS", tyreInput, wheel + axleSensors[0].sensorName.slice(1, 3));
                    createPressureChart(unit.imei, chartDataMonth, "THIRTY DAYS");
                } else {
                    updatePressureChart(chartDataMonth, tyreInput, wheel + axleSensors[0].sensorName.slice(1, 3));
                }
                gPressureChartCounter++;

                if (gTemperatureChartCounter === 0) {
                    //createTemperatureChart(chartDataMonth, "THIRTY DAYS", tyreInput, wheel + axleSensors[0].sensorName.slice(1, 3));
                    createTemperatureChart(unit.imei, chartDataMonth, "THIRTY DAYS");
                } else {
                    updateTemperatureChart(chartDataMonth, tyreInput, wheel + axleSensors[0].sensorName.slice(1, 3));
                }
                gTemperatureChartCounter++;

                if (gVoltageChartCounter === 0) {
                    //createVoltageChart(chartDataMonth, "THIRTY DAYS", tyreInput, wheel + axleSensors[0].sensorName.slice(1, 3));
                    createVoltageChart(unit.imei, chartDataMonth, "THIRTY DAYS");
                } else {
                    updateVoltageChart(chartDataMonth, tyreInput, wheel + axleSensors[0].sensorName.slice(1, 3));
                }
                gVoltageChartCounter++;
            }

            if (gSensorValuesDateRangeRadio.checked) {

                const sensor = axleSensors.find(s => s.sensorName === `${wheel}${axleSensors[0].sensorName.slice(1)}`);
                const chartDataCustomDates = await getSensorHistoryData(unit, sensor, gCustomChartDates);

                //let locations = [];
                //for (let i = 0; i < chartData.length; i++) {
                //    let location = {};
                //    location.lon = chartData[i].posx;
                //    location.lat = chartData[i].posy;
                //    locations.push(location);
                //}
                //let sensorValuesLocations = await getUnitLocations(locations);

                if (gPressureChartCounter === 0) {
                    createPressureChart(unit.imei, chartDataCustomDates, "CUSTOM DATES", tyreInput, wheel + axleSensors[0].sensorName.slice(1, 3));
                } else {
                    updatePressureChart(chartDataCustomDates, tyreInput, wheel + axleSensors[0].sensorName.slice(1, 3));
                }
                gPressureChartCounter++;

                if (gTemperatureChartCounter === 0) {
                    createTemperatureChart(unit.imei, chartDataCustomDates, "CUSTOM DATES", tyreInput, wheel + axleSensors[0].sensorName.slice(1, 3));
                } else {
                    updateTemperatureChart(chartDataCustomDates, tyreInput, wheel + axleSensors[0].sensorName.slice(1, 3));
                }
                gTemperatureChartCounter++;

                if (gVoltageChartCounter === 0) {
                    createVoltageChart(unit.imei, chartDataCustomDates, "CUSTOM DATES", tyreInput, wheel + axleSensors[0].sensorName.slice(1, 3));
                } else {
                    updateVoltageChart(chartDataCustomDates, tyreInput, wheel + axleSensors[0].sensorName.slice(1, 3));
                }
                gVoltageChartCounter++;

            }

        } else {

            if (gPressureChart.data.datasets.length === 1) {
                clearChartOptions(unit);
            } else {
                tyreSlider.classList.remove('chart' + tyreInput.colourIndex);
                gChartColours[tyreInput.colourIndex].used = false;

                const pa = {};
                pa.minPressureValue = tyreInput.minPressureValue;
                pa.manufacturersRecommendedPressure = tyreInput.manufacturersRecommendedPressure;
                pa.maxPressureValue = tyreInput.maxPressureValue;
                const indexOfPressureAnnotation = gPressureAnnotation.findIndex(object => {
                    return object.manufacturersRecommendedPressure === pa.manufacturersRecommendedPressure;
                });
                if (indexOfPressureAnnotation !== -1) gPressureAnnotation.splice(indexOfPressureAnnotation, 1);

                //removePressureChart(axleSensors[0].sensorName.slice(0, 1) + wheel);
                removePressureChart(wheel + axleSensors[0].sensorName.slice(1, 3));

                gTemperatureAnnotation.indexOf(tyreInput.maxTemperatureValue) !== -1 && gTemperatureAnnotation.splice(gTemperatureAnnotation.indexOf(tyreInput.maxTemperatureValue), 1);

                //removeTemperatureChart(axleSensors[0].sensorName.slice(0, 1) + wheel);
                removeTemperatureChart(wheel + axleSensors[0].sensorName.slice(1, 3));

                gVoltageAnnotation.indexOf(tyreInput.minVoltageValue) !== -1 && gVoltageAnnotation.splice(gVoltageAnnotation.indexOf(tyreInput.minVoltageValue), 1);

                //removeVoltageChart(axleSensors[0].sensorName.slice(0, 1) + wheel);
                removeVoltageChart(wheel + axleSensors[0].sensorName.slice(1, 3));
            }
        };
        document.body.style.cursor = 'default';
    }    
    return tyreSwitch; 
}

function clearChartGlobals(unit) {

    clearChartOptions(unit);

    gCustomChartDates = [];
    document.getElementById('pressurechartdate').innerText = "";
    document.getElementById('temperaturechartdate').innerText = "";
    document.getElementById('voltagechartdate').innerText = "";
      
    gSensorValuesDataDateRange = [];
    gOutOfRangeDataDateRange = [];
    gOutOfRangeDataDateRangePressure = [];
    gOutOfRangeDataDateRangeTemperature = [];
    gOutOfRangeDataDateRangeVoltage = [];
    gSensorValuesDataToday = [];
    gSensorValuesOutOfRangeDataToday = [];
    gOutOfRangeDataTodayPressure = [];
    gOutOfRangeDataTodayTemperature = [];
    gOutOfRangeDataTodayVoltage = [];
    gSensorValuesData7Days = [];
    gSensorValuesOutOfRangeDataWeek = [];
    gOutOfRangeDataWeekPressure = [];
    gOutOfRangeDataWeekTemperature = [];
    gOutOfRangeDataWeekVoltage = [];
    gSensorValuesData30Days = [];
    gSensorValuesOutOfRangeDataMonth = [];
    gOutOfRangeDataMonthPressure = [];
    gOutOfRangeDataMonthTemperature = [];
    gOutOfRangeDataMonthVoltage = [];

    gSensorValuesDateRangeRadio.checked = false;
    gSensorValuesTodayRadio.checked = false;
    gSensorValuesLast7DaysRadio.checked = false;
    gSensorValuesLast30DaysRadio.checked = false;

    gSensorValuesPressureCheck.checked = false;
    gSensorValuesTemperatureCheck.checked = false;
    gSensorValuesVoltageCheck.checked = false;
}

function updateChartDisplays(unit) {

    if (gSensorValuesPressureCheck.checked && gSensorValuesTemperatureCheck.checked && gSensorValuesVoltageCheck.checked) {

        if (gSensorValuesDateRangeRadio.checked) getWheelColoursPressure(unit.id, unit.reportDataCustom);
        if (gSensorValuesTodayRadio.checked) getWheelColoursPressure(unit.id, unit.reportDataDay);
        if (gSensorValuesLast7DaysRadio.checked) getWheelColoursPressure(unit.id, unit.reportDataWeek);
        if (gSensorValuesLast30DaysRadio.checked) getWheelColoursPressure(unit.id, unit.reportDataMonth);

        const pressureChart = document.getElementById('pressurechart');
        pressureChart.classList.remove(...pressureChart.classList);
        const pressureChartClasses = ['threecharts', 'twoofthreecharts'];
        pressureChart.classList.add(...pressureChartClasses);
        pressureChart.style.display = 'block';

        const temperatureChart = document.getElementById('temperaturechart');
        temperatureChart.classList.remove(...temperatureChart.classList);
        const temperatureChartClasses = ['threecharts', 'twoofthreecharts'];
        temperatureChart.classList.add(...temperatureChartClasses);
        temperatureChart.style.display = 'block';

        const voltageChart = document.getElementById('voltagechart');
        voltageChart.classList.remove(...voltageChart.classList);
        const voltageChartClasses = ['threecharts', 'oneofthreecharts'];
        voltageChart.classList.add(...voltageChartClasses);
        voltageChart.style.display = 'block';
    }

    if (gSensorValuesPressureCheck.checked && !gSensorValuesTemperatureCheck.checked && !gSensorValuesVoltageCheck.checked) {

        if (gSensorValuesDateRangeRadio.checked) {
            if (unit.reportDataCustom) getWheelColoursPressure(unit.id, unit.reportDataCustom);
            else resetWheelColours(unit.id, unit.reportDataMonth);
        }
        if (gSensorValuesTodayRadio.checked) getWheelColoursPressure(unit.id, unit.reportDataDay);
        if (gSensorValuesLast7DaysRadio.checked) getWheelColoursPressure(unit.id, unit.reportDataWeek);
        if (gSensorValuesLast30DaysRadio.checked) getWheelColoursPressure(unit.id, unit.reportDataMonth);

        let pressureChart = document.getElementById('pressurechart');
        pressureChart.classList.remove(...pressureChart.classList);
        let pressureChartClasses = ['threecharts', 'chart'];
        pressureChart.classList.add(...pressureChartClasses);
        pressureChart.style.display = 'block';

        let temperatureChart = document.getElementById('temperaturechart');
        temperatureChart.classList.remove(...temperatureChart.classList);
        //let temperatureChartClasses = ['threecharts', 'twocharts'];
        //temperatureChart.classList.add(...temperatureChartClasses);
        temperatureChart.style.display = 'none';

        let voltageChart = document.getElementById('voltagechart');
        voltageChart.classList.remove(...voltageChart.classList);
        //let voltageChartClasses = ['threecharts', 'twocharts'];
        //voltageChart.classList.add(...voltageChartClasses);
        voltageChart.style.display = 'none';
    }

    if (gSensorValuesPressureCheck.checked && gSensorValuesTemperatureCheck.checked && !gSensorValuesVoltageCheck.checked) {

        if (gSensorValuesDateRangeRadio.checked) getWheelColoursPressure(unit.id, unit.reportDataCustom);
        if (gSensorValuesTodayRadio.checked) getWheelColoursPressure(unit.id, unit.reportDataDay);
        if (gSensorValuesLast7DaysRadio.checked) getWheelColoursPressure(unit.id, unit.reportDataWeek);
        if (gSensorValuesLast30DaysRadio.checked) getWheelColoursPressure(unit.id, unit.reportDataMonth);

        let pressureChart = document.getElementById('pressurechart');
        pressureChart.classList.remove(...pressureChart.classList);
        let pressureChartClasses = ['threecharts', 'twocharts'];
        pressureChart.classList.add(...pressureChartClasses);
        pressureChart.style.display = 'block';

        let temperatureChart = document.getElementById('temperaturechart');
        temperatureChart.classList.remove(...temperatureChart.classList);
        let temperatureChartClasses = ['threecharts', 'twocharts'];
        temperatureChart.classList.add(...temperatureChartClasses);
        temperatureChart.style.display = 'block';

        let voltageChart = document.getElementById('voltagechart');
        voltageChart.classList.remove(...voltageChart.classList);
        //let voltageChartClasses = ['threecharts', 'twocharts'];
        //voltageChart.classList.add(...voltageChartClasses);
        voltageChart.style.display = 'none';
    }

    if (gSensorValuesPressureCheck.checked && !gSensorValuesTemperatureCheck.checked && gSensorValuesVoltageCheck.checked) {
        
        if (gSensorValuesDateRangeRadio.checked) getWheelColoursPressure(unit.id, unit.reportDataCustom);
        if (gSensorValuesTodayRadio.checked) getWheelColoursPressure(unit.id, unit.reportDataDay);
        if (gSensorValuesLast7DaysRadio.checked) getWheelColoursPressure(unit.id, unit.reportDataWeek);
        if (gSensorValuesLast30DaysRadio.checked) getWheelColoursPressure(unit.id, unit.reportDataMonth);

        let pressureChart = document.getElementById('pressurechart');
        pressureChart.classList.remove(...pressureChart.classList);
        let pressureChartClasses = ['threecharts', 'twocharts'];
        pressureChart.classList.add(...pressureChartClasses);
        pressureChart.style.display = 'block';

        let temperatureChart = document.getElementById('temperaturechart');
        temperatureChart.classList.remove(...temperatureChart.classList);
        temperatureChart.style.display = 'none';

        let voltageChart = document.getElementById('voltagechart');
        voltageChart.classList.remove(...voltageChart.classList);
        let voltageChartClasses = ['threecharts', 'twocharts'];
        voltageChart.classList.add(...voltageChartClasses);
        voltageChart.style.display = 'block';
    }

    if (!gSensorValuesPressureCheck.checked && gSensorValuesTemperatureCheck.checked && !gSensorValuesVoltageCheck.checked) {

        if (gSensorValuesDateRangeRadio.checked) getWheelColoursTemperature(unit.id, unit.reportDataCustom);
        if (gSensorValuesTodayRadio.checked) getWheelColoursTemperature(unit.id, unit.reportDataDay);
        if (gSensorValuesLast7DaysRadio.checked) getWheelColoursTemperature(unit.id, unit.reportDataWeek);
        if (gSensorValuesLast30DaysRadio.checked) getWheelColoursTemperature(unit.id, unit.reportDataMonth);

        let pressureChart = document.getElementById('pressurechart');
        pressureChart.classList.remove(...pressureChart.classList);
        pressureChart.style.display = 'none';

        let temperatureChart = document.getElementById('temperaturechart');
        temperatureChart.classList.remove(...temperatureChart.classList);
        let temperatureChartClasses = ['threecharts', 'chart'];
        temperatureChart.classList.add(...temperatureChartClasses);
        temperatureChart.style.display = 'block';

        let voltageChart = document.getElementById('voltagechart');
        voltageChart.classList.remove(...voltageChart.classList);
        voltageChart.style.display = 'none';
    }

    if (!gSensorValuesPressureCheck.checked && gSensorValuesTemperatureCheck.checked && gSensorValuesVoltageCheck.checked) {

        //let outOfRangeDataToday = [...gOutOfRangeDataTodayTemperature, ...gOutOfRangeDataTodayVoltage];
        //outOfRangeDataToday = outOfRangeDataToday.reduce((unique, o) => {
        //    if (!unique.some(obj => obj.id === o.id && obj.sensorName === o.sensorName)) {
        //        unique.push(o);
        //    }
        //    return unique;
        //}, []);

        //let outOfRangeDataWeek = [...gOutOfRangeDataWeekTemperature, ...gOutOfRangeDataWeekVoltage];
        //outOfRangeDataWeek = outOfRangeDataWeek.reduce((unique, o) => {
        //    if (!unique.some(obj => obj.id === o.id && obj.sensorName === o.sensorName)) {
        //        unique.push(o);
        //    }
        //    return unique;
        //}, []);

        //let outOfRangeDataMonth = [...gOutOfRangeDataMonthTemperature, ...gOutOfRangeDataMonthVoltage];
        //outOfRangeDataMonth = outOfRangeDataMonth.reduce((unique, o) => {
        //    if (!unique.some(obj => obj.id === o.id && obj.sensorName === o.sensorName)) {
        //        unique.push(o);
        //    }
        //    return unique;
        //}, []);

        if (gSensorValuesDateRangeRadio.checked) getWheelColoursTemperature(unit.id, unit.reportDataCustom);
        if (gSensorValuesTodayRadio.checked) getWheelColoursTemperature(unit.id, unit.reportDataDay);
        if (gSensorValuesLast7DaysRadio.checked) getWheelColoursTemperature(unit.id, unit.reportDataWeek);
        if (gSensorValuesLast30DaysRadio.checked) getWheelColoursTemperature(unit.id, unit.reportDataMonth);

        let pressureChart = document.getElementById('pressurechart');
        pressureChart.classList.remove(...pressureChart.classList);
        pressureChart.style.display = 'none';

        let temperatureChart = document.getElementById('temperaturechart');
        temperatureChart.classList.remove(...temperatureChart.classList);
        let temperatureChartClasses = ['threecharts', 'twocharts'];
        temperatureChart.classList.add(...temperatureChartClasses);
        temperatureChart.style.display = 'block';

        let voltageChart = document.getElementById('voltagechart');
        voltageChart.classList.remove(...voltageChart.classList);
        let voltageChartClasses = ['threecharts', 'twocharts'];
        voltageChart.classList.add(...voltageChartClasses);
        voltageChart.style.display = 'block';
    }

    if (!gSensorValuesPressureCheck.checked && !gSensorValuesTemperatureCheck.checked && gSensorValuesVoltageCheck.checked) {

        if (gSensorValuesDateRangeRadio.checked) getWheelColoursVoltage(unit.id, unit.reportDataCustom);
        if (gSensorValuesTodayRadio.checked) getWheelColoursVoltage(unit.id, unit.reportDataDay);
        if (gSensorValuesLast7DaysRadio.checked) getWheelColoursVoltage(unit.id, unit.reportDataWeek);
        if (gSensorValuesLast30DaysRadio.checked) getWheelColoursVoltage(unit.id, unit.reportDataMonth);

        let pressureChart = document.getElementById('pressurechart');
        pressureChart.classList.remove(...pressureChart.classList);
        pressureChart.style.display = 'none';

        let temperatureChart = document.getElementById('temperaturechart');
        temperatureChart.classList.remove(...temperatureChart.classList);
        temperatureChart.style.display = 'none';

        let voltageChart = document.getElementById('voltagechart');
        voltageChart.classList.remove(...voltageChart.classList);
        let voltageChartClasses = ['threecharts', 'chart'];
        voltageChart.classList.add(...voltageChartClasses);
        voltageChart.style.display = 'block';
    }
}

function removeDataSpikes(sensorValuesData) {

    for (let i = 1; i < sensorValuesData.length - 1; i++) {
        //if (parseFloat(sensorValuesData[i].pressure) < 1) console.log(`index: ${i} pressure: ${parseFloat(sensorValuesData[i].pressure)}`)
        if (parseFloat(sensorValuesData[i].pressure) === 0) {
            if (parseFloat(sensorValuesData[i - 1].pressure) > 0 && parseFloat(sensorValuesData[i + 1].pressure) > 0) {
                sensorValuesData.splice(i, 1);
                continue;
            };
        }

        if (parseFloat(sensorValuesData[i].pressure) > 12)  {
            if (parseFloat(sensorValuesData[i - 1].pressure) < 12 && parseFloat(sensorValuesData[i + 1].pressure) < 12) {
                sensorValuesData.splice(i, 1);
                continue;
            }
        }

        if (sensorValuesData[i - 1].temperature < sensorValuesData[i].temperature && sensorValuesData[i + 1].temperature < sensorValuesData[i].temperature) {
            const d1 = Math.abs(sensorValuesData[i - 1].temperature - sensorValuesData[i].temperature);
            if (d1 > 35) {
                sensorValuesData.splice(i, 1);
                continue;
            }
        }

        if (sensorValuesData[i - 1].temperature > sensorValuesData[i].temperature && sensorValuesData[i + 1].temperature > sensorValuesData[i].temperature) {
            const d1 = Math.abs(sensorValuesData[i - 1].temperature - sensorValuesData[i].temperature);
            if (d1 > 35) {
                sensorValuesData.splice(i, 1);
                continue;
            }
        }

        if (sensorValuesData[i - 1].temperature < sensorValuesData[i].temperature && sensorValuesData[i + 1].temperature < sensorValuesData[i].temperature) {
            const d1 = Math.abs(sensorValuesData[i - 1].temperature - sensorValuesData[i].temperature);
            if (d1 > 35) {
                sensorValuesData.splice(i, 1);
                continue;
            }
        }

        if (sensorValuesData[i - 1].voltage > sensorValuesData[i].voltage && sensorValuesData[i + 1].voltage > sensorValuesData[i].voltage) {
                sensorValuesData.splice(i, 1);
            continue;
        }

        if (sensorValuesData[i - 1].voltage < sensorValuesData[i].voltage && sensorValuesData[i + 1].voltage < sensorValuesData[i].voltage) {
            sensorValuesData.splice(i, 1);
            continue;
        }

    }

    return sensorValuesData;
}

function getSensorValuesChartReportData(unit, sensorSummaryData) {

    document.body.style.cursor = 'wait';
    //createChartUnit(unit);
    //createDiagramView(unit, 'REPORTSDIAGRAM', 'chartunit');

    const d = new Date();
    const ts = d.getTime();

    let today = Math.round((ts - (24 * 60 * 60 * 1000)) / 1000);

    gSensorValuesDataToday = sensorSummaryData.filter((sv) => {
        return sv.unixTime >= today;
    });

    //getWheelColoursPressure(gSensorValuesDataToday, "TODAY");
    getWheelColoursTemperature(gSensorValuesDataToday, "TODAY");
    getWheelColoursVoltage(gSensorValuesDataToday, "TODAY");
    getWheelColours(gSensorValuesDataToday, "TODAY");

    let sevenDays = Math.round((ts - (7 * 24 * 60 * 60 * 1000)) / 1000);
    gSensorValuesData7Days = sensorSummaryData.filter((sv) => {
        return sv.unixTime >= sevenDays;
    });
    //getWheelColoursPressure(gSensorValuesData7Days, "WEEK");
    getWheelColoursTemperature(gSensorValuesData7Days, "WEEK");
    getWheelColoursVoltage(gSensorValuesData7Days, "WEEK");
    getWheelColours(gSensorValuesData7Days, "WEEK");

    gSensorValuesData30Days = sensorSummaryData;
    getWheelColoursPressure(gSensorValuesData30Days, "MONTH");  //***default    
    getWheelColoursTemperature(gSensorValuesData30Days, "MONTH");
    getWheelColoursVoltage(gSensorValuesData30Days, "MONTH");
    getWheelColours(gSensorValuesData30Days, "MONTH");

    //if (unit.name === "Test Wialon IPS 2") 
        applyWheelColours(unit, gOutOfRangeDataMonthPressure, gSensorValuesData30Days); //***default - MONTH - pressure
    //else
    //    applyWheelColours(unit, gOutOfRangeDataMonthPressure, gSensorValuesData30Days); //***default - MONTH - pressure

    //disable blue wheels
    //for (let ub = 0; ub < unit.sensors.length; ub++) {
    //    let tyreSwitch = document.getElementById(unit.id + 'tyreswitch' + unit.sensors[ub].sensorName.slice(0, 2));
    //    if (tyreSwitch != null) {
    //        if (tyreSwitch.classList.contains('chartwheelblue')) {
    //            while (tyreSwitch.firstChild) {
    //                tyreSwitch.removeChild(tyreSwitch.firstChild);
    //            }
    //            //let wheelCheck = document.getElementById(unit.id + 'wheelinput' + unit.sensors[ub].sensorName.slice(0, 2));
    //            //tyreSwitch.removeChild(wheelCheck);
    //        }
    //    }
    //}
    console.log('time3: [' + Date.now() + '] ');
    //console.log(gSensorValuesOutOfRangeDataMonth);

        /*$("#loadingBox").modal("hide");*/
        document.body.style.cursor = 'default';
    //} catch (err) {
    //    console.log(`reportsJS - getSensorValuesChartReportData: ERROR ${err.message}`);
    //}
  }

function getSensorValuesChartDateRangeData(selectedChartDates) {

    //sensorValuesData.sort((a, b) => a.id - b.id || a.axleWheel - b.axleWheel || a.unixTime - b.unixTime);

    const ds = new Date(selectedChartDates[0]);
    const startOfSelectedDay = ds.setHours(0, 0, 0, 0);

    const de = new Date(selectedChartDates[1]);
    const endOfSelectedDay = de.setHours(23, 59, 59, 999);

    //gPressureDataSelectedDate = pressureData.filter((p) => {
    //    return new Date(p.time).getTime() >= startOfSelectedDay && new Date(p.time).getTime() <= endOfSelectedDay;
    //});

    //gTemperatureDataSelectedDate = temperatureData.filter((t) => {
    //    return new Date(t.time).getTime() >= startOfSelectedDay && new Date(t.time).getTime() <= endOfSelectedDay;
    //});
    
    gSensorValuesDataDateRange = gSensorValuesData30Days.filter((v) => {
        return new Date(v.time).getTime() >= startOfSelectedDay && new Date(v.time).getTime() <= endOfSelectedDay;
    });
    //gSensorValuesDataDateRange = removeDataSpikes(gSensorValuesDataDateRange);    

    //getWheelColoursPressure(gSensorValuesDataDateRange, "SELECTEDDATE");
    //getWheelColoursTemperature(gSensorValuesDataDateRange, "SELECTEDDATE");
    //getWheelColoursVoltage(gSensorValuesDataDateRange, "SELECTEDDATE");
    //getWheelColours(gSensorValuesDataDateRange, "SELECTEDDATE");

    /*$("#loadingBox").modal("hide");
    document.body.style.cursor = 'default';*/
}

function assignWheelColours(unit, sensorSummaryData) {

    const sensors = [...unit.wheelSensors, ...unit.spareSensors]

    sensorSummaryData.forEach(s => {
        // Find the corresponding object in arr1
        let sensor = sensors.find(sensor => sensor.sensorName === s.sensorName);

        if (sensor) {
            // Compare minVoltageValue
            if (s.minVoltageValue < sensor.minVoltageValue) {
                s.voltageClassName = 'chartwheelyellow';
            } else {
                s.voltageClassName = 'chartwheelactive';
            }

            // Compare maxTemperatureValue
            if (s.maxTemperatureValue > sensor.maxTemperatureValue) {
                s.temperatureClassName = 'chartwheelorange';
            } else {
                s.temperatureClassName = 'chartwheelactive';
            }

            // Compare maxPressureValue first
            if (s.maxPressureValue > sensor.maxPressureValue) {
                s.pressureClassName = 'chartwheelpurple';
            } else {
                s.pressureClassName = 'chartwheelactive';
            }

            // Compare minPressureValue
            if (s.minPressureValue < sensor.minPressureValue) {
                s.pressureClassName = 'chartwheelred';
            } 
        }
    });
    
    return sensorSummaryData;
}

function resetWheelColours(unitId, reportData) {

    for (let u = 0; u < reportData.length; u++) {
        let tyreSwitch = document.getElementById(unitId + 'tyreswitch' + reportData[u].sensorName);
        if (tyreSwitch != null) {
            let wheeltype = tyreSwitch.classList[0];
            tyreSwitch.className = '';
            tyreSwitch.classList.add(wheeltype, 'chartwheelblue');
        }
    }
}

function getWheelColoursPressure(unitId, reportData) {

    for (let u = 0; u < reportData.length; u++) {
        let tyreSwitch = document.getElementById(unitId + 'tyreswitch' + reportData[u].sensorName);
        if (tyreSwitch != null) {
            let wheeltype = tyreSwitch.classList[0];
            tyreSwitch.className = '';
            tyreSwitch.classList.add(wheeltype, reportData[u].pressureClassName);
        }
    }
}

function getWheelColoursTemperature(unitId, reportData) {

    for (let u = 0; u < reportData.length; u++) {
        let tyreSwitch = document.getElementById(unitId + 'tyreswitch' + reportData[u].sensorName);
        if (tyreSwitch != null) {
            let wheeltype = tyreSwitch.classList[0];
            tyreSwitch.className = '';
            tyreSwitch.classList.add(wheeltype, reportData[u].temperatureClassName);
        }
    }
}

function getWheelColoursVoltage(unitId, reportData) {

    for (let u = 0; u < reportData.length; u++) {
        let tyreSwitch = document.getElementById(unitId + 'tyreswitch' + reportData[u].sensorName);
        if (tyreSwitch != null) {
            let wheeltype = tyreSwitch.classList[0];
            tyreSwitch.className = '';
            tyreSwitch.classList.add(wheeltype, reportData[u].voltageClassName);
        }
    }
}

function getWheelColours(sensorValuesData, timeSpan) {

    let sensorValuesOutOfRangeData = [];

    //for (let us = 0; us < unit.sensors.length; us++) {
    //    const sensorValueOutOfRange = {};
    //    sensorValueOutOfRange.id = unit.id;
    //    sensorValueOutOfRange.axleWheel = unit.sensors[us].sensorName.slice(0, 2);
    //    sensorValueOutOfRange.className = 'chartwheelblue';
    //    sensorValuesOutOfRangeData.push(sensorValueOutOfRange);
    //}

    //if (unit.unitTrailers.length > 0) {
    //    for (let ut = 0; ut < unit.unitTrailers.length; ut++) {
    //        const sensorValueOutOfRange = {};
    //        sensorValueOutOfRange.id = unit.unitTrailers[ut].id;
    //        for (let ust = 0; ust < unit.unitTrailers[ut].sensors.length; ust++) {
    //            sensorValueOutOfRange.axleWheel = unit.unitTrailers[ut].sensors[ust].sensorName.slice(0, 2);
    //            sensorValueOutOfRange.className = 'chartwheelblue';
    //            sensorValuesOutOfRangeData.push(sensorValueOutOfRange);
    //        }
    //    }
    //}

    for (let s = 0; s < sensorValuesData.length; s++) {

        //const classNameWheelBlueIndex = sensorValuesOutOfRangeData.findIndex(obj => {
        //    if (obj.id === sensorValuesData[s].id
        //        && obj.axleWheel === sensorValuesData[s].axleWheel
        //        && obj.className === 'chartwheelblue') {
        //        return true;
        //    }
        //    return false;
        //});

        //if (classNameWheelBlueIndex !== -1) {
        //    sensorValuesOutOfRangeData.splice(classNameWheelBlueIndex, 1);
        //}

        const hasClassNameWheelRed = sensorValuesOutOfRangeData.some(obj => {
            if (obj.id === sensorValuesData[s].id
                && obj.sensorName === sensorValuesData[s].sensorName
                    && obj.className === 'chartwheelred') {
                    return true;
                }
        });
        if (hasClassNameWheelRed) continue;

        if (parseFloat(sensorValuesData[s].pressure) < parseFloat(sensorValuesData[s].minPressureValue)) {

            const sensorValueOutOfRange = {};
            sensorValueOutOfRange.id = sensorValuesData[s].id;
            sensorValueOutOfRange.sensorName = sensorValuesData[s].sensorName;
            sensorValueOutOfRange.className = 'chartwheelred';
            sensorValuesOutOfRangeData.push(sensorValueOutOfRange);

            continue;
        }

        const hasClassNameWheelPurple = sensorValuesOutOfRangeData.some(obj => {
            if (obj.id === sensorValuesData[s].id
                && obj.axleWheel === sensorValuesData[s].axleWheelPos
                && obj.className === 'chartwheelpurple') {
                return true;
            }
        });
        if (hasClassNameWheelPurple) continue;

        if (parseFloat(sensorValuesData[s].pressure) > parseFloat(sensorValuesData[s].maxPressureValue)) {

            const sensorValueOutOfRange = {};
            sensorValueOutOfRange.id = sensorValuesData[s].id;
            sensorValueOutOfRange.sensorName = sensorValuesData[s].sensorName;
            sensorValueOutOfRange.className = 'chartwheelpurple';
            sensorValuesOutOfRangeData.push(sensorValueOutOfRange);

            continue;
        }

        const hasClassNameWheelOrange = sensorValuesOutOfRangeData.some(obj => {
            if (obj.id === sensorValuesData[s].id
                && obj.axleWheel === sensorValuesData[s].axleWheelPos
                && obj.className === 'chartwheelorange') {
                return true;
            }
        });
        if (hasClassNameWheelOrange) continue;

        if (parseInt(sensorValuesData[s].temperature) > parseInt(sensorValuesData[s].maxTemperatureValue)) {

            const sensorValueOutOfRange = {};
            sensorValueOutOfRange.id = sensorValuesData[s].id;
            sensorValueOutOfRange.sensorName = sensorValuesData[s].sensorName;
            sensorValueOutOfRange.className = 'chartwheelorange';
            sensorValuesOutOfRangeData.push(sensorValueOutOfRange);

            continue;
        }

        const hasClassNameWheelYellow = sensorValuesOutOfRangeData.some(obj => {
            if (obj.id === sensorValuesData[s].id
                && obj.axleWheel === sensorValuesData[s].axleWheelPos
                && obj.className === 'chartwheelyellow') {
                return true;
            }
        });
        if (hasClassNameWheelYellow) continue;

        if (parseFloat(sensorValuesData[s].voltage) < parseFloat(sensorValuesData[s].minVoltageValue)) {

            const sensorValueOutOfRange = {};
            sensorValueOutOfRange.id = sensorValuesData[s].id;
            sensorValueOutOfRange.sensorName = sensorValuesData[s].sensorName;
            sensorValueOutOfRange.className = 'chartwheelyellow';
            sensorValuesOutOfRangeData.push(sensorValueOutOfRange);

            continue;
        }
    }

    if (timeSpan === "SELECTEDDATE") gOutOfRangeDataDateRange = sensorValuesOutOfRangeData;
    if (timeSpan === "TODAY") gSensorValuesOutOfRangeDataToday = sensorValuesOutOfRangeData;    
    if (timeSpan === "WEEK") gSensorValuesOutOfRangeDataWeek = sensorValuesOutOfRangeData;
    if (timeSpan === "MONTH") gSensorValuesOutOfRangeDataMonth = sensorValuesOutOfRangeData;
}

function applyWheelColours(unit, sensorValuesOutOfRangeData, sensorValuesData) {

    let unitWheels = []; //Get all wheels in Sensor Values dataset ie NOT blue wheels
     for (let s = 0; s < unit.wheelSensors.length; s++) { 
        let unitWheel = {};
        unitWheel.id = unit.id;
        unitWheel.sensorName = unit.wheelSensors[s].sensorName
        unitWheel.className = 'chartwheelblue';        
        for (let sv = 0; sv < sensorValuesData.length; sv++) {
            if (unit.id === sensorValuesData[sv].id && unit.wheelSensors[s].sensorName === sensorValuesData[sv].sensorName) {
                unitWheel.className = 'chartwheelactive';
                break;
            }
        }
        unitWheels.push(unitWheel);
    }

    //set all applicable wheels to default black;
    for (let u = 0; u < unitWheels.length; u++) {
        let tyreSwitch = document.getElementById(unitWheels[u].id + 'tyreswitch' + unitWheels[u].sensorName);
        if (tyreSwitch != null) {
            let wheeltype = tyreSwitch.classList[0];
            tyreSwitch.className = '';
            tyreSwitch.classList.add(wheeltype, unitWheels[u].className);
        }
    }

    for (let s = 0; s < sensorValuesOutOfRangeData.length; s++) {
        let tyreSwitch = document.getElementById(sensorValuesOutOfRangeData[s].id + 'tyreswitch' + sensorValuesOutOfRangeData[s].sensorName);
        if (tyreSwitch != null) {
            let wheeltype = tyreSwitch.classList[0];
            tyreSwitch.className = '';
            tyreSwitch.classList.add(wheeltype, sensorValuesOutOfRangeData[s].className);

        }
    }
}

function getDefaultWheelColoursMonth(sensorValuesData) {
    for (let s = 0; s < sensorValuesData.length; s++) {
        let tyreSwitch = document.getElementById(sensorValuesData[s].id + 'tyreswitch' + sensorValuesData[s].axleWheelPos);
        //if (tyreSwitch != null) console.log(`tyreswitch id ${tyreSwitch.id}`);

        if (tyreSwitch != null) {

            if (tyreSwitch.classList.contains('wheelred')) continue;

            if (parseFloat(sensorValuesData[s].pressure) < parseFloat(sensorValuesData[s].minPressureValue)) {

                let wheeltype = tyreSwitch.classList[0];
                tyreSwitch.className = '';
                tyreSwitch.classList.add(wheeltype, 'wheelred');

                const sensorValueOutOfRangeMonth = {};
                sensorValueOutOfRangeMonth.id = sensorValuesData[s].id;
                sensorValueOutOfRangeMonth.axleWheel = sensorValuesData[s].axleWheelPos;
                sensorValueOutOfRangeMonth.className = 'wheelred';
                gSensorValuesOutOfRangeDataMonth.push(sensorValueOutOfRangeMonth);

                continue;
            }

            if (tyreSwitch.classList.contains('wheelpurple')) continue;

            if (parseFloat(sensorValuesData[s].pressure) > parseFloat(sensorValuesData[s].maxPressureValue)) {

                let wheeltype = tyreSwitch.classList[0];
                tyreSwitch.className = '';
                tyreSwitch.classList.add(wheeltype, 'wheelpurple');

                const sensorValueOutOfRangeMonth = {};
                sensorValueOutOfRangeMonth.id = sensorValuesData[s].id;
                sensorValueOutOfRangeMonth.axleWheel = sensorValuesData[s].axleWheelPos;
                sensorValueOutOfRangeMonth.className = 'wheelpurple';
                gSensorValuesOutOfRangeDataMonth.push(sensorValueOutOfRangeMonth);

                continue;
            }

            if (tyreSwitch.classList.contains('wheelorange')) continue;

            if (parseInt(sensorValuesData[s].temperature) > parseInt(sensorValuesData[s].maxTemperatureValue)) {

                let wheeltype = tyreSwitch.classList[0];
                tyreSwitch.className = '';
                tyreSwitch.classList.add(wheeltype, 'wheelorange');

                const sensorValueOutOfRangeMonth = {};
                sensorValueOutOfRangeMonth.id = sensorValuesData[s].id;
                sensorValueOutOfRangeMonth.axleWheel = sensorValuesData[s].axleWheelPos;
                sensorValueOutOfRangeMonth.className = 'wheelorange';
                gSensorValuesOutOfRangeDataMonth.push(sensorValueOutOfRangeMonth);

                continue;
            }

            if (tyreSwitch.classList.contains('wheelyellow')) continue;

            if (parseFloat(sensorValuesData[s].voltage) < parseFloat(sensorValuesData[s].minVoltageValue)) {

                let wheeltype = tyreSwitch.classList[0];
                tyreSwitch.className = '';
                tyreSwitch.classList.add(wheeltype, 'wheelyellow');

                const sensorValueOutOfRangeMonth = {};
                sensorValueOutOfRangeMonth.id = sensorValuesData[s].id;
                sensorValueOutOfRangeMonth.axleWheel = sensorValuesData[s].axleWheelPos;
                sensorValueOutOfRangeMonth.className = 'wheelyellow';
                gSensorValuesOutOfRangeDataMonth.push(sensorValueOutOfRangeMonth);

                continue;
            }
        }
    }
}

function openSensorValuesChartReport(unit, sensorSummaryData) {

    //gSelectedUnit = unit;
    document.getElementById('rmUnitId').value = unit.id;
    document.getElementById('rmUnitName').value = unit.name;
    unit.reportDataMonth = assignWheelColours(unit, sensorSummaryData);
    getWheelColoursPressure(unit.id, unit.reportDataMonth);
    //gMonthSummaryData = sensorSummaryData;
    gSensorValuesLast30DaysRadio.checked = true;
    gSensorValuesPressureCheck.checked = true;
    
    //getSensorValuesChartReportData(unit, sensorSummaryData);

    const chartsClasses = ['threecharts', 'chart'];
    const pressureChart = document.getElementById('pressurechart');
    pressureChart.classList.remove(...pressureChart.classList);    
    pressureChart.classList.add(...chartsClasses);
    pressureChart.style.display = 'block';

    const temperatureChart = document.getElementById('temperaturechart');
    temperatureChart.classList.remove(...temperatureChart.classList);
    temperatureChart.style.display = 'none';

    const voltageChart = document.getElementById('voltagechart');
    voltageChart.classList.remove(...voltageChart.classList);
    voltageChart.style.display = 'none';

    const reportsModal = document.getElementById('reportsmodal');
    reportsModal.style.display = 'block';

    //$("#loadingBox").modal("hide");
    //document.getElementById('loadingBox').style.display = 'none';
    //if (reportsModal.style.display === 'block' && document.getElementById("loadingBox").style.display === 'block') {
    //    //TODO: might also need to load chart messages here...
        
    //    $("#loadingBox").modal('hide');
    //    //alert("Something went wrong...");
    //}

    $(() => {
        $('#selectchartdate').datepicker({
            todayHighlight: true,
            startDate: '-1m',
            endDate: '+0d',
            multidate: 2,
            showOnFocus: true
        });
    }).on('changeDate', async (e) => {

        gSensorValuesDateRangeRadio.checked = true;
        gSensorValuesTodayRadio.checked = false;
        gSensorValuesLast7DaysRadio.checked = false;
        gSensorValuesLast30DaysRadio.checked = false;

        document.getElementById('pressurechartdate').innerText = formatDate(e.date);
        document.getElementById('temperaturechartdate').innerText = formatDate(e.date);
        document.getElementById('voltagechartdate').innerText = formatDate(e.date);

        const dates = e.dates;
        if (dates.length > 1) {
            //dates.sort((a, b) => {
            //    const date1 = a.getTime();
            //    const date2 = b.getTime();
            //    if (date1 < date2) {
            //        return -1;
            //        return 1;
            //    } 
            //});

            dates.sort((a, b) => a - b);
            
            const selectedChartStartDate = formatDate(dates[0]);
            const selectedChartEndDate = formatDate(dates[1]);
            gCustomChartDates.length = 0;
            gCustomChartDates.push(selectedChartStartDate);
            gCustomChartDates.push(selectedChartEndDate);
            const dateRangeText = `${selectedChartStartDate} to ${selectedChartEndDate}`;
            document.getElementById('pressurechartdate').innerText = dateRangeText;
            document.getElementById('temperaturechartdate').innerText = dateRangeText;
            document.getElementById('voltagechartdate').innerText = dateRangeText;
                        
            try {
                const summaryDataCustom = await getSensorSummaryData(unit, gCustomChartDates);
                unit.reportDataCustom = assignWheelColours(unit, summaryDataCustom);
                updateChartDisplays(unit);
            } catch (error) {
                console.error('Error fetching sensor summary data:', error);
            }
            $('#selectchartdate').datepicker('update', "");
            $('#selectchartdate').datepicker('hide');
            //document.body.style.cursor = 'default';
            e.stopImmediatePropagation();
        }
    });

    let sensorValuesTimespanOptions = document.getElementById('sensorvaluestimespanoptions');
    sensorValuesTimespanOptions.onclick = async (e) => {
        //document.body.style.cursor = 'wait';
        //$('#selectchartdate').datepicker('hide');
        if (gSensorValuesDateRangeRadio.checked) {            
            $('#selectchartdate').datepicker('show');
        } else {
            $('#selectchartdate').datepicker('hide');
            if (gSensorValuesTodayRadio) {
                if (!unit.reportDataDay) {
                    const date = new Date();
                    date.setDate(date.getDate() - 1);
                    const summaryDataToday = await getSensorSummaryData(unit, formatDate(date));
                    unit.reportDataDay = assignWheelColours(unit, summaryDataToday);
                }                
            }
            if (gSensorValuesLast7DaysRadio) {
                if (!unit.reportDataWeek) {
                    const date = new Date();
                    date.setDate(date.getDate() - 7);
                    const summaryDataWeek = await getSensorSummaryData(unit, formatDate(date));
                    unit.reportDataWeek = assignWheelColours(unit, summaryDataWeek);
                }
            }
            if (gSensorValuesLast30DaysRadio){ }
        }
        
        if (mapBox) {
            document.getElementById('mapbox').style.display = 'none';
        }

        clearChartOptions(unit)
        updateChartDisplays(unit);  

        e.stopImmediatePropagation();
        //document.body.style.cursor = 'default';
    };

    gSensorValuesPressureCheck.onclick = (e) => {
        updateChartDisplays(unit);
        e.stopImmediatePropagation();
    };

    gSensorValuesTemperatureCheck.onclick = (e) => {
        updateChartDisplays(unit);
        e.stopImmediatePropagation();
    };

    gSensorValuesVoltageCheck.onclick = (e) => {
        updateChartDisplays(unit);
        e.stopImmediatePropagation();
    };

    const mapBoxContent = document.getElementById('mapboxcontent');
    let unitMapContainerDiv = createUnitMapContainerElement('reportsmapcontainer', 'reportschartmap', 'chartmap');
    mapBoxContent.appendChild(unitMapContainerDiv);
    initialiseChartMap('reportschartmap');

    let span = document.getElementById("closereportsmodal");
    span.onclick = function (e) {
        e.stopImmediatePropagation;
        //document.cursor.style = 'default';
        reportsModal.style.display = 'none';
        gCustomChartDates = [];
        document.getElementById('pressurechartdate').innerText = "";
        document.getElementById('temperaturechartdate').innerText = "";
        document.getElementById('voltagechartdate').innerText = "";
        clearChartGlobals(unit);
        
        return false;
    };

    reportsModal.onclick = function (e) {        
        if (e.target == reportsModal) {
            e.stopImmediatePropagation();
            //document.cursor.style = 'default';
            reportsModal.style.display = 'none';

            clearChartGlobals(unit);            
            return false;
        }
    };       

    return false;
}

function clearChartOptions(unit) {

    let tyreInputs = document.getElementsByName('wheel');
    for (let t = 0; t < tyreInputs.length; t++) {
        tyreInputs[t].checked = false;
    }

    for (let c = 0; c <= gChartColourCounter; c++) {
        let sliderChartColours = document.querySelectorAll('.slider.chart' + c);
        if (sliderChartColours.length > 0) {
            [].forEach.call(sliderChartColours, function (el) {
                el.classList.remove('chart' + c);
            });
        }
        let sparesliderChartColours = document.querySelectorAll('.spareslider.chart' + c);
        if (sparesliderChartColours.length > 0) {
            [].forEach.call(sparesliderChartColours, function (el) {
                el.classList.remove('chart' + c);
            });
        }
    }
    gChartColourCounter = -1;

    const chartColours = gChartColours.map((colour) => ({ ...colour, used: false }));
    gChartColours = chartColours;

    document.getElementById('mapbox').style.display = 'none';

    gOutOfRangeDataDateRange = [];
    gOutOfRangeDataDateRangePressure = [];
    gOutOfRangeDataDateRangeTemperature = [];
    gOutOfRangeDataDateRangeVoltage = [];

    if (gSensorValuesDateRangeRadio.checked) {        
        let dataDateRangeDefault = [];
        const sensors = [...unit.wheelSensors, ...unit.spareSensors];
        for (let us = 0; us < sensors.length; us++) {
            const sensorValueOutOfRange = {};
            sensorValueOutOfRange.id = unit.id;
            sensorValueOutOfRange.sensorName = sensors[us].sensorName;
            sensorValueOutOfRange.className = 'chartwheelblue';
            dataDateRangeDefault.push(sensorValueOutOfRange);
        }

        gOutOfRangeDataDateRange = [...dataDateRangeDefault];
        gOutOfRangeDataDateRangePressure = [...dataDateRangeDefault];
        gOutOfRangeDataDateRangeTemperature = [...dataDateRangeDefault];
        gOutOfRangeDataDateRangeVoltage = [...dataDateRangeDefault];
    } else {
        if (unit.reportDataCustom) delete unit.reportDataCustom;
        gCustomChartDates = []
        document.getElementById('pressurechartdate').innerText = "";
        document.getElementById('temperaturechartdate').innerText = "";
        document.getElementById('voltagechartdate').innerText = "";
    }

    gPressureAnnotation = [];
    gTemperatureAnnotation = [];
    gVoltageAnnotation = [];

    gPressureChartMaxY = 0;
    gTemperatureChartMaxY = 0;
    gVoltageChartMaxY = 0;

    gPressureChartCounter = 0;
    gTemperatureChartCounter = 0;
    gVoltageChartCounter = 0;

    if (gPressureChart)
        gPressureChart.destroy();
    if (gTemperatureChart)
        gTemperatureChart.destroy();
    if (gVoltageChart)
        gVoltageChart.destroy();
}

function sensorValuesChartOptionsChange(sensorValuesChartType, todayData, last7DaysData, sensorValuesChartMessages) {

    let minPressureValue = document.getElementById('rmMinPressureValue').value;
    let maxPressureValue = document.getElementById('rmMaxPressureValue').value;
    let manufacturersRecommendedPressure = document.getElementById('rmManufacturersRecommendedPressure').value;
    let maxTemperatureValue = document.getElementById('rmMaxTemperatureValue').value;
    let minVoltageValue = document.getElementById('rmMinVoltageValue').value;

    let pressureValueRange = [];
    pressureValueRange.push(minPressureValue);
    pressureValueRange.push(maxPressureValue);
    pressureValueRange.push(manufacturersRecommendedPressure);

    if (document.getElementById("sensorValuesToday").checked === true) {
        document.getElementById("sensorValuesToday").checked = true;
        document.body.style.cursor = 'wait';
        switch (sensorValuesChartType) {
            case 'PRESSURE':
                displayPressureData(todayData, "TODAY", pressureValueRange);
                document.getElementById("sensorValuesPressure").checked = true;
                break;
            case 'TEMPERATURE':
                displayTemperatureData(todayData, "TODAY", maxTemperatureValue);
                document.getElementById("sensorValuesTemperature").checked = true;
                break;
            case 'VOLTAGE':
                displayVoltageData(todayData, "TODAY", minVoltageValue);
                document.getElementById("sensorValuesVoltage").checked = true;
                break;
        }
    }

    if (document.getElementById("sensorValuesLast7Days").checked === true) {
        document.body.style.cursor = 'wait';
        switch (sensorValuesChartType) {
            case 'PRESSURE':
                displayPressureData(last7DaysData, "SEVEN DAYS", pressureValueRange);
                document.getElementById("sensorValuesPressure").checked = true;
                break;
            case 'TEMPERATURE':
                displayTemperatureData(last7DaysData, "SEVEN DAYS", maxTemperatureValue);
                document.getElementById("sensorValuesTemperature").checked = true;
                break;
            case 'VOLTAGE':
                displayVoltageData(last7DaysData, "SEVEN DAYS", minVoltageValue);
                document.getElementById("sensorValuesVoltage").checked = true;
                break;
        }
    }
    if (document.getElementById("sensorValuesLast30Days").checked === true) {
        document.getElementById("sensorValuesLast30Days").checked = true;
        document.body.style.cursor = 'wait';
        let last30DaysData = sensorValuesChartMessages;
        switch (sensorValuesChartType) {
            case 'PRESSURE':
                displayPressureData(last30DaysData, "THIRTY DAYS", pressureValueRange);
                document.getElementById("sensorValuesPressure").checked = true;
                break;
            case 'TEMPERATURE':
                displayTemperatureData(last30DaysData, "THIRTY DAYS", maxTemperatureValue);
                document.getElementById("sensorValuesTemperature").checked = true;
                break;
            case 'VOLTAGE':
                displayVoltageData(last30DaysData, "THIRTY DAYS", minVoltageValue);
                document.getElementById("sensorValuesVoltage").checked = true;
                break;
        }
    }
}

function getxAxesTimeLabelFormat(timespan) {

    let timeLabel = {};
    switch (timespan) {
        case 'TODAY':
            timeLabel.unit = "hour";
            timeLabel.timeFormat = "HH:mm";
            break;
        case 'SEVEN DAYS':
            timeLabel.unit = "day";
            timeLabel.timeFormat = "DD MMM";
            break;
        case 'THIRTY DAYS':
            timeLabel.unit = "day";
            timeLabel.timeFormat = "DD MMM";
            break;
        case 'CUSTOM DATES':
            timeLabel.unit = "day";
            timeLabel.timeFormat = "DD MMM";
            break;
    }
    return timeLabel;
}

function downloadPDF() {
    const canvas = document.getElementById('pressurechartcanvas');
    const canvasImage = canvas.toDataURL('image/jpeg', 1.0);
    let pdf = new jsPDF('landscape');
    pdf.setFontSize(20);
    pdf.addImage(canvasImage, 'JPEG', 15, 15, 280, 150);
    pdf.text(15, 15, "we have discoverd...");
    pdf.save("pressure chart")
}

function initialiseChartMap(mapDiv) {

    if (!map) {
        // create a map in the "map" div, set the view to a given place and zoom
        map = L.map(mapDiv, {
            fullscreenControl: true,
            fullscreenControlOptions: {
                position: 'topleft'
            }
        }).setView([-33.97823, 22.45808], 10);

        // add an OpenStreetMap tile layer
        L.tileLayer('https://{s}.tile.osm.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="http://gurtam.com">Gurtam</a>'
        }).addTo(map);
    } else {
        map.invalidateSize(); // Ensure the map resizes correctly
    }
}

function showMap(posx, posy) {
    if (map) { // check if map created
        const customIcon = L.divIcon({
            className: 'map-icon'
        });
        if (marker != null) {
            map.removeLayer(marker);
        }

        marker = L.marker({ lat: posy, lng: posx }, { icon: customIcon }).addTo(map);
        marker.setLatLng({ lat: posy, lng: posx });
        marker.setIcon(customIcon);
        map.invalidateSize();
        map.setView({ lat: posy, lng: posx });
    }
}

//function showUnitOnMap(pos) {
//    if (map) {
//        // check if map created
//        //let icon = L.icon({
//        //    iconUrl: unitIcon
//        //});
//        let customIcon = L.divIcon({
//            className: 'map-icon'
//        });

//        marker = L.marker({ lat: pos.y, lng: pos.x }, { icon: customIcon }).addTo(map);
//        marker.setLatLng({ lat: pos.y, lng: pos.x });
//        marker.setIcon(customIcon);
//        //}
//        map.setView({ lat: pos.y, lng: pos.x });
//    }
//}

function mapContainerVisible() {
    const mapContainer = document.getElementById('mapbox');
    if (mapContainer.style.display !== 'block') {
        mapContainer.style.display = 'block';
        mapContainer.style.visibility = 'visible';
    }
}

function tooltipLines(imei, strokeStyle) {
    
    const tooltipLine = {
        id: 'tooltipLine',
        beforeDraw: async chart => {
            if (chart.tooltip != null) {
            //    let t = 0;
            //} else {
                if (chart.tooltip._active && chart.tooltip._active.length) {
                    const i = chart._active[0].datasetIndex;
                    const d = chart.tooltip._active[0].index;
                    if (d >= 0 && chart.data.datasets.length && chart.data.datasets[0].data.length >= d) {

                        if (document.getElementById('mapbox').style.display === 'block') {
                            if (chart.data.datasets[i].data[d].x != null) {
                                const location = await getCurrentLocation(imei, chart.data.datasets[i].data[d].x);
                                showMap(location.longitude, location.latitude);
                            }
                        }
                    }

                    const ctx = chart.ctx;
                    if (ctx) { 
                        ctx.save();
                        ctx.beginPath();
                        const activePoint = chart.tooltip._active[0];                   
                        if (activePoint) {
                            ctx.moveTo(activePoint.element.x, chart.chartArea.top);
                            ctx.lineTo(activePoint.element.x, chart.chartArea.bottom);
                        }
                        ctx.lineWidth = 0.85;
                        ctx.strokeStyle = strokeStyle; //ctx.strokeStyle = '#075ea5';
                        ctx.stroke();
                        ctx.restore();
                    }
                }
            }
        }
    };  

    return tooltipLine;

}

function setClickablePoints(clicked) {

    const clickablePoints = {
        id: 'clickablePoints',
        afterEvent: (chart, args, pluginOptions) => {
            if (args.event.type === 'click') {
                const i = chart._active[0].datasetIndex;
                const xCursor = args.event.x;
                const yCursor = args.event.y;
                for (let d = 0; d < chart._metasets[i].data.length; d++) {
                    const xMin = chart._metasets[i].data[d].x - 1.5;
                    const xMax = chart._metasets[i].data[d].x + 1.5;
                    const yMin = chart._metasets[i].data[d].y - 4.5;
                    const yMax = chart._metasets[i].data[d].y + 4.5;
                    if (xMin <= xCursor && xCursor <= xMax && yMin <= yCursor && yCursor <= yMax) {
                        if (!mapBox) mapBox = new DialogBox("mapbox");
                        mapBox.showDialog();
                        if (clicked[d] === 0) {
                            clicked[d] = 1;
                        } else {
                            clicked[d] = 0;
                        }
                    }
                }
                chart.update();
            }
        },
        //beforeDatasetsDraw: (chart, args, pluginOptions) => {
        //    const { ctx, chartArea: { top, bottom } } = chart;
        //    class Line {
        //        constructor(xCoord) {
        //            this.width = xCoord;
        //        }
        //        draw(ctx) {
        //            ctx.restore();
        //            ctx.beginPath();
        //            ctx.moveTo(this.width, top);
        //            ctx.lineTo(this.width, bottom);
        //            ctx.stroke();
        //            ctx.lineWidth = 0.85;
        //            ctx.strokeStyle = 'gray';
        //            ctx.save();
        //        }
        //    }
        //    for (let c = 0; c < clicked.length; c++) {
        //        if (clicked[c] === 1) {
        //            let drawLine = new Line(chart._metasets[0].data[c].x);
        //            drawLine.draw(ctx);
        //        }
        //    }
        //}
    };

    return clickablePoints;
}

function createPressureChart(imei, chartData, timeSpan) {

    try {

        document.body.style.cursor = 'wait';

//        let pressureValueRange = [chartData[0].minPressureValue, chartData[0].maxPressureValue, chartData[0].manufacturersRecommendedPressure];
//        gPrevMinPressureValue = chartData[0].minPressureValue;
//        let description = ["Min Press: ", "Max Press: ", "Rec Press: "];
//        let annotations = [];
//        if (chartData[0].minPressureValue != null) {
//            annotations = pressureValueRange.map(function (outOfRangeValue, index) {
//                let colour = PRIMARY_COLOUR;
//                let position = 'center';
//                let backgroundColour = '#eee';
//                let opacity = '0.85';
//                if (index === 0) {
//                    colour = LOW_PRESSURE_COLOUR; //'#ad0505'
//                    position = 'start';
//                }
//                if (index === 1) {
//                    colour = HIGH_PRESSURE_COLOUR;
//                    position = 'start';
//                }
//                if (index === 2) {
//                    colour = SECONDARY_COLOUR;
//                    //backgroundColour = '#dde7c8';
//                    //opacity = '0.85';
//                    position = 'start';
//                }
//                return {
//                    type: 'line',
//                    id: 'hline' + index,
//                    mode: 'horizontal',
//                    /*scaleID: 'y-axis-0',*/
//                    drawTime: 'beforeDatasetsDraw',
//                    yMin: outOfRangeValue,
//                    yMax: outOfRangeValue,
//                    borderColor: colour,
//                    borderWidth: 1,
//                    label: {
//                        display: true,
//                        padding: {
//                            left: 2,
//                            right: 2,
//                        },
//                        width: 50,
//                        position: position,
//                        color: colour,
//                        backgroundColor: backgroundColour,
//                        //opacity: opacity,
//                        content: description[index] + outOfRangeValue + ' bar'
//                    }
//                };
//            });
//        }

//        let chartAxesData = [];
//        let clicked = [];
//        for (let i = 0; i < chartData.length; i++) {
//            clicked.push(0);
//            let xyData = {};
//            xyData.y = chartData[i].pressure;
//            xyData.x = chartData[i].time;
//            xyData.sensorId = chartData[i].sensorId;
//            xyData.temperature = chartData[i].temperature;
//            xyData.voltage = chartData[i].voltage;
//            //xyData.posx = chartData[i].posx;
//            //xyData.posy = chartData[i].posy;
//            //xyData.address = sensorValuesLocations[i];
//            chartAxesData.push(xyData);
//        }

//        let maxY = Math.max(...chartAxesData.map(c => c?.y || 0));
//        gPressureChartMaxY = Math.round(Math.max(maxY, chartData[0].maxPressureValue) + 1);

//        let timeLabel = getxAxesTimeLabelFormat(timeSpan);

//        //const bgColour = {
//        //    id: 'bgColour',
//        //    beforeDraw: (chart, options) => {
//        //        const { ctx, width, height } = chart;
//        //        ctx.fillStyle = options.backgroundColor;
//        //        ctx.fillRect = (0, 0, width, height);
//        //        ctx.restore();
//        //    }
//        //};

//        const tooltipLine = tooltipLines(imei, SECONDARY_COLOUR);
//        const clickablePoints = setClickablePoints(clicked);

//        let sensorValuesChartData = {
//            type: 'line',
//            data: {
//                datasets: [{
//                    label: 'A' + parseInt(chartData[0].axle) + '-T' + chartData[0].sensorName.slice(0, 1),
//                    backgroundColor: PRIMARY_COLOUR,
//                    fill: false,
//                    borderColor: PRIMARY_COLOUR,
//                    data: chartAxesData,
//                    borderWidth: 0.5,
//                    radius: 0.85,
//                    hitRadius: 4.5,
//                    hoverRaius: 4.5,
//                    tension: 0.5,
//                    pointHoverRadius: 4.5
//                }]
//            },
//            plugins: [tooltipLine, clickablePoints],
//            options: {
//                responsive: true,
//                maintainAspectRatio: false,
//                scales: {
//                    y: {
//                        beginAtZero: true,
//                        max: gPressureChartMaxY,
//                        ticks: {
//                            stepSize: 0.5,
//                        }
//                    },
//                    x: {
//                        scaleLabel: {
//                            display: true
//                        },
//                        type: 'time',
//                        time: {
//                            unit: timeLabel.unit,
//                            displayFormats: {
//                                hour: timeLabel.timeFormat,
//                                day: timeLabel.timeFormat
//                            },
//                            tooltipFormat: "DD MMM HH:mm:ss"
//                        },
//                        position: "bottom"
//                    },
//                },
//                plugins: {
//                    tooltip: {
//                        callbacks: {
//                            title: () => {
//                                return `Click data point to view map`;
//                            },
//                            label: (context) => {
//                                const date = context.label;
//                                return [`${date}`, `${context.dataset.label}`];
//                            },
//                            afterBody: (context) => {
//                                const xtraData = [];
//                                const sensorId = context[0].raw.sensorId.toString();
//                                xtraData.push(`Sensor Id: ${sensorId}`);
//                                const temperature = context[0].raw.temperature.toString();
//                                const voltage = context[0].raw.voltage.toString();
//                                xtraData.push(`${context[0].dataset.data[context[0].dataIndex].y.toString()} bar  ${temperature} \u2103  ${voltage} V`);
//                                return xtraData;
//                            }
//                        },
//                    },
//                    zoom: {
//                        zoom: {
//                            wheel: {
//                                enabled: true,
//                            },
//                            mode: "xy",
//                        }
//                    },
//                    annotation: {
//                        annotations: annotations
//                    },
//                },
//                layout: {
//                    padding: {
//                        bottom: 25,
//                    },
//                },

//            },
//        };

        gPressureChart = new Chart(gPressureCtx, getPressureChartData(imei, chartData, timeSpan));

        document.body.style.cursor = 'default';
    } catch (err) {

        const canvas = document.getElementById('pressurechartcanvas');

        if (canvas) {
            // Remove the canvas element
            const textNode = document.createTextNode(`No Data`);
            canvas.parentNode.appendChild(textNode);
            canvas.parentNode.removeChild(canvas);
        }
    }
}

function createTemperatureChart(imei, chartData, timeSpan) {
    try {
        document.body.style.cursor = 'wait';

        let chartAxesData = [];

        let clicked = [];            
        for (let i = 0; i < chartData.length; i++) {
            clicked.push(0);
            let xyData = {};
            xyData.y = chartData[i].temperature;
            xyData.x = chartData[i].time;
            xyData.sensorId = chartData[i].sensorId;
            xyData.pressure = chartData[i].pressure;
            xyData.voltage = chartData[i].voltage;
            chartAxesData.push(xyData);
        }

        let timeLabel = getxAxesTimeLabelFormat(timeSpan);

        let annotations = [];
        if (chartData[0].maxTemperatureValue != null) {
            gPrevMaxTemperatureValue = chartData[0].maxTemperatureValue;
            annotations = [{
                type: 'line',
                id: 'hline',
                mode: 'horizontal',
                drawTime: 'beforeDatasetsDraw',
                /*scaleID: 'y-axis-0',*/
                yMin: chartData[0].maxTemperatureValue,
                yMax: chartData[0].maxTemperatureValue,
                borderColor: HIGH_TEMPERATURE_COLOUR,
                borderWidth: 1,
                label: {
                    display: true,
                    padding: {
                        left: 2,
                        right: 2,
                    },
                    position: 'start',
                    backgroundColor: '#eeeeee',
                    color: HIGH_TEMPERATURE_COLOUR,
                    content: 'Max Temp: ' + chartData[0].maxTemperatureValue + '\u2103'
                }
            }];

            let maxY = Math.max(...chartAxesData.map(c => c?.y || 0));
            gTemperatureChartMaxY = Math.round(Math.max(maxY, chartData[0].maxTemperatureValue) + 5);
        }

        const tooltipLine = tooltipLines(imei, HIGH_TEMPERATURE_COLOUR);
        const clickablePoints = setClickablePoints(clicked);

        let sensorValuesChartData = {
            type: 'line',
            data: {
                datasets: [{
                    label: 'A' + chartData[0].axle + '-T' + chartData[0].sensorName.slice(0, 1),
                    afterLabel: + ' \u2103',
                    backgroundColor: PRIMARY_COLOUR,
                    fill: false,
                    borderColor: PRIMARY_COLOUR,
                    data: chartAxesData,
                    borderWidth: 0.5,
                    radius: 0.85,
                    hitRadius: 4.5,
                    hoverRaius: 4.5,
                    tension: 0.5,
                    pointHoverRadius: 4.5
                },]
            },
            plugins: [tooltipLine, clickablePoints],
            options: {
                maintainAspectRatio: false,
                scales: {
                    y: {
                        beginAtZero: true,
                        max: gTemperatureChartMaxY,
                        ticks: {
                            stepSize: 5,
                        }
                    },
                    x: {
                        scaleLabel: {
                            display: true
                        },
                        type: "time",
                        time: {
                            unit: timeLabel.unit,
                            displayFormats: {
                                hour: timeLabel.timeFormat,
                                day: timeLabel.timeFormat
                            },
                            tooltipFormat: "DD MMM HH:mm:ss"
                        },
                        position: "bottom"
                    }
                },
                plugins: {
                    tooltip: {
                        callbacks: {
                            title: () => {
                                return `Click data point to view map`;
                            },
                            label: (context) => {
                                const date = context.label;
                                return [`${date}`, `${context.dataset.label}`];
                            },
                            afterBody: (context) => {
                                const xtraData = [];
                                const sensorId = context[0].raw.sensorId.toString();
                                xtraData.push(`Sensor Id: ${sensorId}`);
                                const pressure = context[0].raw.pressure.toString();
                                const voltage = context[0].raw.voltage.toString();
                                //xtraData.push(context[0].label); //date and time
                                xtraData.push(`${context[0].dataset.data[context[0].dataIndex].y.toString()} \u2103  ${pressure} bar  ${voltage} V`);
                                return xtraData;
                            }
                        },
                    },
                    zoom: {
                        zoom: {
                            wheel: {
                                enabled: true,

                            },
                            mode: "xy",
                        }
                    },
                    annotation: {
                        annotations: annotations
                    },
                },
                layout: {
                    padding: {
                        bottom: 25,
                    },
                },
                registry: {
                }
            }
        };
                
        gTemperatureChart = new Chart(gTemperatureCtx, sensorValuesChartData);

        document.body.style.cursor = 'default';
    } catch (err) {
        console.log(err);
        if (gTemperatureChart)
            gTemperatureChart.destroy();
        const canvas = document.getElementById('temperaturechartcanvas');
        if (canvas) {
            // Remove the canvas element            
            const textNode = document.createTextNode(`No Data`);
            canvas.parentNode.appendChild(textNode);
            canvas.parentNode.removeChild(canvas);
        }
    }
}

function createVoltageChart(imei, chartData, timeSpan) {

    try {
        document.body.style.cursor = 'wait';

        let chartAxesData = [];

        let clicked = [];            
        for (let i = 0; i < chartData.length; i++) {
            clicked.push(0);
            let xyData = {};
            xyData.y = chartData[i].voltage;
            xyData.x = chartData[i].time;
            xyData.sensorId = chartData[i].sensorId;
            xyData.pressure = chartData[i].pressure;
            xyData.temperature = chartData[i].temperature;
            chartAxesData.push(xyData);
        }

        let timeLabel = getxAxesTimeLabelFormat(timeSpan);

        let maxY = Math.max(...chartAxesData.map(c => c?.y || 0));
        gVoltageChartMaxY = Math.round(Math.max(maxY, chartData[0].minVoltageValue) + 1);

        let annotations = [];
        if (chartData[0].minVoltageValue != null) {
            gPrevMinVoltageValue = chartData[0].minVoltageValue;
            annotations = [{
                type: 'line',
                id: 'hline',
                mode: 'horizontal',
                drawTime: 'beforeDatasetsDraw',
                /*scaleID: 'y-axis-0',*/
                yMin: chartData[0].minVoltageValue,
                yMax: chartData[0].minVoltageValue,
                borderColor: LOW_VOLTAGE_COLOUR,
                borderWidth: 1,
                label: {
                    display: true,
                    padding: {
                        left: 2,
                        right: 2,
                    },
                    position: 'start',
                    backgroundColor: '#eeeeee',
                    color: LOW_VOLTAGE_COLOUR,
                    content: 'Min Volt: ' + chartData[0].minVoltageValue + 'V'
                }
            }];
        }

        const tooltipLine = tooltipLines(imei, LOW_VOLTAGE_COLOUR);
        const clickablePoints = setClickablePoints(clicked);

        let sensorValuesChartData = {
            type: 'line',
            data: {
                datasets: [{
                    label: 'A' + chartData[0].axle + '-T' + chartData[0].sensorName.slice(0, 1),
                    backgroundColor: PRIMARY_COLOUR,
                    fill: false,
                    borderColor: PRIMARY_COLOUR,
                    data: chartAxesData,
                    borderWidth: 0.5,
                    radius: 0.85,
                    hitRadius: 4.5,
                    hoverRaius: 4.5,
                    tension: 0.5,
                    pointHoverRadius: 4.5
                }],
            },
            plugins: [tooltipLine, clickablePoints],
            options: {
                maintainAspectRatio: false,
                scales: {
                    y: {
                        beginAtZero: true,
                        max: gVoltageChartMaxY,
                        ticks: {
                            stepSize: 0.5,
                        }
                    },
                    x: {
                        scaleLabel: {
                            display: true
                        },
                        type: "time",
                        time: {
                            unit: timeLabel.unit,
                            displayFormats: {
                                hour: timeLabel.timeFormat,
                                day: timeLabel.timeFormat
                            },
                            tooltipFormat: "DD MMM HH:mm:ss"
                        },
                        position: "bottom"
                    }
                },
                plugins: {
                    tooltip: {
                        callbacks: {
                            title: () => {
                                return `Click data point to view map`;
                            },
                            label: (context) => {
                                const date = context.label;
                                return [`${date}`, `${context.dataset.label}`];
                            },
                            afterBody: (context) => {
                                const xtraData = [];
                                const sensorId = context[0].raw.sensorId;
                                xtraData.push(`Sensor Id: ${sensorId}`);
                                const pressure = context[0].raw.pressure.toString();
                                const temperature = context[0].raw.temperature.toString();
                                //xtraData.push(context[0].label); //date and time
                                xtraData.push(`${context[0].dataset.data[context[0].dataIndex].y.toString()} V  ${pressure} bar  ${temperature} \u2103`);
                                return xtraData;
                            }
                        },
                    },

                    zoom: {
                        zoom: {
                            wheel: {
                                enabled: true,
                            },
                            mode: "xy",
                        }
                    },
                    annotation: {
                        annotations: annotations
                    },
                },
                layout: {
                    padding: {
                        bottom: 25,
                    },
                },
                registry: {
                    tooltip: {
                        callbacks: {
                            label: function (tooltipItem, data) {
                                return tooltipItem.yLabel + ' V';
                            },
                        }
                    },
                }
            }
        };

        gVoltageChart = new Chart(gVoltageCtx, sensorValuesChartData);

        document.body.style.cursor = 'default';
    } catch (err) {
        const canvas = document.getElementById('voltagechartcanvas');

        if (canvas) {
            // Remove the canvas element
            const textNode = document.createTextNode(`No Data`);
            canvas.parentNode.appendChild(textNode);
            canvas.parentNode.removeChild(canvas);
        }
    }
}

//function getxAxisLabels(chartData, timespan) {

//    let xAxisLabels = [];
//    switch (timespan) {
//        case 'TODAY':
//            for (let i = 0; i < chartData.length; i++) {
//                xAxisLabels.push(chartData[i].time.toString().slice(16, 24));
//            }
//            //xAxisLabels = getLast24Hours()
//            break;
//        case 'SEVEN DAYS':
//            for (let i = 0; i < chartData.length; i++) {
//                //xAxisLabels.push(chartData[i].time.toString().slice(0, 15));
//                xAxisLabels.push(chartData[i].time);
//            }
//            break;
//        case 'THIRTY DAYS':
//            for (let i = 0; i < chartData.length; i++) {
//                xAxisLabels.push(chartData[i].time.toString().slice(4, 15));
//            }
//            break;
//        case 'NINETY DAYS':
//            for (let i = 0; i < chartData.length; i++) {
//                xAxisLabels.push(chartData[i].time.toString().slice(4, 15));
//            }
//            break;
//    }
//    return xAxisLabels;
//}

function getLast24Hours() {

    let dateTime = new Date();
    let yesterday = (d => new Date(d.setDate(d.getDate() - 1)))(new Date);

    let last24Hours = [];
    //yesterday = yesterday.setHours(yesterday.getHours() + 1); 
    for (i = 0; i <= 24; i++) {
        //let hrs = yesterday.getHours();
        dateTime = new Date(yesterday.setHours(yesterday.getHours() + 1));
        //last24Hours.push(dateTime.toString().slice(0, 11) + dateTime.toString().slice(16, 18) + ":00");
        last24Hours.push(dateTime);

    }

    //startDate.setDate(startDate.getDate() - startDate.getHours());

    //let hrs = startDate.getHours();
    //let minutes = ("0" + startDate.getMinutes().toString()).slice(0, 2);


    //for (i = 0; i < hrs; i++) {
    //    if (i < 10)
    //        hour = ("0" + i.toString()).slice(1, 2)
    //    else hour = i.toString();
    //    last24Hours.push(hour + ":" + minutes);
    //}

    //return date.toString().slice(0, 24);
    //for (i = 0; i < todayData.length; i++) {
    //    last24Hours.push(todayData[i].time.toString().slice(16, 24));
    //}
    return last24Hours;
}

function getLast7Days() {

    let startDate = new Date();

    startDate.setDate(startDate.getDate() - 6);

    let last7Days = [];
    for (i = 0; i < 7; i++) {
        startDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
        last7Days.push(startDate.toDateString());
        startDate.setDate(startDate.getDate() + 1);
    }
    return last7Days;
}

function getLast30Days() {

    let startDate = new Date();

    startDate.setDate(startDate.getDate() - 29);

    let last30Days = [];
    for (i = 0; i < 30; i++) {
        startDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
        last30Days.push(startDate.toDateString().replace(/^\S+\s/, ''));
        startDate.setDate(startDate.getDate() + 1);
    }

    return last30Days;
}

function getLast90Days() {

    let startDate = new Date();

    startDate.setDate(startDate.getDate() - 89);

    var last90Days = [];
    for (i = 0; i < 90; i++) {
        startDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
        last90Days.push(startDate.toDateString().replace(/^\S+\s/, ''));
        startDate.setDate(startDate.getDate() + 1);
    }
    return last90Days;
}

async function getSensorValuesChartData(unit) {

    try {

        return await new Promise((resolve, reject) => {

            loadSensorValuesChartMessages(unit)
            //session.updateDataFlags(
            //    [{ type: "type", data: "avl_resource", flags: flags, mode: 1 }],
            //    (error) => {
            //        if (error) {
            //            console.log("notificationsJS: getResourceNotifications - API ERROR " + error + " (" + wialon.core.Errors.getErrorText(error)) + "))";
            //            reject({ type: 'API_ERROR', error: error });
            //            return;
            //        }
            //        //let notifications = [];

            //        let resourceNotifications = { resourceId: resourceId, resourceName: resource.getName(), notifications: resource.getNotifications() };
            //        //notifications.push(resourceNotifications);
            //        //console.log("notificationsJS: getResourceNotifications - SUCCESS");
            //        resolve(resourceNotifications);
            //    },
            //);
        });
    } catch (e) {
        console.error('reportsJS: getSensorValuesChartData - ERROR: ', e);
    }
}


async function loadSensorValuesChartMessages(unit) {

    let session = global.session;
    let timeTo = session.getServerTime();
    let timeFrom = timeTo - 3600 * 24 * 30;
    //let timeFrom = timeTo - 3600 * 24 * 1;
    let msgLoadCount = 0xffffffff; //0xffffffff=4294967295 All messages
    //let msgLoadCount = 80000; //0xffffffff=4294967295 All messages

    let prms = [];
    let unitId = unit.id;
    let prmsObj = {
        "svc": "messages/load_interval",
        "params": {
            "itemId": unitId,
            "timeFrom": timeFrom,
            "timeTo": timeTo, "flags": 32, "flagsMask": 0, "loadCount": msgLoadCount
        }
    };
    prms.push(prmsObj);

    //if (unit.unitTrailers.length > 0) {
    //    for (let t = 0; t < unit.unitTrailers.length; t++) {
    //        let prmsObj = {
    //            "svc": "messages/load_interval",
    //            "params": {
    //                "itemId": unit.unitTrailers[t].id,
    //                "timeFrom": timeFrom,
    //                "timeTo": timeTo, "flags": 1, "flagsMask": 65281, "loadCount": msgLoadCount
    //            }
    //        };
    //        prms.push(prmsObj);
    //    }
    //}

    let data = await loadSensorValuesBatch(prms);

    return await new Promise((resolve, reject) => {

        let sensorValues = [];

        //if (unit.name === "Test Wialon IPS 2") {

            for (let p = 0; p < prms.length; p++) {

                for (let i = 0; i < data[p].messages.length; i++) {

                    for (let property in data[p].messages[i].p) {

                        if (property.slice(0, 1) === "w" || property.slice(0, 2) === "vr" || property.slice(0, 2) === "vl") {
                            continue;
                        }

                        if (property.slice(0, 1) === "p") {
                            let sensorValue = {};

                            sensorValue.id = prms[p].params.itemId;
                            sensorValue.unitNumber = property.slice(-1);
                            sensorValue.sensorName = property.slice(1);
                            sensorValue.axle = property.slice(2, 4);
                            sensorValue.wheelAxle = property.slice(1, 4);
                            sensorValue.wheelPos = property.slice(1, 2);
                            sensorValue.axleWheelPos = sensorValue.axle + sensorValue.wheelPos;

                            for (let s = 0; s < unit.wheelSensors.length; s++) {
                                if (unit.wheelSensors[s].sensorName === sensorValue.sensorName) {
                                    sensorValue.minPressureValue = unit.wheelSensors[s].minPressureValue;
                                    sensorValue.maxPressureValue = unit.wheelSensors[s].maxPressureValue;
                                    sensorValue.maxTemperatureValue = unit.wheelSensors[s].maxTemperatureValue;
                                    sensorValue.minVoltageValue = unit.wheelSensors[s].minVoltageValue;

                                    break;
                                }
                            }

                            sensorValue.unixTime = data[p].messages[i].t;
                            sensorValue.time = convertUnixTime(data[p].messages[i].t);
                            sensorValue.posx = data[p].messages[i].pos?.x || 0;
                            const tempi = i;
                            sensorValue.posy = data[p].messages[i].pos?.y || 0;
                            sensorValue.pressure = (parseFloat(data[p].messages[i].p[property])).toFixed(2);
                            sensorValue.temperature = data[p].messages[i].p["t" + property.slice(1)];
                            sensorValue.voltage = data[p].messages[i].p["v" + property.slice(1)];
                            sensorValues.push(sensorValue);

                        }
                    }
                }
            }

        //} else {

        //    for (let p = 0; p < prms.length; p++) {
        //        for (let i = 0; i < data[p].messages.length; i++) {
        //            if (data[p].messages[i].p.data_type === "E6") {

        //                for (let property in data[p].messages[i].p) {

        //                    if (property === 'data_type' || property === 'real_count' || property === 'total_count') continue; //{                   

        //                    if (property.includes("pressure")) {
        //                        let sensorValue = {};
        //                        sensorValue.id = prms[p].params.itemId;
        //                        sensorValue.axleWheel = property.slice(1, 3);
        //                        if (p === 0) {
        //                            for (let s = 0; s < unit.sensors.length; s++) {
        //                                if (unit.sensors[s].sensorName.slice(0, 2) === sensorValue.axleWheel) {
        //                                    sensorValue.minPressureValue = unit.sensors[s].minPressureValue;
        //                                    sensorValue.maxPressureValue = unit.sensors[s].maxPressureValue;
        //                                    sensorValue.maxTemperatureValue = unit.sensors[s].maxTemperatureValue;
        //                                    sensorValue.minVoltageValue = unit.sensors[s].minVoltageValue;
        //                                    break;
        //                                }
        //                            }
        //                        }
        //                        if (p > 0) {
        //                            for (let s = 0; s < unit.unitTrailers[p - 1].sensors.length; s++) {
        //                                if (unit.unitTrailers[p - 1].sensors[s].sensorName.slice(0, 2) === sensorValue.axleWheel) {
        //                                    sensorValue.minPressureValue = unit.unitTrailers[p - 1].sensors[s].minPressureValue;
        //                                    sensorValue.maxPressureValue = unit.unitTrailers[p - 1].sensors[s].maxPressureValue;
        //                                    sensorValue.maxTemperatureValue = unit.unitTrailers[p - 1].sensors[s].maxTemperatureValue;
        //                                    sensorValue.minVoltageValue = unit.unitTrailers[p - 1].sensors[s].minVoltageValue;
        //                                    break;
        //                                }
        //                            }
        //                        }
        //                        sensorValue.unixTime = data[p].messages[i].t;
        //                        sensorValue.time = convertUnixTime(data[p].messages[i].t);
        //                        sensorValue.posx = data[p].messages[i].pos?.x || 0;
        //                        sensorValue.posy = data[p].messages[i].pos?.y || 0;
        //                        sensorValue.pressure = (parseFloat(data[p].messages[i].p[property]) * 0.0689475729).toFixed(2);
        //                        sensorValue.temperature = data[p].messages[i].p["t" + property.substring(1, 3) + "_temp"];
        //                        sensorValue.voltage = data[p].messages[i].p["t" + property.substring(1, 3) + "_sensor_volt"];
        //                        sensorValues.push(sensorValue);
        //                    }
        //                }
        //            }
        //        }
        //    }
        //}

        //console.log('time1sort: [' + Date.now() + '] ' );

        if (sensorValues.length) {
            //if (unit.name === "Test Wialon IPS 2") 
                sensorValues.sort((a, b) => a.unitNumber - b.unitNumber || a.axleWheelPos - b.axleWheelPos || a.unixTime - b.unixTime);
            //else
            //    sensorValues.sort((a, b) => a.id - b.id || a.axleWheel - b.axleWheel || a.unixTime - b.unixTime);

            //console.log('time2sort: [' + Date.now() + '] ' );
            //sensorValues = removeDataSpikes(sensorValues);

            //global.sensorValuesChartMessages = sensorValues;
            //global.isProcessingChartMessages = false;

            console.log('time2: [' + Date.now() + '] ' + unitId + ' record count: ' + sensorValues.length);

            resolve(sensorValues);
        } else {
            reject(new Error(`Whoops!`));
        }


    }).catch(alert);
}

async function getUnitLocations(unitPositions) {

    try {
        return await new Promise((resolve, reject) => {
            wialon.util.Gis.getLocations(unitPositions,
                (error, addresses) => {
                    if (error) {
                        console.log("reportsJS: getUnitLocations - API ERROR " + error + " #" + wialon.core.Errors.getErrorText(error));
                        reject({ type: 'API_ERROR', error: error });
                        return;
                    }
                    resolve(addresses);
                });
        });
    } catch (e) {
        console.error("reportsJS: getUnitLocations - ERROR ", e);
    }
}

//let custSensorValues = [];     //for (let i = data.messages.length - 1; i >= 0; i--) { // get last received message with values
//let axleWheel = sensorId.substring(0, 2);

//for (let i = 0; i < data.messages.length; i++) { // get last received message with values
//    if (data.messages[i].p.data_type === "E6") { // E6 = custom sensor values??

//        let custSensorValue = {};
//        for (let property in data.messages[i].p) {
//            if (property === 'data_type' || property === 'real_count' || property === 'total_count') continue; //{                   
//            if (property.substring(1, 3) === axleWheel) {
//                custSensorValue.time = convertUnixTime(data.messages[i].t);
//                if (property.includes("pressure")) {
//                    custSensorValue.pressure = (parseFloat(data.messages[i].p[property]) * 0.0689475729).toFixed(2);

//                    continue;
//                }
//                if (property.includes("temp")) {
//                    custSensorValue.temperature = data.messages[i].p[property];
//                    continue;
//                }
//                if (property.includes("volt")) {
//                    custSensorValue.voltage = data.messages[i].p[property];
//                    continue;
//                }
//            }
//        }

//        custSensorValues.push(custSensorValue);

//    }

export { loadSensorValuesChartMessages, openSensorValuesChartReport, getSensorValuesChartReportData };