
import { SPARE_AXLE } from "../global";
export async function getSensorSummaryData(unit, date) {

    const results = await fetchSensorSummaryData(unit.imei, date);
   
    return await new Promise((resolve, reject) => {

        if (results.data.length) {
            //const data = filterObjectsByTimestamp(results.data);
            const data = results.data;
            let sensorSummaryValues = [];
            for (let s = 0; s < data.length; s++) {
                const sensorSummary = {};
                //sensorSummary.unitId = unit.id;
                //sensorSummary.unitNumber = data[s].wheelId.slice(-1);
                sensorSummary.sensorName = data[s].wheelId;
                //sensorSummary.axle = parseInt(data[s].wheelId.slice(1, 3));
                //sensorSummary.wheelAxle = data[s].wheelId.slice(0, -1);
                sensorSummary.minPressureValue = data[s].minPressure;
                sensorSummary.maxPressureValue = data[s].maxPressure;
                //sensorSummary.manufacturersRecommendedPressure = wheel.manufacturersRecommendedPressure;
                sensorSummary.maxTemperatureValue = data[s].maxTemperature;
                sensorSummary.minVoltageValue = data[s].minVoltage;
                //sensorSummary.unixTime = new Date(data[s].payloadTS).getTime() / 1000;
                //sensorSummary.time = data[s].payloadTS;
                //sensorSummary.posx = data[s].pos?.x || 0;
                //sensorSummary.posy = data[s].pos?.y || 0;
                //sensorSummary.pressure = (parseFloat(data[s].pressure)).toFixed(2);
                //sensorSummary.temperature = parseInt(data[s].temperature);
                //sensorSummary.voltage = parseFloat(data[s].voltage);
                sensorSummaryValues.push(sensorSummary);
            }

            if (sensorSummaryValues.length) {
                resolve(sensorSummaryValues);
            } else {
                reject(new Error(`Whoops!`));
            }
        }

    }).catch(alert);
}

export async function getSensorHistoryData(unit, wheel, date) {

    if (typeof variable === 'object' && variable !== null && !Array.isArray(variable)) {
        // variable is an object and not a string or array
    }

    const wheelLocation = typeof wheel === 'object' && wheel !== null ? wheel.sensorName : wheel;
    const results = await fetchSensorHistoryData(unit.imei, wheelLocation, date);

    return await new Promise((resolve, reject) => {

        if (results.data.length) {
            //const data = filterObjectsByTimestamp(results.data);
            const sensors = wheelLocation.slice(1, 3) === SPARE_AXLE.toString() ? unit.spareSensors : unit.wheelSensors;
            const data = results.data;
            let sensorValues = [];
            for (let s = 0; s < data.length; s++) {
                const sensorValue = {};
                sensorValue.id = unit.id;
                sensorValue.unitNumber = wheelLocation.slice(-1);
                sensorValue.sensorId = data[s].sensorId;
                sensorValue.sensorName = wheelLocation;
                sensorValue.axle = parseInt(wheelLocation.slice(1, 3));
                sensorValue.wheelAxle = wheelLocation.slice(1);
                sensorValue.minPressureValue = sensors.find(s => s.sensorName === wheelLocation)?.minPressureValue || wheel.minPressureValue || 0;
                sensorValue.maxPressureValue = sensors.find(s => s.sensorName === wheelLocation)?.maxPressureValue || wheel.maxPressureValue || 0;
                sensorValue.manufacturersRecommendedPressure = sensors.find(s => s.sensorName === wheelLocation)?.manufacturersRecommendedPressure || wheel.manufacturersRecommendedPressure || 0;
                sensorValue.maxTemperatureValue = sensors.find(s => s.sensorName === wheelLocation)?.maxTemperatureValue || wheel.maxTemperatureValue || 0;
                sensorValue.minVoltageValue = sensors.find(s => s.sensorName === wheelLocation)?.minVoltageValue || wheel.minVoltageValue || 0;
                sensorValue.unixTime = new Date(data[s].payloadTS).getTime() / 1000;

                const apiTimestamp = data[s].payloadTS;           
                const entryTime = new Date(apiTimestamp);
                const startTime = new Date(Date.UTC(2024, 6, 4, 7, 0, 0)); // Note: month is zero-based, so July is 6
                const endTime = new Date(Date.UTC(2024, 6, 4, 8, 0, 0));

                // Check if the entry's timestamp is within the range
                //if (entryTime >= startTime && entryTime < endTime) {
                //    console.log(`${entryTime} ${data[s].pressure} bar`);
                //} 

                sensorValue.time = data[s].payloadTS;
                sensorValue.posx = data[s].pos?.x || 0;
                sensorValue.posy = data[s].pos?.y || 0;
                sensorValue.pressure = (parseFloat(data[s].pressure)).toFixed(2);
                sensorValue.temperature = parseInt(data[s].temperature);
                sensorValue.voltage = parseFloat(data[s].voltage);
                sensorValues.push(sensorValue);

            }

            if (sensorValues.length) {
                sensorValues.sort((a, b) => a.unixTime - b.unixTime);     
                resolve(sensorValues);
            } else {
                reject(new Error(`Whoops!`));
            }
        }

    }).catch(alert);
}

function filterObjectsByTimestamp(array) {
    const currentDate = new Date(); // Current date and time
    const currentUnixTimestamp = currentDate.getTime() / 1000; // Convert to Unix timestamp in seconds

    // Filter the array
    const filteredArray = array.filter(obj => {
        const receivedDate = new Date(obj.receivedTS);
        const receivedUnixTimestamp = receivedDate.getTime() / 1000; // Convert to Unix timestamp in seconds

        // Calculate the difference in seconds between current timestamp and received timestamp
        const differenceInSeconds = currentUnixTimestamp - receivedUnixTimestamp;

        // Check if the difference is less than or equal to 7 days (7 days * 24 hours * 60 minutes * 60 seconds)
        return differenceInSeconds <= (7 * 24 * 60 * 60);
    });

    return filteredArray;
}

async function fetchSensorHistoryData(imei, wheel, date) {

    let apiUrl;      

    // Check if 'date' is a single date object or an array with two date objects
    if (Array.isArray(date) && date.length === 2) {
        apiUrl = `https://cloud-connector-dashboard.restiolabs.com/api/unit/${imei}/wheel/${wheel}/history?datapointInterval=1&startTimestamp=${date[0]}&endTimestamp=${date[1]}`;
    } else if (typeof date === "string") {
        //const dateString = date.toISOString().split('T')[0];
        apiUrl = `https://cloud-connector-dashboard.restiolabs.com/api/unit/${imei}/wheel/${wheel}/history?datapointInterval=1&startTimestamp=${date}`;
    } else {
        throw new Error('Invalid date format');
    }

    try {
        const response = await fetch(apiUrl);

        //const response = await fetch(apiUrl, {
        //    method: 'GET',
        //    headers: {
        //        'Authorization': `Bearer ${jwtToken}`,
        //        'Content-Type': 'application/json'
        //    },
        //    credentials: 'include' // Include cookies in the request
        //});

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        return await response.json();
        //return results;
      
        //console.log(`DATA!!!!!!! ${data}`);
        // You can process the 'data' object here as needed

    } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
    }
}

async function fetchSensorSummaryData(imei, date) {

    let apiUrl;

    // Check if 'date' is a single date object or an array with two date objects
    if (Array.isArray(date) && date.length === 2) {
        //const startDate = date[0].toISOString().split('T')[0];
        //const endDate = date[1].toISOString().split('T')[0];
        //apiUrl = `https://cloud-connector-dashboard.restiolabs.com/api/unit/${imei}/wheel/1010/history?datapointInterval=16&startTimestamp=${startDate}&endTimestamp=${endDate}`;
        apiUrl = `https://cloud-connector-dashboard.restiolabs.com/api/unit/${imei}/sensor-summary?startTimestamp=${date[0]}&endTimestamp=${date[1]}`;
    } else if (typeof date === "string") {
        //const dateString = date.toISOString().split('T')[0];
        apiUrl = `https://cloud-connector-dashboard.restiolabs.com/api/unit/${imei}/sensor-summary?startTimestamp=${date}`;
    } else {
        throw new Error('Invalid date format');
    }

    try {
        const response = await fetch(apiUrl);
        return await response.json();
    } catch (error) {
        console.error('Error fetching sensor summary data:', error);
        throw error;
    }
}

//async function fetchSensorSummaryData(imei, date) {

//    const apiUrl = `https://cloud-connector-dashboard.restiolabs.com/api/imei/${imei}/sensor-summary?startTimestamp=${date}`;

//    try {
//        const response = await fetch(apiUrl);

//        //const response = await fetch(apiUrl, {
//        //    method: 'GET',
//        //    headers: {
//        //        'Authorization': `Bearer ${jwtToken}`,
//        //        'Content-Type': 'application/json'
//        //    },
//        //    credentials: 'include' // Include cookies in the request
//        //});

//        if (!response.ok) {
//            throw new Error('Network response was not ok');
//        }

//        return await response.json();
//        //return results;

//        //console.log(`DATA!!!!!!! ${data}`);
//        // You can process the 'data' object here as needed

//    } catch (error) {
//        console.error('There was a problem with the fetch operation:', error);
//    }
//}

export function getUnitSensorData(unitId, sensorName) { // Execute after login succeed
    var sess = wialon.core.Session.getInstance(); // get instance of current Session
    // flags to specify what kind of data should be returned
    var flags = wialon.item.Item.dataFlag.base |
        wialon.item.Unit.dataFlag.sensors |
        wialon.item.Unit.dataFlag.lastMessage;
    sess.loadLibrary("unitSensors"); // load Sensor Library
    sess.loadLibrary("unitEvents");
    sess.updateDataFlags( // load items to current session
        [{ type: "type", data: "avl_unit", flags: flags, mode: 0 }], // Items specification
        function (code) { // updateDataFlags callback
            if (code) { msg(wialon.core.Errors.getErrorText(code)); return; } // exit if error code  

            var units = sess.getItems("avl_unit"); // get loaded 'avl_unit's items
            if (!units || !units.length) { return; } // check if units found

            getSensorInfo(unitId, sensorName); // get Sensors for currently selected unit

        });
}

function getSensors(unitId, sensorName) { // construct sensors Select list for selected unit

    var sess = wialon.core.Session.getInstance(); // get instance of current Session
    var unit = sess.getItem(unitId); // get unit by id
    var sens = unit.getSensors(); // get unit's sensors
    getSensorInfo(unitId, sensorName)
}

function getSensorInfo(unitId) { // get and show information about selected Sensor

    var sess = wialon.core.Session.getInstance(); // get instance of current Session
    var unit = sess.getItem(unitId); // get unit by id
    //var sens = unit.getSensor(103); // get sensor by id

    //get sensor events
    unit.getCurrentSensors(103, null, 20, function (code, data) {
        if (code) { msg(wialon.core.Errors.getErrorText(code)); return; } // exit if error code
        console.log(`API_ERROR ${code} ${JSON.stringify(data)}`);

        let timeTo = session.getServerTime();
        const timeFrom = timeTo - 3600 * 24 * 7;
        unit.getSensorsHistory(1, timeFrom, timeTo, 103, null, function (error, result) {
            if (error) { msg(wialon.core.Errors.getErrorText(code)); return; } // exit if error code
            console.log(`API_ERROR ${error} ${JSON.stringify(result)}`);
        });
    });
}


export async function getUnitSensorData1(unitId, sensorName) {

    const session = wialon.core.Session.getInstance();
    var flags = wialon.item.Item.dataFlag.base |
        wialon.item.Unit.dataFlag.sensors |
        wialon.item.Unit.dataFlag.lastMessage;
    session.loadLibrary("unitSensors"); // load Sensor Library
    session.loadLibrary('unitEvents');
    try {

        const remote = wialon.core.Remote.getInstance();
        const prms = {
            spec: {
                itemsType: 'avl_unit',
                propName: 'sys_id',
                propValueMask: `${unitId}`,
                sortType: 'sys_id',
            },
            force: 1,
            flags: -1,
            from: 0,
            to: 0,
        };

        return new Promise((resolve, reject) => {
            remote.remoteCall('core/search_items', prms, async (error, result) => {
                if (error) {
                    console.log(`API_ERROR ${error} ${JSON.stringify(result)}`);
                    reject({ type: 'API_ERROR', code: error, fullError: result });
                }

                const unitSensors = result.items[0].sens;

                const sensors = Object.keys(unitSensors).filter(obj => unitSensors[obj].n === `P${sensorName}` || unitSensors[obj].n === `T${sensorName}` || unitSensors[obj].n === `V${sensorName}`)
                    .map(obj => ({ id: unitSensors[obj].id, n: unitSensors[obj].n }));

                const sensorIds = Object.keys(unitSensors).filter(obj => unitSensors[obj].n === `P${sensorName}` || unitSensors[obj].n === `T${sensorName}` || unitSensors[obj].n === `V${sensorName}`)
                    .map(obj => unitSensors[obj].id);

                var unit = session.getItem(unitId); // get unit by id
                //var sens = unit.getSensor(sensorIds[0]); // get sensor by id
                // calculate sensor value
                // var result = unit.calculateSensorValue(sens, unit.getLastMessage());
                //if (result == -348201.3876) result = "N/A"; // compare result with invalid sensor value constant
                // print result message
                //msg("Value of " + unit.getName() + " <b>'" + sens.n + "'</b> sensor (" + sens.t + "): " + result + " (" + sens.m + ")");

                // get sensor events

                let timeTo = session.getServerTime();
                const timeFrom = timeTo - 3600 * 24 * 7;
                await unit.getSensorsHistory(1, timeFrom, timeTo, sensorIds[0],
                    async function (code, data) {
                    if (code) { msg(wialon.core.Errors.getErrorText(code)); return; }
                    //await console.log("hallo");
                    // exit if error code
                    //console.log(data);
                });

                //console.log('sensor data');
                //console.log(unitSensors);
                unit.sensors = unitSensors;
                resolve(unit);
            });
        });
    } catch (e) {
        console.error('unitsJS - getUnit: ERROR ', e);
    }
}

export async function getEvents(unitId, sensorIds) {
    try {
        const session = wialon.core.Session.getInstance();
        session.loadLibrary('unitEvents');
        let timeTo = session.getServerTime();
        const timeFrom = timeTo - 3600 * 24 * 7;
        const remote = wialon.core.Remote.getInstance();
        const prms = {
            ivalType: 1,
            itemId: unitId,
            timeFrom: timeFrom,
            timeTo: timeTo,
            detectors: [
                {
                    type: "sensors",
                    filter1: sensorIds[0]
                },
                {
                    type: "sensors",
                    filter1: sensorIds[1]
                }, {
                    type: "sensors",
                    filter1: sensorIds[2]
                }
            ],
            selector: [{
                type: "sensors",
                timeFrom: timeFrom,
                timeTo: timeTo,
                detalization: 25,
                filter1: sensorIds[0]
            },
            {
                type: "sensors",
                timeFrom: timeFrom,
                timeTo: timeTo,
                detalization: 25,
                filter1: sensorIds[1]
            },
            {
                type: "sensors",
                timeFrom: timeFrom,
                timeTo: timeTo,
                detalization: 25,
                filter1: sensorIds[2]
            }],
        };
        return new Promise((resolve, reject) => {
            remote.remoteCall('events/load', prms, async (error, result) => {
                if (error) {
                    console.log(`API_ERROR ${error} ${JSON.stringify(result)}`);
                    reject({ type: 'API_ERROR', code: error, fullError: result });
                }
                //console.log(JSON.stringify(result));
                resolve(result);
            });
        });
    } catch (e) {
        console.error('pressuretrackJS: getEvents - ERROR ', e);
    }
}


