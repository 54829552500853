
export async function getUnitSensorData(unitId) {
  try {
    //console.log('unitId');
    //console.log(unitId);

    const remote = wialon.core.Remote.getInstance();
    const prms = {
      spec: {
        itemsType: 'avl_unit',
        //propName: 'sys_id, sys_unique_id, sys_phone_number',
        propName: 'sys_id',
        propValueMask: `${unitId}`,
        sortType: 'sys_id',
      },
      force: 1,
      flags: -1,
      from: 0,
      to: 0,
    };

    return new Promise((resolve, reject) => {
      remote.remoteCall('core/search_items', prms, async (error, result) => {
        if (error) {
          console.log(`API_ERROR ${error} ${JSON.stringify(result)}`);
          reject({ type: 'API_ERROR', code: error, fullError: result });
        }
        const unit = {};

        unit.name = result.items[0].nm;

        const uSensors = [];
        const sensors = result.items[0].sens;

        Object.keys(sensors).forEach((key) =>
          uSensors.push({ s: sensors[key] })
        );

        //console.log('uSensors');
        //console.log(uSensors);

        const unitSensors = [];
        for (let us = 0; us < uSensors.length; us++) {
          const wheelSensor = {};
          wheelSensor.name = uSensors[us].s.n;
          wheelSensor.description = uSensors[us].s.d;
          wheelSensor.measurement = uSensors[us].s.m;
          wheelSensor.prm = uSensors[us].s.p;
          unitSensors.push(wheelSensor);
        }

        //console.log('sensor data');
        //console.log(unitSensors);
        unit.sensors = unitSensors;
        resolve(unit);
      });
    });
  } catch (e) {
    console.error('pressuretrackJS: getUnit - ERROR ', e);
  }
}

export async function getDeviceInfo(deviceTypeId) {
  try {
    // let prms = {
    //   hwId: '600001043',
    //   action: 'get',
    //   itemId: unitId,
    //   fulldata: 1,
    // };
    let prms = {
      filterType: 'id',
      filterValue: [deviceTypeId],
      includeType: true,
    };
    const remote = wialon.core.Remote.getInstance();
    return new Promise((resolve, reject) => {
      remote.remoteCall('core/get_hw_types', prms, (error, result) => {
        if (error) {
          reject({ type: 'API_ERROR', code: error, fullError: result });
          return;
        }
        /*console.log(result);*/
        resolve(result); //result = id: 600001043; name: "Totem Tech - AT09"
      });
    });
  } catch (e) {
    console.error('pressuretrackJS: getUnit - ERROR ', e);
  }
}

export async function getDevices() {
  try {
    // let prms = {
    //   hwId: '600001043',
    //   action: 'get',
    //   itemId: unitId,
    //   fulldata: 1,
    // };
    let prms = {
      filterType: 'name',
        filterValue: [
            'Wialon IPS',
        'Teltonika FMC640',
        'Teltonika FMC650',
        'Totem Tech - AT09',
      ],
      includeType: true,
    };
    const remote = wialon.core.Remote.getInstance();
    return new Promise((resolve, reject) => {
      remote.remoteCall('core/get_hw_types', prms, (error, result) => {
        if (error) {
          reject({ type: 'API_ERROR', code: error, fullError: result });
          return;
        }

        resolve(result); //result = id: 600001043; name: "Totem Tech - AT09"
      });
    });
  } catch (e) {
    console.error('pressuretrackJS: getUnit - ERROR ', e);
  }
}

export async function addUnit(unit) {
  try {
    let prms = {
      creatorId: unit.creatorId,
      name: unit.name,
      hwTypeId: unit.device,
      dataFlags: 1,
    };
    const remote = wialon.core.Remote.getInstance();
    return new Promise((resolve, reject) => {
      remote.remoteCall('core/create_unit', prms, (error, result) => {
        if (error) {
          reject({ type: 'API_ERROR', code: error, fullError: result });
          return;
        }

        updateDeviceInfo(result.item.id, unit.device, unit.imei);
        updatePhoneNo(result.item.id, unit.phoneNo);
        resolve(result); //result = id: 600001043; name: "Totem Tech - AT09"
      });
    });
  } catch (e) {
    console.error('addUnit - ERROR ', e);
  }
}

export async function addSensor(sensor) {
  try {
    let prms = {
      itemId: Number(sensor.id),
      id: 0,
      callMode: 'create',
      n: sensor.sensorId,
      d: '',
      c: '',
      m: sensor.measurement,
      p: sensor.parameter,
      t: 'custom',
      f: 0,
      vt: 1,
      vs: 0,
      tbl: [],
    };
    //console.log('prms');
    //console.log(prms);
    const remote = wialon.core.Remote.getInstance();
    return new Promise((resolve, reject) => {
      remote.remoteCall('unit/update_sensor', prms, (error, result) => {
        if (error) {
          console.log('adsensor error');
          console.log(result);
          reject({ type: 'API_ERROR', code: error, fullError: result });

          return;
        }

        console.log('adsensor res');
        console.log(result);
        resolve(result); //result = id: 600001043; name: "Totem Tech - AT09"
      });
    });
  } catch (e) {
    console.error('addUnit - ERROR ', e);
  }
}

export async function addSensorPressureTemplate(unitId) {
  try {
    let prms = [];
    const metric = ['P', 'T', 'V'];
    const metricUnit = ['bar', 'degreeC', 'V'];
    for (let u = 0; u < 3; u++) {
      //unit = 0, trailer = 1, 2
      for (let m = 0; m < 3; m++) {
        for (let a = 1; a < 5; a++) {
          for (let w = 1; w <= 4; w++) {
            // const axle = Math.floor(a / 4);
            // const wheel = (a % 4) + 1;
            // const sensorId =
            //   axle.toString().padStart(2, '0') + wheel.toString() + '0';
            const sensorId =
              metric[m] +
              w.toString() +
              a.toString().padStart(2, '0') +
              u.toString();
            console.log('chatgpt');
            console.log(sensorId);
            const prmsObj = {
              svc: 'unit/update_sensor',

              params: {
                itemId: Number(unitId),
                id: 0,
                callMode: 'create',
                n: sensorId,
                d: '',
                c: '',
                m: metricUnit[m],
                p: `${sensorId}_pressure`,
                t: 'custom',
                f: 0,
                vt: 1,
                vs: 0,
                tbl: [],
              },
            };
            prms.push(prmsObj);
          }
        }
      }
    }

    const spareAxles = ['2160', '3160', '2161', '3161', '2162', '3162'];
    for (let sm = 0; sm < 3; sm++) {
      for (let sa = 0; sa < spareAxles.length; sa++) {
        const spareSensorId = metric[sm] + spareAxles[sa];
        const prmsObj = {
          svc: 'unit/update_sensor',

          params: {
            itemId: Number(unitId),
            id: 0,
            callMode: 'create',
            n: spareSensorId,
            d: '',
            c: '',
            m: metricUnit[sm],
            p: `${spareSensorId}_pressure`,
            t: 'custom',
            f: 0,
            vt: 1,
            vs: 0,
            tbl: [],
          },
        };
        prms.push(prmsObj);
      }
    }

    const remote = wialon.core.Remote.getInstance();
    return new Promise((resolve, reject) => {
      remote.remoteCall('core/batch', prms, (error, result) => {
        if (error) {
          console.log('adsensor error');
          console.log(result);
          reject({ type: 'API_ERROR', code: error, fullError: result });

          return;
        }

        console.log('adsensor res');
        console.log(result);
        resolve(result); //result = id: 600001043; name: "Totem Tech - AT09"
      });
    });
  } catch (e) {
    console.error('addUnit - ERROR ', e);
  }
}

export async function updateUnitName(unitId, unit) {
  try {
    let prms = {
      itemId: unitId,
      name: unit,
    };
    const remote = wialon.core.Remote.getInstance();
    return new Promise((resolve, reject) => {
      remote.remoteCall('item/update_name', prms, (error, result) => {
        if (error) {
          reject({ type: 'API_ERROR', code: error, fullError: result });
          return;
        }
        console.log('newname');
        console.log(result);
        resolve(result); //result = id: 600001043; name: "Totem Tech - AT09"
      });
    });
  } catch (e) {
    console.error('updateUnitName - ERROR ', e);
  }
}

export async function addTrailer(trailer) {
    try {
        const prms = {
            itemId: global.resource.getId(),
            id: 0,
            callMode: 'create',
            n: trailer.name,
            c: trailer.sensorId,
            ds: `${trailer.unitLinkedTo}-${trailer.name.slice(-1)}`, //trailer.linkedTo,
            p: "", //trailer.phoneNo,
            ck: 0,
            r: 0,
            f: 0,
            jp: {},
        };
        console.log('prms');
        console.log(prms);
        const remote = wialon.core.Remote.getInstance();
        return new Promise((resolve, reject) => {
            remote.remoteCall('resource/update_trailer', prms, (error, result) => {
                if (error) {
                    console.log('update_trailer error');
                    console.log(result);
                    reject({ type: 'API_ERROR', code: error, fullError: result });
                    return;
                }
                console.log('add trailer res');
                console.log(result);
                resolve(result); //result = id: 600001043; name: "Totem Tech - AT09"
            });
        });
    } catch (e) {
        console.error('addTrailer - ERROR ', e);
    }
}

export async function editTrailer(trailer) {
    try {
        let prms = {
            itemId: global.resource.getId(),
            id: trailer.id,
            callMode: 'update',
            n: trailer.name,
            c: trailer.sensorId,
            ds: `${trailer.unitLinkedTo}-${trailer.order}`
        };

        const remote = wialon.core.Remote.getInstance();
        return new Promise((resolve, reject) => {
            remote.remoteCall('resource/update_trailer', prms, (error, result) => {
                if (error) {
                    console.log('update_trailer error');
                    console.log(result);
                    reject({ type: 'API_ERROR', code: error, fullError: result });
                    return;
                }
                resolve(result);
            });
        });
    } catch (e) {
        console.error('addTrailer - ERROR ', e);
    }
}

export async function updatePhoneNo(unitId, phoneNo) {
  try {
    let prms = {
      itemId: unitId,
      phoneNumber: phoneNo,
    };
    const remote = wialon.core.Remote.getInstance();
    return new Promise((resolve, reject) => {
      remote.remoteCall('unit/update_phone', prms, (error, result) => {
        if (error) {
          reject({ type: 'API_ERROR', code: error, fullError: result });
          return;
        }
        console.log(result);
        resolve(result); //result = id: 600001043; name: "Totem Tech - AT09"
      });
    });
  } catch (e) {
    console.error('updatePhoneNo - ERROR ', e);
  }
}

export async function updateDeviceInfo(unitId, deviceTypeId, imei) {
  try {
    // let prms = {
    //   hwId: '600001043',
    //   action: 'get',
    //   itemId: unitId,
    //   fulldata: 1,
    // };
    console.log('deviceTypeId');
    console.log(deviceTypeId);
    let prms = {
      itemId: unitId,
      deviceTypeId: deviceTypeId,
      uniqueId: imei,
    };
    const remote = wialon.core.Remote.getInstance();
    return new Promise((resolve, reject) => {
      remote.remoteCall('unit/update_device_type', prms, (error, result) => {
        if (error) {
          reject({ type: 'API_ERROR', code: error, fullError: result });
          return;
        }
        resolve(result); //result = id: 600001043; name: "Totem Tech - AT09"
      });
    });
  } catch (e) {
    console.error('updateDeviceInfo - ERROR ', e);
  }
}

export async function deleteUnit(unitId) {
  try {
    let prms = {
      id: unitId,
    };
    const remote = wialon.core.Remote.getInstance();
    return new Promise((resolve, reject) => {
      remote.remoteCall('item/delete_item', prms, (error, result) => {
        if (error) {
          reject({ type: 'API_ERROR', code: error, fullError: result });
          return;
        }
        console.log('new unit');
        console.log(result);
        resolve(result); //result = id: 600001043; name: "Totem Tech - AT09"
      });
    });
  } catch (e) {
    console.error('addUnit - ERROR ', e);
  }
}
