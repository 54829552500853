import { getCurrentLocation } from "./data/getunitdata";
import { convertUnixTime, formatDate } from "./utilities";
import { loadIntervalAsync } from "./pressuretrack";
import { createUnitMapContainerElement } from "./components/views/unitinfo";
import { loadSensorValuesBatch } from "./components/views/units";
import { DialogBox } from "../thirdparty/customdialog/draggable-resizable-dialog.js";

import { PRIMARY_COLOUR, SECONDARY_COLOUR, LOW_PRESSURE_COLOUR, HIGH_PRESSURE_COLOUR, HIGH_TEMPERATURE_COLOUR, LOW_VOLTAGE_COLOUR, SPARE_AXLE } from "./global";

let map = null, marker = null, mapBox = null; // global variables for maps (leaflet)

var gSelectedUnit = {};

var gPressureChartCanvas = document.getElementById('sensorreportpressurechartcanvas');
var gPressureCtx = gPressureChartCanvas.getContext('2d');
var gTemperatureChartCanvas = document.getElementById('sensorreporttemperaturechartcanvas');
var gTemperatureCtx = gTemperatureChartCanvas.getContext('2d');
var gVoltageChartCanvas = document.getElementById('sensorreportvoltagechartcanvas');
var gVoltageCtx = gVoltageChartCanvas.getContext('2d');
var gPressureChartCounter = 0;
var gTemperatureChartCounter = 0;
var gVoltageChartCounter = 0;
var gPressureChart, gTemperatureChart, gVoltageChart;
var gPressureChartMaxY = 0;
var gTemperatureChartMaxY = 0;
var gVoltageChartMaxY = 0;

//var gSensorValuesDateRangeRadio = document.getElementById('sensorreportdaterange');
var gSensorValuesTodayRadio = document.getElementById('sensorreporttoday');
var gSensorValuesLast7DaysRadio = document.getElementById('sensorreport7days');
//var gSensorValuesLast30DaysRadio = document.getElementById('sensorreport30days');

var gSensorValuesPressureCheck = document.getElementById('sensorreportpressure');
var gSensorValuesTemperatureCheck = document.getElementById('sensorreporttemperature');
var gSensorValuesVoltageCheck = document.getElementById('sensorreportvoltage');

var gSensorValuesDataToday = [];
var gSensorValuesData7Days = [];
var gPrevMinPressureValue, gPrevMaxTemperatureValue, gPrevMinVoltageValue;
var gPressureAnnotation = [];
var gTemperatureAnnotation = [];
var gVoltageAnnotation = [];

function removePressureChart(wheelAxle) {

    let pressureAnnotation = gPressureAnnotation.filter((value, index, self) =>
        index === self.findIndex((t) => (
            t.minPressureValue === value.minPressureValue
        ))
    );

    if (gPressureChart && gPressureChart.data.datasets.length > 0) {
        if (gPressureChart.config.options.plugins.annotation.annotations.length) {
            if (pressureAnnotation.length === 1) {
                gPressureChart.config.options.plugins.annotation.annotations[0].yMax = pressureAnnotation[0].minPressureValue;
                gPressureChart.config.options.plugins.annotation.annotations[0].yMin = pressureAnnotation[0].minPressureValue;
                gPressureChart.config.options.plugins.annotation.annotations[0].label.content = "Min Press: " + pressureAnnotation[0].minPressureValue + " bar";
                gPressureChart.config.options.plugins.annotation.annotations[1].yMax = pressureAnnotation[0].maxPressureValue;
                gPressureChart.config.options.plugins.annotation.annotations[1].yMin = pressureAnnotation[0].maxPressureValue;
                gPressureChart.config.options.plugins.annotation.annotations[1].label.content = "Max Press: " + pressureAnnotation[0].maxPressureValue + " bar";
                gPressureChart.config.options.plugins.annotation.annotations[2].yMax = pressureAnnotation[0].manufacturersRecommendedPressure;
                gPressureChart.config.options.plugins.annotation.annotations[2].yMin = pressureAnnotation[0].manufacturersRecommendedPressure;
                gPressureChart.config.options.plugins.annotation.annotations[2].label.content = "Rec Press: " + pressureAnnotation[0].manufacturersRecommendedPressure + " bar";

                gPrevMinPressureValue = pressureAnnotation[0].minPressureValue;

                if (gPressureChartMaxY < pressureAnnotation[0].maxPressureValue) {
                    gPressureChartMaxY = parseFloat(pressureAnnotation[0].maxPressureValue) + 1;
                    gPressureChart.config.options.scales.y.max = gPressureChartMaxY;
                }
            }
        }

        for (let p = 0; p < gPressureChart.data.datasets.length; p++) {
            if (gPressureChart.data.datasets[p].label === 'A' + parseInt(wheelAxle.slice(1)) + '-T' + wheelAxle.slice(0, 1)) {
                gPressureChart.data.datasets.splice(p, 1);
                gPressureChart.update();
            }
        }
    }
}

function removeTemperatureChart(wheelAxle) {

    let temperatureAnnotation = [...new Set(gTemperatureAnnotation)];

    if (gTemperatureChart && gTemperatureChart.data.datasets.length > 0) {
        if (gTemperatureChart.config.options.plugins.annotation.annotations.length) {
            if (temperatureAnnotation.length === 1) {
                gTemperatureChart.config.options.plugins.annotation.annotations[0].yMax = temperatureAnnotation[0];
                gTemperatureChart.config.options.plugins.annotation.annotations[0].yMin = temperatureAnnotation[0];
                gTemperatureChart.config.options.plugins.annotation.annotations[0].label.content = "Max Temp: " + temperatureAnnotation[0] + "\u2103";

                gPrevMaxTemperatureValue = temperatureAnnotation[0];

                if (gTemperatureChartMaxY < temperatureAnnotation[0]) {
                    gTemperatureChartMaxY = parseInt(temperatureAnnotation[0]) + 10;
                    gTemperatureChart.config.options.scales.y.max = gTemperatureChartMaxY;
                }
            }
        }

        for (let p = 0; p < gTemperatureChart.data.datasets.length; p++) {
            if (gTemperatureChart.data.datasets[p].label === 'A' + parseInt(wheelAxle.slice(1)) + '-T' + wheelAxle.slice(0, 1)) {
                gTemperatureChart.data.datasets.splice(p, 1);
                gTemperatureChart.update();
            }
        }
    }
}

function removeVoltageChart(wheelAxle) {

    let voltageAnnotation = [...new Set(gVoltageAnnotation)];

    if (gVoltageChart && gVoltageChart.data.datasets.length > 0) {
        if (gVoltageChart.config.options.plugins.annotation.annotations.length) {
            if (voltageAnnotation.length === 1) {
                gVoltageChart.config.options.plugins.annotation.annotations[0].yMax = voltageAnnotation[0];
                gVoltageChart.config.options.plugins.annotation.annotations[0].yMin = voltageAnnotation[0];
                gVoltageChart.config.options.plugins.annotation.annotations[0].label.content = "Min Volt: " + voltageAnnotation[0] + "V";

                gPrevMinVoltageValue = voltageAnnotation[0];

                if (gVoltageChartMaxY < voltageAnnotation[0]) {
                    gVoltageChartMaxY = parseFloat(voltageAnnotation[0]) + 1;
                    gVoltageChart.config.options.scales.y.max = gVoltageChartMaxY;
                }
            }
        }

        for (let p = 0; p < gVoltageChart.data.datasets.length; p++) {
            if (gVoltageChart.data.datasets[p].label === 'A' + parseInt(wheelAxle.slice(1)) + '-T' + wheelAxle.slice(0, 1)) {
                gVoltageChart.data.datasets.splice(p, 1);
                gVoltageChart.update();
            }
        }
    }
}

function updatePressureChart(chartData, tyreInput, wheelAxle) {

    if (gPressureChart.config.options.plugins.annotation.annotations.length) {
        if (parseFloat(gPrevMinPressureValue) !== parseFloat(tyreInput.minPressureValue)) {
            gPressureChart.config.options.plugins.annotation.annotations[0].yMax = '99';
            gPressureChart.config.options.plugins.annotation.annotations[0].yMin = '99';
            gPressureChart.config.options.plugins.annotation.annotations[1].yMax = '99';
            gPressureChart.config.options.plugins.annotation.annotations[1].yMin = '99';
            gPressureChart.config.options.plugins.annotation.annotations[2].yMax = '99';
            gPressureChart.config.options.plugins.annotation.annotations[2].yMin = '99';
        }
    }

    let chartAxesData = [];

    for (let i = 0; i < chartData.length; i++) {
        let xyData = {};
        xyData.y = chartData[i].pressure;
        xyData.x = chartData[i].time;
        xyData.temperature = chartData[i].temperature;
        xyData.voltage = chartData[i].voltage;
        //xyData.address = sensorValuesLocations[i];
        chartAxesData.push(xyData);
    }

    let obj = {
        label: 'A' + parseInt(wheelAxle.slice(1)) + '-T' + wheelAxle.slice(0, 1),
        backgroundColor: tyreInput.chartColour,
        fill: false,
        borderColor: tyreInput.chartColour,
        data: chartAxesData, //yAxisLabels,
        borderWidth: 0.5,
        radius: 0.85,
        hitRadius: 4.5,
        hoverRaius: 4.5,
        tension: 0.5,
        pointHoverRadius: 4.5
    };
    gPressureChart.data.datasets.push(obj);

    if (gPressureChart.config.options.plugins.annotation.annotations.length) {
        let maxY = Math.max(...chartAxesData.map(c => c.y));
        if (gPressureChart.config.options.plugins.annotation.annotations[0].yMax === '99') {
            if (gPressureChartMaxY <= maxY) gPressureChartMaxY = Math.round(maxY + 1);
        } else {
            const tempMaxY = Math.max(maxY, tyreInput.maxPressureValue);
            gPressureChartMaxY = Math.round(tempMaxY + 2);
        }
        gPressureChart.options.scales.y.max = gPressureChartMaxY;
    }

    gPressureChart.update();
}

function updateTemperatureChart(chartData, sensorValuesLocations, tyreInput, wheelAxle) {

    if (gTemperatureChart.config.options.plugins.annotation.annotations.length) {
        if (parseFloat(gPrevMaxTemperatureValue) !== parseFloat(tyreInput.maxTemperatureValue)) {
            gTemperatureChart.config.options.plugins.annotation.annotations[0].yMax = '999';
            gTemperatureChart.config.options.plugins.annotation.annotations[0].yMin = '999';
        }
    }

    let chartAxesData = [];

    for (let i = 0; i < chartData.length; i++) {
        let xyData = {};
        xyData.y = chartData[i].temperature;
        xyData.x = chartData[i].time;
        xyData.pressure = chartData[i].pressure;
        xyData.voltage = chartData[i].voltage;
        xyData.address = sensorValuesLocations[i];
        chartAxesData.push(xyData);
    }

    let obj = {
        label: 'A' + parseInt(wheelAxle.slice(1)) + '-T' + wheelAxle.slice(0, 1),
        backgroundColor: tyreInput.chartColour,
        fill: false,
        borderColor: tyreInput.chartColour,
        data: chartAxesData, //yAxisLabels,
        borderWidth: 0.5,
        radius: 0.85,
        hitRadius: 4.5,
        hoverRaius: 4.5,
        tension: 0.5,
        pointHoverRadius: 4.5
    };
    gTemperatureChart.data.datasets.push(obj);

    if (gTemperatureChart.config.options.plugins.annotation.annotations.length) {
        let maxY = Math.max(...chartAxesData.map(c => c.y));
        if (gTemperatureChart.config.options.plugins.annotation.annotations[0].yMax === '999') {
            if (gTemperatureChartMaxY <= maxY) gTemperatureChartMaxY = Math.round(maxY + 5);
        } else {
            const tempMaxY = Math.max(maxY, tyreInput.maxTemperatureValue);
            gTemperatureChartMaxY = Math.round(tempMaxY + 5);
        }
        gTemperatureChart.options.scales.y.max = gTemperatureChartMaxY;
    }

    gTemperatureChart.update();
}

function updateVoltageChart(chartData, sensorValuesLocations, tyreInput, wheelAxle) {

    if (gVoltageChart.config.options.plugins.annotation.annotations.length) {
        if (parseFloat(gPrevMinVoltageValue) !== parseFloat(tyreInput.minVoltageValue)) {
            gVoltageChart.config.options.plugins.annotation.annotations[0].yMax = '99';
            gVoltageChart.config.options.plugins.annotation.annotations[0].yMin = '99';
        }
    }

    let chartAxesData = [];
    for (let i = 0; i < chartData.length; i++) {
        let xyData = {};
        xyData.y = chartData[i].voltage;
        xyData.x = chartData[i].time;
        xyData.pressure = chartData[i].pressure;
        xyData.temperature = chartData[i].temperature;
        xyData.address = sensorValuesLocations[i];
        chartAxesData.push(xyData);
    }

    let obj = {
        label: 'A' + parseInt(wheelAxle.slice(1)) + '-T' + wheelAxle.slice(0, 1),
        backgroundColor: tyreInput.chartColour,
        fill: false,
        borderColor: tyreInput.chartColour,
        data: chartAxesData, //yAxisLabels,
        borderWidth: 0.5,
        radius: 0.85,
        hitRadius: 4.5,
        hoverRaius: 4.5,
        tension: 0.5,
        pointHoverRadius: 4.5
    };
    gVoltageChart.data.datasets.push(obj);

    if (gVoltageChart.config.options.plugins.annotation.annotations.length) {
        let maxY = Math.max(...chartAxesData.map(c => c.y));
        if (gVoltageChart.config.options.plugins.annotation.annotations[0].yMax === '99') {
            if (gVoltageChartMaxY <= maxY) gVoltageChartMaxY = Math.round(maxY + 1);
        } else {
            const tempMaxY = Math.max(maxY, tyreInput.minVoltageValue);
            gVoltageChartMaxY = Math.round(tempMaxY + 2);
        }
        gVoltageChart.options.scales.y.max = gVoltageChartMaxY;
    }

    gVoltageChart.update();
}

function createCharts(imei, sensorValuesData, timeSpan) {
    
    document.body.style.cursor = 'wait';

    clearCharts();

    createPressureChart(imei, sensorValuesData, timeSpan);
    createTemperatureChart(imei, sensorValuesData, timeSpan);
    createVoltageChart(imei, sensorValuesData, timeSpan);

    document.body.style.cursor = 'default';
}

function clearChartGlobals() {

    clearCharts();

    gSensorValuesDataToday = [];
    gSensorValuesData7Days = [];

    //gSensorValuesDateRangeRadio.checked = false;
    gSensorValuesTodayRadio.checked = false;
    gSensorValuesLast7DaysRadio.checked = false;
    //gSensorValuesLast30DaysRadio.checked = false;

    gSensorValuesPressureCheck.checked = false;
    gSensorValuesTemperatureCheck.checked = false;
    gSensorValuesVoltageCheck.checked = false;
}

function updateChartDisplays() {

    if (gSensorValuesPressureCheck.checked && gSensorValuesTemperatureCheck.checked && gSensorValuesVoltageCheck.checked) {

        const pressureChart = document.getElementById('sensorreportpressurechart');
        pressureChart.classList.remove(...pressureChart.classList);
        const pressureChartClasses = ['threecharts', 'twoofthreecharts'];
        pressureChart.classList.add(...pressureChartClasses);
        pressureChart.style.display = 'block';

        const temperatureChart = document.getElementById('sensorreporttemperaturechart');
        temperatureChart.classList.remove(...temperatureChart.classList);
        const temperatureChartClasses = ['threecharts', 'twoofthreecharts'];
        temperatureChart.classList.add(...temperatureChartClasses);
        temperatureChart.style.display = 'block';

        const voltageChart = document.getElementById('sensorreportvoltagechart');
        voltageChart.classList.remove(...voltageChart.classList);
        const voltageChartClasses = ['threecharts', 'oneofthreecharts'];
        voltageChart.classList.add(...voltageChartClasses);
        voltageChart.style.display = 'block';
    }

    if (gSensorValuesPressureCheck.checked && !gSensorValuesTemperatureCheck.checked && !gSensorValuesVoltageCheck.checked) {

        let pressureChart = document.getElementById('sensorreportpressurechart');
        pressureChart.classList.remove(...pressureChart.classList);
        let pressureChartClasses = ['threecharts', 'chart'];
        pressureChart.classList.add(...pressureChartClasses);
        pressureChart.style.display = 'block';

        let temperatureChart = document.getElementById('sensorreporttemperaturechart');
        temperatureChart.classList.remove(...temperatureChart.classList);
        //let temperatureChartClasses = ['threecharts', 'twocharts'];
        //temperatureChart.classList.add(...temperatureChartClasses);
        temperatureChart.style.display = 'none';

        let voltageChart = document.getElementById('sensorreportvoltagechart');
        voltageChart.classList.remove(...voltageChart.classList);
        //let voltageChartClasses = ['threecharts', 'twocharts'];
        //voltageChart.classList.add(...voltageChartClasses);
        voltageChart.style.display = 'none';
    }

    if (gSensorValuesPressureCheck.checked && gSensorValuesTemperatureCheck.checked && !gSensorValuesVoltageCheck.checked) {

        //let outOfRangeDataToday = [...gOutOfRangeDataTodayPressure, ...gOutOfRangeDataTodayTemperature];
        //outOfRangeDataToday = outOfRangeDataToday.reduce((unique, o) => {
        //    if (!unique.some(obj => obj.id === o.id && obj.sensorName === o.sensorName)) {
        //        unique.push(o);
        //    }
        //    return unique;
        //}, []);

        //let outOfRangeDataWeek = [...gOutOfRangeDataWeekPressure, ...gOutOfRangeDataWeekTemperature];
        //outOfRangeDataWeek = outOfRangeDataWeek.reduce((unique, o) => {
        //    if (!unique.some(obj => obj.id === o.id && obj.sensorName === o.sensorName)) {
        //        unique.push(o);
        //    }
        //    return unique;
        //}, []);

        let pressureChart = document.getElementById('sensorreportpressurechart');
        pressureChart.classList.remove(...pressureChart.classList);
        let pressureChartClasses = ['threecharts', 'twocharts'];
        pressureChart.classList.add(...pressureChartClasses);
        pressureChart.style.display = 'block';

        let temperatureChart = document.getElementById('sensorreporttemperaturechart');
        temperatureChart.classList.remove(...temperatureChart.classList);
        let temperatureChartClasses = ['threecharts', 'twocharts'];
        temperatureChart.classList.add(...temperatureChartClasses);
        temperatureChart.style.display = 'block';

        let voltageChart = document.getElementById('sensorreportvoltagechart');
        voltageChart.classList.remove(...voltageChart.classList);
        //let voltageChartClasses = ['threecharts', 'twocharts'];
        //voltageChart.classList.add(...voltageChartClasses);
        voltageChart.style.display = 'none';
    }

    if (gSensorValuesPressureCheck.checked && !gSensorValuesTemperatureCheck.checked && gSensorValuesVoltageCheck.checked) {

        //let outOfRangeDataToday = [...gOutOfRangeDataTodayPressure, ...gOutOfRangeDataTodayVoltage];
        //outOfRangeDataToday = outOfRangeDataToday.reduce((unique, o) => {
        //    if (!unique.some(obj => obj.id === o.id && obj.sensorName === o.sensorName)) {
        //        unique.push(o);
        //    }
        //    return unique;
        //}, []);

        //let outOfRangeDataWeek = [...gOutOfRangeDataWeekPressure, ...gOutOfRangeDataWeekVoltage];
        //outOfRangeDataWeek = outOfRangeDataWeek.reduce((unique, o) => {
        //    if (!unique.some(obj => obj.id === o.id && obj.sensorName === o.sensorName)) {
        //        unique.push(o);
        //    }
        //    return unique;
        //}, []);

        let pressureChart = document.getElementById('sensorreportpressurechart');
        pressureChart.classList.remove(...pressureChart.classList);
        let pressureChartClasses = ['threecharts', 'twocharts'];
        pressureChart.classList.add(...pressureChartClasses);
        pressureChart.style.display = 'block';

        let temperatureChart = document.getElementById('sensorreporttemperaturechart');
        temperatureChart.classList.remove(...temperatureChart.classList);
        temperatureChart.style.display = 'none';

        let voltageChart = document.getElementById('sensorreportvoltagechart');
        voltageChart.classList.remove(...voltageChart.classList);
        let voltageChartClasses = ['threecharts', 'twocharts'];
        voltageChart.classList.add(...voltageChartClasses);
        voltageChart.style.display = 'block';
    }

    if (!gSensorValuesPressureCheck.checked && gSensorValuesTemperatureCheck.checked && !gSensorValuesVoltageCheck.checked) {

        let pressureChart = document.getElementById('sensorreportpressurechart');
        pressureChart.classList.remove(...pressureChart.classList);
        pressureChart.style.display = 'none';

        let temperatureChart = document.getElementById('sensorreporttemperaturechart');
        temperatureChart.classList.remove(...temperatureChart.classList);
        let temperatureChartClasses = ['threecharts', 'chart'];
        temperatureChart.classList.add(...temperatureChartClasses);
        temperatureChart.style.display = 'block';

        let voltageChart = document.getElementById('sensorreportvoltagechart');
        voltageChart.classList.remove(...voltageChart.classList);
        voltageChart.style.display = 'none';
    }

    if (!gSensorValuesPressureCheck.checked && gSensorValuesTemperatureCheck.checked && gSensorValuesVoltageCheck.checked) {

        //let outOfRangeDataToday = [...gOutOfRangeDataTodayTemperature, ...gOutOfRangeDataTodayVoltage];
        //outOfRangeDataToday = outOfRangeDataToday.reduce((unique, o) => {
        //    if (!unique.some(obj => obj.id === o.id && obj.sensorName === o.sensorName)) {
        //        unique.push(o);
        //    }
        //    return unique;
        //}, []);

        //let outOfRangeDataWeek = [...gOutOfRangeDataWeekTemperature, ...gOutOfRangeDataWeekVoltage];
        //outOfRangeDataWeek = outOfRangeDataWeek.reduce((unique, o) => {
        //    if (!unique.some(obj => obj.id === o.id && obj.sensorName === o.sensorName)) {
        //        unique.push(o);
        //    }
        //    return unique;
        //}, []);

        let pressureChart = document.getElementById('sensorreportpressurechart');
        pressureChart.classList.remove(...pressureChart.classList);
        pressureChart.style.display = 'none';

        let temperatureChart = document.getElementById('sensorreporttemperaturechart');
        temperatureChart.classList.remove(...temperatureChart.classList);
        let temperatureChartClasses = ['threecharts', 'twocharts'];
        temperatureChart.classList.add(...temperatureChartClasses);
        temperatureChart.style.display = 'block';

        let voltageChart = document.getElementById('sensorreportvoltagechart');
        voltageChart.classList.remove(...voltageChart.classList);
        let voltageChartClasses = ['threecharts', 'twocharts'];
        voltageChart.classList.add(...voltageChartClasses);
        voltageChart.style.display = 'block';
    }

    if (!gSensorValuesPressureCheck.checked && !gSensorValuesTemperatureCheck.checked && gSensorValuesVoltageCheck.checked) {

        let pressureChart = document.getElementById('sensorreportpressurechart');
        pressureChart.classList.remove(...pressureChart.classList);
        pressureChart.style.display = 'none';

        let temperatureChart = document.getElementById('sensorreporttemperaturechart');
        temperatureChart.classList.remove(...temperatureChart.classList);
        temperatureChart.style.display = 'none';

        let voltageChart = document.getElementById('sensorreportvoltagechart');
        voltageChart.classList.remove(...voltageChart.classList);
        let voltageChartClasses = ['threecharts', 'chart'];
        voltageChart.classList.add(...voltageChartClasses);
        voltageChart.style.display = 'block';
    }
}


function removeDataSpikes(sensorValuesData) {

    for (let i = 1; i < sensorValuesData.length - 1; i++) {
        //if (parseFloat(sensorValuesData[i].pressure) < 1) console.log(`index: ${i} pressure: ${parseFloat(sensorValuesData[i].pressure)}`)
        if (parseFloat(sensorValuesData[i].pressure) === 0) {
            if (parseFloat(sensorValuesData[i - 1].pressure) > 0 && parseFloat(sensorValuesData[i + 1].pressure) > 0) {
                sensorValuesData.splice(i, 1);
                continue;
            };
        }

        if (parseFloat(sensorValuesData[i].pressure) > 12) {
            if (parseFloat(sensorValuesData[i - 1].pressure) < 12 && parseFloat(sensorValuesData[i + 1].pressure) < 12) {
                sensorValuesData.splice(i, 1);
                continue;
            }
        }

        if (sensorValuesData[i - 1].temperature < sensorValuesData[i].temperature && sensorValuesData[i + 1].temperature < sensorValuesData[i].temperature) {
            const d1 = Math.abs(sensorValuesData[i - 1].temperature - sensorValuesData[i].temperature);
            if (d1 > 35) {
                sensorValuesData.splice(i, 1);
                continue;
            }
        }

        if (sensorValuesData[i - 1].temperature > sensorValuesData[i].temperature && sensorValuesData[i + 1].temperature > sensorValuesData[i].temperature) {
            const d1 = Math.abs(sensorValuesData[i - 1].temperature - sensorValuesData[i].temperature);
            if (d1 > 35) {
                sensorValuesData.splice(i, 1);
                continue;
            }
        }

        if (sensorValuesData[i - 1].temperature < sensorValuesData[i].temperature && sensorValuesData[i + 1].temperature < sensorValuesData[i].temperature) {
            const d1 = Math.abs(sensorValuesData[i - 1].temperature - sensorValuesData[i].temperature);
            if (d1 > 35) {
                sensorValuesData.splice(i, 1);
                continue;
            }
        }

        if (sensorValuesData[i - 1].voltage > sensorValuesData[i].voltage && sensorValuesData[i + 1].voltage > sensorValuesData[i].voltage) {
            sensorValuesData.splice(i, 1);
            continue;
        }

        if (sensorValuesData[i - 1].voltage < sensorValuesData[i].voltage && sensorValuesData[i + 1].voltage < sensorValuesData[i].voltage) {
            sensorValuesData.splice(i, 1);
            continue;
        }

    }

    return sensorValuesData;
}

function getSensorValuesChartDateRangeData(selectedChartDates) {

    //sensorValuesData.sort((a, b) => a.id - b.id || a.axleWheel - b.axleWheel || a.unixTime - b.unixTime);

    const ds = new Date(selectedChartDates[0]);
    const startOfSelectedDay = ds.setHours(0, 0, 0, 0);

    const de = new Date(selectedChartDates[1]);
    const endOfSelectedDay = de.setHours(23, 59, 59, 999);

    //gPressureDataSelectedDate = pressureData.filter((p) => {
    //    return new Date(p.time).getTime() >= startOfSelectedDay && new Date(p.time).getTime() <= endOfSelectedDay;
    //});

    //gTemperatureDataSelectedDate = temperatureData.filter((t) => {
    //    return new Date(t.time).getTime() >= startOfSelectedDay && new Date(t.time).getTime() <= endOfSelectedDay;
    //});

    //gSensorValuesDataDateRange = gSensorValuesData30Days.filter((v) => {
    //    return new Date(v.time).getTime() >= startOfSelectedDay && new Date(v.time).getTime() <= endOfSelectedDay;
    //});
    //gSensorValuesDataDateRange = removeDataSpikes(gSensorValuesDataDateRange);    

    //getWheelColoursPressure(gSensorValuesDataDateRange, "SELECTEDDATE");
    //getWheelColoursTemperature(gSensorValuesDataDateRange, "SELECTEDDATE");
    //getWheelColoursVoltage(gSensorValuesDataDateRange, "SELECTEDDATE");
    //getWheelColours(gSensorValuesDataDateRange, "SELECTEDDATE");

    /*$("#loadingBox").modal("hide");
    document.body.style.cursor = 'default';*/
}

function getWheelColoursPressure(sensorValuesData, timeSpan) {

    let sensorValuesOutOfRangeData = [];

    for (let s = 0; s < sensorValuesData.length; s++) {

        const hasClassNameWheelRed = sensorValuesOutOfRangeData.some(obj => {
            if (obj.id === sensorValuesData[s].id
                && obj.sensorName === sensorValuesData[s].sensorName
                && obj.className === 'chartwheelred') {
                return true;
            }
        });

        if (hasClassNameWheelRed) continue;

        if (parseFloat(sensorValuesData[s].pressure) < parseFloat(sensorValuesData[s].minPressureValue)) {

            const sensorValueOutOfRange = {};
            sensorValueOutOfRange.id = sensorValuesData[s].id;
            sensorValueOutOfRange.sensorName = sensorValuesData[s].sensorName;
            sensorValueOutOfRange.className = 'chartwheelred';
            sensorValuesOutOfRangeData.push(sensorValueOutOfRange);

            continue;
        }

        const hasClassNameWheelPurple = sensorValuesOutOfRangeData.some(obj => {
            if (obj.id === sensorValuesData[s].id
                && obj.sensorName === sensorValuesData[s].sensorName
                && obj.className === 'chartwheelpurple') {
                return true;
            }
        });

        if (hasClassNameWheelPurple) continue;

        if (parseFloat(sensorValuesData[s].pressure) > parseFloat(sensorValuesData[s].maxPressureValue)) {

            const sensorValueOutOfRange = {};
            sensorValueOutOfRange.id = sensorValuesData[s].id;
            sensorValueOutOfRange.sensorName = sensorValuesData[s].sensorName;
            sensorValueOutOfRange.className = 'chartwheelpurple';
            sensorValuesOutOfRangeData.push(sensorValueOutOfRange);

            continue;
        }
    }

    if (timeSpan === "SELECTEDDATE") gOutOfRangeDataDateRangePressure = sensorValuesOutOfRangeData;
    if (timeSpan === "TODAY") gOutOfRangeDataTodayPressure = sensorValuesOutOfRangeData;
    if (timeSpan === "WEEK") gOutOfRangeDataWeekPressure = sensorValuesOutOfRangeData;
    if (timeSpan === "MONTH") gOutOfRangeDataMonthPressure = sensorValuesOutOfRangeData; //***Default
}

function getWheelColoursTemperature(sensorValuesData, timeSpan) {

    let sensorValuesOutOfRangeData = [];

    for (let s = 0; s < sensorValuesData.length; s++) {

        const hasClassNameWheelOrange = sensorValuesOutOfRangeData.some(obj => {
            if (obj.id === sensorValuesData[s].id
                && obj.sensorName === sensorValuesData[s].sensorName
                && obj.className === 'chartwheelorange') {
                return true;
            }
        });

        if (hasClassNameWheelOrange) continue;

        if (parseInt(sensorValuesData[s].temperature) > parseInt(sensorValuesData[s].maxTemperatureValue)) {

            const sensorValueOutOfRange = {};
            sensorValueOutOfRange.id = sensorValuesData[s].id;
            sensorValueOutOfRange.sensorName = sensorValuesData[s].sensorName;
            sensorValueOutOfRange.className = 'chartwheelorange';
            sensorValuesOutOfRangeData.push(sensorValueOutOfRange);

            continue;
        }
    }

    if (timeSpan === "SELECTEDDATE") gOutOfRangeDataDateRangeTemperature = sensorValuesOutOfRangeData;
    if (timeSpan === "TODAY") gOutOfRangeDataTodayTemperature = sensorValuesOutOfRangeData;
    if (timeSpan === "WEEK") gOutOfRangeDataWeekTemperature = sensorValuesOutOfRangeData;
    if (timeSpan === "MONTH") gOutOfRangeDataMonthTemperature = sensorValuesOutOfRangeData;
}

function getWheelColoursVoltage(sensorValuesData, timeSpan) {

    let sensorValuesOutOfRangeData = [];

    for (let s = 0; s < sensorValuesData.length; s++) {

        const hasClassNameWheelYellow = sensorValuesOutOfRangeData.some(obj => {
            if (obj.id === sensorValuesData[s].id
                && obj.sensorName === sensorValuesData[s].sensorName
                && obj.className === 'chartwheelyellow') {
                return true;
            }
        });

        if (hasClassNameWheelYellow) continue;

        if (parseFloat(sensorValuesData[s].voltage) < parseFloat(sensorValuesData[s].minVoltageValue)) {

            const sensorValueOutOfRange = {};
            sensorValueOutOfRange.id = sensorValuesData[s].id;
            sensorValueOutOfRange.sensorName = sensorValuesData[s].sensorName;
            sensorValueOutOfRange.className = 'chartwheelyellow';
            sensorValuesOutOfRangeData.push(sensorValueOutOfRange);

            continue;
        }
    }

    if (timeSpan === "SELECTEDDATE") gOutOfRangeDataDateRangeVoltage = sensorValuesOutOfRangeData;
    if (timeSpan === "TODAY") gOutOfRangeDataTodayVoltage = sensorValuesOutOfRangeData;
    if (timeSpan === "WEEK") gOutOfRangeDataWeekVoltage = sensorValuesOutOfRangeData;
    if (timeSpan === "MONTH") gOutOfRangeDataMonthVoltage = sensorValuesOutOfRangeData;
}

function getWheelColours(sensorValuesData, timeSpan) {

    let sensorValuesOutOfRangeData = [];

    //for (let us = 0; us < unit.sensors.length; us++) {
    //    const sensorValueOutOfRange = {};
    //    sensorValueOutOfRange.id = unit.id;
    //    sensorValueOutOfRange.axleWheel = unit.sensors[us].sensorName.slice(0, 2);
    //    sensorValueOutOfRange.className = 'chartwheelblue';
    //    sensorValuesOutOfRangeData.push(sensorValueOutOfRange);
    //}

    //if (unit.unitTrailers.length > 0) {
    //    for (let ut = 0; ut < unit.unitTrailers.length; ut++) {
    //        const sensorValueOutOfRange = {};
    //        sensorValueOutOfRange.id = unit.unitTrailers[ut].id;
    //        for (let ust = 0; ust < unit.unitTrailers[ut].sensors.length; ust++) {
    //            sensorValueOutOfRange.axleWheel = unit.unitTrailers[ut].sensors[ust].sensorName.slice(0, 2);
    //            sensorValueOutOfRange.className = 'chartwheelblue';
    //            sensorValuesOutOfRangeData.push(sensorValueOutOfRange);
    //        }
    //    }
    //}

    for (let s = 0; s < sensorValuesData.length; s++) {

        //const classNameWheelBlueIndex = sensorValuesOutOfRangeData.findIndex(obj => {
        //    if (obj.id === sensorValuesData[s].id
        //        && obj.axleWheel === sensorValuesData[s].axleWheel
        //        && obj.className === 'chartwheelblue') {
        //        return true;
        //    }
        //    return false;
        //});

        //if (classNameWheelBlueIndex !== -1) {
        //    sensorValuesOutOfRangeData.splice(classNameWheelBlueIndex, 1);
        //}

        const hasClassNameWheelRed = sensorValuesOutOfRangeData.some(obj => {
            if (obj.id === sensorValuesData[s].id
                && obj.sensorName === sensorValuesData[s].sensorName
                && obj.className === 'chartwheelred') {
                return true;
            }
        });
        if (hasClassNameWheelRed) continue;

        if (parseFloat(sensorValuesData[s].pressure) < parseFloat(sensorValuesData[s].minPressureValue)) {

            const sensorValueOutOfRange = {};
            sensorValueOutOfRange.id = sensorValuesData[s].id;
            sensorValueOutOfRange.sensorName = sensorValuesData[s].sensorName;
            sensorValueOutOfRange.className = 'chartwheelred';
            sensorValuesOutOfRangeData.push(sensorValueOutOfRange);

            continue;
        }

        const hasClassNameWheelPurple = sensorValuesOutOfRangeData.some(obj => {
            if (obj.id === sensorValuesData[s].id
                && obj.axleWheel === sensorValuesData[s].axleWheelPos
                && obj.className === 'chartwheelpurple') {
                return true;
            }
        });
        if (hasClassNameWheelPurple) continue;

        if (parseFloat(sensorValuesData[s].pressure) > parseFloat(sensorValuesData[s].maxPressureValue)) {

            const sensorValueOutOfRange = {};
            sensorValueOutOfRange.id = sensorValuesData[s].id;
            sensorValueOutOfRange.sensorName = sensorValuesData[s].sensorName;
            sensorValueOutOfRange.className = 'chartwheelpurple';
            sensorValuesOutOfRangeData.push(sensorValueOutOfRange);

            continue;
        }

        const hasClassNameWheelOrange = sensorValuesOutOfRangeData.some(obj => {
            if (obj.id === sensorValuesData[s].id
                && obj.axleWheel === sensorValuesData[s].axleWheelPos
                && obj.className === 'chartwheelorange') {
                return true;
            }
        });
        if (hasClassNameWheelOrange) continue;

        if (parseInt(sensorValuesData[s].temperature) > parseInt(sensorValuesData[s].maxTemperatureValue)) {

            const sensorValueOutOfRange = {};
            sensorValueOutOfRange.id = sensorValuesData[s].id;
            sensorValueOutOfRange.sensorName = sensorValuesData[s].sensorName;
            sensorValueOutOfRange.className = 'chartwheelorange';
            sensorValuesOutOfRangeData.push(sensorValueOutOfRange);

            continue;
        }

        const hasClassNameWheelYellow = sensorValuesOutOfRangeData.some(obj => {
            if (obj.id === sensorValuesData[s].id
                && obj.axleWheel === sensorValuesData[s].axleWheelPos
                && obj.className === 'chartwheelyellow') {
                return true;
            }
        });
        if (hasClassNameWheelYellow) continue;

        if (parseFloat(sensorValuesData[s].voltage) < parseFloat(sensorValuesData[s].minVoltageValue)) {

            const sensorValueOutOfRange = {};
            sensorValueOutOfRange.id = sensorValuesData[s].id;
            sensorValueOutOfRange.sensorName = sensorValuesData[s].sensorName;
            sensorValueOutOfRange.className = 'chartwheelyellow';
            sensorValuesOutOfRangeData.push(sensorValueOutOfRange);

            continue;
        }
    }

    //if (timeSpan === "SELECTEDDATE") gOutOfRangeDataDateRange = sensorValuesOutOfRangeData;
    //if (timeSpan === "TODAY") gSensorValuesOutOfRangeDataToday = sensorValuesOutOfRangeData;
    //if (timeSpan === "WEEK") gSensorValuesOutOfRangeDataWeek = sensorValuesOutOfRangeData;
    //if (timeSpan === "MONTH") gSensorValuesOutOfRangeDataMonth = sensorValuesOutOfRangeData;
}

//function applyWheelColours(unit, sensorValuesOutOfRangeData, sensorValuesData) {

//    let unitWheels = []; //Get all wheels in Sensor Values dataset ie NOT blue wheels
//    for (let us = 0; us < unit.wheelSensors.length; us++) {
//        let unitWheel = {};
//        unitWheel.id = unit.id;
//        unitWheel.axleWheel = unit.sensors[us].sensorName.slice(0, 2);
//        unitWheel.className = 'chartwheelblue';
//        for (let sv = 0; sv < sensorValuesData.length; sv++) {
//            if (unit.id === sensorValuesData[sv].id && unit.sensors[us].sensorName.slice(0, 2) === sensorValuesData[sv].axleWheel) {
//                unitWheel.className = 'chartwheelactive';
//                break;
//            }
//        }
//        unitWheels.push(unitWheel);
//    }

//    if (unit.unitTrailers.length > 0) {
//        for (let t = 0; t < unit.unitTrailers.length; t++) {
//            for (let us = 0; us < unit.unitTrailers[t].sensors.length; us++) {
//                let unitWheel = {};
//                unitWheel.id = unit.unitTrailers[t].id;
//                unitWheel.axleWheel = unit.unitTrailers[t].sensors[us].sensorName.slice(0, 2);
//                unitWheel.className = 'chartwheelblue';
//                for (let sv = 0; sv < sensorValuesData.length; sv++) {

//                    if (unit.unitTrailers[t].id === sensorValuesData[sv].id && unit.unitTrailers[t].sensors[us].sensorName.slice(0, 2) === sensorValuesData[sv].axleWheel) {
//                        unitWheel.className = 'chartwheelactive';
//                        break;
//                    }
//                }
//                unitWheels.push(unitWheel);
//            }
//        }
//    }

//    //set all applicable wheels to default black;
//    for (let u = 0; u < unitWheels.length; u++) {
//        let tyreSwitch = document.getElementById(unitWheels[u].id + 'tyreswitch' + unitWheels[u].axleWheel);
//        if (tyreSwitch != null) {
//            let wheeltype = tyreSwitch.classList[0];
//            tyreSwitch.className = '';
//            tyreSwitch.classList.add(wheeltype, unitWheels[u].className);
//        }
//    }

//    for (let s = 0; s < sensorValuesOutOfRangeData.length; s++) {
//        let tyreSwitch = document.getElementById(sensorValuesOutOfRangeData[s].id + 'tyreswitch' + sensorValuesOutOfRangeData[s].sensorName);
//        if (tyreSwitch != null) {
//            let wheeltype = tyreSwitch.classList[0];
//            tyreSwitch.className = '';
//            tyreSwitch.classList.add(wheeltype, sensorValuesOutOfRangeData[s].className);

//        }
//    }

//    //disable blue wheels
//    //for (let ub = 0; ub < unit.sensors.length; ub++) {
//    //    let tyreSwitch = document.getElementById(unit.id + 'tyreswitch' + unit.sensors[ub].sensorName.slice(0, 2));
//    //    if (tyreSwitch != null) {
//    //        if (tyreSwitch.classList.contains('chartwheelblue')) {
//    //            while (tyreSwitch.firstChild) {
//    //                tyreSwitch.removeChild(tyreSwitch.firstChild);
//    //            }
//    //            //let wheelCheck = document.getElementById(unit.id + 'wheelinput' + unit.sensors[ub].sensorName.slice(0, 2));
//    //            //tyreSwitch.removeChild(wheelCheck);
//    //        }
//    //    }
//    //}
//}

function applyWheelColours(unit, sensorValuesOutOfRangeData, sensorValuesData) {

    let unitWheels = []; //Get all wheels in Sensor Values dataset ie NOT blue wheels
    for (let s = 0; s < unit.wheelSensors.length; s++) {
        let unitWheel = {};
        unitWheel.id = unit.id;
        unitWheel.sensorName = unit.wheelSensors[s].sensorName;
        unitWheel.className = 'chartwheelblue';
        for (let sv = 0; sv < sensorValuesData.length; sv++) {
            if (unit.id === sensorValuesData[sv].id && unit.wheelSensors[s].sensorName === sensorValuesData[sv].sensorName) {
                unitWheel.className = 'chartwheelactive';
                break;
            }
        }
        unitWheels.push(unitWheel);
    }

    //set all applicable wheels to default black;
    for (let u = 0; u < unitWheels.length; u++) {
        let tyreSwitch = document.getElementById(unitWheels[u].id + 'tyreswitch' + unitWheels[u].sensorName);
        if (tyreSwitch != null) {
            let wheeltype = tyreSwitch.classList[0];
            tyreSwitch.className = '';
            tyreSwitch.classList.add(wheeltype, unitWheels[u].className);
        }
    }

    for (let s = 0; s < sensorValuesOutOfRangeData.length; s++) {
        let tyreSwitch = document.getElementById(sensorValuesOutOfRangeData[s].id + 'tyreswitch' + sensorValuesOutOfRangeData[s].sensorName);
        if (tyreSwitch != null) {
            let wheeltype = tyreSwitch.classList[0];
            tyreSwitch.className = '';
            tyreSwitch.classList.add(wheeltype, sensorValuesOutOfRangeData[s].className);

        }
    }
}

function getDefaultWheelColoursMonth(sensorValuesData) {
    for (let s = 0; s < sensorValuesData.length; s++) {
        let tyreSwitch = document.getElementById(sensorValuesData[s].id + 'tyreswitch' + sensorValuesData[s].axleWheelPos);
        //if (tyreSwitch != null) console.log(`tyreswitch id ${tyreSwitch.id}`);

        if (tyreSwitch != null) {

            if (tyreSwitch.classList.contains('wheelred')) continue;

            if (parseFloat(sensorValuesData[s].pressure) < parseFloat(sensorValuesData[s].minPressureValue)) {

                let wheeltype = tyreSwitch.classList[0];
                tyreSwitch.className = '';
                tyreSwitch.classList.add(wheeltype, 'wheelred');

                //const sensorValueOutOfRangeMonth = {};
                //sensorValueOutOfRangeMonth.id = sensorValuesData[s].id;
                //sensorValueOutOfRangeMonth.axleWheel = sensorValuesData[s].axleWheelPos;
                //sensorValueOutOfRangeMonth.className = 'wheelred';
                //gSensorValuesOutOfRangeDataMonth.push(sensorValueOutOfRangeMonth);

                continue;
            }

            if (tyreSwitch.classList.contains('wheelpurple')) continue;

            if (parseFloat(sensorValuesData[s].pressure) > parseFloat(sensorValuesData[s].maxPressureValue)) {

                let wheeltype = tyreSwitch.classList[0];
                tyreSwitch.className = '';
                tyreSwitch.classList.add(wheeltype, 'wheelpurple');

                //const sensorValueOutOfRangeMonth = {};
                //sensorValueOutOfRangeMonth.id = sensorValuesData[s].id;
                //sensorValueOutOfRangeMonth.axleWheel = sensorValuesData[s].axleWheelPos;
                //sensorValueOutOfRangeMonth.className = 'wheelpurple';
                //gSensorValuesOutOfRangeDataMonth.push(sensorValueOutOfRangeMonth);

                continue;
            }

            if (tyreSwitch.classList.contains('wheelorange')) continue;

            if (parseInt(sensorValuesData[s].temperature) > parseInt(sensorValuesData[s].maxTemperatureValue)) {

                let wheeltype = tyreSwitch.classList[0];
                tyreSwitch.className = '';
                tyreSwitch.classList.add(wheeltype, 'wheelorange');

                //const sensorValueOutOfRangeMonth = {};
                //sensorValueOutOfRangeMonth.id = sensorValuesData[s].id;
                //sensorValueOutOfRangeMonth.axleWheel = sensorValuesData[s].axleWheelPos;
                //sensorValueOutOfRangeMonth.className = 'wheelorange';
                //gSensorValuesOutOfRangeDataMonth.push(sensorValueOutOfRangeMonth);

                continue;
            }

            if (tyreSwitch.classList.contains('wheelyellow')) continue;

            if (parseFloat(sensorValuesData[s].voltage) < parseFloat(sensorValuesData[s].minVoltageValue)) {

                let wheeltype = tyreSwitch.classList[0];
                tyreSwitch.className = '';
                tyreSwitch.classList.add(wheeltype, 'wheelyellow');

                //const sensorValueOutOfRangeMonth = {};
                //sensorValueOutOfRangeMonth.id = sensorValuesData[s].id;
                //sensorValueOutOfRangeMonth.axleWheel = sensorValuesData[s].axleWheelPos;
                //sensorValueOutOfRangeMonth.className = 'wheelyellow';
                //gSensorValuesOutOfRangeDataMonth.push(sensorValueOutOfRangeMonth);

                continue;
            }
        }
    }
}

function getSensorValuesTrailerChartReportData(sensorValuesTrailerChartMessages) {

    let pressureData = sensorValuesTrailerChartMessages.filter(obj => Object.keys(obj).includes("pressure"));
    let temperatureData = sensorValuesTrailerChartMessages.filter(obj => Object.keys(obj).includes("temperature"));
    let voltageData = sensorValuesTrailerChartMessages.filter(obj => Object.keys(obj).includes("voltage"));

    const d = new Date();
    const ts = d.getTime();
    let today = ts - (24 * 60 * 60 * 1000);
    gTrailerPressureDataToday = pressureData.filter((u) => {
        return new Date(u.time).getTime() >= today;
    });
    gTrailerTemperatureDataToday = temperatureData.filter((u) => {
        return new Date(u.time).getTime() >= today;
    });
    gTrailerVoltageDataToday = voltageData.filter((u) => {
        return new Date(u.time).getTime() >= today;
    });

    let sevenDays = ts - (7 * 24 * 60 * 60 * 1000);
    gTrailerPressureData7Days = pressureData.filter((u) => {
        return new Date(u.time).getTime() >= sevenDays;
    });
    gTrailerTemperatureData7Days = temperatureData.filter((u) => {
        return new Date(u.time).getTime() >= sevenDays;
    });
    gTrailerVoltageData7Days = voltageData.filter((u) => {
        return new Date(u.time).getTime() >= sevenDays;
    });

    gTrailerPressureData30Days = pressureData;
    gTrailerTemperatureData30Days = temperatureData;
    gTrailerVoltageData30Days = voltageData;
}

function openSensorReport(unit, sensorData) {

    gSelectedUnit = unit;

    let chartsModal = document.getElementById('sensorchartreportmodal');

    document.getElementById('scrmUnitId').value = unit.id;
    //document.getElementById('scrmUnitImg').src = wUnit.getIconUrl(64);
    document.getElementById('scrmUnitName').value = unit.name;

    let chartsClasses = ['threecharts', 'chart'];
    let pressureChart = document.getElementById('sensorreportpressurechart');
    pressureChart.classList.remove(...pressureChart.classList);
    pressureChart.classList.add(...chartsClasses);
    pressureChart.style.display = 'block';

    let temperatureChart = document.getElementById('sensorreporttemperaturechart');
    temperatureChart.classList.remove(...temperatureChart.classList);
    temperatureChart.style.display = 'none';

    let voltageChart = document.getElementById('sensorreportvoltagechart');
    voltageChart.classList.remove(...voltageChart.classList);
    voltageChart.style.display = 'none';

    gSensorValuesLast7DaysRadio.checked = true;
    gSensorValuesPressureCheck.checked = true;

    chartsModal.style.display = 'block';
    $("#loadingBox").modal("hide");
    if (chartsModal.style.display === 'block' && document.getElementById("loadingBox").style.display === 'block') {
        //TODO: might also need to load chart messages here...
        document.getElementById('loadingBox').style.display = 'none';
        $("#loadingBox").modal('hide');
        //alert("Something went wrong...");
    }

    const timespanOptions = document.getElementById('sensorreporttimespanoptions');
    timespanOptions.onclick = (e) => {

        if (gSensorValuesTodayRadio.checked) {
            createCharts(unit.imei, gSensorValuesDataToday, "TODAY");
        }

        if (gSensorValuesLast7DaysRadio.checked) {
            createCharts(unit.imei, gSensorValuesData7Days, "SEVEN DAYS");
        }

        updateChartDisplays();

        e.stopImmediatePropagation();
    };

    gSensorValuesPressureCheck.onclick = (e) => {
        updateChartDisplays();
        e.stopImmediatePropagation();
    };

    gSensorValuesTemperatureCheck.onclick = (e) => {
        updateChartDisplays();
        e.stopImmediatePropagation();
    };

    gSensorValuesVoltageCheck.onclick = (e) => {
        updateChartDisplays();
        e.stopImmediatePropagation();
    };

    const span = document.getElementById("closesensorchartreportmodal");
    span.onclick = function (e) {
        e.stopImmediatePropagation;
        document.getElementById('mapbox').style.display = 'none';
        chartsModal.style.display = 'none';
        clearChartGlobals();
        return false;
    };

    chartsModal.onclick = function (e) {
        if (e.target == chartsModal) {
            e.stopImmediatePropagation();
            document.getElementById('mapbox').style.display = 'none';
            chartsModal.style.display = 'none';
            clearChartGlobals();
            return false;
        }
    };

    const mapBoxContent = document.getElementById('mapboxcontent');
    let unitMapContainerDiv = createUnitMapContainerElement('sensorreportmapcontainer', 'sensorreportchartmap', 'chartmap');
    mapBoxContent.appendChild(unitMapContainerDiv);
    initialiseChartMap('sensorreportchartmap');

    const d = new Date();
    const ts = d.getTime();
    let today = Math.round((ts - (24 * 60 * 60 * 1000)) / 1000);

    gSensorValuesDataToday = sensorData.filter((sv) => {
        return sv.unixTime >= today;
    });

    gSensorValuesData7Days = sensorData;

    createCharts(unit.imei, gSensorValuesData7Days, "SEVEN DAYS")

    document.body.style.cursor = 'default';

    return false;
}

function clearCharts() {     

    if (gPressureChart)
        gPressureChart.destroy();
    if (gTemperatureChart)
        gTemperatureChart.destroy();
    if (gVoltageChart)
        gVoltageChart.destroy();
}

function sensorValuesChartOptionsChange(sensorValuesChartType, todayData, last7DaysData, sensorValuesChartMessages) {

    let minPressureValue = document.getElementById('rmMinPressureValue').value;
    let maxPressureValue = document.getElementById('rmMaxPressureValue').value;
    let manufacturersRecommendedPressure = document.getElementById('rmManufacturersRecommendedPressure').value;
    let maxTemperatureValue = document.getElementById('rmMaxTemperatureValue').value;
    let minVoltageValue = document.getElementById('rmMinVoltageValue').value;

    let pressureValueRange = [];
    pressureValueRange.push(minPressureValue);
    pressureValueRange.push(maxPressureValue);
    pressureValueRange.push(manufacturersRecommendedPressure);

    if (document.getElementById("sensorValuesToday").checked === true) {
        document.getElementById("sensorValuesToday").checked = true;
        document.body.style.cursor = 'wait';
        switch (sensorValuesChartType) {
            case 'PRESSURE':
                displayPressureData(todayData, "TODAY", pressureValueRange);
                document.getElementById("sensorValuesPressure").checked = true;
                break;
            case 'TEMPERATURE':
                displayTemperatureData(todayData, "TODAY", maxTemperatureValue);
                document.getElementById("sensorValuesTemperature").checked = true;
                break;
            case 'VOLTAGE':
                displayVoltageData(todayData, "TODAY", minVoltageValue);
                document.getElementById("sensorValuesVoltage").checked = true;
                break;
        }
    }

    if (document.getElementById("sensorValuesLast7Days").checked === true) {
        document.body.style.cursor = 'wait';
        switch (sensorValuesChartType) {
            case 'PRESSURE':
                displayPressureData(last7DaysData, "SEVEN DAYS", pressureValueRange);
                document.getElementById("sensorValuesPressure").checked = true;
                break;
            case 'TEMPERATURE':
                displayTemperatureData(last7DaysData, "SEVEN DAYS", maxTemperatureValue);
                document.getElementById("sensorValuesTemperature").checked = true;
                break;
            case 'VOLTAGE':
                displayVoltageData(last7DaysData, "SEVEN DAYS", minVoltageValue);
                document.getElementById("sensorValuesVoltage").checked = true;
                break;
        }
    }
    if (document.getElementById("sensorValuesLast30Days").checked === true) {
        document.getElementById("sensorValuesLast30Days").checked = true;
        document.body.style.cursor = 'wait';
        let last30DaysData = sensorValuesChartMessages;
        switch (sensorValuesChartType) {
            case 'PRESSURE':
                displayPressureData(last30DaysData, "THIRTY DAYS", pressureValueRange);
                document.getElementById("sensorValuesPressure").checked = true;
                break;
            case 'TEMPERATURE':
                displayTemperatureData(last30DaysData, "THIRTY DAYS", maxTemperatureValue);
                document.getElementById("sensorValuesTemperature").checked = true;
                break;
            case 'VOLTAGE':
                displayVoltageData(last30DaysData, "THIRTY DAYS", minVoltageValue);
                document.getElementById("sensorValuesVoltage").checked = true;
                break;
        }
    }
}

function getxAxesTimeLabelFormat(timespan) {

    let timeLabel = {};
    switch (timespan) {
        case 'TODAY':
            timeLabel.unit = "hour";
            timeLabel.timeFormat = "HH:mm";
            break;
        case 'SEVEN DAYS':
            timeLabel.unit = "day";
            timeLabel.timeFormat = "DD MMM";
            break;
        case 'THIRTY DAYS':
            timeLabel.unit = "day";
            timeLabel.timeFormat = "DD MMM";
            break;
        case 'CUSTOM DATES':
            timeLabel.unit = "day";
            timeLabel.timeFormat = "DD MMM";
            break;
    }
    return timeLabel;
}

function downloadPDF() {
    const canvas = document.getElementById('pressurechartcanvas');
    const canvasImage = canvas.toDataURL('image/jpeg', 1.0);
    let pdf = new jsPDF('landscape');
    pdf.setFontSize(20);
    pdf.addImage(canvasImage, 'JPEG', 15, 15, 280, 150);
    pdf.text(15, 15, "we have discoverd...");
    pdf.save("pressure chart");
}

function initialiseChartMap(mapDiv) {

    if (!map) {
        // create a map in the "map" div, set the view to a given place and zoom
        map = L.map(mapDiv, {
            fullscreenControl: true,
            fullscreenControlOptions: {
                position: 'topleft'
            }
        }).setView([-33.97823, 22.45808], 10);

        // add an OpenStreetMap tile layer
        L.tileLayer('https://{s}.tile.osm.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="http://gurtam.com">Gurtam</a>'
        }).addTo(map);
    }
}

function showMap(posx, posy) {
    if (map) { // check if map created
        const customIcon = L.divIcon({
            className: 'map-icon'
        });
        if (marker != null) {
            map.removeLayer(marker);
        }

        marker = L.marker({ lat: posy, lng: posx }, { icon: customIcon }).addTo(map);
        marker.setLatLng({ lat: posy, lng: posx });
        marker.setIcon(customIcon);
        map.invalidateSize();
        map.setView({ lat: posy, lng: posx });
    }
}

function tooltipLines(imei, strokeStyle) {

    const tooltipLine = {
        id: 'tooltipLine',
        beforeDraw: async chart => {
            if (chart.tooltip != null) {
                //    let t = 0;
                //} else {
                if (chart.tooltip._active && chart.tooltip._active.length) {
                    const i = chart._active[0].datasetIndex;
                    const d = chart.tooltip._active[0].index;
                    if (d >= 0 && chart.data.datasets.length && chart.data.datasets[0].data.length >= d) {

                        if (document.getElementById('mapbox').style.display === 'block') {
                            if (chart.data.datasets[i].data[d].x != null) {
                                const location = await getCurrentLocation(imei, chart.data.datasets[i].data[d].x);
                                showMap(location.longitude, location.latitude);
                            }
                        }
                    }

                    const ctx = chart.ctx;
                    if (ctx) {
                        ctx.save();
                        ctx.beginPath();
                        const activePoint = chart.tooltip._active[0];                        
                        //ctx.setLineDash([5, 7]);
                        if (activePoint) {
                            ctx.moveTo(activePoint.element.x, chart.chartArea.top);
                            ctx.lineTo(activePoint.element.x, chart.chartArea.bottom);
                        }
                        ctx.lineWidth = 0.85;
                        ctx.strokeStyle = strokeStyle; //ctx.strokeStyle = '#075ea5';
                        ctx.stroke();
                        ctx.restore();
                    }
                }
            }
        }
    };

    return tooltipLine;

}

function setClickablePoints(clicked) {

    const clickablePoints = {
        id: 'clickablePoints',
        afterEvent: (chart, args, pluginOptions) => {
            if (args.event.type === 'click') {
                const i = chart._active[0].datasetIndex;
                const xCursor = args.event.x;
                const yCursor = args.event.y;
                for (let d = 0; d < chart._metasets[i].data.length; d++) {
                    const xMin = chart._metasets[i].data[d].x - 1.5;
                    const xMax = chart._metasets[i].data[d].x + 1.5;
                    const yMin = chart._metasets[i].data[d].y - 4.5;
                    const yMax = chart._metasets[i].data[d].y + 4.5;
                    if (xMin <= xCursor && xCursor <= xMax && yMin <= yCursor && yCursor <= yMax) {
                        if (!mapBox) mapBox = new DialogBox("mapbox");
                        mapBox.showDialog();
                        if (clicked[d] === 0) {
                            clicked[d] = 1;
                        } else {
                            clicked[d] = 0;
                        }
                    }
                }
                chart.update();
            }
        },
    };

    return clickablePoints;
}

function hexToRgb(hex) {
    const hexInt = parseInt(hex.slice(1), 16);
    const r = (hexInt >> 16) & 255;
    const g = (hexInt >> 8) & 255;
    const b = hexInt & 255;

    return { r, g, b };
}


function rgbToHex(r, g, b) {
    return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}

function tintColour(hex, percent) {
    let { r, g, b } = hexToRgb(hex);
    r = Math.min(255, Math.floor(r + (255 - r) * percent / 35));
    g = Math.min(255, Math.floor(g + (255 - g) * percent / 85));
    b = Math.min(255, Math.floor(b + (255 - b) * percent / 85));

    return rgbToHex(r, g, b);
}

function hexToHsl(hex) {
    let { r, g, b } = hexToRgb(hex);
    r /= 255;
    g /= 255;
    b /= 255;

    let max = Math.max(r, g, b), min = Math.min(r, g, b);
    let h, s, l = (max + min) / 2;

    if (max === min) {
        h = s = 0; // achromatic
    } else {
        let d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
            case r: h = (g - b) / d + (g < b ? 6 : 0); break;
            case g: h = (b - r) / d + 2; break;
            case b: h = (r - g) / d + 4; break;
        }
        h /= 6;
    }

    return { h, s, l };
}

function hslToHex(h, s, l) {
    let r, g, b;

    if (s === 0) {
        r = g = b = l; // achromatic
    } else {
        function hue2rgb(p, q, t) {
            if (t < 0) t += 1;
            if (t > 1) t -= 1;
            if (t < 1 / 6) return p + (q - p) * 6 * t;
            if (t < 1 / 2) return q;
            if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
            return p;
        }

        let q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        let p = 2 * l - q;
        r = hue2rgb(p, q, h + 1 / 3);
        g = hue2rgb(p, q, h);
        b = hue2rgb(p, q, h - 1 / 3);
    }

    return rgbToHex(Math.round(r * 255), Math.round(g * 255), Math.round(b * 255));
}

function adjustHue(hex, degree) {
    let { h, s, l } = hexToHsl(hex);
    h = (h + degree / 360) % 1;
    return hslToHex(h, s, l);
}

function complementaryColor(hex) {
    let hexInt = parseInt(hex.slice(1), 16);
    let r = (hexInt >> 16) & 255;
    let g = (hexInt >> 8) & 255;
    let b = hexInt & 255;

    // Calculate complementary color
    r = 255 - r;
    g = 255 - g;
    b = 255 - b;

    return rgbToHex(r, g, b);
}

function createPressureChart(imei, chartData, timeSpan) {

   try { 

    document.body.style.cursor = 'wait';
    
    //let pressureValueRange = [chartData[0].minPressureValue, chartData[0].maxPressureValue, chartData[0].manufacturersRecommendedPressure];    
    //let description = ["Min Press: ", "Max Press: ", "Rec Press: "];
    //let annotations = [];
    //if (chartData[0].minPressureValue != null) {
    //    annotations = pressureValueRange.map(function (outOfRangeValue, index) {
    //        let colour = PRIMARY_COLOUR;
    //        let position = 'center';
    //        let backgroundColour = '#eee';
    //        let opacity = '0.85';
    //        if (index === 0) {
    //            colour = LOW_PRESSURE_COLOUR; //'#ad0505'
    //            position = 'start';
    //        }
    //        if (index === 1) {
    //            colour = HIGH_PRESSURE_COLOUR;
    //            position = 'start';
    //        }
    //        if (index === 2) {
    //            colour = SECONDARY_COLOUR;
    //            //backgroundColour = '#dde7c8';
    //            //opacity = '0.85';
    //            position = 'start';
    //        }
    //        return {
    //            type: 'line',
    //            id: 'hline' + index,
    //            mode: 'horizontal',
    //            /*scaleID: 'y-axis-0',*/
    //            drawTime: 'beforeDatasetsDraw',
    //            yMin: outOfRangeValue,
    //            yMax: outOfRangeValue,
    //            borderColor: colour,
    //            borderWidth: 1,
    //            label: {
    //                display: true,
    //                padding: {
    //                    left: 2,
    //                    right: 2,
    //                },
    //                width: 50,
    //                position: position,
    //                color: colour,
    //                backgroundColor: backgroundColour,
    //                //opacity: opacity,
    //                content: description[index] + outOfRangeValue + ' bar'
    //            }
    //        };
    //    });
    //}

    //let chartAxesData = [];
    //let previousSensorId = null;
    //let currentColour = PRIMARY_COLOUR;
    //let clicked = [];
    //let pointBackgroundColours = [];
    //let pointBorderColours = [];
    //let borderColours = [];

    //for (let i = 0; i < chartData.length; i++) {
    //    clicked.push(0);
        
    //    let xyData = {};
    //    xyData.y = chartData[i].pressure;
    //    xyData.temperature = chartData[i].temperature;
    //    xyData.voltage = chartData[i].voltage;
    //    xyData.x = chartData[i].time;
    //    xyData.sensorId = chartData[i].sensorId;

    //    if (previousSensorId !== null && chartData[i].sensorId !== previousSensorId) {
    //        currentColour = tintColour(currentColour, 45); // Adjust the percentage as needed
    //        //currentColour = adjustHue(currentColour, 180); // Adjust the percentage as needed
    //        //currentColour = complementaryColor(currentColour); 
    //    }

    //    pointBackgroundColours.push(currentColour); // Store the colour with the data point
    //    pointBorderColours.push(currentColour);
    //    borderColours.push(currentColour); 

    //    chartAxesData.push(xyData);
    //    previousSensorId = chartData[i].sensorId;
    //}
        
    ////borderColours.push(currentColour); // Add an extra color at the end to ensure the last segment gets the correct color

    //// Segment color configuration based on sensor ID changes
    //const segmentColourConfig = {
    //    borderColor: (ctx) => {
    //        const index = ctx.p1DataIndex;
    //        return pointBorderColours[index];
    //    }
    //};

    //let maxY = Math.max(...chartAxesData.map(c => c?.y || 0));
    //gPressureChartMaxY = Math.round(Math.max(maxY, chartData[0].maxPressureValue) + 1);

    //let timeLabel = getxAxesTimeLabelFormat(timeSpan);

    //const tooltipLine = tooltipLines(imei, SECONDARY_COLOUR);
    //const clickablePoints = setClickablePoints(clicked);

    //let sensorValuesChartData = {
    //    type: 'line',
    //    data: {
    //        datasets: [{
    //            label: 'A' + parseInt(chartData[0].axle) + '-T' + chartData[0].sensorName.slice(0, 1),
    //            backgroundColor: pointBackgroundColours, 
    //            borderColor: borderColours,
    //            data: chartAxesData,
    //            pointBackgroundColor: pointBackgroundColours, // Use the stored color
    //            pointBorderColor: pointBorderColours, // Use the stored color
    //            borderWidth: 0.5,
    //            radius: 0.85,
    //            hitRadius: 4.5,
    //            hoverRaius: 4.5,
    //            tension: 0.5,
    //            pointHoverRadius: 4.5,
    //            segment: segmentColourConfig,
    //        }]
    //    },
    //    plugins: [tooltipLine, clickablePoints],
    //    options: {
    //        responsive: true,
    //        maintainAspectRatio: false,
    //        scales: {
    //            y: {
    //                beginAtZero: true,
    //                max: gPressureChartMaxY,
    //                ticks: {
    //                    stepSize: 0.5,
    //                }
    //            },
    //            x: {
    //                scaleLabel: {
    //                    display: true
    //                },
    //                type: 'time',
    //                time: {
    //                    unit: timeLabel.unit,
    //                    displayFormats: {
    //                        hour: timeLabel.timeFormat,
    //                        day: timeLabel.timeFormat
    //                    },
    //                    tooltipFormat: "DD MMM HH:mm:ss"
    //                },
    //                position: "bottom"
    //            },
    //        },
    //        plugins: {
    //            tooltip: {
    //                callbacks: {
    //                    title: () => {
    //                        return `Click data point to view map`;
    //                    },
    //                    label: (context) => {
    //                        const date = context.label;
    //                        return [`${date}`, `${context.dataset.label}`];
    //                    },
    //                    afterBody: (context) => {
    //                        const xtraData = [];
    //                        const sensorId = context[0].raw.sensorId.toString();
    //                        xtraData.push(`Sensor Id: ${sensorId}`);
    //                        const temperature = context[0].raw.temperature.toString();
    //                        const voltage = context[0].raw.voltage.toString();
    //                        xtraData.push(`${context[0].dataset.data[context[0].dataIndex].y.toString()} bar  ${temperature} \u2103  ${voltage} V`);
    //                        return xtraData;
    //                    }
    //                },
    //            },
    //            zoom: {
    //                zoom: {
    //                    wheel: {
    //                        enabled: true,
    //                    },
    //                    mode: "xy",
    //                }
    //            },
    //            annotation: {
    //                annotations: annotations
    //            },
    //        },
    //        layout: {
    //            padding: {
    //                bottom: 25,
    //            },
    //        },

    //    },
    //};

       gPressureChart = new Chart(gPressureCtx, getPressureChartData(imei, chartData, timeSpan));

    document.body.style.cursor = 'default';
    } catch (err) {

        const canvas = document.getElementById('sensorreportpressurechartcanvas');

        if (canvas) {
            // Remove the canvas element
            
            const textNode = document.createTextNode(`No Data`);
            canvas.parentNode.appendChild(textNode);
            canvas.parentNode.removeChild(canvas);
        }
    }
}

export function getPressureChartData(imei, chartData, timeSpan) {

    try {

        const pressureValueRange = [chartData[0].minPressureValue, chartData[0].maxPressureValue, chartData[0].manufacturersRecommendedPressure];
        const description = ["Min Press: ", "Max Press: ", "Rec Press: "];
        let annotations = [];
        if (chartData[0].minPressureValue != null) {
            annotations = pressureValueRange.map(function (outOfRangeValue, index) {
                let colour = PRIMARY_COLOUR;
                let position = 'center';
                let backgroundColour = '#eee';
                if (index === 0) {
                    colour = LOW_PRESSURE_COLOUR; //'#ad0505'
                    position = 'start';
                }
                if (index === 1) {
                    colour = HIGH_PRESSURE_COLOUR;
                    position = 'start';
                }
                if (index === 2) {
                    colour = SECONDARY_COLOUR;
                    //backgroundColour = '#dde7c8';
                    //opacity = '0.85';
                    position = 'start';
                }
                return {
                    type: 'line',
                    id: 'hline' + index,
                    mode: 'horizontal',
                    /*scaleID: 'y-axis-0',*/
                    drawTime: 'beforeDatasetsDraw',
                    yMin: outOfRangeValue,
                    yMax: outOfRangeValue,
                    borderColor: colour,
                    borderWidth: 1,
                    label: {
                        display: true,
                        padding: {
                            left: 2,
                            right: 2,
                        },
                        width: 50,
                        position: position,
                        color: colour,
                        backgroundColor: backgroundColour,
                        //opacity: opacity,
                        content: description[index] + outOfRangeValue + ' bar'
                    }
                };
            });
        }

        const label = chartData[0].axle === SPARE_AXLE ? `S-${chartData[0].sensorName.slice(0, 1) }` : 'A' + parseInt(chartData[0].axle) + '-T' + chartData[0].sensorName.slice(0, 1)
        const chartAxesData = getPressureChartAxesData(chartData, PRIMARY_COLOUR);

        const maxY = Math.max(...chartAxesData.dataPoints.map(c => c?.y || 0));
        gPressureChartMaxY = Math.round(Math.max(maxY, chartData[0].maxPressureValue) + 1);

        const timeLabel = getxAxesTimeLabelFormat(timeSpan);

        const tooltipLine = tooltipLines(imei, SECONDARY_COLOUR);
        const clickablePoints = setClickablePoints(chartAxesData.clicked);

        let sensorValuesChartData = {
            type: 'line',
            data: {
                datasets: [{
                    label: label,
                    backgroundColor: chartAxesData.pointBackgroundColours,
                    borderColor: chartAxesData.borderColours,                    
                    pointBackgroundColor: chartAxesData.pointBackgroundColours, // Use the stored color
                    pointBorderColor: chartAxesData.pointBorderColours, // Use the stored color
                    segment: chartAxesData.segmentColourConfig,
                    data: chartAxesData.dataPoints,
                    borderWidth: 0.5,
                    radius: 0.85,
                    hitRadius: 4.5,
                    hoverRaius: 4.5,
                    tension: 0.5,
                    pointHoverRadius: 4.5,                   
                }]
            },
            plugins: [tooltipLine, clickablePoints],
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    y: {
                        beginAtZero: true,
                        max: gPressureChartMaxY,
                        ticks: {
                            stepSize: 0.5,
                        }
                    },
                    x: {
                        scaleLabel: {
                            display: true
                        },
                        type: 'time',
                        time: {
                            unit: timeLabel.unit,
                            displayFormats: {
                                hour: timeLabel.timeFormat,
                                day: timeLabel.timeFormat
                            },
                            tooltipFormat: "DD MMM HH:mm:ss"
                        },
                        position: "bottom"
                    },
                },
                plugins: {
                    tooltip: {
                        callbacks: {
                            title: () => {
                                return `Click data point to view map`;
                            },
                            label: (context) => {
                                const date = context.label;
                                return [`${date}`, `${context.dataset.label}`];
                            },
                            afterBody: (context) => {
                                const xtraData = [];
                                const sensorId = context[0].raw.sensorId.toString();
                                xtraData.push(`Sensor Id: ${sensorId}`);
                                const temperature = context[0].raw.temperature.toString();
                                const voltage = context[0].raw.voltage.toString();
                                xtraData.push(`${context[0].dataset.data[context[0].dataIndex].y.toString()} bar  ${temperature} \u2103  ${voltage} V`);
                                return xtraData;
                            }
                        },
                    },
                    zoom: {
                        zoom: {
                            wheel: {
                                enabled: true,
                            },
                            mode: "xy",
                        }
                    },
                    annotation: {
                        annotations: annotations
                    },
                },
                layout: {
                    padding: {
                        bottom: 25,
                    },
                },

            },
        };

        return sensorValuesChartData;

        //gPressureChart = new Chart(gPressureCtx, sensorValuesChartData);

        //document.body.style.cursor = 'default';
    } catch (err) {

    }
}

export function getPressureChartAxesData(chartData, currentColour) {

    const chartAxesData = {};

    let clicked = [];
    let pointBackgroundColours = [];
    let pointBorderColours = [];
    let borderColours = [];
    let dataPoints = [];

    //let currentColour = PRIMARY_COLOUR;
    let previousSensorId = null;

    for (let i = 0; i < chartData.length; i++) {
        clicked.push(0);

        let xyData = {};
        xyData.y = chartData[i].pressure;
        xyData.temperature = chartData[i].temperature;
        xyData.voltage = chartData[i].voltage;
        xyData.x = chartData[i].time;
        xyData.sensorId = chartData[i].sensorId;

        if (previousSensorId !== null && chartData[i].sensorId !== previousSensorId) {
            //currentColour = tintColour(currentColour, 25); // Adjust the percentage as needed
            currentColour = adjustHue(currentColour, 180); // Adjust the percentage as needed
            //currentColour = complementaryColor(currentColour); 
        }

        pointBackgroundColours.push(currentColour); // Store the colour with the data point
        pointBorderColours.push(currentColour);
        borderColours.push(currentColour);

        dataPoints.push(xyData);

        previousSensorId = chartData[i].sensorId;
    }

    // Segment color configuration based on sensor ID changes
    chartAxesData.segmentColourConfig = {
        borderColor: (ctx) => {
            const index = ctx.p1DataIndex;
            return pointBorderColours[index];
        }
    };
    
    chartAxesData.pointBackgroundColours = pointBackgroundColours;
    chartAxesData.pointBorderColours = pointBorderColours;
    chartAxesData.borderColours = borderColours;
    chartAxesData.clicked = clicked;
    chartAxesData.dataPoints = dataPoints;
    return chartAxesData;

}

function createTemperatureChart(imei, chartData, timeSpan) {

    try {
        document.body.style.cursor = 'wait';

        let chartAxesData = [];

        for (let i = 0; i < chartData.length; i++) {
            let xyData = {};
            xyData.y = chartData[i].temperature;
            xyData.x = chartData[i].time;
            xyData.sensorId = chartData[i].sensorId;
            xyData.pressure = chartData[i].pressure;
            xyData.voltage = chartData[i].voltage;
            chartAxesData.push(xyData);
        }

        let timeLabel = getxAxesTimeLabelFormat(timeSpan);

    let annotations = [];
    if (chartData[0].maxTemperatureValue != null) {
        annotations = [{
            type: 'line',
            id: 'hline',
            mode: 'horizontal',
            drawTime: 'beforeDatasetsDraw',
            /*scaleID: 'y-axis-0',*/
            yMin: chartData[0].maxTemperatureValue,
            yMax: chartData[0].maxTemperatureValue,
            borderColor: HIGH_TEMPERATURE_COLOUR,
            borderWidth: 1,
            label: {
                display: true,
                padding: {
                    left: 2,
                    right: 2,
                },
                position: 'start',
                backgroundColor: '#eeeeee',
                color: HIGH_TEMPERATURE_COLOUR,
                content: 'Max Temp: ' + chartData[0].maxTemperatureValue + '\u2103'
            }
        }];


        }

        let maxY = Math.max(...chartAxesData.map(c => c?.y || 0));
        gTemperatureChartMaxY = Math.round(Math.max(maxY, chartData[0].maxTemperatureValue) + 5);

        const label = chartData[0].axle === SPARE_AXLE ? `S-${chartData[0].sensorName.slice(0, 1)}` : 'A' + parseInt(chartData[0].axle) + '-T' + chartData[0].sensorName.slice(0, 1);

    const tooltipLine = tooltipLines(imei, HIGH_TEMPERATURE_COLOUR);

    let sensorValuesChartData = {
        type: 'line',
        data: {
            datasets: [{
                label: label,
                afterLabel: + ' \u2103',
                backgroundColor: PRIMARY_COLOUR,
                fill: false,
                borderColor: PRIMARY_COLOUR,
                data: chartAxesData,
                borderWidth: 0.5,
                radius: 0.85,
                hitRadius: 4.5,
                hoverRaius: 4.5,
                tension: 0.5,
                pointHoverRadius: 4.5
            },]
        },
        plugins: [tooltipLine],
        options: {
            maintainAspectRatio: false,
            scales: {
                y: {
                    beginAtZero: true,
                    max: gTemperatureChartMaxY,
                    ticks: {
                        stepSize: 5,
                    }
                },
                x: {
                    scaleLabel: {
                        display: true
                    },
                    type: "time",
                    time: {
                        unit: timeLabel.unit,
                        displayFormats: {
                            hour: timeLabel.timeFormat,
                            day: timeLabel.timeFormat
                        },
                        tooltipFormat: "DD MMM HH:mm:ss"
                    },
                    position: "bottom"
                }
            },
            plugins: {
                tooltip: {
                    callbacks: {
                        title: () => {
                            return `Click data point to view map`;
                        },
                        label: (context) => {
                            const date = context.label;
                            return [`${date}`, `${context.dataset.label}`];
                        },
                        afterBody: (context) => {
                            const xtraData = [];
                            const sensorId = context[0].raw.sensorId.toString();
                            xtraData.push(`Sensor Id: ${sensorId}`);
                            const pressure = context[0].raw.pressure.toString();
                            const voltage = context[0].raw.voltage.toString();
                            //xtraData.push(context[0].label); //date and time
                            xtraData.push(`${context[0].dataset.data[context[0].dataIndex].y.toString()} \u2103  ${pressure} bar  ${voltage} V`);
                            return xtraData;
                        }
                    },
                },
                zoom: {
                    zoom: {
                        wheel: {
                            enabled: true,

                        },
                        mode: "xy",
                    }
                },
                annotation: {
                    annotations: annotations
                },
            },
            layout: {
                padding: {
                    bottom: 25,
                },
            },
            registry: {
            }
        }
    };

    gTemperatureChart = new Chart(gTemperatureCtx, sensorValuesChartData);

        document.body.style.cursor = 'default';
    } catch (err) {
        console.log(err);
        if (gTemperatureChart)
            gTemperatureChart.destroy();
        const canvas = document.getElementById('sensorreporttemperaturechartcanvas');
        if (canvas) {
            // Remove the canvas element            
            const textNode = document.createTextNode(`No Data`);
            canvas.parentNode.appendChild(textNode);
            canvas.parentNode.removeChild(canvas);
        }
    }
}

function createVoltageChart(imei, chartData, timeSpan) {

    try {
    document.body.style.cursor = 'wait';

    let chartAxesData = [];
 
    for (let i = 0; i < chartData.length; i++) {
        let xyData = {};
        xyData.y = chartData[i].voltage;
        xyData.x = chartData[i].time;
        xyData.sensorId = chartData[i].sensorId;
        xyData.pressure = chartData[i].pressure;
        xyData.temperature = chartData[i].temperature;
        chartAxesData.push(xyData);
    }

    let timeLabel = getxAxesTimeLabelFormat(timeSpan);

    let maxY = Math.max(...chartAxesData.map(c => c?.y || 0));
    gVoltageChartMaxY = Math.round(Math.max(maxY, chartData[0].minVoltageValue) + 1);

    let annotations = [];
    if (chartData[0].minVoltageValue != null) {
        annotations = [{
            type: 'line',
            id: 'hline',
            mode: 'horizontal',
            drawTime: 'beforeDatasetsDraw',
            /*scaleID: 'y-axis-0',*/
            yMin: chartData[0].minVoltageValue,
            yMax: chartData[0].minVoltageValue,
            borderColor: LOW_VOLTAGE_COLOUR,
            borderWidth: 1,
            label: {
                display: true,
                padding: {
                    left: 2,
                    right: 2,
                },
                position: 'start',
                backgroundColor: '#eeeeee',
                color: LOW_VOLTAGE_COLOUR,
                content: 'Min Volt: ' + chartData[0].minVoltageValue + 'V'
            }
        }];
    }

        const tooltipLine = tooltipLines(imei, LOW_VOLTAGE_COLOUR);

    //const tooltipLine = {
    //    id: 'tooltipLine',
    //    beforeDraw: chart => {
    //        if (chart.tooltip._active && chart.tooltip._active.length) {
    //            const d = chart.tooltip._active[0].index;
    //            if (d && chart.data.datasets[0].data[d].posx) {
    //                document.getElementById('mapboxtitlebar').innerText = chart.data.datasets[0].data[d].address;
    //                showMap(chart.data.datasets[0].data[d].posx, chart.data.datasets[0].data[d].posy);
    //            } else {
    //                console.log(`error showmap d index: ${d} x: ${chart.data.datasets[0].data[d].posx} y: ${chart.data.datasets[0].data[d].posy}`);
    //            }

    //            const ctx = chart.ctx;
    //            ctx.save();
    //            const activePoint = chart.tooltip._active[0];
    //            ctx.beginPath();
    //            //ctx.setLineDash([5, 7]);
    //            ctx.moveTo(activePoint.element.x, chart.chartArea.top);
    //            ctx.lineTo(activePoint.element.x, chart.chartArea.bottom);
    //            ctx.lineWidth = 0.85;
    //            ctx.strokeStyle = '#ffce00';
    //            ctx.stroke();
    //            ctx.restore();
    //        }
    //    }
    //};

    let sensorValuesChartData = {
        type: 'line',
        data: {
            datasets: [{
                label: 'A' + chartData[0].axle + '-T' + chartData[0].sensorName.slice(0, 1),
                backgroundColor: PRIMARY_COLOUR,
                fill: false,
                borderColor: PRIMARY_COLOUR,
                data: chartAxesData,
                borderWidth: 0.5,
                radius: 0.85,
                hitRadius: 4.5,
                hoverRaius: 4.5,
                tension: 0.5,
                pointHoverRadius: 4.5
            }],
        },
        plugins: [tooltipLine],
        options: {
            maintainAspectRatio: false,
            scales: {
                y: {
                    beginAtZero: true,
                    max: gVoltageChartMaxY,
                    ticks: {
                        stepSize: 0.5,
                    }
                },
                x: {
                    scaleLabel: {
                        display: true
                    },
                    type: "time",
                    time: {
                        unit: timeLabel.unit,
                        displayFormats: {
                            hour: timeLabel.timeFormat,
                            day: timeLabel.timeFormat
                        },
                        tooltipFormat: "DD MMM HH:mm:ss"
                    },
                    position: "bottom"
                }
            },
            plugins: {
                tooltip: {
                    callbacks: {
                        title: () => {
                            return `Click data point to view map`;
                        },
                        label: (context) => {
                            const date = context.label;
                            return [`${date}`, `${context.dataset.label}`];
                        },
                        afterBody: (context) => {
                            const xtraData = [];
                            const sensorId = context[0].raw.sensorId;
                            xtraData.push(`Sensor Id: ${sensorId}`);
                            const pressure = context[0].raw.pressure.toString();
                            const temperature = context[0].raw.temperature.toString();
                            //xtraData.push(context[0].label); //date and time
                            xtraData.push(`${context[0].dataset.data[context[0].dataIndex].y.toString()} V  ${pressure} bar  ${temperature} \u2103`);
                            return xtraData;
                        }
                    },
                },
                //tooltip: {
                //    yAlign: 'bottom',
                //    callbacks: {
                //        beforeBody: (context) => {
                //            const xtraData = [];
                //            const address = context[0].raw.address;
                //            const emptyLine = "";
                //            xtraData.push(address);
                //            xtraData.push(emptyLine);
                //            return xtraData;
                //        },
                //        label: (context) => {
                //            return `${context.dataset.data[context.dataIndex].y.toString()} V`;
                //        },
                //        afterLabel: (context) => {
                //            const xtraData = [];
                //            const pressure = context.raw.pressure.toString();
                //            const temperature = context.raw.temperature.toString();
                //            xtraData.push(`${pressure} bar`);
                //            xtraData.push(`${temperature} \u2103`);
                //            return xtraData;
                //        },
                //    },
                //},
                zoom: {
                    zoom: {
                        wheel: {
                            enabled: true,
                        },
                        mode: "xy",
                    }
                },
                annotation: {
                    annotations: annotations
                },
            },
            layout: {
                padding: {
                    bottom: 25,
                },
            },
            registry: {
                tooltip: {
                    callbacks: {
                        label: function (tooltipItem, data) {
                            return tooltipItem.yLabel + ' V';
                        },
                    }
                },
            }
        }
    };

    gVoltageChart = new Chart(gVoltageCtx, sensorValuesChartData);

        document.body.style.cursor = 'default';
    } catch (err) {
        const canvas = document.getElementById('sensorreportvoltagechartcanvas');

        if (canvas) {
            // Remove the canvas element
            const textNode = document.createTextNode(`No Data`);
            canvas.parentNode.appendChild(textNode);
            canvas.parentNode.removeChild(canvas);
        }
    }
}

function getxAxisLabels(chartData, timespan) {

    let xAxisLabels = [];
    switch (timespan) {
        case 'TODAY':
            for (let i = 0; i < chartData.length; i++) {
                xAxisLabels.push(chartData[i].time.toString().slice(16, 24));
            }
            //xAxisLabels = getLast24Hours()
            break;
        case 'SEVEN DAYS':
            for (let i = 0; i < chartData.length; i++) {
                //xAxisLabels.push(chartData[i].time.toString().slice(0, 15));
                xAxisLabels.push(chartData[i].time);
            }
            break;
        case 'THIRTY DAYS':
            for (let i = 0; i < chartData.length; i++) {
                xAxisLabels.push(chartData[i].time.toString().slice(4, 15));
            }
            break;
        case 'NINETY DAYS':
            for (let i = 0; i < chartData.length; i++) {
                xAxisLabels.push(chartData[i].time.toString().slice(4, 15));
            }
            break;
    }
    return xAxisLabels;
}

async function loadSensorChartMessages(unit, wheel) {

    const session = global.session;
    const timeTo = session.getServerTime();
    //let timeFrom = timeTo - 3600 * 24 * 30;
    const timeFrom = timeTo - 3600 * 24 * 7;
    const msgLoadCount = 0xffffffff; 
    const prms = [];
    const unitId = unit.id;
    const prmsObj = {
        "svc": "messages/load_interval",
        "params": {
            "itemId": unitId,
            "timeFrom": timeFrom,
            "timeTo": timeTo, "flags": 32, "flagsMask": 0, "loadCount": msgLoadCount
        }
    };
    prms.push(prmsObj);

    if (unit.unitTrailers.length > 0) {
        for (let t = 0; t < unit.unitTrailers.length; t++) {
            let prmsObj = {
                "svc": "messages/load_interval",
                "params": {
                    "itemId": unit.unitTrailers[t].id,
                    "timeFrom": timeFrom,
                    "timeTo": timeTo, "flags": 1, "flagsMask": 65281, "loadCount": msgLoadCount
                }
            };
            prms.push(prmsObj);
        }
    }

    let data = await loadSensorValuesBatch(prms);

    return await new Promise((resolve, reject) => {

        let sensorValues = [];

        for (let p = 0; p < prms.length; p++) {

            for (let i = 0; i < data[p].messages.length; i++) {

                for (let property in data[p].messages[i].p) {

                    if (property === "p" + wheel.sensorName) {

                        const sensorValue = {};
                        sensorValue.id = prms[p].params.itemId;
                        sensorValue.unitNumber = property.slice(-1);
                        sensorValue.sensorName = wheel.sensorName;
                        sensorValue.axle = parseInt(property.slice(2, 4));
                        sensorValue.wheelAxle = property.slice(1, 4);
                        sensorValue.minPressureValue = wheel.minPressureValue;
                        sensorValue.maxPressureValue = wheel.maxPressureValue;
                        sensorValue.manufacturersRecommendedPressure = wheel.manufacturersRecommendedPressure;
                        sensorValue.maxTemperatureValue = wheel.maxTemperatureValue;
                        sensorValue.minVoltageValue = wheel.minVoltageValue;
                        sensorValue.unixTime = data[p].messages[i].t;
                        sensorValue.time = convertUnixTime(data[p].messages[i].t);
                        sensorValue.posx = data[p].messages[i].pos?.x || 0;
                        sensorValue.posy = data[p].messages[i].pos?.y || 0;
                        sensorValue.pressure = (parseFloat(data[p].messages[i].p[property])).toFixed(2);
                        sensorValue.temperature = data[p].messages[i].p["t" + property.slice(1)];
                        sensorValue.voltage = data[p].messages[i].p["v" + property.slice(1)];
                        sensorValues.push(sensorValue);
                    }
                }
            }
        }

        if (sensorValues.length) {
            sensorValues.sort((a, b) => a.unixTime - b.unixTime);

            console.log('time2: [' + Date.now() + '] ' + unitId + ' record count: ' + sensorValues.length);

            resolve(sensorValues);
        } else {
            reject(new Error(`Whoops!`));
        }

    }).catch(alert);
}

export { loadSensorChartMessages, openSensorReport };