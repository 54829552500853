import { getRepeaterData } from "../../../data/repeaterdata";
import { getUnitData } from "../../../data/getunitdata";

import { createDiagramView } from "./uds";
import { openNotificationsForm } from "./wheelsdiagram";
import { createTrailerHeader } from "./unitdiagrams";

import { SPARE_AXLE } from "../../../global"

async function createRepeaterDiagram(unit) {
    //const resourceNotifications = await getResourceNotifications();
    //const unit = await getRepeaterData(unitdata);
    displayDiagram(unit);
}

function displayDiagram(unit) {

    const diagram = document.getElementById('rightpane');
    while (diagram.hasChildNodes()) {
        diagram.removeChild(diagram.lastChild);
    }
    const diagramFragment = document.createDocumentFragment();

    let diagramDiv = document.createElement('div');
    diagramDiv.id = `repeaterdiagram`;
    diagramDiv.className = 'unitdiagram';

    diagramFragment.appendChild(diagramDiv);
    diagram.appendChild(diagramFragment);

    const repeaterDiagramButtons = createRepeaterDiagramButtons(unit)
    diagramDiv.appendChild(repeaterDiagramButtons);

    const repeaterDiagramUnitFrame = createDiagramUnitFrame(unit);
    diagramDiv.appendChild(repeaterDiagramUnitFrame);
}

function createDiagramUnitFrame(unit) {    

    const repeaterDiagramUnitFrame = document.createElement('div');   

    let unitBoxes = [...new Set(Object.keys(unit.locationSensors).map(item => unit.locationSensors[item].sensorName.slice(-1)))].sort();
    const unitBoxesWheelSensors = [...new Set(Object.keys(unit.wheelSensors).map(item => unit.wheelSensors[item].sensorName.slice(-1)))].sort();
    if (unitBoxesWheelSensors.length > unitBoxes.length) unitBoxes = unitBoxesWheelSensors;
    for (let ub = 0; ub < unitBoxes.length; ub++) {
        repeaterDiagramUnitFrame.appendChild(createDiagramUnitBox(unit, ub));
    }

    //repeaterDiagramUnitFrame.appendChild(createGatewayElements(unit));

    return repeaterDiagramUnitFrame
}

function createGatewayElements(unit) {

    const gatewayElements = document.createElement('div');
    gatewayElements.className = 'diagramaxle';

    gatewayElements.appendChild(createGatewayElement(unit));
    gatewayElements.appendChild(createSyncElement());

    return gatewayElements;
}

function createGatewayElement(unit) {

    const gateway = document.createElement('div');
    gateway.className = 'gateway';
    gateway.appendChild(createGatewayTopElement());
    gateway.appendChild(createGatewayInfoElement(unit));
    gateway.appendChild(createGatewayBottomElement());

    return gateway;
}

function createSyncElement() {
    
    const sync = document.createElement('div');
    sync.className = 'sync';

    //sync.appendChild(createSensorTopElement());
    sync.appendChild(createSyncSensorValues());
    //sync.appendChild(createSensorBottomElement());

    return sync;
}

function createSyncSensorValues() {

    const sensorValues = document.createElement('div');
    sensorValues.className = 'repeatersensorvalues';

    sensorValues.appendChild(createSyncSignalStrengthElement());
    sensorValues.appendChild(createSyncVoltageElement());

    return sensorValues;
}

function createSyncSignalStrengthElement() {

    const signalStrength = document.createElement('div');
    let sensorValuesClasses = ['repeatersensorvaluesdefault'];
    //if (axleSensor.signalStrengthLabelColour === "blue")
    //    sensorValuesClasses = ['repeatersensorvaluesdefault', 'blue'];

    signalStrength.innerText = "undefined";
    signalStrength.classList.add(...sensorValuesClasses);
    //signalStrength.innerText = Math.round(axleSensor.signalStrength * 1000) / 1000 + " dBi";

    return signalStrength;
}

function createSyncVoltageElement() {

    const sensorVoltage = document.createElement('div');
    let sensorValuesClasses = ['repeatersensorvaluesdefault'];
    //if (axleSensor.voltageLabelColour === "yellow")
    //    sensorValuesClasses = ['repeatersensorvaluesdefault', 'yellow'];
    //const voltage = Math.round(axleSensor.voltageValue * 1000) / 1000;

    sensorVoltage.classList.add(...sensorValuesClasses);
    sensorVoltage.innerText = "undefined";

    return sensorVoltage;
}

function createGatewayTopElement() {

    const gatewayTop = document.createElement('div');
    gatewayTop.className = 'gatewaytop';
    const gatewayTopLeft = document.createElement('div');
    gatewayTopLeft.className = 'gatewaytopleft';
    const gatewayTopRight = document.createElement('div');
    gatewayTopRight.className = 'gatewaytopright';
    gatewayTop.appendChild(gatewayTopLeft);
    gatewayTop.appendChild(gatewayTopRight);
    
    return gatewayTop;
}

function createGatewayBottomElement() {

    const gatewayBottom = document.createElement('div');
    gatewayBottom.className = 'gatewaybottom';

    return gatewayBottom;
}

function createGatewayInfoElement(unit) {

    const gatewayInfo = document.createElement('div');
    gatewayInfo.className = 'gatewayinfo';

    //let sensorType = 'wheelcorrect';
    //if (axleSensors.filter(as => parseInt(as.sensorName.slice(0, 1)) === tyre)[0].hasOwnProperty('sensorType')
    //    && axleSensors.filter(as => parseInt(as.sensorName.slice(0, 1)) === tyre)[0].sensorType !== "") {
    //    sensorType = axleSensors.filter(as => parseInt(as.sensorName.slice(0, 1)) === tyre)[0].sensorType;
    //}

    //const deviceData = await getUnitData("", unit.id);

    //gatewayInfo.appendChild(createGatewayInfo(deviceData.deviceType));
    //gatewayInfo.appendChild(createGatewayInfo(deviceData.imei));
    //gatewayInfo.appendChild(createGatewayInfo(deviceData.phoneNumber));
    //gatewayInfo.appendChild(createGatewaySignalStrengthElement("undefined"));
    //gatewayInfo.appendChild(createBatteryVoltageElement("undefined"));

    gatewayInfo.appendChild(createGatewayInfo("undefined"));
    gatewayInfo.appendChild(createGatewayInfo("undefined"));
    gatewayInfo.appendChild(createGatewayInfo("undefined"));
    gatewayInfo.appendChild(createGatewaySignalStrengthElement("undefined"));
    gatewayInfo.appendChild(createBatteryVoltageElement("undefined"));

    return gatewayInfo;
}

function createGatewayInfo(info) {

    const deviceInfo = document.createElement('div');
    let sensorValuesClasses = ['gatewayvaluesdefault'];

    deviceInfo.classList.add(...sensorValuesClasses);
    deviceInfo.innerText = info;

    return deviceInfo;
}


function createGatewaySignalStrengthElement(signalStrengthValue) {

    const signalStrength = document.createElement('div');
    let sensorValuesClasses = ['gatewayvaluesdefault'];    

    signalStrength.classList.add(...sensorValuesClasses);
    signalStrength.innerText = signalStrengthValue;

    return signalStrength;
}

function createBatteryVoltageElement(voltage) {

    const batteryVoltage = document.createElement('div');
    let sensorValuesClasses = ['gatewayvaluesdefault'];

    //const voltage = Math.round(axleSensor.voltageValue * 1000) / 1000;

    batteryVoltage.classList.add(...sensorValuesClasses);
    batteryVoltage.innerText = voltage;

    return batteryVoltage;
}


function createDiagramUnitBox(unit, unitBox) {

    const repeaterDiagramUnitBox = document.createElement('div');

    if (unitBox !== 0)
        repeaterDiagramUnitBox.appendChild(createTrailerHeader('r', unitBox, unit.trailers));

    let unitBoxAxle = {};
    let axles = [];
    for (let ls = 0; ls < unit.locationSensors.length; ls++) {
        if (parseInt(unit.locationSensors[ls].sensorName.slice(-1)) === unitBox && unit.locationSensors[ls].sensorName.slice(1, 3) !== SPARE_AXLE.toString()) {
            axles.push(unit.locationSensors[ls].sensorName.slice(1, 3));
        }
    }

    const axlesWheelSensors = [];
    for (let ws = 0; ws < unit.wheelSensors.length; ws++) {
        if (parseInt(unit.wheelSensors[ws].sensorName.slice(-1)) === unitBox && unit.wheelSensors[ws].sensorName.slice(1, 3) !== SPARE_AXLE.toString()) {
            axlesWheelSensors.push(unit.wheelSensors[ws].sensorName.slice(1, 3));
        }
    }

    if (axles.length < axlesWheelSensors.length) axles = axlesWheelSensors;
    
    axles = [...new Set(axles)].sort();
    for (let a = 0; a < axles.length; a++) {
        
        unitBoxAxle = createUnitBoxAxle(unit.locationSensors, unitBox, a + +1);
        repeaterDiagramUnitBox.appendChild(unitBoxAxle);
    }

    return repeaterDiagramUnitBox;
}

function createUnitBoxAxle(sensors, unitBox, axle) {

    const unitBoxAxle = document.createElement('div');
    unitBoxAxle.className = 'diagramaxle';
    
    const axleSensorLeft = sensors.filter(s => parseInt(s.sensorName.slice(-1)) === unitBox && parseInt(s.sensorName.slice(1, 3)) === axle && s.sensorName.slice(0, 1) === "l");

    let sensorLeft = createSensorElement(axleSensorLeft);
    unitBoxAxle.appendChild(sensorLeft);

    const axleSensorRight = sensors.filter(s => parseInt(s.sensorName.slice(-1)) === unitBox && parseInt(s.sensorName.slice(1, 3)) === axle && s.sensorName.slice(0, 1) === "r");
    let sensorRight = createSensorElement(axleSensorRight);
    unitBoxAxle.appendChild(sensorRight);

    //let unitAxleSpareSensors = unit.sensors.filter(s => parseInt(s.sensorName.slice(1, 3)) === 16);
    //let tyreSpareAxleDiv = createSpareTyreAxleElements(diagramView, unit, unitAxleSpareSensors, resourceNotifications);
    //unitDiv.appendChild(tyreSpareAxleDiv);

    return unitBoxAxle;
}


function createSensorElement(axleSensor) {

    const sensor = document.createElement('div');
    sensor.id = 'sensor' + axleSensor.sensorName;
    sensor.className = 'repeater';     
    sensor.appendChild(createSensorTopElement());    
    sensor.appendChild(createSensorValuesElement(axleSensor));
    sensor.appendChild(createSensorBottomElement());
    return sensor;
}

function createSensorTopElement() {

    const sensorTop = document.createElement('svg');
    sensorTop.className = 'repeaterimages repeatertop';
    return sensorTop;
}

function createSensorBottomElement() {

    const sensorBottom = document.createElement('svg');
    sensorBottom.className = 'repeaterimages repeaterbottom';
    return sensorBottom;
}

function createSensorValuesElement(axleSensor) {   

    const sensorValues = document.createElement('div');
    sensorValues.className = 'repeatersensorvalues';

    if (axleSensor.length > 0) {
        sensorValues.appendChild(createRepeaterIdElement(axleSensor[0]));
        sensorValues.appendChild(createWheelConfigElement(axleSensor[0]));
        sensorValues.appendChild(createSensorVoltageElement(axleSensor[0]));
    } else {
        sensorValues.appendChild(createNoSignalElement());
    }


    return sensorValues;   
}

function createNoSignalElement() {

    const noSignal = document.createElement('div');
    noSignal.className = 'repeatersensorvaluesdefault wheelnosignal';
    noSignal.innerText = "Signal";

    return noSignal;
}

function createRepeaterIdElement(axleSensor) {

    const repeaterId = document.createElement('div');
    repeaterId.className = 'repeatersensorvaluesdefault sensorid';
    repeaterId.innerText = axleSensor.sensorId;

    return repeaterId;
}

function createWheelConfigElement(axleSensor) {

    const wheelConfig = document.createElement('div');
    let sensorValuesClasses = ['repeatersensorvaluesdefault'];

    wheelConfig.classList.add(...sensorValuesClasses);
    wheelConfig.innerText = axleSensor.wheelConfig === 0 ? "Single" : "Double";
    if (axleSensor.wheelConfig === -1) wheelConfig.innerText = "No Value";
    return wheelConfig;
}

function createSignalStrengthElement(axleSensor) {

    const signalStrength = document.createElement('div');
    let sensorValuesClasses = ['repeatersensorvaluesdefault'];
    if (axleSensor.signalStrengthLabelColour === "blue")
        sensorValuesClasses = ['repeatersensorvaluesdefault', 'blue'];

    signalStrength.classList.add(...sensorValuesClasses);
    signalStrength.innerText = Math.round(axleSensor.signalStrength * 1000) / 1000 + " dBi";

    return signalStrength;
}

function createSensorVoltageElement(axleSensor) {

    const sensorVoltage = document.createElement('div');

    let sensorValuesClasses = ['repeatersensorvaluesdefault'];

    const voltage = Math.round((axleSensor.voltage / 1000) * 100) / 100;
    //const voltage = axleSensor.voltage;
    if (voltage < 3.2)
        sensorValuesClasses = ['repeatersensorvaluesdefault', 'yellow'];

    sensorVoltage.classList.add(...sensorValuesClasses);
    sensorVoltage.innerText = voltage + " V";

    return sensorVoltage;
}

async function getDiagramConfiguration(unit, diagramDiv) {

    for (let un = 0; un < unitNumbers.length; un++) {
        let unitDiv = createUnitElement(unitNumbers[un]);
        diagramDiv.appendChild(unitDiv);

        const unitAxles = [];
        for (let ls = 0; ls < unit.locationSensors.length; ls++) {
            if (unit.locationSensors[ls].sensorName.slice(-1) === unitNumbers[un].toString() && unit.locationSensors[ls].sensorName.slice(1, 3) !== "16")
                unitAxles.push(unit.locationSensors[ls].sensorName.slice(1, 3));
        }

        unitAxles = [...new Set(unitAxles)];
        for (let a = 0; a < unitAxles.length; a++) {

            let axleSensors = unit.locationSensors.filter(s => parseInt(s.sensorName.slice(1, 3)) === (a + +1));
            let axleDiv = createAxleElements(unit, a + +1, axleSensors);

            unitDiv.appendChild(axleDiv);
        }

        let unitAxleSpareSensors = unit.sensors.filter(s => parseInt(s.sensorName.slice(1, 3)) === 16);

    }
}



function createRepeaterDiagramButtons(unit) {

    let schematicDiagramButtonDivClasses = ['row', 'pane2buttons'];
    let schematicDiagramButtonDiv = document.createElement('div');
    schematicDiagramButtonDiv.classList.add(...schematicDiagramButtonDivClasses);

    let schematicDiagramButtonClasses = ['btn', 'btn-primary', 'panebutton', 'flex-child'];

    let notificationsButton = document.createElement('button');
    notificationsButton.classList.add(...schematicDiagramButtonClasses);
    notificationsButton.type = 'button';
    notificationsButton.title = 'SET NOTIFICATIONS';

    const notificationsIcon = document.createElement('svg');
    notificationsIcon.className = 'buttonicon notificationbluesvg';

    const notificationsButtonText = document.createElement('span');
    //wheelsDiagramButtonText.className = 'buttontext'; //class="buttontext buttontext-disabled"
    notificationsButtonText.innerText = 'Notifications'; //'\xa0Unit'
    notificationsButton.appendChild(notificationsIcon);
    notificationsButton.appendChild(notificationsButtonText);

    notificationsButton.onclick = function (e) {
        openNotificationsForm(unit.id);
        e.stopImmediatePropagation();
    };

    schematicDiagramButtonDiv.appendChild(notificationsButton);

    let repeaterDiagramButton = document.createElement('button');
    repeaterDiagramButton.classList.add(...schematicDiagramButtonClasses);
    repeaterDiagramButton.type = 'button';
    repeaterDiagramButton.title = 'VIEW WHEELS DIAGRAM';

    const tyreIcon = document.createElement('svg');
    tyreIcon.className = 'buttonicon tyrebluesvg';

    const repeaterDiagramButtonText = document.createElement('span');
    repeaterDiagramButtonText.innerText = 'Wheels Diagram';

    repeaterDiagramButton.appendChild(tyreIcon);
    repeaterDiagramButton.appendChild(repeaterDiagramButtonText);

    repeaterDiagramButton.onclick = function (e) {
        
        e.stopImmediatePropagation();
        createDiagramView(unit, 'WHEELSDIAGRAM', 'rightpane');
    };

    schematicDiagramButtonDiv.appendChild(repeaterDiagramButton);

    return schematicDiagramButtonDiv;
}

export { createRepeaterDiagram };