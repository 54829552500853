
import { setMainInterval } from "../units";
import { createDiagramView } from "./uds";
import { getSensorHistoryData } from "../../../data/getsensordata";
import { editTrailer } from "../../../data/unitdata";
import { openSensorReport } from "../../../sensorchartreport";
import { convertUnixTime, formatDate } from "../../../utilities";
import { updateReplacedSensorNotifications, getResourceNotifications } from "../../../notifications";

import { SPARE_AXLE } from '../../../global.js';

var gRegisterUnitEvent = false;

function createUnitElement(diagramView, unitNumber, unit) {

    //switch (diagramView) {
    //    case "SENSORVALUESDIAGRAM":
    //        // code block
    //        break;
    //    case "WHEELSDIAGRAM":
    //        // code block
    //        break;
    //    default:
    //    // code block
    //}

    let diagram = "sv";
    if (diagramView === "WHEELSDIAGRAM") diagram = "w";

    const unitDiv = document.createElement('div');
    unitDiv.className = 'unitbox';

    if (unitNumber < unit.unitConfig.length) {
        if (unitNumber !== 0) {
            const unitHeader = createTrailerHeader(diagram, unitNumber, unit.trailers);
            unitDiv.appendChild(unitHeader);
        }
    } else {
        if (unitNumber
        ) {
            const unitHeader = createSpareTyresHeader();
            unitDiv.appendChild(unitHeader);
        }
    }
    
    return unitDiv;
}

function createUnitElementOld() {

    let unitDiv = document.createElement('div');

    return unitDiv;
}

function createSpareTyresHeader() {

    const spareTyresHeader = document.createElement('div');
    spareTyresHeader.className = 'unitbox';

    const header = document.createElement('h6');
    //const headerLabel = document.createElement('div');
    //headerLabel.className = 'edittrailername';

    //const headerText = document.createElement('span');
    header.id = "sparetyres";
    header.innerText = "Spare Tyres";
    //headerLabel.appendChild(headerText);
    //header.appendChild(headerLabel);
    spareTyresHeader.appendChild(header);

    return spareTyresHeader;
}

function createTrailerHeader(diagram, trailerNo, trailers) {

    const trailerHeader = document.createElement('div');    

    const header = document.createElement('h6');
    const headerLabel = document.createElement('div');    

    const linkIcon = document.createElement('svg');
    linkIcon.className = 'linkicon';

    const trailer = trailers.find(t => t.order === trailerNo);
    const headerText = document.createElement('span');

    headerLabel.appendChild(linkIcon);
    headerLabel.appendChild(headerText);

    if (diagram === 'sv' || diagram === 'r') {
        trailerHeader.className = 'unitbox';

        headerText.id = `${diagram}trailer${trailerNo}`;
        //headerText.innerText = `Trailer ${trailerNo}`;
        headerText.innerText = trailer.name;
 
        //const element = document.querySelector('.unitbox h6');
        //const classesToRemove = ['unitbox', 'h6', 'tooltiptext']; // Add all classes you want to remove here
        //classesToRemove.forEach(className => {
        //    element.classList.remove(className);
        //});
        //headerLabel.classList.remove('edittrailername');
    } else {
        trailerHeader.className = 'unitboxedit';
        headerText.id = `${diagram}trailer${trailerNo}`;
        headerText.innerText = trailer.name;

        const tooltipText = document.createElement('span');
        tooltipText.className = 'tooltiptext';
        tooltipText.innerText = "EDIT TRAILER NAME";
        headerLabel.appendChild(tooltipText);
        headerLabel.className = 'edittrailername';
        headerLabel.onclick = function (e) {
            e.stopImmediatePropagation();
            openEditTrailerForm(trailer);
        };
    }

    header.appendChild(headerLabel);
    trailerHeader.appendChild(header);

    return trailerHeader;
}

function openEditTrailerForm(trailer) {

    const editTrailerModal = document.getElementById('editunitmodal');

    //const editTrailer = document.getElementById('editunitform');
    document.getElementById('editunitheader').innerText = "EDIT TRAILER";
    document.getElementById('editunitlabel').innerText = "Trailer Name";

    document.getElementById('eufUnitId').value = "";
    document.getElementById('eufUnitName').value = trailer.name;
   
    const closeSpan = document.getElementById("closeeditunitmodal");
    closeSpan.onclick = function () {
        editTrailerModal.style.display = "none";
    };

    window.onclick = function (e) {
        if (e.target == editunitmodal) {
            editTrailerModal.style.display = "none";
        }
    };

    const btnSubmitEditTrailer = document.getElementById('btneditunitname');   
    btnSubmitEditTrailer.onclick = function (e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        trailer.name = document.getElementById('eufUnitName').value;
        submitEditTrailerForm(trailer);        
    };

    editTrailerModal.style.display = "block";
}

async function submitEditTrailerForm(trailer) {

    document.body.style.cursor = 'wait';

    //document.getElementById('trailer' + trailerNo).innerText = document.getElementById('eufUnitName').value;

    //const trailer = {};
    //trailer.id = updateTrailer.id;
    //trailer.name = updateTrailer.name;
    //trailer.trailerId = 0;
    //trailer.code = updateTrailer.sensorId;
    //trailer.description = `${updateTrailer.unitLinkedTo}-${updateTrailer.order}`;
    //trailer.phoneNo = "";

    const editTrailerName = await editTrailer(trailer); //TODO: SHOULD BE editTrailer, but don't have repeaterdata for now...

    if (editTrailerName) {
        document.getElementById(`svtrailer${trailer.order}`).innerText = trailer.name;
        document.getElementById(`wtrailer${trailer.order}`).innerText = trailer.name;
        document.getElementById("editunitmodal").style.display = "none";
        document.body.style.cursor = 'default';
    } else {
        console.log(`ERROR: Trailer name not updated - ${trailer.name}`);
    }
}

function createTyreNameElement() {

    let sensorNameDiv = document.createElement('div');
    sensorNameDiv.className = "axletyresensor";

    return sensorNameDiv;
}

function createSensorIdElement(sensorId) {

    let sensorIdDiv = document.createElement('div');
    sensorIdDiv.className = 'sensorvalues sensorid';
    sensorIdDiv.innerText = sensorId;

    return sensorIdDiv;
}

function createTyreIdElement(tyreArrayIndex, axleSensors) {

    const tyreId = document.createElement('div');
    tyreId.id = `sv${axleSensors[tyreArrayIndex].sensorName}tyreid`;
    tyreId.className = 'sensorvalues tyreid';
    tyreId.innerText = axleSensors[tyreArrayIndex].tyreId;

    return tyreId;
}

function createTreadDepthElement(tyreArrayIndex, axleSensors) {

    const tyreIdTreadDepth = document.createElement('div');
    tyreIdTreadDepth.id = `sv${axleSensors[tyreArrayIndex].sensorName}treaddepth`;
    tyreIdTreadDepth.className = 'sensorvalues';

    tyreIdTreadDepth.innerText = axleSensors[tyreArrayIndex].treadDepth;

    return tyreIdTreadDepth;
}

function createSensorPressureElement(tyreArrayIndex, axleSensors) {
    
    const sensorPressureDiv = document.createElement('div');
    sensorPressureDiv.id = `sv${axleSensors[tyreArrayIndex].sensorName}pressure`;
    let sensorvaluesdivclasses = ['sensorvalues'];
    if (axleSensors[tyreArrayIndex].pressureLabelColour !== "green")
        sensorvaluesdivclasses = ['sensorvalues', axleSensors[tyreArrayIndex].pressureLabelColour];
    let pressure = axleSensors[tyreArrayIndex].pressureValue;    
    if (global.sensorValuesDiagramDateSelected && axleSensors[tyreArrayIndex].selectedDatePressureValue) {
        sensorvaluesdivclasses = ['sensorvalues', axleSensors[tyreArrayIndex].selectedDatePressureLabelColour];
        pressure = axleSensors[tyreArrayIndex].selectedDatePressureValue;        
    }
    sensorPressureDiv.classList.add(...sensorvaluesdivclasses);
    sensorPressureDiv.innerText = pressure + " bar";    
    return sensorPressureDiv;
}

function createSensorTemperatureElement(tyreArrayIndex, axleSensors) {
    
    let sensorTemperatureDiv = document.createElement('div');
    sensorTemperatureDiv.id = `sv${axleSensors[tyreArrayIndex].sensorName}temperature`;
    let sensorvaluesdivclasses = ['sensorvalues'];
    if (axleSensors[tyreArrayIndex].temperatureLabelColour !== "green")
        sensorvaluesdivclasses = ['sensorvalues', axleSensors[tyreArrayIndex].temperatureLabelColour];
    let temperature = axleSensors[tyreArrayIndex].temperatureValue;
    if (global.sensorValuesDiagramDateSelected && axleSensors[tyreArrayIndex].selectedDateTemperatureValue) {
        sensorvaluesdivclasses = ['sensorvalues', axleSensors[tyreArrayIndex].selectedDateTemperatureLabelColour];
        temperature = axleSensors[tyreArrayIndex].selectedDateTemperatureValue;
    }
    sensorTemperatureDiv.classList.add(...sensorvaluesdivclasses);
    sensorTemperatureDiv.innerHTML = temperature + " &#x2103";    

    return sensorTemperatureDiv;
}

function createSensorVoltageElement(tyreArrayIndex, axleSensors) {
        
    let sensorVoltageDiv = document.createElement('div');
    sensorVoltageDiv.id = `sv${axleSensors[tyreArrayIndex].sensorName}voltage`;
    let sensorvaluesdivclasses = ['sensorvalues'];
    if (axleSensors[tyreArrayIndex].voltageLabelColour !== "green")
        sensorvaluesdivclasses = ['sensorvalues', axleSensors[tyreArrayIndex].voltageLabelColour];
    let voltage = axleSensors[tyreArrayIndex].voltageValue;
    if (global.sensorValuesDiagramDateSelected && axleSensors[tyreArrayIndex].selectedDateVoltageValue) {
        sensorvaluesdivclasses = ['sensorvalues', axleSensors[tyreArrayIndex].selectedDateVoltageLabelColour];
        voltage = axleSensors[tyreArrayIndex].selectedDateVoltageValue;
    }
    sensorVoltageDiv.classList.add(...sensorvaluesdivclasses);
    sensorVoltageDiv.innerHTML = voltage + " V";

    return sensorVoltageDiv;
}

function createSensorNoSignalElement() {

    let sensorNoSignalDiv = document.createElement('div');
    let sensorvaluesdivclasses = ['sensorvalues', 'blue'];    
    if (global.sensorValuesDiagramDateSelected) {
        sensorvaluesdivclasses = ['sensorvalues', 'blue'];
    }
    sensorNoSignalDiv.classList.add(...sensorvaluesdivclasses);
    sensorNoSignalDiv.innerText = "Signal";

    return sensorNoSignalDiv;
}

function openTyreContextMenu(unit, wheel) {

    const menu = document.getElementById('tyrecontextmenu');
    const closeTyreMenu = document.getElementById('closetyremenu');
    const contextMenuList = document.getElementById('tyrecontextmenu');
    menu.classList.add('show');

    closeTyreMenu.style.display = "block";

    closeTyreMenu.addEventListener('click', () => {
        menu.classList.remove('show');
        closeTyreMenu.style.display = "none";
    });

    contextMenuList.addEventListener('click', async function clickHandler(ev) {
        ev.currentTarget.removeEventListener(ev.type, clickHandler);
        if (ev.target.tagName === 'LI') {
            ev.stopImmediatePropagation();
            menu.classList.remove('show');
            closeTyreMenu.style.display = "none";
            
            switch (ev.target.innerText) {
                case 'Edit Tyre Info':
                        openEditTyreIdForm(unit, wheel);
                    break;
                case 'Sensor Report':
                    document.body.style.cursor = 'none';
                    document.getElementById("loadingBoxText").innerText = "Collecting Report Data... ";
                    $("#loadingBox").modal({
                        backdrop: "static", 
                        keyboard: false,
                        show: true 
                    });

                    let date = new Date();
                    date.setDate(date.getDate() - 7); //week ago - subtract 7 days from the current date                   

                    if (typeof wheel === 'object' && wheel !== null && 'sensorName' in wheel) {
                        const axle = wheel.sensorName.slice(1, 3);
                        if (axle === SPARE_AXLE.toString()) {
                            const s = unit.spareSensors.findIndex(sensor => sensor.sensorName === wheel.sensorName);
                            wheel.manufacturersRecommendedPressure = unit.spareSensors[s]?.manufacturersRecommendedPressure || 3.5;
                            wheel.percentageDeviationAllowed = unit.spareSensors[s]?.percentageDeviationAllowed || 20;
                            wheel.minPressureValue = unit.spareSensors[s]?.minPressureValue;
                            wheel.maxPressureValue = unit.spareSensors[s]?.maxPressureValue;
                            wheel.maxTemperatureValue = unit.spareSensors[s]?.maxTemperatureValue;
                            wheel.minVoltageValue = unit.spareSensors[s]?.minVoltageValue;
                        }
                    } else if (typeof wheel === 'string') {
                        const axle = wheel.slice(1, 3);
                        if (axle === SPARE_AXLE.toString()) {
                            const s = unit.spareSensors.findIndex(sensor => sensor.sensorName === wheel.sensorName);
                            wheel = {
                                manufacturersRecommendedPressure: unit.spareSensors[s]?.manufacturersRecommendedPressure || 3.5,
                                percentageDeviationAllowed: unit.spareSensors[s]?.percentageDeviationAllowed || 20,
                                minPressureValue: unit.spareSensors[s].minPressureValue,
                                maxPressureValue: unit.spareSensors[s].maxPressureValue,
                                maxTemperatureValue: unit.spareSensors[s].maxTemperatureValue,
                                minVoltageValue: unit.spareSensors[s].minVoltageValue
                            };
                        }
                    }

                    const sensorData = await getSensorHistoryData(unit, wheel, formatDate(date));
                    
                    $("#loadingBox").modal("hide");
                    openSensorReport(unit, sensorData);

                    break;
                case 'Action Log':
                        gRegisterUnitEvent = false;
                        openActionLogForm(unit, wheel);
                    break;
            }            
        }        
    }, false);
}

function openEditTyreIdForm(unit, tyre) {

    let editTyreInfo = document.getElementById('edittyreinfomodal');    

    let span = document.getElementById("closeedittyreidmodal");
    span.onclick = function () {
        editTyreInfo.style.display = "none";
    };

    window.onclick = function (event) {
        if (event.target == editTyreInfo) {
            editTyreInfo.style.display = "none";
        }
    };

    let edittyreidform = document.getElementById('edittyreidform');
    edittyreidform.reset();

    document.getElementById('etfSelectedUnitId').value = unit.id;

    document.getElementById('etfUnitId').value = unit.id;

    document.getElementById('etfSensorName').value = tyre.sensorName;
    const tyreInfo = tyre.sensorName.slice(1, 3) === SPARE_AXLE.toString() ? unit.spareSensors.find(s => s.sensorName === tyre.sensorName) : unit.wheelSensors.find(s => s.sensorName === tyre.sensorName);
    
    document.getElementById('etfTyreId').value = tyreInfo.tyreId;
    //document.getElementById('etfTyreTreadDepth').value = parseFloat(tyre.treadDepth.slice(0, -3)) > 0 ? parseFloat(tyre.treadDepth.slice(0, -3)) : 0;

    const treadDepthValue = tyreInfo.treadDepth ? parseFloat(tyreInfo.treadDepth.slice(0, -3)) : 0;
    document.getElementById('etfTyreTreadDepth').value = treadDepthValue > 0 ? treadDepthValue : 0;

    document.getElementById("edittyreinfomodal").style.display = "block";

    edittyreidform.addEventListener('submit', function (e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        submitEditTyreIdForm(unit);
    });
}

function submitEditTyreIdForm(unit) {

    document.getElementById("loadingBoxText").innerHTML = "Updating tyre data... ";
    $("#loadingBox").modal({
        backdrop: "static", //remove ability to close modal with click
        keyboard: false, //remove option to close with keyboard
        show: true //Display loader!
    });

    const editedTyreId = {}; //unitId, sensorName, newSensorName
    const sensorName = document.getElementById('etfSensorName').value;
    const tyreId = document.getElementById('etfTyreId').value;
    const treadDepth = document.getElementById('etfTyreTreadDepth').value; 
    editedTyreId.selectedUnitId = document.getElementById('etfSelectedUnitId').value;
    editedTyreId.id = document.getElementById('etfUnitId').value;
    editedTyreId.sensorName = `P${sensorName}`;
    editedTyreId.tyreId = tyreId;
    //if (document.getElementById(`sv${sensorName}tyreid`)) document.getElementById(`sv${sensorName}tyreid`).innerText = tyreId;
    editedTyreId.treadDepth = treadDepth;
    //if (document.getElementById(`sv${sensorName}treaddepth`)) document.getElementById(`sv${sensorName}treaddepth`).innerText = `${treadDepth} mm`;
    const tyre = sensorName.slice(1, 3) === SPARE_AXLE.toString() ? unit.spareSensors.find(s => s.sensorName === sensorName) : unit.wheelSensors.find(s => s.sensorName === sensorName);
        //unit.wheelSensors.find(s => s.sensorName === sensorName);
    
    tyre.tyreId = tyreId;
    tyre.treadDepth = `${treadDepth} mm`;
    createDiagramView(unit, 'SENSORVALUESDIAGRAM', 'middlepane');
    editTyreId(editedTyreId); 

    document.getElementById("edittyreinfomodal").style.display = "none";
    return false;
}

async function editTyreId(editedTyreId) {

    try {
        let flags = wialon.item.Item.dataFlag.base |
            wialon.item.Unit.dataFlag.sensors |
            wialon.item.Unit.dataFlag.lastMessage;
        global.session.loadLibrary("unitSensors");

        global.session.updateDataFlags(
            [{ type: "type", data: "avl_unit", flags: flags, mode: 0 }],
            async function (err) {
                if (err) {
                    console.log("pressuretrackJS: editTyreID - API ERROR " + err + " (" + wialon.core.Errors.getErrorText(err)) + ")";
                    return;
                }
                let unit = global.session.getItem(editedTyreId.id);
                let sensors = unit.getSensors();
                for (let s in sensors) {
                    if (sensors[s].n === editedTyreId.sensorName) {

                        sensors[s].d = `Tyre Id: ${editedTyreId.tyreId}; Tread Depth: ${editedTyreId.treadDepth} mm`;
                       
                        let tyreIdUpdated = await updateSensor(unit, sensors[s]);

                        if (tyreIdUpdated) {
                            global.selectedUnitId = editedTyreId.id;
                            $("#loadingBox").modal("hide");                            
                            return; 
                        }
                    }
                }
            }
        );
    } catch (e) {
        console.error('unitdiagramJS: editTyreId - ERROR: ', e);
        setMainInterval(global.units);
    }
}

async function updateSensor(unit, sensor) {
    return await new Promise((resolve, reject) => {
        unit.updateSensor(sensor,
            (error, data) => {
                if (error) {
                    console.log("pressuretrackJS: updateSensor - API ERROR " + error + " (" + wialon.core.Errors.getErrorText(error)) + ")";
                    reject({ type: 'API_ERROR', error: error });
                    return;
                } else {
                    console.log("pressuretrackJS: updateSensor - SUCCESS (" + data.n + " sensor updated)");
                    resolve(true);
                }
            }
        );
    });
}

function openNewSensorForm(unit, tyre) {

    let newsensormodal = document.getElementById('newsensormodal');

    let span = document.getElementById("closenewsensormodal");
    span.onclick = function () {
        newsensormodal.style.display = "none";
    };

    window.onclick = function (event) {
        if (event.target == newsensormodal) {
            newsensormodal.style.display = "none";
        }
    };

    let newsensorform = document.getElementById('newsensorform');
    newsensorform.addEventListener('submit', function (e) {
        e.preventDefault();
        e.stopPropagation();
        submitAddNewSensorForm();
    });

    document.getElementById('nsfUnitId').value = unit.id;
    document.getElementById('nsfUnitMobileNo').value = unit.mobileNo;
    document.getElementById('nsfWheelId').value = tyre; 

    document.getElementById("newsensormodal").style.display = "block";
}

function openReplaceSensorForm(unit, tyre) {

    let replacesensormodal = document.getElementById('replacesensormodal');

    let span = document.getElementById("closereplacesensormodal");
    span.onclick = function () {
        replacesensormodal.style.display = "none";
    };

    window.onclick = function (event) {
        if (event.target == replacesensormodal) {
            replacesensormodal.style.display = "none";
        }
    };

    let replacesensorform = document.getElementById('replacesensorform');
    replacesensorform.addEventListener('submit', function (e) {
        e.preventDefault();
        e.stopPropagation();
        submitReplaceSensorForm();
    });

    document.getElementById('rsfUnitId').value = unit.id;
    document.getElementById('rsfUnitMobileNo').value = unit.mobileNo;
    document.getElementById('rsfWheelId').value = tyre.sensorName;
    document.getElementById('rsfCurrentSensorId').value = tyre.sensorName.slice(tyre.sensorName.length - 8);

    document.getElementById("replacesensormodal").style.display = "block";
}

function smsDevice(sensor) {

    let mobileNo = sensor.mobileNo;
    let axleWheel = sensor.wheelId.slice(0, 2);
    let smsText = "*000000,301," + axleWheel + "," + sensor.newSensorId + "#";

    let flags = wialon.item.User.userFlag.canSendSMS;
    global.session.updateDataFlags( // load items to current session
        [{ type: "type", data: "user", flags: flags, mode: 0 }],
        function (code) { // updateDataFlags callback
            if (code) { console.log(wialon.core.Errors.getErrorText(code)); return; } // exit if error code 
            global.session.sendSms(mobileNo, smsText, (function (error) {
                if (error) {
                    alert("Billing Error - The sms sent to the device was unsuccessful.");
                    console.log("SMS NOT send! " + wialon.core.Errors.getErrorText(error));
                    return;
                } // exit if error code
                console.log("SMS - Success");
            }));
        }
    );
}

function submitAddNewSensorForm() {

    //document.body.style.cursor = 'wait';
    //$('#container').overlayMask();
    document.getElementById("loadingBoxText").innerHTML = "Updating sensor data... ";
    $("#loadingBox").modal({
        backdrop: "static", //remove ability to close modal with click
        keyboard: false, //remove option to close with keyboard
        show: true //Display loader!
    });

    updateSensorDataFlags();

    let newSensor = {};
    newSensor.id = document.getElementById('nsfUnitId').value;
    newSensor.wheelId = document.getElementById('nsfWheelId').value;
    newSensor.newSensorId = document.getElementById('nsfNewSensorId').value;
    newSensor.mobileNo = document.getElementById('nsfUnitMobileNo').value;

    smsDevice(newSensor);
    addNewSensorMetrics(newSensor);

    let eventDescription = newSensor.id + "-" + newSensor.wheelId + ": New sensor '" + newSensor.newSensorId + "' added.'";
    let prms = { "date": global.session.getServerTime(), "x": 0, "y": 0, "description": eventDescription, "violation": 0, "itemId": parseInt(newSensor.id) };
    let remote = wialon.core.Remote.getInstance();
    remote.remoteCall('unit/registry_custom_event', prms);

    global.selectedUnitId = newSensor.id;
    //getUnits();

    document.getElementById("newsensormodal").style.display = "none";

    document.getElementById("newsensorform").reset();

    document.body.style.cursor = 'default';
    $("#loadingBox").modal("hide");
    return false;
}

async function updateSensorDataFlags() {

    let flags = wialon.item.Item.dataFlag.base |
        wialon.item.Unit.dataFlag.sensors |
        wialon.item.Unit.dataFlag.lastMessage;

    global.session.loadLibrary("unitSensors");

    return new Promise((resolve, reject) => {
        global.session.updateDataFlags(
            [{ type: "type", data: "avl_unit", flags: flags, mode: 0 }],
            (err) => {
                if (err) {
                    console.log("pressuretrackJS: updateSensorDataFlags - API ERROR " + err + " (" + wialon.core.Errors.getErrorText(err)) + ")";
                    reject({ type: 'API_ERROR', error: err });
                    return false;
                } else {
                    console.log("pressuretrackJS: updateSensorDataFlags - SUCCESS (Sensor Data Flags updated)");
                    resolve(true);
                }
            }
        );
    });
}

function submitReplaceSensorForm() {

    //document.body.style.cursor = 'wait';
    //$('#container').overlayMask();
    document.getElementById("loadingBoxText").innerHTML = "Replacing sensor... ";
    $("#loadingBox").modal({
        backdrop: "static", //remove ability to close modal with click
        keyboard: false, //remove option to close with keyboard
        show: true //Display loader!
    });

    updateSensorDataFlags();

    let replacedSensor = {};
    replacedSensor.id = document.getElementById('rsfUnitId').value;
    replacedSensor.wheelId = document.getElementById('rsfWheelId').value;
    replacedSensor.currentSensorId = document.getElementById('rsfCurrentSensorId').value;
    replacedSensor.newSensorId = document.getElementById('rsfNewSensorId').value;
    replacedSensor.mobileNo = document.getElementById('rsfUnitMobileNo').value;

    smsDevice(replacedSensor);
    updateSensorMetrics(replacedSensor);
    updateReplacedSensorNotifications(replacedSensor);

    let eventDescription = replacedSensor.id + "-" + replacedSensor.wheelId + ": Replaced sensor '" + replacedSensor.currentSensorId + "' with sensor '" + replacedSensor.newSensorId + "'";
    let prms = { "date": global.session.getServerTime(), "x": 0, "y": 0, "description": eventDescription, "violation": 0, "itemId": parseInt(replacedSensor.id) };
    let remote = wialon.core.Remote.getInstance();
    remote.remoteCall('unit/registry_custom_event', prms);

    global.selectedUnitId = replacedSensor.id;
    //getUnits();

    document.getElementById("replacesensormodal").style.display = "none";

    document.getElementById("replacesensorform").reset();

    //$('#container').overlayMask('hide');
    document.body.style.cursor = 'default';
    $("#loadingBox").modal("hide");
    return false;
}

function updateSensorName(unitId, sensorName, newSensorName) {

    let unit = global.session.getItem(unitId); //"t11_pressure*const0.0689475729"
    var sensors = unit.getSensors(); // get unit's sensors
    for (let s in sensors) {
        if (sensors[s].n === sensorName) {
            sensors[s].n = newSensorName;
            unit.updateSensor(sensors[s],
                function (error, data) { // delete sensor callback
                    if (error) console.log("pressuretrackJS: updateSensorName - API ERROR " + error + " (" + wialon.core.Errors.getErrorText(error)) + ")";
                    else {
                        console.log("pressuretrackJS: updateSensorName - SUCCESS (" + data.n + " sensor updated)"); //getSensors();
                    }
                }
            );
            break;
        }
    }
}

function updateSensorMetrics(sensor) {

    let pressureSensorName = "P" + sensor.wheelId;
    let newPressureSensorName = pressureSensorName.slice(0, 3) + sensor.newSensorId;
    updateSensorName(sensor.id, pressureSensorName, newPressureSensorName);

    let temperatureSensorName = "T" + sensor.wheelId;
    let newTemperatureSensorName = temperatureSensorName.slice(0, 3) + sensor.newSensorId;
    updateSensorName(sensor.id, temperatureSensorName, newTemperatureSensorName);

    let voltageSensorName = "V" + sensor.wheelId; //_sensor_volt
    let newVoltageSensorName = voltageSensorName.slice(0, 3) + sensor.newSensorId;
    updateSensorName(sensor.id, voltageSensorName, newVoltageSensorName);
}

function createStandardSensorFromCurrent(replacedSensor) {  //Create Sensor

    let name = replacedSensor.currentSensorId;
    let type = "custom";
    let param = "tmp_1";

    let obj = { n: name, d: "", f: 0, c: "", vt: 1, vs: 0, tbl: [], m: "", p: param, t: type };
    global.session.getItem(replacedSensor.id).createSensor(obj,
        function (code, data) { // create sensor callback
            if (code) msg(wialon.core.Errors.getErrorText(code));
            else {
                console.log(data.n + " sensor created successfully");
            }
        }
    );
}

function openActionLogForm(unit, wheel) {

    document.body.style.cursor = 'wait';

    let actionlogmodal = document.getElementById('actionlogmodal');

    let span = document.getElementById("closeactionlogmodal");
    span.onclick = function (e) {
        actionlogmodal.style.display = "none";
        e.stopImmediatePropagation();
        return false;
    };

    //window.onclick = function (event) {
    //if (event.target == actionlogmodal) {
    //    actionlogmodal.style.display = "none";
    //    event.stopImmediatePropagation();
    //    //return false;
    //}
    //return false;
    //};

    let actionLogSubmitButton = document.getElementById('alfsubmitbtn'); 
    actionLogSubmitButton.onclick = (e) => {
        e.preventDefault();
        if (!gRegisterUnitEvent)
            submitActionLogForm();
        return false;
    };

    //let actionLogSubmitButton = document.getElementById('alfsubmitbtn');
    //actionLogSubmitButton.addEventListener('click', function actionLogHandler(e) {
    //    actionLogSubmitButton.removeEventListener('click', actionLogHandler);
    //    if (!gRegisterUnitEvent)
    //        submitActionLogForm();
    //    return false;
    //});

    const axleNo = parseInt(wheel.sensorName.slice(1,3));
    const tyreNo = wheel.sensorName.slice(0,1);
    const sensorName = wheel.sensorName;

    let wUnit = global.session.getItem(unit.id);

    let to = global.session.getServerTime(); 
    let from = to - 3600 * 24 * 90; 

    let ml = global.session.getMessagesLoader();

    //ml.loadInterval(unit.id, from, to, 1536, 65280, 0xffffffff = 4294967295 (max),
    ml.loadInterval(unit.id, from, to, 0x0600, 0xff0, 0xffffffff, // load messages for given time interval
        function (code, result) { // loadInterval callback
            if (code) { console.log(wialon.core.Errors.getErrorText(code)); return; } // exit if error code

            let arrHeader = new Array();	// array for header.    
            arrHeader = ['', 'Date', 'Action'];

            let actionlog = document.getElementById('actionlog');
            actionlog.innerText = "";

            let tbody = actionlog.createTBody();
            let itemNo = 0;
            for (let i = result.messages.length - 1; i >= 0; i--) {
                let str = result.messages[i].et;
                let colonIndex = str.indexOf(":");
                if (unit.name + "-" + wheel.sensorName === result.messages[i].et.slice(0, colonIndex)) {

                    itemNo++;
                    let date = convertUnixTime(result.messages[i].t);
                    let actionTaken = result.messages[i].et.slice(colonIndex + +1); //+4 (sensorId) +1 = +9

                    let tr = tbody.insertRow();

                    for (let c = 0; c < arrHeader.length; c++) {
                        let cell = tr.insertCell();
                        switch (c) {
                            case 0:
                                cell.textContent = itemNo;
                                break;
                            case 1:
                                cell.textContent = date.toString().slice(0, -38);
                                break;
                            case 2:
                                cell.textContent = actionTaken;
                                break;
                        }
                    }
                }

                //let wheelIdColumnIndex = str.indexOf("Sensor Id:");
                //if (wheel.sensorName === result.messages[i].et.slice(wheelIdColumnIndex + 12, wheelIdColumnIndex + 22) || wheel.sensorName === result.messages[i].et.slice(-12, -2)) { //"tyreId === result.messages[i].et.slice(-12, -2)" - old notification message, prior to Feb 2021.  Take out after end May 2021 (after 3 months)

                //    itemNo++;
                //    let date = convertUnixTime(result.messages[i].t);
                //    let actionTaken = result.messages[i].et;

                //    let tr = tbody.insertRow();

                //    for (let c = 0; c < arrHeader.length; c++) {
                //        let cell = tr.insertCell();
                //        switch (c) {
                //            case 0:
                //                cell.textContent = itemNo;
                //                break;
                //            case 1:
                //                cell.textContent = date.toString().slice(0, -38);
                //                break;
                //            case 2:
                //                cell.textContent = actionTaken;
                //                break;
                //        }
                //    }
                //}
            }

            let theader = actionlog.createTHead();

            let headerRow = theader.insertRow();
            for (let key of arrHeader) {
                let th = document.createElement('th'); // create table headers
                let text = document.createTextNode(key);
                th.appendChild(text);
                headerRow.appendChild(th);
            }

            document.getElementById('alfUnitId').value = unit.id;
            document.getElementById('alfWheelId').value = wheel.sensorName;
            //document.getElementById("alfUnitImg").src = wUnit.getIconUrl(64);
            document.getElementById("alfUnitName").value = unit.name;
            
            let trailerName = "";
            if (parseInt(wheel.unitNumber) > 0) trailerName = `Trailer ${wheel.unitNumber}`
            document.getElementById("alfTrailerName").value = trailerName;
            document.getElementById("alfTrailerName").removeAttribute('type');

            if (axleNo !== SPARE_AXLE) {
                document.getElementById("alfAxleTyreDescription").value = "A" + axleNo + "-T" + tyreNo;
            } else {

                document.getElementById("alfAxleTyreDescription").value = "Spare " + tyreNo;
            }

            document.getElementById("actionlogmodal").style.display = "block";
            document.body.style.cursor = 'default';
        }
    );    
}

async function submitActionLogForm() {

    document.body.style.cursor = 'wait';
    //$('#container').overlayMask();

    const unitId = document.getElementById('alfUnitId').value;
    const unitName = document.getElementById('alfUnitName').value;
    const wheelId = document.getElementById('alfWheelId').value;
    const action = document.getElementById('alfAction').value;

    const eventDescription = unitName + "-" + wheelId + ":" + action;
    let prms = { "date": global.session.getServerTime(), "x": 0, "y": 0, "description": eventDescription, "violation": 0, "itemId": parseInt(unitId) };
    gRegisterUnitEvent = await registerUnitEvent(prms);

    document.getElementById("actionlogmodal").style.display = "none";

    document.getElementById("actionlogform").reset();

    //$('#container').overlayMask('hide');
    document.body.style.cursor = 'default';

    return false;
}

function registerUnitEvent(prms) {

    gRegisterUnitEvent = true;
    let remote = wialon.core.Remote.getInstance();

    return new Promise((resolve, reject) => {
        remote.remoteCall('unit/registry_custom_event', prms,
            (error, result) => {
                if (error) {
                    reject({ type: 'API_ERROR', code: error, fullError: result });
                    return false;
                }
                resolve(true);
            },
        );
    });
}

export { openTyreContextMenu, createUnitElement, createUnitElementOld, createTrailerHeader, createSensorNoSignalElement, createTyreNameElement, createTyreIdElement, createTreadDepthElement, createSensorIdElement, createSensorPressureElement, createSensorTemperatureElement, createSensorVoltageElement };