import { setMainInterval, displayMainPane, createMainUnitContainer } from "./units";
import { displayStatusSummary } from "./unitdiagrams/unitstatusesdiagrams";
import { displayDetailPage } from "../detailpage";

var gEditUnitGroup = false;

//log message
function logmsg(text) { $("#log").empty().prepend(text + "<br/>"); }

export function registerUnitsGroupsEvents(units) {

    const unitsListButtonMain = document.getElementById('unitslistbuttonmain');
    unitsListButtonMain.addEventListener('click', function clickHandler(e) {
        //e.stopImmediatePropagation();

        global.mainUnitsListSelected = true;
        global.selectedUnitId = 0;
        global.selectedGroupId = 0;
        global.selectedGroupUnits = [];

        document.getElementById('rbtnTemperature').disabled = false;
        document.getElementById('rbtnVoltage').disabled = false;

        displayMainPane(units);
    });

    const unitGroupsButtonMain = document.getElementById('unitgroupsbuttonmain');
    unitGroupsButtonMain.addEventListener('click', function clickHandler(e) {
        //e.stopImmediatePropagation();

        global.mainUnitsListSelected = false;
        global.selectedUnitId = 0;
        global.selectedGroupId = 0;
        global.selectedGroupUnits = [];

        document.getElementById('rbtnPressure').checked = true;
        global.selectedMetric = 'PRESSURE';

        document.getElementById('rbtnTemperature').disabled = true;
        document.getElementById('rbtnVoltage').disabled = true;

        displayMainPane(units);
    });

    const unitGroupsMenuMain = document.getElementById('unitgroupsmenumain');
    unitGroupsMenuMain.addEventListener('click', function clickHandler(e) {
        if (e.target.tagName === 'LI') {
            switch (e.target.id) {
                case 'newunitsgroupmain':
                    openNewUnitsGroupForm(units);
                    break;
                case 'editdeleteunitsgroupmain':
                    openEditDeleteUnitsGroupForm(units);
                    break;
                case 'assignunitsgroupmain':
                    openAssignUnitsGroupForm(units);
                    break;
            }
        }
    }, false);

    const unitgroupsmenu = document.getElementById('unitgroupsmenu');
    unitgroupsmenu.addEventListener('click', function clickHandler(event) {
        if (event.target.tagName === 'LI') {
            switch (event.target.id) {
                case 'newunitsgroup':
                    openNewUnitsGroupForm(units);
                    break;
                case 'editdeleteunitsgroup':
                    openEditDeleteUnitsGroupForm(units);
                    break;
                case 'assignunitsgroup':
                    openAssignUnitsGroupForm();
                    break;
            }
        }
    }, false);

    const unitslistbutton = document.getElementById('unitslistbutton');
    unitslistbutton.addEventListener('click', function clickHandler(e) {
        //e.stopImmediatePropagation();
        global.showMainPane = true;
        global.mainUnitsListSelected = true;
        global.selectedUnitId = 0;
        global.selectedGroupId = 0;
        global.selectedGroupUnits = [];

        
        displayMainPane(units);
    });

    const unitGroupsButton = document.getElementById('unitgroupsbutton');
    unitGroupsButton.addEventListener('click', function clickHandler(e) {
        //e.stopImmediatePropagation();
        
        global.mainUnitsListSelected = false;
        global.selectedUnitId = 0;
        global.selectedGroupId = 0;
        global.selectedGroupUnits = [];

        document.getElementById('rbtnTemperature').disabled = true;
        document.getElementById('rbtnVoltage').disabled = true;

        document.getElementById('rbtnPressure').checked = true;
        global.selectedMetric = 'PRESSURE';

        displayMainPane(units);
    });
}

export function getUnitGroupName() {
    let unitGroups = global.unitGroups;
    let unitGroupId = global.selectedGroupId;
    let unitGroupName = "";
    if (unitGroups.length > 0) {
        for (let i = 0; i < unitGroups.length; i++) {
            if (unitGroups[i].getId() === unitGroupId) {
                unitGroupName = unitGroups[i].getName();
                break;
            }
        }
    } else {
        return "";
    }
    return unitGroupName;
}

export async function displayMainUnitsGroupsList(units) {

    try {
        let unitGroups = await getUnitGroups();
        global.unitGroups = unitGroups;
        global.units = units;
        
        let unitsGroupsListMain = document.getElementById('unitslistmain');
        while (unitsGroupsListMain.hasChildNodes()) {
            unitsGroupsListMain.removeChild(unitsGroupsListMain.lastChild);
        }

        let unitsGroupsListMainFragment = document.createDocumentFragment();
        let unitsGroupsListMainContainerDiv = document.createElement('div');
        unitsGroupsListMainContainerDiv.id = 'unitgroupslistcontainer';
        unitsGroupsListMainContainerDiv.className = 'unitslistcontainer';

        unitsGroupsListMainFragment.appendChild(unitsGroupsListMainContainerDiv);
        unitsGroupsListMain.appendChild(unitsGroupsListMainFragment);

        let previousSelectedGroup = false;        
        let selectedGroupId = global.selectedGroupId;
        for (let i = 0; i < unitGroups.length; i++) {
            let unitGroupDiv = document.createElement('div');
            unitGroupDiv.className = 'unitgroup';
            let unitGroupId = unitGroups[i].getId();
            unitGroupDiv.id = unitGroupId;
            unitGroupDiv.show = false;
            let groupUnits = unitGroups[i].getUnits();
            unitGroupDiv.innerHTML = `${unitGroups[i].getName()}`;
            unitsGroupsListMainContainerDiv.appendChild(unitGroupDiv);

            if (selectedGroupId > 0 && selectedGroupId === unitGroupId ) {
                unitGroupDiv.show = true;
                let selectedGroupUnits = [];
                for (const groupUnit of groupUnits) {
                    for (let u = 0; u < units.length; u++) {
                        if (groupUnit === units[u].id) {
                            selectedGroupUnits.push(units[u]);
                            let unitDiv = createMainUnitContainer(units[u], units, true);
                            unitGroupDiv.appendChild(unitDiv);
                            unitDiv.onclick = function (event) {
                                event.stopImmediatePropagation();

                                global.selectedUnitId = groupUnit;
                                global.selectedGroupUnits = selectedGroupUnits;

                                displayDetailPage(units[u]);
                            };
                        }
                    }
                }
                global.selectedGroupUnits = selectedGroupUnits;
                displayStatusSummary(selectedGroupUnits,);  
            }
 
            unitGroupDiv.onclick = function (e) {
                e.stopImmediatePropagation();
                //document.getElementById('rbtnPressure').checked = true;
                document.getElementById('rbtnTemperature').disabled = false;
                document.getElementById('rbtnVoltage').disabled = false;
                selectedGroupId = global.selectedGroupId;
                if (selectedGroupId && selectedGroupId > 0) {
                    let selectedUnitGroupDiv = document.getElementById(selectedGroupId);
                    if (selectedUnitGroupDiv.id === unitGroupDiv.id && selectedUnitGroupDiv.show) {
                        previousSelectedGroup = true;
                    } else {
                        previousSelectedGroup = false;
                    }
                    if (selectedUnitGroupDiv.show) {
                        selectedUnitGroupDiv.show = false;
                        while (selectedUnitGroupDiv.childNodes.length > 1) {
                            selectedUnitGroupDiv.removeChild(selectedUnitGroupDiv.lastChild);
                        }
                    }
                } else { previousSelectedGroup = false;}

                if (!unitGroupDiv.show && groupUnits.length && !previousSelectedGroup) {
                    unitGroupDiv.show = true;
                    selectedGroupId = unitGroups[i].getId();
                    //let selectedGroupName = unitGroups[i].getName();
                    global.selectedGroupId = selectedGroupId;
                    let selectedGroupUnits = [];
                    for (const groupUnit of groupUnits) {
                        for (let u = 0; u < units.length; u++) {
                            if (groupUnit === units[u].id) {
                                selectedGroupUnits.push(units[u]);
                                let unitDiv = createMainUnitContainer(units[u], units, true);
                                unitGroupDiv.appendChild(unitDiv);
                                unitDiv.onclick = function (event) {
                                    event.stopImmediatePropagation();

                                    global.selectedUnitId = groupUnit;
                                    global.selectedGroupUnits = selectedGroupUnits;

                                    //displayUnitInfoView(parseInt(units[u].id), units);
                                    //displayDiagramViews(units[u]);
                                    displayDetailPage(units[u]);
                                };
                            }
                        }
                    }
                    global.selectedGroupUnits = selectedGroupUnits;
                    displayStatusSummary(selectedGroupUnits);
                } else {
                    document.getElementById('rbtnTemperature').disabled = true;
                    document.getElementById('rbtnVoltage').disabled = true;

                    document.getElementById('rbtnPressure').checked = true;
                    global.selectedMetric = 'PRESSURE';

                    global.selectedGroupId = 0;
                    global.selectedGroupUnits = [];

                    displayStatusSummary([]); //all groups collapsed - total of all groups
                }
            }
        }
        if (global.selectedMetric === 'PRESSURE')
        displayStatusSummary([]);
    }
    catch (e) {
        console.error("unitgroupsJS - displayMainUnitsGroupsList: " + e.error);
        setMainInterval(global.units);
    }
}

function openNewUnitsGroupForm(units) {

    document.body.style.cursor = 'wait';
    $('#container').overlayMask();

    let newgroupmodal = document.getElementById('newgroupmodal');

    let span = document.getElementById("closenewgroupmodal");
    span.onclick = function () {
        newgroupmodal.style.display = "none";
    };

    window.onclick = function (event) {
        if (event.target == newgroupmodal) {
            newgroupmodal.style.display = "none";
        }
    };

    $('#container').overlayMask('hide');
    document.body.style.cursor = 'default';

    let btnsubmitnewgroup = document.getElementById('btnsubmitnewgroup');    
    btnsubmitnewgroup.addEventListener('click', function (e) {
        e.stopImmediatePropagation();
        document.body.style.cursor = 'wait';
        submitNewUnitsGroupForm(document.getElementById('groupname').value);
        setTimeout(function () {            
            document.getElementById('newgroupmodal').style.display = 'none';
  
            displayMainPane(units);
            document.body.style.cursor = 'default';            
        }, 1850);    
    }, false);

    document.getElementById("newgroupform").reset();
    document.getElementById("newgroupmodal").style.display = "block";

    document.body.style.cursor = 'default';
    $("#loadingBox").modal("hide");
}

async function submitNewUnitsGroupForm(groupName) {
    try {

        const session = global.session;
        const userId = session.getCurrUser().getId();

        const prms = { "creatorId": userId, "name": groupName, "dataFlags": 1 };
        const remote = wialon.core.Remote.getInstance();
        remote.remoteCall('core/create_unit_group', prms); 

        //let flag = wialon.item.UnitGroup.base.dataFlag.base;
        
        //let unitGroupCreated = await session.createUnitGroup(userId, groupName, flag,
        //    function (error, data) { 
        //        if (error) {
        //            console.log("unitGroupJS: submitNewUnitsGroupForm - ERROR " + error + " (" + wialon.core.Errors.getErrorText(error)) + ")";
        //            return false;
        //        }
        //        else {
        //            console.log("unitGroupJS: submitNewUnitsGroupForm - SUCCESS (" + data + " new group added.)");
        //            return true;
        //        }
        //    }
        //);

        //return unitGroupCreated;
    } catch (e) {
        console.error('unitgroupsJS: submitNewUnitsGroupForm - ERROR: ', e);
        setMainInterval(global.units);
    }
}

//try {
//    let session = global.session;
//    let group = session.getItem(selectedGroupId);
//    let flags = wialon.item.Item.accessFlag.editSubItems;

//    return await new Promise((resolve, reject) => {
//        session.updateDataFlags(
//            [{ type: "type", data: "avl_unit_group", flags: flags, mode: 1 }], // Items specification
//            (error) => {
//                if (error) {
//                    console.log("unitgroupsJS: submitAssignUnitsGroupForm - API ERROR " + error + " #" + wialon.core.Errors.getErrorText(error));
//                    reject({ type: 'API_ERROR', error: error });
//                    return;
//                }
//                group.updateUnits(units);
//                resolve(true);
//            });
//    });
//} catch (e) {
//    console.error("unitgroupsJS: getUnitGroups - ERROR ", e);
//}

function openEditDeleteUnitsGroupForm(units) {

    document.body.style.cursor = 'wait';
    $('#container').overlayMask();

    let editDeleteGroupModal = document.getElementById('editdeletegroupmodal');

    let span = document.getElementById("closeeditdeletegroupmodal");
    span.onclick = function () {
        editDeleteGroupModal.style.display = "none";
    };

    window.onclick = function (event) {
        if (event.target == editDeleteGroupModal) {
            editDeleteGroupModal.style.display = "none";
        }
    };

    gEditUnitGroup = true;
    populateUnitGroupsSelect(gEditUnitGroup);

    let selectedGroupId = 0;
    let groupName = "";
    let unitGroupsSelect = document.getElementById('editdeleteunitgroup');
    unitGroupsSelect.addEventListener('change', function (e) {
        selectedGroupId = unitGroupsSelect.options[unitGroupsSelect.selectedIndex].value;
        groupName = unitGroupsSelect.options[unitGroupsSelect.selectedIndex].text;
        document.getElementById('editgroupname').value = groupName;         
        e.stopImmediatePropagation();
    }, false);

    let btneditgroupname = document.getElementById('btneditgroupname');
    btneditgroupname.addEventListener('click', function (e) {
        document.body.style.cursor = 'wait';
        submitEditUnitsGroupForm(selectedGroupId, document.getElementById('editgroupname').value);
        setTimeout(function () {
        editDeleteGroupModal.style.display = 'none';
            displayMainPane(units);
            document.body.style.cursor = 'default';
            e.stopImmediatePropagation();
        }, 1850);
    }, false);

    let btndeletegroupname = document.getElementById('btndeletegroupname');
    btndeletegroupname.addEventListener('click', function (e) {
        document.body.style.cursor = 'wait';
        deleteSelectedGroup(selectedGroupId);
        setTimeout(function () {
            editDeleteGroupModal.style.display = 'none';
            displayMainPane(units);
            document.body.style.cursor = 'default';
            e.stopImmediatePropagation();
        }, 1850);
    }, false);

    document.getElementById("editdeletegroupform").reset();
    editDeleteGroupModal.style.display = "block";

    $('#container').overlayMask('hide');
    document.body.style.cursor = 'default';

    return false;
}

function submitEditUnitsGroupForm(groupId, groupName) {

    try {

        let prms = { "itemId": groupId, "name": groupName, "dataFlags": 1 };
        let remote = wialon.core.Remote.getInstance();
        remote.remoteCall('item/update_name', prms);

    } catch (e) {
        console.error('unitgroupsJS: submitEditUnitsGroupForm - ERROR: ', e);
        setMainInterval(global.units);
    }
}

function deleteSelectedGroup(groupId) {

    try {
            let prms = { "itemId": groupId };
            let remote = wialon.core.Remote.getInstance();
            remote.remoteCall('item/delete_item', prms);       
    } catch (e) {
        console.error('unitgroupsJS: deleteSelectedGroup - ERROR: ', e);
        setMainInterval(global.units);
    }
}

function addNewUnitsGroup(groupName) {

    try {
        let prms = { "creatorId": gUser.getId(), "name": groupName, "dataFlags": 1 };
        let remote = wialon.core.Remote.getInstance();
        remote.remoteCall('core/create_unit_group', prms);
    } catch (e) {
        console.error('unitgroupsJS: addNewUnitsGroup - ERROR: ', e);
        setMainInterval(global.units);
    }
}

function openAssignUnitsGroupForm(units) {

    document.body.style.cursor = 'wait';
    $('#container').overlayMask();

    let assignunitsgroupmodal = document.getElementById('assignunitsgroupmodal');

    let assignUnitsList = document.getElementById('assignunits');
    while (assignUnitsList.options.length) assignUnitsList.options.remove(0);

    let assignedUnitsList = document.getElementById('assignedunits');
    while (assignedUnitsList.options.length) assignedUnitsList.options.remove(0);

    let span = document.getElementById("closeassignunitsgroupmodal");
    span.onclick = function () {
        assignunitsgroupmodal.style.display = "none";
    };

    window.onclick = function (event) {
        if (event.target == assignunitsgroupmodal) {
            assignunitsgroupmodal.style.display = "none";
        }
    };

    gEditUnitGroup = false;
    populateUnitGroupsSelect(gEditUnitGroup);

    let selectedGroupId = 0;
    let unitGroupsSelect = document.getElementById('newunitgroup');
    unitGroupsSelect.addEventListener('change',function () {
        selectedGroupId = unitGroupsSelect.options[unitGroupsSelect.selectedIndex].value;
        populateUnitsGroupsLists(selectedGroupId);
    });

    $("#btnRight").click(function (e) {
        let selectedOpts = $("#assignunits option:selected");
        if (selectedOpts.length == 0) {
            //alert("Nothing to move.");
            e.preventDefault();
        }
        $("#assignedunits").append($(selectedOpts).clone());
        $(selectedOpts).remove();
        e.preventDefault();
    });
    $("#btnAllRight").click(function (e) {
        let selectedOpts = $("#assignunits option");
        if (selectedOpts.length == 0) {
            //alert("Nothing to move.");
            e.preventDefault();
        }
        $("#assignedunits").append($(selectedOpts).clone());
        $(selectedOpts).remove();
        e.preventDefault();
    });
    $("#btnLeft").click(function (e) {
        let selectedOpts = $("#assignedunits option:selected");
        if (selectedOpts.length === 0) {
            //alert("Nothing to move.");
            e.preventDefault();
        }
        $("#assignunits").append($(selectedOpts).clone());
        $(selectedOpts).remove();
        e.preventDefault();
    });
    $("#btnAllLeft").click(function (e) {
        let selectedOpts = $("#assignedunits option");
        if (selectedOpts.length == 0) {
            //alert("Nothing to move.");
            e.preventDefault();
        }
        $("#assignunits").append($(selectedOpts).clone());
        $(selectedOpts).remove();
        e.preventDefault();
    });

    $('#container').overlayMask('hide');
    document.body.style.cursor = 'default';

    let assignunitsgroupform = document.getElementById('assignunitsgroupform');
    assignunitsgroupform.addEventListener('submit', async function (e) {
        e.preventDefault();
        e.stopPropagation();
        let assignunits = [];
        let assignedUnits = document.getElementById('assignedunits');
        if (assignedUnits.length > 0) {
            for (let u = 0; u < assignedUnits.length; u++) {
                assignunits.push(assignedUnits[u].value);
            }
        }

        const unitsAssigned = await submitAssignUnitsGroupForm(selectedGroupId, assignunits);
        if (unitsAssigned) {
            document.getElementById("assignunitsgroupmodal").style.display = "none";
            displayMainPane(units);
        }
    });

    document.getElementById("assignunitsgroupmodal").style.display = "block";

    return false;
}

async function submitAssignUnitsGroupForm(selectedGroupId, units) {

    try {
        let session = global.session;
        let group = session.getItem(selectedGroupId);
        let flags = wialon.item.Item.accessFlag.editSubItems;

        return await new Promise((resolve, reject) => {
            session.updateDataFlags(
                [{ type: "type", data: "avl_unit_group", flags: flags, mode: 1 }], // Items specification
                (error) => {
                    if (error) {
                        console.log("unitgroupsJS: submitAssignUnitsGroupForm - API ERROR " + error + " #" + wialon.core.Errors.getErrorText(error));
                        reject({ type: 'API_ERROR', error: error });
                        return;
                    }
                    group.updateUnits(units);
                    resolve(true);
                });
        });
    } catch (e) {
        console.error("unitgroupsJS: submitAssignUnitsGroupForm - ERROR ", e);
        setMainInterval(global.units);
    }
}

async function populateUnitGroupsSelect(gEditUnitGroup) {

    let unitGroupsSelect = document.getElementById('newunitgroup');
    if (gEditUnitGroup)
        unitGroupsSelect = document.getElementById('editdeleteunitgroup');

    while (unitGroupsSelect.options.length) unitGroupsSelect.options.remove(0);
    unitGroupsSelect.value = "";
    let unitGroups = await getUnitGroups();
    const unitGroupOption = document.createElement("option");
    unitGroupOption.value = "";
    unitGroupOption.text = " -- select a group --";
    unitGroupOption.hidden = true;
    unitGroupsSelect.appendChild(unitGroupOption);
    for (const unitGroup of unitGroups) {
        const unitGroupOption = document.createElement("option");
        unitGroupOption.value = unitGroup.getId();
        unitGroupOption.text = unitGroup.getName();
        unitGroupsSelect.appendChild(unitGroupOption);
    }
    return false;
}

function populateUnitsGroupsLists(selectedGroupId) {

    let units = session.getItems("avl_unit");
    if (!units || !units.length) {
        console.log("unitgroupsJS: populateUnitsGroupsLists - no units found");
        return;
    }

    let unitGroups = session.getItems("avl_unit_group");
    let groupUnits = [];
    for (let g = 0; g < unitGroups.length; g++) {
        if (unitGroups[g].getId() === parseInt(selectedGroupId)) {
            groupUnits = unitGroups[g].getUnits();
            break;
        }
    }

    let assignedUnitsList = document.getElementById('assignedunits');
    while (assignedUnitsList.options.length) assignedUnitsList.options.remove(0);
    for (const groupUnit of groupUnits) {
        const assignedUnitOption = document.createElement("option");
        assignedUnitOption.value = groupUnit;
        for (let u = 0; u < units.length; u++) {
            if (units[u].getId() === groupUnit) {
                assignedUnitOption.text = units[u].getName();
                break;
            }
        }
        assignedUnitsList.appendChild(assignedUnitOption);
    }

    units = units.filter(u => !groupUnits.includes(u.getId()));
    let assignUnitsList = document.getElementById('assignunits');
    while (assignUnitsList.options.length) assignUnitsList.options.remove(0);
    for (const canAssignUnit of units) {
        const canAssignUnitOption = document.createElement("option");
        canAssignUnitOption.value = canAssignUnit.getId();
        canAssignUnitOption.text = canAssignUnit.getName();
        assignUnitsList.appendChild(canAssignUnitOption);
    }
}

async function getUnitGroups() {

    try {
        let session = global.session;
        let flags = wialon.item.Item.dataFlag.base;

        return await new Promise((resolve, reject) => {
            session.updateDataFlags(
                [{ type: "type", data: "avl_unit_group", flags: flags, mode: 1 }], // Items specification
                (error) => {
                    if (error) {
                        console.log("unitgroupsJS: getUnitGroups - API ERROR " + error + " #" + wialon.core.Errors.getErrorText(error));
                        reject({ type: 'API_ERROR', error: error });
                        return;
                    }
                    const unitGroups = session.getItems("avl_unit_group");
                    if (!unitGroups || !unitGroups.length) {
                        console.log("unitgroupsJS: getUnitGroups - No Unit Groups found");
                        return;
                    }
                    resolve(unitGroups);
                });
        });
    } catch (e) {
        console.error("unitgroupsJS: getUnitGroups - ERROR ", e);
        setMainInterval(global.units);
    }
}


//function getUnit(unitId, uwt) {

//    let unit = {};
//    for (let u = 0; u < uwt.length; u++) {
//        if (uwt[u].id === unitId) {
//            unit = uwt[u];
//            break;
//        }
//    }
//    return unit;
//}

//async function getAxleSensorPressureMetricAttributes(wUnit, axles) {
//    try {

//        let session = global.session;
//        if (!session) session = wialon.core.Session.getInstance();
//        session.loadLibrary("itemCustomFields");
//        var flags = wialon.util.Number.or(wialon.item.Item.dataFlag.base, wialon.item.Item.dataFlag.customFields, wialon.item.Item.dataFlag.adminFields);

//        return await new Promise((resolve, reject) => {
//            session.updateDataFlags(
//                [{ type: "type", data: "avl_unit", flags: flags, mode: 0 }],
//                (error) => {

//                    if (error) {
//                        console.log("pressuretrackJS: getAxleSensorPressureMetricAttributes - API ERROR " + error + " (" + wialon.core.Errors.getErrorText(error)) + ")";
//                        reject({ type: 'API_ERROR', error: error });
//                        return;
//                    }

//                    //let unitPressureMetricAttributes = {};

//                    //let unit = session.getItem(unitId);

//                    let customFields = wUnit.getCustomFields();

//                    let axlesPressureMetricAttributes = [];

//                    for (let a = 0; a < axles.length; a++) {

//                        let axlePressureMetricAttributes = {};
//                        axlePressureMetricAttributes.axle = axles[a];

//                        let axleMRPValue = false;
//                        let axlePPDValue = false;

//                        for (let cf in customFields) {

//                            if (customFields[cf].n.trim() === "A" + axles[a] + "MRP".trim()) {
//                                axlePressureMetricAttributes.manufacturersRecommendedPressure = customFields[cf].v;
//                                axleMRPValue = true;
//                            }

//                            if (customFields[cf].n.trim() === "A" + axles[a] + "PPD".trim()) {
//                                axlePressureMetricAttributes.percentageDeviationAllowed = customFields[cf].v;
//                                axlePPDValue = true;
//                            }

//                            if (axleMRPValue && axlePPDValue) break;
//                            //resolve(axleSensorPressureMetricAttributes);
//                        }

//                        if (!axleMRPValue && !axlePPDValue) {
//                            wUnit.createCustomField({ n: "A" + axles[a] + "MRP", v: "" });
//                            axlePressureMetricAttributes.manufacturersRecommendedPressure = "";
//                            wUnit.createCustomField({ n: "A" + axles[a] + "PPD", v: "" });
//                            axlePressureMetricAttributes.percentageDeviationAllowed = "";
//                        }

//                        axlesPressureMetricAttributes.push(axlePressureMetricAttributes);
//                        //resolve(axleSensorPressureMetricAttributes);
//                    }
//                    resolve(axlesPressureMetricAttributes);
//                }
//            );
//        });
//    } catch (e) {
//        console.error('pressuretrackJS: getAxleSensorPressureMetricAttributes - ERROR: ', e);
//    }
//}



export { logmsg };