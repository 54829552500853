export const PRIMARY_COLOUR = '#185785';
export const SECONDARY_COLOUR = '#aece25';
export const LOW_PRESSURE_COLOUR = '#ad0505';
export const HIGH_PRESSURE_COLOUR = '#320e75';
export const HIGH_TEMPERATURE_COLOUR = '#ff7e25';
export const LOW_VOLTAGE_COLOUR = '#ffce00';

export const SPARE_AXLE = 17;

export async function getResource() { // Execute after login succeed

    try {

        const session = wialon.core.Session.getInstance();
        //let resourceFlags = wialon.util.Number.or(wialon.item.Item.dataFlag.base, wialon.item.Resource.dataFlag.trailers);
        const flags = wialon.util.Number.or(wialon.item.Item.dataFlag.base, wialon.item.Resource.dataFlag.trailers);

        session.loadLibrary("resourceTrailers");
        return resource = await new Promise((resolve, reject) => {
            session.updateDataFlags(
                [{ type: "type", data: "avl_resource", flags: flags, mode: 1 }],
                (error) => {
                    if (error) {
                        console.log("pressuretrackJS: getResource - API ERROR " + error + " #" + wialon.core.Errors.getErrorText(error));
                        reject({ type: 'API_ERROR', error: error });
                        return;
                    }

                    const resources = session.getItems("avl_resource");
                    if (!resources || !resources.length) {
                        console.log("pressuretrackJS: getResource - No resources found");
                        return;
                    }

                    //for (let i = 0; i < resources.length; i++) 
                    //{
                    //    resource = resources[i];                     
                    //}
                    resolve(resources[0]);
                });
        });
    } catch (e) {
        console.error("pressuretrackJS: getResource - ERROR ", e);
    }
}

