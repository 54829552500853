
import { SPARE_AXLE } from '../global';
import { formatDateTime, convertUnixTime, formatCoordinate } from "../utilities";
import { getDeviceInfo, getDevices } from './unitdata';
import { getResourceNotifications } from '../notifications';

export async function getCurrentLocation(emei, timestamp) {
    try {
        const results = await getLocationCoordinates(emei, timestamp);

        if (results.data) {
            const data = results.data;
            const currentLocation = {
                longitude: formatCoordinate(data.longitude),
                latitude: formatCoordinate(data.latitude)
            };
            return currentLocation;
        } else {
            throw new Error('No data available');
        }
    } catch (error) {
        alert('No location data is available!');
        throw error;
    }
}

export async function getLocationCoordinates(emei, timestamp) {

    let apiUrl = `https://cloud-connector-dashboard.restiolabs.com/api/unit/${emei}/location?timestamp=${timestamp}`;
    try {
        const response = await fetch(apiUrl);
        return await response.json();
    } catch (error) {
        console.error('Error fetching location data:', error);
        throw error;
    }
}

export async function getResource() { // Execute after login succeed

    try {

        const session = wialon.core.Session.getInstance();
        //let resourceFlags = wialon.util.Number.or(wialon.item.Item.dataFlag.base, wialon.item.Resource.dataFlag.trailers);
        const flags = wialon.util.Number.or(wialon.item.Item.dataFlag.base, wialon.item.Resource.dataFlag.trailers);

        session.loadLibrary("resourceTrailers");
        return resource = await new Promise((resolve, reject) => {
            session.updateDataFlags(
                [{ type: "type", data: "avl_resource", flags: flags, mode: 1 }],
                (error) => {
                    if (error) {
                        console.log("pressuretrackJS: getResource - API ERROR " + error + " #" + wialon.core.Errors.getErrorText(error));
                        reject({ type: 'API_ERROR', error: error });
                        return;
                    }

                    const resources = session.getItems("avl_resource");
                    if (!resources || !resources.length) {
                        console.log("pressuretrackJS: getResource - No resources found");
                        return;
                    }

                    //for (let i = 0; i < resources.length; i++) 
                    //{
                    //    resource = resources[i];                     
                    //}
                    resolve(resources[0]);
                });
        });
    } catch (e) {
        console.error("pressuretrackJS: getResource - ERROR ", e);
    }
}

export async function getUnitData(unitId) {
  try {

    const remote = wialon.core.Remote.getInstance();
    const prms = {
      spec: {
        itemsType: 'avl_unit',
        propName: 'sys_id',
        propValueMask: `${unitId}`,
        sortType: 'sys_id',
      },
      force: 1,
      flags: -1,
      from: 0,
      to: 0,
    };

    return new Promise((resolve, reject) => {
        remote.remoteCall('core/search_items', prms, async (error, result) => {

        if (error) {
          console.log(`API_ERROR ${error} ${JSON.stringify(result)}`);
          reject({ type: 'API_ERROR', code: error, fullError: result });
        }

        let units = [];
        for (let i = 0; i < result.items.length; i++) {
            const unit = {};
            unit.id = result.items[i].id;
            unit.name = result.items[i].nm; 
            unit.imei = result.items[i].uid;
            unit.mobileNo = result.items[i].ph;
            unit.speed = result.items[i].pos?.s || 0;
            unit.position = result.items[i].pos;
            unit.location = await getUnitLocation(result.items[i]?.pos || null);
            unit.customFields = result.items[i].flds;
            unit.parameters = result.items[i].prms;
            unit.deviceTypeId = result.items[i].hw;

            const deviceInfo = await getDeviceInfo(result.items[i].hw);
            unit.deviceType = deviceInfo[0].name;
            unit.tcpPort = deviceInfo[0].tp;
            unit.lastMessage = formatDateTime(convertUnixTime(result.items[i]?.pos?.t)) || "Not available";

            const resourceNotifications = await getResourceNotifications();
            const sensorValueRanges = Object.values(resourceNotifications.notifications).filter(obj =>
                obj.un && obj.un.length > 0 && obj.un[0] === result.items[i].id
            );
            const sensors = getUnitSensors(result.items[i].prms, result.items[i].sens, sensorValueRanges);
            unit.wheelSensors = sensors.filter(item => parseInt(item.sensorName.slice(1, 3)) < SPARE_AXLE);
            unit.spareSensors = sensors.filter(item => parseInt(item.sensorName.slice(1, 3)) === SPARE_AXLE);
            unit.locationSensors = getLocationSensors(result.items[i].prms);
            //unit.trailers = getTrailers();

            unit.grayFlags = 0;
            unit.blinkFlags = 0;
            unit.redFlags = 0;
            unit.purpleFlags = 0;
            unit.orangeFlags = 0;
            unit.yellowFlags = 0;

            units.push(unit)
        }
        //console.log('data');
        //console.log(units);
        resolve(units);
      });
    });
  } catch (e) {
    console.error('getunitdataJS: getUnit - ERROR ', e);
  }
}

async function getUnitLocation(unitPosition) {
    try {
        if (unitPosition != null) {
            return await new Promise((resolve, reject) => {
                wialon.util.Gis.getLocations([{ lon: unitPosition.x, lat: unitPosition.y }],
                    (error, address) => {
                        if (error) {
                            console.log("unitInfo: getUnitLocation - API ERROR " + error + " #" + wialon.core.Errors.getErrorText(error));
                            reject({ type: 'API_ERROR', error: error });
                            return "Unknown";
                        }
                        resolve(address[0]);
                    });
            });
        } else { return "Unknown"};
    } catch (e) {
        console.error("unitInfo: getUnitLocation - ERROR ", e);
    }
}

function getUnitSensors(sensorParameters, unitSensors, sensorValueRanges) {
    
    let sensors = [];
    for (let s in sensorParameters) {       
        if (s.slice(0, 1) === 'p') {
            const sensor = {};
            const currentUnixTimestamp = Math.floor(Date.now() / 1000);
            const timestamp = sensorParameters[s]?.at;
            const maxDifferenceSeconds = 20 * 60; //20 minutes
            const timeDifference = currentUnixTimestamp - timestamp;
            if (timeDifference <= maxDifferenceSeconds) {
                sensor.sensorName = s.slice(1);
                sensor.sensorId = sensorParameters[`i${s.slice(1)}`]?.v || "";
                sensor.unitNumber = s.slice(-1);
                sensor.axle = s.slice(2, 4);
                sensor.pressureValue = parseFloat(sensorParameters[s]?.v || 0).toFixed(2);
                sensor.temperatureValue = parseInt(sensorParameters[`t${s.slice(1)}`]?.v || 0);
                sensor.voltageValue = parseFloat(sensorParameters[`v${s.slice(1)}`]?.v || 0).toFixed(2);
                sensor.rotationValue = parseFloat(sensorParameters[`r${s.slice(1)}`]?.v || 0).toFixed(2);

                for (let us in unitSensors) {
                    if (s === unitSensors[us].n.toLowerCase()) {
                        const tyreIdMatch = unitSensors[us]?.d.match(/Tyre Id: ([\w\d]+);/);
                        sensor.tyreId = tyreIdMatch ? tyreIdMatch[1] : unitSensors[s]?.d || "undefined";

                        const treadDepthMatch = unitSensors[us]?.d.match(/Tread Depth: ([\d.]+ mm)/);
                        sensor.treadDepth = treadDepthMatch ? treadDepthMatch[1] : "undefined";
                    }
                }

                for (let vr in sensorValueRanges) {
                    if (s.slice(1) === sensorValueRanges[vr].n.slice(-4)) {
                        const sensorMetric = sensorValueRanges[vr].n.slice(-5).slice(0, 1);
                        switch (sensorMetric) {
                            case 'P':
                                sensor.minPressureValue = sensorValueRanges[vr].trg_p.lower_bound;
                                sensor.maxPressureValue = sensorValueRanges[vr].trg_p.upper_bound;
                                break;
                            case 'T':
                                sensor.maxTemperatureValue = sensorValueRanges[vr].trg_p.upper_bound;
                                break;
                            case 'V':
                                sensor.minVoltageValue = sensorValueRanges[vr].trg_p.lower_bound;
                                break;
                        }
                    }
                }
                sensors.push(sensor);
            }
        }        
    }
    return sensors;
}

function getLocationSensors(sensorParameters) {

    let sensors = [];
    for (let s in sensorParameters) {
        if (s.slice(0, 2) === 'il' || s.slice(0, 2) === 'ir') {
            const currentUnixTimestamp = Math.floor(Date.now() / 1000);
            const timestamp = sensorParameters[s]?.at;
            const maxDifferenceSeconds = 20 * 60; //20 minutes
            const timeDifference = currentUnixTimestamp - timestamp;
            if (timeDifference <= maxDifferenceSeconds) {
                const sensor = {};
                sensor.sensorName = s.slice(1);
                sensor.sensorId = sensorParameters[`i${s.slice(1)}`]?.v || "";
                sensor.unitNumber = s.slice(-1);
                sensor.axle = s.slice(2, 4);
                sensor.axleUnit = s.slice(2);
                sensor.wheelConfig = sensorParameters[`w${s.slice(1)}`]?.v; //|| -1
                sensor.voltage = sensorParameters[`v${s.slice(1)}`]?.v || 0;
                sensors.push(sensor);
            }
        }
    }
    return sensors;
}

export async function getLinkedTrailers(unitId) {
    try {

        const remote = wialon.core.Remote.getInstance();
        const prms = {
            unitId: unitId
        };

        return new Promise((resolve, reject) => {
            remote.remoteCall('resource/get_unit_trailers', prms, async (error, result) => {

                if (error) {
                    console.log(`API_ERROR ${error} ${JSON.stringify(result)}`);
                    reject({ type: 'API_ERROR', code: error, fullError: result });
                }

                let trailers = [];
                for (let i = 0; i < result.items.length; i++) {

                }
                //console.log('data');
                //console.log(trailers);
                resolve(trailers);
            });
        });
    } catch (e) {
        console.error('getunitdataJS: getUnit - ERROR ', e);
    }
}

export async function getTrailers() {
    try {

        const remote = wialon.core.Remote.getInstance();
        const prms = {
            spec: {
                itemsType: 'avl_resource',
                propName: 'trailers',
                propType: 'trailers',
                propValueMask: '*',
                sortType: 'sys_id',
            },
            force: 1,
            flags: -1,
            from: 0,
            to: 0,
        };

        return new Promise((resolve, reject) => {
            remote.remoteCall('core/search_items', prms, async (error, result) => {

                if (error) {
                    console.log(`API_ERROR ${error} ${JSON.stringify(result)}`);
                    reject({ type: 'API_ERROR', code: error, fullError: result });
                }

                let trailers = [];
                for (let i = 0; i < result.items.length; i++) {

                }
                //console.log('data');
                //console.log(trailers);
                //resolve(untrailersits);
            });
        });
    } catch (e) {
        console.error('getunitdataJS: getUnit - ERROR ', e);
    }
}

async function getServerData(tokenId) {
  const remote = wialon.core.Remote.getInstance();
  const prms = {
    token: tokenId,
    fl: -1,
  };
  return new Promise((resolve, reject) => {
    remote.remoteCall('token/login', prms, (error, result) => {
      if (error) {
        console.log(`API_ERROR ${error} ${JSON.stringify(result)}`);
        reject({ type: 'API_ERROR', code: error, fullError: result });
      }
      const serverData = {};

      serverData.ipAddress = result.hw_gw_ip;
      serverData.serverAddress = result.hw_gw_dns;
      //console.log('unit result');
      //console.log(result);

      resolve(serverData);
    });
  });
}

//Simple rule - run it once after login. When you call updateDataFlags all data come to your page and updates automatically.
//After updateDataFlags you can use wialon.core.Session.getInstance().getItems('avl_unit') without request to wialon

async function updateUnitDataFlags() {

    try {

        let session = wialon.core.Session.getInstance();

        session.loadLibrary("itemIcon");
        session.loadLibrary("unitSensors");

        let unitflags = wialon.item.Item.dataFlag.base
            | wialon.item.Unit.dataFlag.sensors
            | wialon.item.Unit.dataFlag.lastMessage;

        session.updateDataFlags(
            [{ type: "type", data: "avl_unit", flags: unitflags, mode: 0 }],
            (error) => {
                if (error) {
                    console.log("pressuretrackJS: updateUnitDataFlags - API ERROR " + error + " #" + wialon.core.Errors.getErrorText(error));
                    return;
                }
            }
        );
    } catch (e) {
        console.error('pressuretrackJS: updateUnitDataFlags - Error:', e);
    }
}

async function updateResourceDataFlags() {

    try {

        let session = global.session;
        if (!session) session = wialon.core.Session.getInstance();

        session.loadLibrary("resourceTrailers");
        session.loadLibrary("resourceNotifications");

        let resourceflags = wialon.item.Item.dataFlag.base
            | wialon.item.Resource.dataFlag.base
            | wialon.item.Item.dataFlag.messages
            | wialon.item.Resource.dataFlag.notifications;

        //var flags = wialon.util.Number.or(wialon.item.Item.dataFlag.base, wialon.item.Resource.dataFlag.drivers, wialon.item.Resource.dataFlag.driverUnits); 

        session.updateDataFlags(
            [{ type: "type", data: "avl_resource", flags: resourceflags, mode: 0 }], // Items (avl_resource) specification
            (error) => {
                if (error) {
                    console.log("pressuretrackJS: updateResourceDataFlags - API ERROR " + error + " #" + wialon.core.Errors.getErrorText(error));
                    return;
                }
            }
        );

    } catch (e) {
        console.error('pressuretrackJS: updateResourceDataFlags - Error:', e);
    }
}