export function convertUnixTime(unixTimeStamp) {
    // Create new JS Date object based on timestamp multiplied by 1000 so that argument is in milliseconds, not seconds.
    let date = new Date(unixTimeStamp * 1000); //
    return date;
}

export function convertToUnixTimeStamp(date) {
    // Create new JS Date object based on timestamp multiplied by 1000 so that argument is in milliseconds, not seconds.
    let unixTimeStamp = Math.round(new Date(date).getTime() / 1000);
    return unixTimeStamp;
}

export function convertToStartOfDayUnixTimeStamp(date) {
    let selectedDate = new Date(date);
    let startOfDayDate = new Date(selectedDate.getFullYear()
                                  , selectedDate.getMonth()
                                  , selectedDate.getDate()
                                  , 0,0,0,0);
    //startOfDayUnixTimeStamp = Math.round(new Date(startOfDayDate).getTime() / 1000);
    let startOfDayUnixTimeStamp = Math.round(startOfDayDate.getTime() / 1000);
    return startOfDayUnixTimeStamp;
}

export function convertToEndOfDayUnixTimeStamp(date) {
    // Create new JS Date object based on timestamp multiplied by 1000 so that argument is in milliseconds, not seconds.
    
    let endOfDayDate = new Date();
    let selectedDate = new Date(date);
    if (new Date(date).toDateString() !== endOfDayDate.toDateString()) {
        endOfDayDate = new Date(selectedDate.getFullYear()
            , selectedDate.getMonth()
            , selectedDate.getDate()
            , 23,59,59,999);
    } 
    let endOfDayUnixTimeStamp = Math.round(endOfDayDate.getTime() / 1000);
    return endOfDayUnixTimeStamp;
}

export function isDateInArray(timestamp, timestamps) {
    for (let i = 0; i < timestamps.length; i++) {
        if (timestamp.getTime() === timestamps[i].getTime()) {
            return true;
        }
    }
    return false;
}

export function formatDateTime(date) {

    let d = new Date(date);

    let formatDateTime = [
        d.getFullYear(),
        '-',
        getDateMonth(d),
        '-',
        getDateDay(d),
        ' ',
        getDateHours(d),
        ':',
        getDateMinutes(d),
        ':',
        getDateSeconds(d)
    ].join('');

    //if (month.length < 2)
    //    month = '0' + month;
    //if (day.length < 2)
    //    day = '0' + day;

    //return [year, month, day].join('-');
    //return d.toLocaleString("sv-SE");

    //return d.toLocaleString("sv-SE");
    return formatDateTime;
}

export function formatDate(date) {

    let d = new Date(date);

    let formatDate = [
        d.getFullYear(),
        '-',
        getDateMonth(d),
        '-',
        getDateDay(d) 
    ].join('');

    return formatDate;
}

export function getToday() {

    let today = new Date();
    let d = today.getDate();
    let m = today.getMonth() + 1; //January is 0!
    let y = today.getFullYear();
    if (d < 10) {
        d = "0" + d;
    }
    if (m < 10) {
        m = "0" + m;
    }

    today = y + '-' + m + '-' + d;

    return today;
}

export function getDateDay(date) {

    let d = new Date(date);
    let day = d.getDate();

    if (day.toString().length < 2)
        day = "0" + day.toString();

    return day;
}

export function getDateMonth(date) {

    let d = new Date(date);
    let month = (d.getMonth() + 1);

    if (month.toString().length < 2)
        month = "0" + month.toString();

    return month;
}

export function getDateYear(date) {

    let d = new Date(date);
    let year = d.getFullYear();

    return year;
}

export function formatTime(date) {

    let d = new Date(date);

    let formatTime = [
        getDateHours(d),
        ':',
        getDateMinutes(d),
        ':',
        getDateSeconds(d)
    ].join('');

    return formatTime;
}

export function getDateHours(date) {

    let d = new Date(date);
    let hours = d.getHours();

    if (hours.toString().length < 2)
        hours = "0" + hours.toString();

    return hours;
}

export function getDateMinutes(date) {

    let d = new Date(date);
    let minutes = d.getMinutes();

    if (minutes.toString().length < 2)
        minutes = "0" + minutes.toString();

    return minutes; 
}

export function getDateSeconds(date) {

    let d = new Date(date);
    let seconds = d.getSeconds();

    if (seconds.toString().length < 2)
        seconds = "0" + seconds.toString();

    return seconds;
}

export function removeSelectOptions(selectElement) {
    let length = selectElement.options.length;
    for (let i = length - 1; i >= 0; i--) {
        selectElement.options[i] = null;
    }
}

export function addLeadingZero(number) {
    return number < 10 ? "0" + number : number.toString();
}

export function getFallbackValue(array, key, defaultValue) {
    let value = defaultValue;
    if (value == null || value == "null" || value == "" || value == "--") {
        for (let i = 0; i < array.length; i++) {
            if (array[i]?.[key] !== null && array[i]?.[key] !== undefined && array[i]?.[key] !== "") {
                value = array[i][key];
                break;
            }
        }
    }
    return value;
}

export function formatCoordinate(coordinate) {
    // Ensure the coordinate is a float and divide by 1e7 if it's in an integer format like -339791899
    const formattedCoordinate = coordinate > 100000000 || coordinate < -100000000 ? coordinate / 1e7 : coordinate;
    return parseFloat(formattedCoordinate);
}



