/// <reference path="units.js" />

import { getUnits } from "../../pressuretrack";
import { setMainInterval } from "./units";
import { updateUnitNameChangeNotification } from "../../notifications";
import { openUnitChartReport } from "../../reports.js";

//import L from 'leaflet';

var map, marker; // global variables for maps (leaflet)

//async function displayUnitInfoView(unitId, uwt, isGroupUnit) {
async function displayUnitInfoView(uwt, unitId) {

    if (!unitId) return;

    let unitDrivers = await getUnitDrivers();

    let selectedUnit = {};
    selectedUnit.id = unitId;
    //let unit = wialon.core.Session.getInstance().getItem(unitId);
    //if (!unit) { console.log("unitinfoJS: displayUnitInfo - unit not found"); return; }//Loglevel

    //selectedUnit.position = unit.getPosition();
    
    let linkedtrailers = [];

    for (let i = 0; i < uwt.length; i++) {
        if (uwt[i].id === parseInt(unitId)) {
            selectedUnit.name = uwt[i].name;
            selectedUnit.lastMessage = uwt[i].lastMessage;
            selectedUnit.speed = uwt[i].speed;
            selectedUnit.position = uwt[i].position;
            
            selectedUnit.unitLocation = (uwt[i].location);
            break;
        }
    }
    selectedUnit.unitTrailers = linkedtrailers;

    //displayUnitInfo(selectedUnit, unitDrivers, isGroupUnit);
    displayUnitInfo(selectedUnit, unitDrivers);
}

//function displayUnitInfo(selectedUnit, unitDrivers, isGroupUnit) {
function displayUnitInfo(selectedUnit, unitDrivers) {

    let unitInfoPaneDiv = document.getElementById('unitinfopane');
    if (unitInfoPaneDiv == null) {
        unitInfoPaneDiv = document.createElement('div');
        unitInfoPaneDiv.id = 'unitinfopane';
        unitInfoPaneDiv.className = 'unitinfopane';
    } else {
        while (unitInfoPaneDiv.hasChildNodes()) {
            unitInfoPaneDiv.removeChild(unitInfoPaneDiv.lastChild);
        }
    }
    unitInfoPaneDiv.canClose = true;

    unitInfoPaneDiv.onclick = function (e) {
        unitInfoPaneDiv.canClose = false;
        e.stopImmediatePropagation;
    }
    
    let unitInfoPaneFragment = document.createDocumentFragment();

    //let unitReportDiv = createUnitReportElement(selectedUnit);
    //unitInfoPaneFragment.appendChild(unitReportDiv);

    let unitInfoDiv = createUnitInfoElement(selectedUnit);
    let unitDriverInfoDiv = null;
    if (!selectedUnit.isTrailer) {
        unitDriverInfoDiv = createUnitDriverInfoElement(selectedUnit.id, unitDrivers);
    }

    let unitMapDiv = createUnitMapElement();    

    unitInfoPaneFragment.appendChild(unitInfoDiv);
    if (!selectedUnit.isTrailer) {
        unitInfoPaneFragment.appendChild(unitDriverInfoDiv);
    }
    //unitInfoPaneFragment.appendChild(notificationsDiv);
    unitInfoPaneFragment.appendChild(unitMapDiv);

    unitInfoPaneDiv.appendChild(unitInfoPaneFragment);
    //displayUnit = {};
    //if (isGroupUnit)
    //    displayUnit = document.getElementById(`groupunit${selectedUnit.id}`);
    //else       
    //displayUnit = document.getElementById(selectedUnit.id);
    
    //let displayUnit = document.getElementById(`mainunit${selectedUnit.id}`);
    let displayUnit = document.getElementById(selectedUnit.id);
    displayUnit.appendChild(unitInfoPaneDiv);
    displayUnit.scrollIntoView();

    initialiseMap('unitmap');
    showUnitOnMap(selectedUnit.position);
}

async function getUnitDrivers() {

    try {

        var sess = wialon.core.Session.getInstance(); // get instance of current Session
        // flags to specify what kind of data should be returned
        var unit_flags = wialon.util.Number.or(wialon.item.Item.dataFlag.base),
            res_flags = wialon.util.Number.or(wialon.item.Item.dataFlag.base, wialon.item.Resource.dataFlag.drivers);

        // load Icon & Driver Library 
        sess.loadLibrary("resourceDrivers");

        //{ type: "type", data: "avl_unit", flags: unit_flags, mode: 0 },
        let unitDrivers = [];
        return await new Promise((resolve, reject) => {
            sess.updateDataFlags(
                [
                    { type: "type", data: "avl_resource", flags: res_flags, mode: 0 }],
                async function (code) { // updateDataFlags callback
                    if (code) { console.log(wialon.core.Errors.getErrorText(code)); resolve({}); }

                    // get loaded 'avl_resource's items  
                    let ress = await sess.getItems("avl_resource"); // get loaded 'avl_resource's items
                    if (!ress || !ress.length) { console.log("Get Selected Unit Driver Info - Resource not found"); return; }

                    //var select = "", // html-string of any select object
                    //    all_drivers = {}, // object with all drivers info
                    let d_obj = {}; // any element of all_drivers list 
                    for (let j = 0; j < ress.length; j++) {
                        let res = ress[j], // get loaded 'avl_resource's items
                            drivers = await res.getDrivers() || {}; // get loaded driver`s items from any resource
                        for (let drv in drivers) {
                            let driver = drivers[drv]; // iterate all drivers

                            // any element of all_drivers list 
                            d_obj = {
                                resource: res,
                                driverId: driver.id,
                                driverName: driver.n,
                                driverMobileNo: driver.p,
                                //driverIcon: res.getDriverImageUrl(driver, 32),
                                unitDriverAssignedTo: driver.bu
                            };

                            unitDrivers.push(d_obj);
                        }
                    }

                    resolve(unitDrivers);
                });
        });
    } catch (e) {
        console.error("unitinfoJS: getUnitDrivers " + e.error);
        setMainInterval(global.units);
    }
}

async function getUnitLocation(unitPosition) {

    try {
        return await new Promise((resolve, reject) => {
            wialon.util.Gis.getLocations([{ lon: unitPosition.x, lat: unitPosition.y }],
                (error, address) => {
                    if (error) {
                        console.log("unitInfo: getUnitLocation - API ERROR " + error + " #" + wialon.core.Errors.getErrorText(error));
                        reject({ type: 'API_ERROR', error: error });
                        return;
                    }
                    resolve(address);
                });
        });
    } catch (e) {
        console.error("unitInfo: getUnitLocation - ERROR ", e);
        setMainInterval(global.units);
    }
}

function initialiseMap(mapDiv) {

    // create a map in the "map" div, set the view to a given place and zoom
    map = L.map(mapDiv, {
        fullscreenControl: true,
        fullscreenControlOptions: {
            position: 'topleft'
        }
    }).setView([-32.57, 22.9375], 10);

    // add an OpenStreetMap tile layer
    const tileLayer = L.tileLayer('https://{s}.tile.osm.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="http://gurtam.com">Gurtam</a>'
    }).addTo(map).bringToBack();

    //tileLayer.setZIndex(10);
}

function showUnitOnMap(pos) { 
    if (map) {
        // check if map created
        //let icon = L.icon({
        //    iconUrl: unitIcon
        //});
        let customIcon = L.divIcon({
            className: 'map-icon'
        });

        //console.log(`Setting marker at UNITINFO coordinates: Latitude: ${pos.y}, Longitude: ${pos.x}`);

        marker = L.marker({ lat: pos.y, lng: pos.x }, { icon: customIcon }).addTo(map);
        marker.setLatLng({ lat: pos.y, lng: pos.x });
        marker.setIcon(customIcon);
        //}
        map.setView({ lat: pos.y, lng: pos.x });
    }
}


function createAssignDriverButtonElement() {

    let assignDriverButton = document.createElement('button');
    assignDriverButton.id = 'assigndriverbutton';
    assignDriverButton.type = 'button';
    let assignDriverButtonClasses = ['btn', 'btn-primary', 'btn-block'];
    assignDriverButton.classList.add(...assignDriverButtonClasses);
    assignDriverButton.innerHTML = "Assign Driver";

    assignDriverButton.onclick = function (e) {
        e.stopImmediatePropagation();
        let modal = document.getElementById("assigndrivermodal");
        modal.style.display = "block";
    };

    return assignDriverButton;
}

function createUnassignDriverButtonElement(unitId, unitDrivers, driver) {

    let unassignDriverButton = document.createElement('button');
    unassignDriverButton.id = 'unassigndriverbutton';
    unassignDriverButton.type = 'button';
    let unassignDriverButtonClasses = ['btn', 'btn-primary', 'btn-block'];
    unassignDriverButton.classList.add(...unassignDriverButtonClasses);
    unassignDriverButton.innerHTML = "Unassign Driver";

    unassignDriverButton.onclick = async function (e) {
        e.stopImmediatePropagation();
        let assignedDriver = await unassignDriverFromUnit(driver);
        if (!assignedDriver) {
            driver.unitDriverAssignedTo = 0;

            let unitDriverInfoContainerDiv = document.getElementById('driverinfocontainer');
            while (unitDriverInfoContainerDiv.hasChildNodes()) {
                unitDriverInfoContainerDiv.removeChild(unitDriverInfoContainerDiv.lastChild);
            }
            let assignDriverButtonElement = createAssignDriverButtonElement();
            unitDriverInfoContainerDiv.appendChild(assignDriverButtonElement);
                        
            getAllDriversNotAssigned(unitDrivers, unitId);
        }
    };

    return unassignDriverButton;
}

function createUnitInfoElement(selectedUnit) {

    let unitInfoDiv = document.createElement('div');
    unitInfoDiv.id = 'unitinfo';
    unitInfoDiv.className = 'paneframe';

    let unitInfoContainerDiv = createUnitInfoContainerElements(selectedUnit);
    unitInfoDiv.appendChild(unitInfoContainerDiv);

    return unitInfoDiv;
}

function createUnitInfoContainerElements(selectedUnit) {

    let unitInfoContainerDiv = document.createElement('div');
    unitInfoContainerDiv.className = 'unitpanecontainer';

    let unitInfoHeaderDiv = document.createElement('div');
    let unitInfoHeaderDivClasses = ['unitinfoheader', 'editunitinfo'];
    unitInfoHeaderDiv.classList.add(...unitInfoHeaderDivClasses);

    unitInfoHeaderDiv.onclick = function (e) {
        e.stopImmediatePropagation();
        openEditUnitForm(selectedUnit) //? unit param
    };

    let unitIcon = document.createElement('svg');
    unitIcon.className = 'trucktrailer-icon';

    let unitName = document.createElement('h6');
    unitName.innerHTML = selectedUnit.name;

    unitInfoHeaderDiv.appendChild(unitIcon);
    unitInfoHeaderDiv.appendChild(unitName);
    unitInfoContainerDiv.appendChild(unitInfoHeaderDiv);

    const unitInfoDiv = document.createElement('div');
    unitInfoDiv.className = 'unitinfo';

    const unitSpeedDiv = document.createElement('div');
    unitSpeedDiv.id = 'unitinfospeed';
    const unitSpeedLabel = document.createElement('b');
    unitSpeedLabel.innerText = "Speed: ";
    const unitSpeed = document.createTextNode(selectedUnit.position.s + " km/h");
    unitSpeedDiv.appendChild(unitSpeedLabel);
    unitSpeedDiv.appendChild(unitSpeed);
    unitInfoDiv.appendChild(unitSpeedDiv);

    const unitLocationDiv = document.createElement('div');
    unitLocationDiv.id = 'unitinfolocation';
    const unitLocationLabel = document.createElement('b');
    unitLocationLabel.innerText = "Location: ";
    const unitLocationText = document.createTextNode(selectedUnit.unitLocation);
    unitLocationDiv.appendChild(unitLocationLabel);
    unitLocationDiv.appendChild(unitLocationText);
    unitInfoDiv.appendChild(unitLocationDiv);

    const unitLastMessageDiv = document.createElement('div');
    unitLastMessageDiv.id = 'unitinfodate';
    const unitLastMessageLabel = document.createElement('b');
    unitLastMessageLabel.innerText = "Last Message: ";
    //const unitLastMessageText = document.createTextNode(wialon.util.DateTime.formatTime(selectedUnit.position.t));
    const unitLastMessageText = document.createTextNode(selectedUnit.lastMessage);
    //unitLastMessageText.id = 'unitinfodate';
    unitLastMessageDiv.appendChild(unitLastMessageLabel);
    unitLastMessageDiv.appendChild(unitLastMessageText);
    unitInfoDiv.appendChild(unitLastMessageDiv);

    unitInfoContainerDiv.appendChild(unitInfoDiv);

    return unitInfoContainerDiv;
}

//function getAllTrailersNotLinked(unitToLinkTo) {
//    var sess = wialon.core.Session.getInstance(); // get instance of current Session
//    // flags to specify what kind of data should be returned
//    var unit_flags = wialon.util.Number.or(wialon.item.Item.dataFlag.base),
//        res_flags = wialon.util.Number.or(wialon.item.Item.dataFlag.base, wialon.item.Resource.dataFlag.drivers);

//    // load Icon & Driver Library 
//    sess.loadLibrary("resourceTrailers");
//    sess.loadLibrary("itemIcon");

//    //load items to current session
//    sess.updateDataFlags(
//        [{ type: "type", data: "avl_unit", flags: unit_flags, mode: 0 },
//        { type: "type", data: "avl_resource", flags: res_flags, mode: 0 }],
//        function (code) { // updateDataFlags callback
//            if (code) { console.log("Get All Trailers Not Linked - " + wialon.core.Errors.getErrorText(code)); return; } // exit if error code

//            // get loaded 'avl_unit's items  
//            var units = sess.getItems("avl_unit");
//            if (!units || !units.length) { logmsg("Get All Trailers Not Linked - Units not found"); return; }

//            // get loaded 'avl_resource's items  
//            var resources = sess.getItems("avl_resource"); // get loaded 'avl_resource's items
//            if (!resources || !resources.length) {
//                logmsg("Get All Trailers Not Linked - Resource not found"); return;
//            }
//            let resourceId = 0;
//            // Get all trailers that are not linked/binded
//            let unitTrailers = [];
//            for (let j = 0; j < resources.length; j++) {
//                let res = resources[j]; // get loaded 'avl_resource's items
//                //if (res.getName() === 'TyreTrackDemo') {
//                resourceId = res.getId();
//                let trailers = res.getTrailers() || {}; // get loaded trailer`s items from any resource
//                for (let trl in trailers) {
//                    let trailer = trailers[trl]; // iterate all trailers

//                    if (trailer.bu === 0) { //not binded/linked to unit
//                        let unitTrailer = {};
//                        //unitTrailer.unitLinkedToId = unitId;
//                        unitTrailer.trailerId = trailer.id;
//                        unitTrailer.name = trailer.n;
//                        for (let i = 0; i < units.length; i++) {
//                            let u = units[i];
//                            if (u.getName() === unitTrailer.name) {
//                                unitTrailer.id = u.getId();
//                                unitTrailer.unitIcon = u.getIconUrl(32);
//                            }
//                        }
//                        unitTrailers.push(unitTrailer);
//                    }
//                }
//                //break;
//                //}
//            }

//            $("#trailerlinkcontent").empty().append(
//                '<span id="closeTrailersNotLinked" class="close">&times;</span>'
//            );
//            $('#trailerlinkcontent').append('<h5>Select a trailer to link: </h5>');
//            $('#trailerlinkcontent').append('<ul id="trailersNotLinked"></ul>');

//            for (let i = 0; i < unitTrailers.length; i++) {
//                let u = unitTrailers[i];

//                let li = document.createElement("li");
//                li.id = u.trailerId;

//                let icon = u.unitIcon; // get unit Icon url
//                let img = document.createElement('img');
//                img.src = icon;
//                li.appendChild(img);

//                let text = " " + u.name;
//                let t = document.createTextNode(text);
//                li.appendChild(t);

//                document.getElementById("trailersNotLinked").appendChild(li);
//            }

//            let modal = document.getElementById("unitlinkmodal");
//            //let btn = document.getElementById("linkTrailerButton"); 
//            let span = document.getElementById("closeTrailersNotLinked");

//            //btn.onclick = function () {
//            //    modal.style.display = "block";
//            //}

//            span.onclick = function () {
//                modal.style.display = "none";
//            };

//            window.onclick = function (event) {
//                if (event.target == modal) {
//                    modal.style.display = "none";
//                }
//            };

//            let list = document.getElementById("trailersNotLinked");
//            list.addEventListener('click', function (ev) {

//                if (ev.target.tagName === 'LI') {
//                    $("#trailerlinkcontent").click(linkTrailerToUnit(parseInt(ev.target.id), unitToLinkTo, resourceId));
//                    //$('#container').overlayMask();
//                    document.getElementById("loadingBoxText").innerHTML = "Updating unit data... ";
//                    $("#loadingBox").modal({
//                        backdrop: "static", //remove ability to close modal with click
//                        keyboard: false, //remove option to close with keyboard
//                        show: true //Display loader!
//                    });
//                    modal.style.display = "none";
//                }
//            }, false);
//        }
//    );
//}

function linkTrailerToUnit(trailerId, unitId) {

    document.body.style.cursor = 'wait';
    global.selectedUnitId = unitId;

    let unit = global.session.getItem(unitId), 
        res = global.resource, 
        trailer = res.getTrailer(trailerId), 
        isBind = true;

    res.bindTrailerToUnit(trailer, unit, 0, isBind, qx.lang.Function.bind(async function (res, trailer, code, result) {
        if (code) { logmsg(wialon.core.Errors.getErrorText(code)); return; }
        //trailer.c = getLinkedTrailerCount(unitId) + +1;
        let updatedTrailer = await updateTrailer(trailer);
        //if (updatedTrailer) getUnits(); 
    }, this, res, trailer));
}

function getLinkedTrailerCount(unitId) {

    let linkedTrailerCount = 0;
    let trailers = global.resource.getTrailers();
    for (let trl in trailers) {
        let trailer = trailers[trl];
        if (unitId === trailer.bu) {
            linkedTrailerCount++;
        }
    }
    return linkedTrailerCount;
}

function createLinkTrailerButtonElement() {

    let linkTrailerButton = document.createElement('button');
    linkTrailerButton.id = 'linktrailerbutton';
    linkTrailerButton.type = 'button';
    let linkTrailerButtonClasses = ['btn', 'btn-primary', 'btn-block'];
    linkTrailerButton.classList.add(...linkTrailerButtonClasses);
    linkTrailerButton.innerHTML = "Link Trailer";

    linkTrailerButton.onclick = function (e) {
        e.stopImmediatePropagation();
        let unitLinkModal = document.getElementById("unitlinkmodal");
        unitLinkModal.style.display = "block";
    };

    return linkTrailerButton;
}

function createDelinkTrailerButtonElement(trailer) {

    let delinkTrailerButton = document.createElement('button');
    delinkTrailerButton.id = 'delinktrailerbutton';
    delinkTrailerButton.type = 'button';
    let delinkTrailerButtonClasses = ['btn', 'btn-primary', 'btn-block'];
    delinkTrailerButton.classList.add(...delinkTrailerButtonClasses);
    delinkTrailerButton.innerHTML = "Delink Trailer";

    delinkTrailerButton.onclick = function (e) {
        //let unitLinkModal = document.getElementById("unitlinkmodal");
        //unitLinkModal.style.display = "block";
        e.stopImmediatePropagation();
        delinkTrailerFromUnit(trailer.unitLinkedToId, trailer.trailerId);
    };

    return delinkTrailerButton;
}

function createUnitDriverInfoElement(unitId, unitDrivers) {
    
    let unitDriverInfoDiv = document.createElement('div');
    unitDriverInfoDiv.id = 'driverinfo';
    unitDriverInfoDiv.className = 'paneframe';

    let unitDriverInfoContainerDiv = createUnitDriverInfoContainerElements(unitId, unitDrivers);
    unitDriverInfoDiv.appendChild(unitDriverInfoContainerDiv);

    return unitDriverInfoDiv;
}

function createUnitDriverInfoContainerElements(unitId, unitDrivers) {

    let driver = getUnitDriverInfo(unitId, unitDrivers);

    let unitDriverInfoContainerDiv = document.createElement('div');
    unitDriverInfoContainerDiv.id = 'driverinfocontainer';
    unitDriverInfoContainerDiv.className = 'panecontainer';
    unitDriverInfoContainerDiv.onclick = function (e) {
        e.stopImmediatePropagation();
    };

    if (driver == null) {

        let assignDriverButtonElement = createAssignDriverButtonElement();
        unitDriverInfoContainerDiv.appendChild(assignDriverButtonElement);

        getAllDriversNotAssigned(unitDrivers, unitId);

    } else {

        let driverInfoDiv = document.createElement('div');
        driverInfoDiv.className = 'editdriverinfo';

        driverInfoDiv.onclick = function () {
            openEditDriverForm(driver);
        }

        let driverNameHeader = document.createElement('div');
        driverNameHeader.className = 'drivername';
        driverNameHeader.innerHTML = driver.driverName;

        let driverMobileNoDiv = document.createElement('div');
        driverMobileNoDiv.className = 'drivermobile';
        driverMobileNoDiv.innerHTML = driver.driverMobileNo;

        driverInfoDiv.appendChild(driverNameHeader);
        driverInfoDiv.appendChild(driverMobileNoDiv);
        unitDriverInfoContainerDiv.appendChild(driverInfoDiv);

        let unassignDriverButtonElement = createUnassignDriverButtonElement(unitId, unitDrivers, driver);
        unitDriverInfoContainerDiv.appendChild(unassignDriverButtonElement);
    }

    return unitDriverInfoContainerDiv;
}

function getUnitDriverInfo(unitId, unitDrivers) {

    if (unitDrivers.length > 0) {
        for (let i = 0; i < unitDrivers.length; i++) {
            let d = unitDrivers[i];
            d.assignedDriver = false;
            if (d.unitDriverAssignedTo === parseInt(unitId)) {
                d.assignedDriver = true;
                return (d);
            }
        }
    }
}

function createUnitMapElement() {

    let unitInfoMapDiv = document.createElement('div');
    unitInfoMapDiv.id = 'unitinfomap';
    unitInfoMapDiv.className = 'paneframe';

    let unitMapContainerDiv = createUnitMapContainerElement('unitmapcontainer', 'unitmap', 'unitmap');
    unitInfoMapDiv.appendChild(unitMapContainerDiv);

    return unitInfoMapDiv;
}

function createUnitMapContainerElement(mapcontainer, mapDiv, mapDivClass) {

    let unitMapContainerDiv = document.createElement('div');
    unitMapContainerDiv.id = mapcontainer;
    unitMapContainerDiv.className = 'panecontainer';
    unitMapContainerDiv.onclick = function (e) {
        e.stopImmediatePropagation();
    };

    let unitMapDiv = document.createElement('div');
    unitMapDiv.id = mapDiv;
    unitMapDiv.className = mapDivClass;

    unitMapContainerDiv.appendChild(unitMapDiv);

    return unitMapContainerDiv;
}

function delinkTrailerFromUnit(unitId, trailerId) {

    document.getElementById("loadingBoxText").innerHTML = "Updating unit data... ";
    $("#loadingBox").modal({
        backdrop: "static", //remove ability to close modal with click
        keyboard: false, //remove option to close with keyboard
        show: true //Display loader!
    });

    global.selectedUnitId = unitId;   

    let unit = global.session.getItem(unitId),
        resource = global.resource,
        trailer = resource.getTrailer(trailerId),
        isBind = false;

    resource.bindTrailerToUnit(trailer, unit, 0, isBind, qx.lang.Function.bind(async function (resource, trailer, code, result) {
        if (code) { "Delink Trailer From Unit - " + logmsg(wialon.core.Errors.getErrorText(code)); return; } // exit if error code	   
        //let reorderedTrailerLinks = reorderTrailerLinks(unitId, parseInt(trailer.c));
        //trailer.c = "";
        let updatedTrailer = await updateTrailer(trailer);
        //if (updatedTrailer) getUnits();
    }, this, resource, trailer));
}

async function reorderTrailerLinks(unitId, trailerLinkOrder) {

    let resource = global.resource;

    let reorderedTrailers = false;
    let allTrailers = resource.getTrailers();
    for (let trl in allTrailers) {
        let trailer = allTrailers[trl];
        if (unitId === trailer.bu) {
            if (parseInt(trailer.c) > trailerLinkOrder) {
                reorderedTrailers = false;
                trailer.c = parseInt(trailer.c) - +1;
                reorderedTrailers = await updateTrailer(trailer);
            } else {
                reorderedTrailers = true;
            }
        }
    }

    return reorderedTrailers;
}

async function updateTrailer(trailer) {

    return await new Promise((resolve, reject) => {
        global.resource.updateTrailer(trailer,
            (error, data) => {
                if (error) {
                    console.log("pressuretrackJS: updateTrailer - API ERROR " + error + " (" + wialon.core.Errors.getErrorText(error)) + ")";
                    reject({ type: 'API_ERROR', error: error });
                    return false;
                } else {
                    console.log("pressuretrackJS: updateTrailer - SUCCESS (" + data.n + " trailer updated)");
                    resolve(true);
                }
            }
        );
    });
}

function openNewDriverForm(unitDriverToAssign, allDrivers) {

    document.body.style.cursor = 'wait';
    $('#container').overlayMask();

    let newdrivermodal = document.getElementById('newdrivermodal');

    let span = document.getElementById("closenewdrivermodal");
    span.onclick = function () {
        newdrivermodal.style.display = "none";
    };

    window.onclick = function (event) {
        if (event.target == newdrivermodal) {
            newdrivermodal.style.display = "none";
        }
    };

    $('#container').overlayMask('hide');
    document.body.style.cursor = 'default';

    let newdriverform = document.getElementById('newdriverform');
    newdriverform.addEventListener('submit', async function (e) {
        e.preventDefault();
        e.stopPropagation();
        submitNewDriverForm(allDrivers);
    });

    document.getElementById("newdriverform").reset();
    document.getElementById('unitId').value = unitDriverToAssign;
    document.getElementById("newdrivermodal").style.display = "block";
}

function openEditDriverForm(driver) {

    document.body.style.cursor = 'wait';
    $('#container').overlayMask();

    let editdrivermodal = document.getElementById('editdrivermodal');

    let span = document.getElementById("closeeditdrivermodal");
    span.onclick = function () {
        editdrivermodal.style.display = "none";
    };

    window.onclick = function (event) {
        if (event.target == editdrivermodal) {
            editdrivermodal.style.display = "none";
        }
    };

    let editdriverform = document.getElementById('editdriverform');
    editdriverform.addEventListener('submit', async function (e) {
        e.preventDefault();
        e.stopPropagation();
        submitEditDriverForm();
    });

    $('#container').overlayMask('hide');
    document.body.style.cursor = 'default';

    document.getElementById('edfDriverId').value = driver.driverId;
    document.getElementById('edfUnitId').value = driver.unitDriverAssignedTo;
    //ekUpload();
    document.getElementById('edfDriverName').value = driver.driverName;
    document.getElementById('edfDriverMobileNo').value = driver.driverMobileNo;

    document.getElementById("editdrivermodal").style.display = "block";
}

function getAllDriversNotAssigned(allDrivers, unitDriverToAssign) {

    $("#driverinfocontent").empty().append('<span id=closeDriversNotAssigned class="close">&times;</span>');
    $('#driverinfocontent').append('<h5>Assign a driver: </h5>');
    $('#driverinfocontent').append('<ul id="driversNotAssigned"></ul >');

    if (allDrivers.length > 0) {
        for (let i = 0; i < allDrivers.length; i++) {
            let d = allDrivers[i];
            if (d.unitDriverAssignedTo === 0) {

                let li = document.createElement("li");
                li.id = d.driverId;

                //let icon = d.driverIcon; // get unit Icon url
                //let img = document.createElement('img');
                //img.src = icon;
                //li.appendChild(img);

                let text = " " + d.driverName;
                let t = document.createTextNode(text);
                li.appendChild(t);

                document.getElementById("driversNotAssigned").appendChild(li);
            }
        }
    }

    let modal = document.getElementById("assigndrivermodal");
    //let btn = document.getElementById("assigndriverbutton");
    let span = document.getElementById("closeDriversNotAssigned");

    span.onclick = function () {
        modal.style.display = "none";
    };

    window.onclick = function (event) {
        if (event.target == modal) {
            modal.style.display = "none";
        }
    };

    let list = document.getElementById("driversNotAssigned");
   // list.addEventListener('click', function (ev) {
    list.onclick = function (e) {
        if (e.target.tagName === 'LI') {
            //$("#driverinfocontent").click(linkTrailerToUnit(parseInt(ev.target.id), unitDriverToAssign, resourceId));
            //$("#driverinfocontent").click(assignDriverToUnit(parseInt(ev.target.id), unitDriverToAssign));

            const driverInfoContent = document.getElementById("driverinfocontent");

            driverInfoContent.onclick = function (ev) {
                
                ev.stopImmediatePropagation();
                assignDriver(parseInt(ev.target.id), unitDriverToAssign, allDrivers);
            };

            document.getElementById("loadingBoxText").innerHTML = "Assigning driver to unit... ";
            $("#loadingBox").modal({
                backdrop: "static", //remove ability to close modal with click
                keyboard: false, //remove option to close with keyboard
                show: true //Display loader!
            });
            modal.style.display = "none";
        }
    }

    $('#driverinfocontent').append('<div class="panebutton"><button id="newDriverButton" class="btn btn-primary btn-block" type="button">New Driver</button></div>');
    let newbtn = document.getElementById("newDriverButton");
    newbtn.onclick = function () {
        modal.style.display = "none";
        openNewDriverForm(unitDriverToAssign, allDrivers);
    };
}

function submitNewDriverForm(allDrivers) {

    //document.body.style.cursor = 'wait';
    //$('#container').overlayMask();
    document.getElementById("loadingBoxText").innerHTML = "Updating driver data... ";
    $("#loadingBox").modal({
        backdrop: "static", //remove ability to close modal with click
        keyboard: false, //remove option to close with keyboard
        show: true //Display loader!
    });

    //let icon = 0;
    //if ($("#driverIcon").get(0) !== null)
    //    icon = $("#driverIcon").get(0);

    let driver = {};
    driver.unitAssignedTo = document.getElementById('unitId').value;
    driver.name = document.getElementById('driverName').value;
    driver.mobileNo = document.getElementById('driverMobileNo').value;
    //driver.icon = document.getElementById('driverIcon').get(0);
    //driver.icon = icon;
    addNewDriver(driver, allDrivers);

    document.getElementById("newdrivermodal").style.display = "none";

    //$('#container').overlayMask('hide');
    document.body.style.cursor = 'default';
    $("#loadingBox").modal("hide");

    return false;
}

function addNewDriver(driverObj, allDrivers) {

    let resource = global.resource;
    let resourceId = resource.getId();

    let driver = {
        "itemId": resourceId,
        "id": 0, // item_id
        "callMode": "create",
        "c": "", // driver code
        "ck": 0, // image checksum
        "ds": "", // description
        "n": driverObj.name, // name
        "p": driverObj.mobileNo, // phone
        "r": 1, // image aspect ratio
        "f": 0, // flags
        "jp": {} // additional fields
    };

    resource.createDriver(driver, function (code, result) {
        console.log('Driver result: ' + (code ? 'Error(' + code + ')' : 'Ok'));
        if (code === 1002) alert("ERROR - 'New Driver' not added, the phone number you entered already exists.");
        //if (driverObj.icon) {
        //    global.resource.setDriverImage(global.resource.getDriver(result.id), driverObj.icon, function (error) { 
        //        if (error) { console.log('Set Driver Image - Error Code: ' + error); return; } 
        //    });
        //}
        if (!code) {
            const driverCopy = allDrivers[0];
            const newDriver = { ...driverCopy };
            newDriver.driverId = result.id;
            newDriver.driverName = result.n;
            newDriver.driverMobileNo = result.p;            
            allDrivers.push(newDriver);
            assignDriver(result.id, parseInt(driverObj.unitAssignedTo), allDrivers);
        }
    });
}

function editDriver(driverObj) {

    let driver = {
        "itemId": global.resource.getId(),
        "id": driverObj.driverId, // item_id
        "callMode": "update",
        "c": "", // driver code
        "ck": 0, // image checksum
        "ds": "", // description
        "n": driverObj.name, // name
        "p": driverObj.mobileNo, // phone
        "r": 1, // image aspect ratio
        "f": 0, // flags
        "jp": {} // additional fields
    };

    global.resource.updateDriver(driver, function (code, result) {
        console.log('Driver result: ' + (code ? 'Error(' + code + ')' : 'Ok'));
        assignDriverToUnit(driverObj.driverId, parseInt(driverObj.unitAssignedTo));
    });
}

function submitEditDriverForm() {

    //document.body.style.cursor = 'wait';
    //$('#container').overlayMask();
    document.getElementById("loadingBoxText").innerHTML = "Updating driver data... ";
    $("#loadingBox").modal({
        backdrop: "static", //remove ability to close modal with click
        keyboard: false, //remove option to close with keyboard
        show: true //Display loader!
    });

    //let icon = 0;
    //if ($("#driverIcon").get(0) !== null)
    //    icon = $("#driverIcon").get(0);

    let driver = {};
    driver.driverId = document.getElementById('edfDriverId').value;
    driver.unitAssignedTo = document.getElementById('edfUnitId').value;
    driver.name = document.getElementById('edfDriverName').value;
    driver.mobileNo = document.getElementById('edfDriverMobileNo').value;
    //driver.icon = document.getElementById('driverIcon').get(0);
    //driver.icon = icon;
    editDriver(driver);

    document.getElementById("editdrivermodal").style.display = "none";

    //$('#container').overlayMask('hide');
    document.body.style.cursor = 'default';
    $("#loadingBox").modal("hide");

    return false;
}

//function assignDriverToUnit(driverId, unitDriverToAssign) {

//    global.selectedUnitId = unitDriverToAssign;

//    let unit = global.session.getItem(unitDriverToAssign),
//        res = global.resource, //get resource by id
//        driver = res.getDriver(driverId);

//    res.bindDriverToUnit(driver, unit, 0, true, qx.lang.Function.bind(function (res, driver, code, result) {
//        if (code !== 0) {
//            console.log(wialon.core.Errors.getErrorText(code));
//            return;
//        }

//        $("#loadingBox").modal("hide");
//    }, this, res, driver));
//}

function assignDriverToUnit(driverId, unitDriverToAssign) {
    return new Promise((resolve, reject) => {
        global.selectedUnitId = unitDriverToAssign;

        let unit = global.session.getItem(unitDriverToAssign),
            res = global.resource, // get resource by id  
            driver = res.getDriver(driverId);

        res.bindDriverToUnit(driver, unit, 0, true, function (res, driver, code, result) {
            if (code !== 0) {
                console.log(wialon.core.Errors.getErrorText(code));
                //reject(false);
                //return;
            }            
            $("#loadingBox").modal("hide");
            resolve(true);
        });
    });
}

async function assignDriver(driverId, unitDriverToAssign, allDrivers) {
    try {
        let success = await assignDriverToUnit(driverId, unitDriverToAssign);
        if (success) {
            console.log("Driver assigned successfully.");

            let unitDriverInfoContainerDiv = document.getElementById('driverinfocontainer');
            while (unitDriverInfoContainerDiv.hasChildNodes()) {
                unitDriverInfoContainerDiv.removeChild(unitDriverInfoContainerDiv.lastChild);
            }

            const driver = allDrivers.find(d => parseInt(d.driverId) === driverId);
            let driverInfoDiv = document.createElement('div');
            driverInfoDiv.className = 'editdriverinfo';
            driverInfoDiv.onclick = function () {
                openEditDriverForm(driver);
            };

            let driverNameHeader = document.createElement('div');
            driverNameHeader.className = 'drivername';
            driverNameHeader.innerHTML = driver.driverName;

            let driverMobileNoDiv = document.createElement('div');
            driverMobileNoDiv.className = 'drivermobile';
            driverMobileNoDiv.innerHTML = driver.driverMobileNo;

            driverInfoDiv.appendChild(driverNameHeader);
            driverInfoDiv.appendChild(driverMobileNoDiv);
            unitDriverInfoContainerDiv.appendChild(driverInfoDiv);

            const unassignDriverButtonElement = createUnassignDriverButtonElement(unitDriverToAssign, allDrivers, driver); //unitId, unitDrivers, driver
            unitDriverInfoContainerDiv.appendChild(unassignDriverButtonElement);
        }
    } catch (error) {
        console.log("Failed to assign driver.");
        setMainInterval(global.units);
    }
}


async function unassignDriverFromUnit(driverObj) {

    const u_id = driverObj.unitDriverAssignedTo, // unitId
        unit = global.session.getItem(u_id),
        resource = global.resource,
        driver = resource.getDriver(driverObj.driverId);

    return new Promise((resolve, reject) => {
        resource.bindDriverToUnit(driver, unit, 0, false, qx.lang.Function.bind((resource, driver, error, result) => {
            if (error) {
                console.log(wialon.core.Errors.getErrorText(error));
                reject({ type: 'API_ERROR', code: error, fullError: result });
                return;
            } // exit if error code
            resolve(false); //Driver assigned = false
        }, this, resource, driver));
    });
}

function submitEditUnitForm() {

    //document.body.style.cursor = 'wait';
    //$('#container').overlayMask();

    document.getElementById("loadingBoxText").innerHTML = "Updating unit data... ";
    $("#loadingBox").modal({
        backdrop: "static", //remove ability to close modal with click
        keyboard: false, //remove option to close with keyboard
        show: true //Display loader!
    });

    let unit = {};
    unit.id = document.getElementById('eufUnitId').value;
    unit.name = document.getElementById('eufUnitName').value;
    unit.trailerId = document.getElementById('eufTrailerId').value;
    //driver.icon = document.getElementById('driverIcon').get(0);
    //driver.icon = icon;
    editUnit(unit);

    document.getElementById("editunitmodal").style.display = "none";

    //$('#container').overlayMask('hide');
    //document.body.style.cursor = 'default';

    return false;
}

async function editUnit(unit) {

    let updatedUnitName = await updateUnitName(unit);
    let updatedTrailerName = false;
    if (unit.trailerId > 0) {
        updatedTrailerName = await updateTrailerName(unit);
    } else {
        updatedTrailerName = true;
    }

    if (updatedUnitName && updatedTrailerName)
        updateUnitNameChangeNotification(unit);
    else
        console.log("pressuretrackJS: editUnit - UNIT NAME NOT UPDATED");
}

async function updateUnitName(unit) {

    try {

        let u = wialon.core.Session.getInstance().getItem(unit.id);

        return await new Promise((resolve, reject) => {
            u.updateName(unit.name, async function (error) {
                if (error) {
                    console.log("pressuretrackJS: editUnit - API ERROR " + error + " (" + wialon.core.Errors.getErrorText(error)) + ")";
                    reject(false);
                    //return;
                } else {
                    console.log("pressuretrackJS: updateUnitName - Unit " + unit.name + " successfully updated.");
                    resolve(true);
                }
            });
        });
    } catch (e) {
        console.error("pressuretrackJS: updateUnitName - ERROR ", e);
        setMainInterval(global.units);
    }
}

async function updateTrailerName(unit) {

    try {
        let trailer = {
            "itemId": global.resource.getId(),
            "id": unit.trailerId, // item_id
            "callMode": "update",
            "ej": "", // ext JSON
            "c": "", // trailer code
            "ds": "", // description
            "n": unit.name, // name            
            "p": "", // phone
            "r": 1, // image aspect ratio
            "f": 2, // flags
            "jp": {} // additional fields
        };

        return await new Promise((resolve, reject) => {
            global.resource.updateTrailer(trailer, async function (error, result) {
                if (error) {
                    console.log("pressuretrackJS: editUnit (updateTrailer) - API ERROR " + error + " #" + wialon.core.Errors.getErrorText(error));
                    reject(false);
                } else {
                    console.log("pressuretrackJS: updateTrailerName - Trailer " + result.n + " (id: " + result.id + ") successfully updated.");
                    resolve(true);
                }
            });
        });
    } catch (e) {
        console.error("pressuretrackJS: updateTrailerName - ERROR ", e);
        setMainInterval(global.units);
    }
}

function openEditUnitForm(selectedUnit) {

    //document.body.style.cursor = 'wait';
    //$('#container').overlayMask();

    const editunitmodal = document.getElementById('editunitmodal');
    document.getElementById('editunitheader').innerText = "EDIT UNIT";
    document.getElementById('editunitlabel').innerText = "Unit Name";

    const closeEditUnit = document.getElementById("closeeditunitmodal");
    closeEditUnit.onclick = function () {
        editunitmodal.style.display = "none";
    };

    window.onclick = function (event) {
        if (event.target == editunitmodal) {
            editunitmodal.style.display = "none";
        }
    };

    let editunitform = document.getElementById('editunitform');
    editunitform.addEventListener('submit', async function (e) {
        e.preventDefault();
        e.stopPropagation();
        submitEditUnitForm();
    });

    //$('#container').overlayMask('hide');
    //document.body.style.cursor = 'default';

    document.getElementById('eufUnitId').value = selectedUnit.id;
    document.getElementById('eufUnitName').value = selectedUnit.name;
    if (selectedUnit.isTrailer)
        document.getElementById('eufTrailerId').value = selectedUnit.trailerId;
    else
        document.getElementById('eufTrailerId').value = 0;

    document.getElementById("editunitmodal").style.display = "block";
}



export { displayUnitInfoView, getUnitLocation, createUnitMapContainerElement, initialiseMap, showUnitOnMap };
                                                                              