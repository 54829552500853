import html2canvas from "../../../../thirdparty/html2canvas/html2canvas";
import { setMainInterval } from "../units";
import { getWheelProperties, getTyreArrayIndex } from "../units";
import { openTyreContextMenu, createSensorNoSignalElement, createTyreNameElement, createTyreIdElement, createSensorIdElement, createSensorPressureElement, createSensorTemperatureElement, createSensorVoltageElement } from "./unitdiagrams";
import { addLeadingZero, getFallbackValue } from "../../../utilities";
import { displayUpdatedData} from "../../detailpage";
import { getResourceNotifications, getNotificationData, updateUnitNotifications, setUnitValuesOutOfRangeNotifications } from "../../../notifications";

import { SPARE_AXLE } from "../../../global";

var gNotificationCounterCheck = 0;
var gNotificationsIds = [];

var gSnipImage = false;

function loadUnitSchematicDiagram(unit) {

    let unitDiagramsDiv = document.getElementById('rightpane');
    while (unitDiagramsDiv.hasChildNodes()) {
        unitDiagramsDiv.removeChild(unitDiagramsDiv.lastChild);
    }
    let unitSchematicDiagramFragment = document.createDocumentFragment();
    let unitSchematicDiagramDiv = document.createElement('div');
    unitSchematicDiagramDiv.id = 'rightpaneunitschematic';
    unitSchematicDiagramDiv.className = 'unitdiagram';

    unitSchematicDiagramFragment.appendChild(unitSchematicDiagramDiv);
    unitDiagramsDiv.appendChild(unitSchematicDiagramFragment);

    let schematicDiagramButtonDiv = createSchematicDiagramButtonsElement(unit);
    unitSchematicDiagramDiv.appendChild(schematicDiagramButtonDiv); 

    let unitSchematicWheelDiagramDiv = createUnitSchematicWheelDiagramDiv('unitschematicwheeldiagram'); //*unitschematicwheeldiagram
    unitSchematicDiagramDiv.appendChild(unitSchematicWheelDiagramDiv);

    unitSchematicWheelDiagramDiv = getDiagramConfiguration(unit, unitSchematicWheelDiagramDiv);

    //switch (vehicleClass) {
    //    case 'bus':
    //        unitSchematicWheelDiagramDiv = loadBusSchematicDiagram(unit, unitSchematicWheelDiagramDiv);
    //        break;
    //    case 'heavy_truck':
    //        unitSchematicWheelDiagramDiv = loadTruckSchematicDiagram(unit, unitSchematicWheelDiagramDiv);
    //        break;
    //    case 'dump_truck':
    //        unitSchematicWheelDiagramDiv = loadDumpTruckSchematicDiagram(unit, unitSchematicWheelDiagramDiv);
    //        break;
    //    case 'empty_vehicle':
    //        if(!unit.isTrailer)
    //            unitSchematicWheelDiagramDiv = loadTruckSchematicDiagram(unit, unitSchematicWheelDiagramDiv);
    //        else
    //            unitSchematicWheelDiagramDiv = loadTrailerSchematicDiagram(unit, unitSchematicWheelDiagramDiv);
    //        break;
    //    default:
    //        unitSchematicWheelDiagramDiv = loadTrailerSchematicDiagram(unit, unitSchematicWheelDiagramDiv);
    //}
}

//function getLinkedTrailers(unitSchematicWheelDiagramDiv, unit, notifications) {

//    for (let t = 0; t < unit.unitTrailers.length; t++) {
//        let trailerDiv = createUnitElement();
//        unitSchematicWheelDiagramDiv.appendChild(trailerDiv);

//        unit.unitTrailers[t] = getWheelProperties(unit.unitTrailers[t]);

//        let trailerAxleMin = 0;
//        let trailerAxleMax = 0;
//        let trailerAxles = [];
//        for (let ts = 0; ts < unit.unitTrailers[t].sensors.length; ts++) {
//            if (parseInt(unit.unitTrailers[t].sensors[ts].sensorName.slice(0, 1)) !== 9)
//                trailerAxles.push(parseInt(unit.unitTrailers[t].sensors[ts].sensorName.slice(0, 1)));
//        }

//        trailerAxleMin = Math.min.apply(this, trailerAxles);
//        trailerAxleMax = Math.max.apply(this, trailerAxles);
//        for (let ta = trailerAxleMin; ta <= trailerAxleMax; ta++) {

//            let axleSensors = unit.unitTrailers[t].sensors.filter(s => parseInt(s.sensorName.slice(0, 1)) === ta);
//            let tyreAxleDiv = createTyreAxleElements(unit.unitTrailers[t], ta, axleSensors, notifications, gSnipImage);

//            trailerDiv.appendChild(tyreAxleDiv);
//        }

//        let trailerAxleSpareSensors = unit.unitTrailers[t].sensors.filter(s => parseInt(s.sensorName.slice(0, 1)) === 9);

//        let tyreSpareAxleDiv = createSpareTyreAxleElements(unit.unitTrailers[t], trailerAxleSpareSensors, notifications, gSnipImage);
//        trailerDiv.appendChild(tyreSpareAxleDiv);
//    }

//    return unitSchematicWheelDiagramDiv;
//}

function createUnitElement() {

    let trailerDiv = document.createElement('div');
    trailerDiv.className = 'trailer';

    return trailerDiv;
}

//switch (vehicleClass) {
//    case 'bus':
//        unitSchematicWheelDiagramSnipDiv = loadBusSchematicDiagram(unit, unitSchematicWheelDiagramSnipDiv);
//        let unitSchematicWheelDiagramDummyDiv = document.createElement('div');
//        unitSchematicWheelDiagramDummyDiv.innerHTML = "Tyretrack image";
//        unitSchematicWheelDiagramDummyDiv.className = 'snipimagedummydiv';
//        unitSchematicWheelDiagramSnipDiv.appendChild(unitSchematicWheelDiagramDummyDiv);
//        break;

//    case 'heavy_truck':
//        unitSchematicWheelDiagramSnipDiv = loadTruckSchematicDiagram(unit, unitSchematicWheelDiagramSnipDiv);
//        break;

//    case 'dump_truck':
//        unitSchematicWheelDiagramSnipDiv = loadDumpTruckSchematicDiagram(unit, unitSchematicWheelDiagramSnipDiv);
//        break;

//    case 'empty_vehicle':
//        if (!unit.isTrailer)
//            unitSchematicWheelDiagramSnipDiv = loadTruckSchematicDiagram(unit, unitSchematicWheelDiagramSnipDiv);
//        else
//            unitSchematicWheelDiagramSnipDiv = loadTrailerSchematicDiagram(unit, unitSchematicWheelDiagramSnipDiv);
//        break;

//    default:
//        unitSchematicWheelDiagramSnipDiv = loadTrailerSchematicDiagram(unit, unitSchematicWheelDiagramSnipDiv);
//}

//if (vehicleClass === 'bus') {
//    unitSchematicWheelDiagramSnipDiv = loadBusSchematicDiagram(unit, unitSchematicWheelDiagramSnipDiv);
//    let unitSchematicWheelDiagramDummyDiv = document.createElement('div');
//    unitSchematicWheelDiagramDummyDiv.innerHTML = "Tyretrack image";
//    unitSchematicWheelDiagramDummyDiv.className = 'snipimagedummydiv';
//    unitSchematicWheelDiagramSnipDiv.appendChild(unitSchematicWheelDiagramDummyDiv);
//} else {
//    unitSchematicWheelDiagramSnipDiv = loadTruckSchematicDiagram(unit, unitSchematicWheelDiagramSnipDiv);
//}

async function snipDiagram(unit) {

    gSnipImage = true;

    window.onclick = function (event) {
        event.stopImmediatePropagation();
        if (event.target == snipContainerModal) {
            snipContainerModal.style.display = "none";
        }
    };

    let snipImage = document.getElementById('snipimage');
    while (snipImage.hasChildNodes()) {
        snipImage.removeChild(snipImage.lastChild);
    }

    let unitDiagramsDiv = document.getElementById('rightpane');

    let unitSchematicWheelDiagramSnipDiv = document.createElement('div');
    unitSchematicWheelDiagramSnipDiv.id = 'unitschematicwheelsnipdiagram';
    unitSchematicWheelDiagramSnipDiv.className = 'unitdiagram'; //*unitschematicwheeldiagram

    unitDiagramsDiv.appendChild(unitSchematicWheelDiagramSnipDiv);

    let unitNameDiv = document.createElement('div');
    
    let unitNameHeaderDiv = document.createElement('div');
    unitNameHeaderDiv.className = 'unitnameheader';
    unitNameHeaderDiv.innerHTML = "Unit(s):"
    unitNameDiv.appendChild(unitNameHeaderDiv);

    let unitNameHeader = document.createElement('h6');
    let unitNameHeaderNode = document.createTextNode(unit.name);
    unitNameHeader.appendChild(unitNameHeaderNode);
    unitNameDiv.appendChild(unitNameHeader);
    if (unit.unitTrailers.length > 0) {
        for (let t = 0; t < unit.unitTrailers.length; t++) {
            let unitNameElement = createUnitNameElements(unit.unitTrailers[t].name);
            unitNameDiv.appendChild(unitNameElement);
        }
    }

    unitSchematicWheelDiagramSnipDiv.appendChild(unitNameDiv);

    unitSchematicWheelDiagramSnipDiv = await getDiagramConfiguration(unit, unitSchematicWheelDiagramSnipDiv);

    let snipContainerModal = document.getElementById('snipcontainermodal');
   
    let snipdiv = document.getElementById('unitschematicwheelsnipdiagram'); 
    snipdiv.className = 'unitschematicwheelsnipdiagram';

    html2canvas(snipdiv, {
        height: screen.height,
    }).then(
        function (canvas) {
            document
                .getElementById('snipimage')
                .appendChild(canvas);
        });

    //unitSchematicWheelDiagramSnipDiv.style.display = 'none';

    document.getElementById('snipcontainermodal').style.display = 'block';
    gSnipImage = false;     
}


function createUnitNameElements(unitName) {

    let unitNameHeader = document.createElement('h6');
    let unitNameHeaderNode = document.createTextNode(unitName);
    unitNameHeader.appendChild(unitNameHeaderNode);
   
    return unitNameHeader;
}

async function getDiagramConfiguration(unit, unitDiagramDiv) {
    
    let unitDiv = createUnitElement();
    unitDiagramDiv.appendChild(unitDiv);

    unit.wheelSensors = getWheelProperties(unit.wheelSensors);
    unit.spareSensors = getWheelProperties(unit.spareSensors);

    let unitAxleMin = 0;
    let unitAxleMax = 0;
    let unitAxles = [];
    for (let ts = 0; ts < unit.sensors.length; ts++) {
        if (parseInt(unit.sensors[ts].sensorName.slice(0, 1)) !== 9)
            unitAxles.push(parseInt(unit.sensors[ts].sensorName.slice(0, 1)));
    }

    let resourceNotifications = await getResourceNotifications();

    unitAxleMin = Math.min.apply(this, unitAxles);
    unitAxleMax = Math.max.apply(this, unitAxles);
    for (let ta = unitAxleMin; ta <= unitAxleMax; ta++) {

        let axleSensors = unit.sensors.filter(s => parseInt(s.sensorName.slice(0, 1)) === ta);
        let tyreAxleDiv = createTyreAxleElements(unit, ta, axleSensors, resourceNotifications, gSnipImage);

        unitDiv.appendChild(tyreAxleDiv);
    }

    let unitAxleSpareSensors = unit.sensors.filter(s => parseInt(s.sensorName.slice(0, 1)) === 9);

    let tyreSpareAxleDiv = createSpareTyreAxleElements(unit, unitAxleSpareSensors, resourceNotifications);
    unitDiv.appendChild(tyreSpareAxleDiv);

    return unitDiagramDiv;
}

function createTyreAxleElements(unit, axle, axleSensors, resourceNotifications, gSnipImage) {

    let tyreAxleDiv = document.createElement('div');
    tyreAxleDiv.className = 'diagramaxle';
    let isAxleActive = getAxleActiveStatus(resourceNotifications, unit, axle);
    let sensorType = "";
    if (axleSensors.length <= 2) {

        if (gSnipImage)
            sensorType = axleSensors.filter(as => parseInt(as.sensorName.slice(1, 2)) === 1)[0].sensorSnipType;
        else
            sensorType = axleSensors.filter(as => parseInt(as.sensorName.slice(1, 2)) === 1)[0].sensorType;

        if (sensorType === "") {
            gSnipImage ? sensorType = 'wheelsnipactive' : sensorType = 'wheelactive';
            //if (gSnipImage)
            //    sensorType = 'wheelsnipactive';
            //else
            //    sensorType = 'wheelactive';
        }
        //tyredivclasses = [typeClass + 'axle' + axle + 'wheel', 'supersinglewheel', 'wheel1', sensorType];
        //tyredivclasses = ['wheelleftside', 'supersinglewheel', 'wheel1', sensorType];
        let tyredivclasses = ['supersinglewheel', sensorType];
        let tyre1Div = createTyreElement(unit, 1, axleSensors, tyredivclasses); //unit, tyre, axleSensors, tyredivclasses
        tyreAxleDiv.appendChild(tyre1Div);

        let tyreSensorValues1Div = createTyreInfoElement(axle, 1, axleSensors);
        tyreAxleDiv.appendChild(tyreSensorValues1Div);

        let axledivclasses = [];
        let axleStatusClass = '';
        isAxleActive ? axleStatusClass = 'axleactive' : axleStatusClass = 'axleinactive';
        axledivclasses = ['supersinglewheelaxle', 'axleinfo', axleStatusClass];

        let axleDiv = createAxleElement(unit, axle, axleSensors, axledivclasses);
        tyreAxleDiv.appendChild(axleDiv);

        let axleInfoDiv = createAxleInfoElement(unit, axle);
        tyreAxleDiv.appendChild(axleInfoDiv);

        if (gSnipImage)
            sensorType = axleSensors.filter(as => parseInt(as.sensorName.slice(1, 2)) === 4)[0].sensorSnipType;
        else
            sensorType = axleSensors.filter(as => parseInt(as.sensorName.slice(1, 2)) === 4)[0].sensorType;

        if (sensorType === "") {
            gSnipImage ? sensorType = 'wheelsnipactive' : sensorType = 'wheelactive';
        }
        //tyredivclasses = [typeClass + 'axle' + axle + 'wheel', 'supersinglewheel', 'wheel4', sensorType];
        //tyredivclasses = ['wheelrightside', 'supersinglewheel', 'wheel4', sensorType];
        tyredivclasses = ['supersinglewheel', sensorType];
        let tyre4Div = createTyreElement(unit, 4, axleSensors, tyredivclasses);
        tyreAxleDiv.appendChild(tyre4Div);

        let tyreSensorValues4Div = createTyreInfoElement(axle, 4, axleSensors);
        tyreAxleDiv.appendChild(tyreSensorValues4Div);
    } else {
        for (let s = 1; s <= 2; s++) {
            if (gSnipImage)
                sensorType = axleSensors.filter(as => parseInt(as.sensorName.slice(1, 2)) === s)[0].sensorSnipType;
            else
                sensorType = axleSensors.filter(as => parseInt(as.sensorName.slice(1, 2)) === s)[0].sensorType;

            if (sensorType === "") {
                gSnipImage ? sensorType = 'wheelsnipactive' : sensorType = 'wheelactive';
            }
            //tyredivclasses = [typeClass + 'axle' + axle + 'wheel', 'wheel', 'wheel' + s, sensorType];
            //tyredivclasses = ['twowheelsleftside', 'wheel', sensorType];
            let tyredivclasses = ['wheel', sensorType];

            let tyreDiv = createTyreElement(unit, s, axleSensors, tyredivclasses);
            tyreAxleDiv.appendChild(tyreDiv);

            let tyreSensorValuesDiv = createTyreInfoElement(axle, s, axleSensors);
            tyreAxleDiv.appendChild(tyreSensorValuesDiv);
        }

        let axledivclasses = [];
        let axleStatusClass = '';
        isAxleActive ? axleStatusClass = 'axleactive' : axleStatusClass = 'axleinactive';
        axledivclasses = ['axle', 'axleinfo', axleStatusClass];

        let axleDiv = createAxleElement(unit, axle, axleSensors, axledivclasses);
        tyreAxleDiv.appendChild(axleDiv);

        let axleInfoDiv = createAxleInfoElement(unit, axle);
        tyreAxleDiv.appendChild(axleInfoDiv);

        for (let s = 3; s <= 4; s++) {
            if (gSnipImage)
                sensorType = axleSensors.filter(as => parseInt(as.sensorName.slice(1, 2)) === s)[0].sensorSnipType;
            else
                sensorType = axleSensors.filter(as => parseInt(as.sensorName.slice(1, 2)) === s)[0].sensorType;

            if (sensorType === "") {
                gSnipImage ? sensorType = 'wheelsnipactive' : sensorType = 'wheelactive';
            }
            //tyredivclasses = [typeClass + 'axle' + axle + 'wheel', 'wheel', 'wheel' + s, sensorType];
            //tyredivclasses = ['twowheelsrightside', 'wheel', sensorType];
            let tyredivclasses = ['wheel', sensorType];

            let tyreDiv = createTyreElement(unit, s, axleSensors, tyredivclasses);

            tyreAxleDiv.appendChild(tyreDiv);

            let tyreSensorValuesDiv = createTyreInfoElement(axle, s, axleSensors);
            tyreAxleDiv.appendChild(tyreSensorValuesDiv);
        }
    }
    return tyreAxleDiv;
}

function createSpareTyreAxleElements(unit, axleSensors, resourceNotifications, gSnipImage) {

    let isAxleActive = getAxleActiveStatus(resourceNotifications, unit, "17");

    let tyreSpareAxleDiv = document.createElement('div');
    tyreSpareAxleDiv.className = 'diagramaxle';

    let tyredivclasses = ['chartsparewheel', 'wheelinactive'];
    let sensorType1 = 'wheelinactive';
    let sensorType2 = 'wheelinactive';

    if (axleSensors.length > 0) {

        for (let st = 0; st < axleSensors.length; st++) {
            let spare = axleSensors[st].sensorName.slice(1, 2);

            switch (spare) {
                case '1':
                    //gSnipImage ? sensorType1 = axleSensors.filter(as => parseInt(as.sensorName.slice(1, 2)) === 1)[0].sensorSnipType : sensorType1 = axleSensors.filter(as => parseInt(as.sensorName.slice(1, 2)) === 1)[0].sensorType;
                    if (gSnipImage)
                        sensorType1 = axleSensors.filter(as => parseInt(as.sensorName.slice(1, 2)) === 1)[0].sensorSnipType;
                    else
                        sensorType1 = axleSensors.filter(as => parseInt(as.sensorName.slice(1, 2)) === 1)[0].sensorType;

                    if (sensorType1 === "") {
                        gSnipImage ? sensorType1 = 'wheelsnipactive' : sensorType1 = 'wheelactive';
                        //if (gSnipImage)
                        //    ;
                        //else
                        //    sensorType1 = 'wheelactive';
                    }
                    break;
                case '2':
                case '3':
                case '4':
                    //gSnipImage ? sensorType2 = axleSensors.filter(as => parseInt(as.sensorName.slice(1, 2)) === parseInt(spare))[0].sensorSnipType : sensorType2 = axleSensors.filter(as => parseInt(as.sensorName.slice(1, 2)) === parseInt(spare))[0].sensorType;
                    if (gSnipImage)
                        sensorType2 = axleSensors.filter(as => parseInt(as.sensorName.slice(1, 2)) === parseInt(spare))[0].sensorSnipType;
                    else
                        sensorType2 = axleSensors.filter(as => parseInt(as.sensorName.slice(1, 2)) === parseInt(spare))[0].sensorType;


                    if (sensorType2 === "") {
                        /*if (gSnipImage)*/
                        gSnipImage ? sensorType2 = 'wheelsnipactive' : sensorType2 = 'wheelactive';
                        //else
                        //    sensorType2 = 'wheelactive';
                    }
                    break;
                default:
                    sensorType1 = 'wheelinactive';
                    sensorType2 = 'wheelinactive';
            }
        }

        //Spare 1
        let tyredivclasses1 = ['chartsparewheel', sensorType1];
        let tyreDiv1 = createTyreElement(unit, 1, axleSensors, tyredivclasses1);
        tyreSpareAxleDiv.appendChild(tyreDiv1);

        if (sensorType1 !== 'wheelinactive') {
            let tyreSensorValuesDiv1 = createTyreInfoElement("S", 1, axleSensors);
            tyreSpareAxleDiv.appendChild(tyreSensorValuesDiv1);
        }

        //Spare Axle
        let axledivclasses = [];
        let axleStatusClass = '';
        isAxleActive ? axleStatusClass = 'axleactive' : axleStatusClass = 'axleinactive';
        axledivclasses = ['spareaxle', 'axleinfo', axleStatusClass]; //'spareaxle'
        let spareAxle = createAxleElement(unit, "S", axleSensors, axledivclasses); //'spareaxle');
        tyreSpareAxleDiv.appendChild(spareAxle);

        let axleInfoDiv = createAxleInfoElement(unit, 9);
        tyreSpareAxleDiv.appendChild(axleInfoDiv);

        //Spare 2
        let tyredivclasses2 = ['chartsparewheel', sensorType2];
        let tyreDiv2 = createTyreElement(unit, 2, axleSensors, tyredivclasses2);
        tyreSpareAxleDiv.appendChild(tyreDiv2);

        if (sensorType2 !== 'wheelinactive') {
            let tyreSensorValuesDiv2 = createTyreInfoElement("S", 2, axleSensors);
            tyreSpareAxleDiv.appendChild(tyreSensorValuesDiv2);
        }

    } else {

        //Spare 1 Inactive
        let tyreDiv1 = createTyreElement(unit, 1, axleSensors, tyredivclasses);
        tyreSpareAxleDiv.appendChild(tyreDiv1);

        //Spare 2 Inactive
        let tyreDiv2 = createTyreElement(unit, 2, axleSensors, tyredivclasses);
        tyreSpareAxleDiv.appendChild(tyreDiv2);
    }

    return tyreSpareAxleDiv;
}

function createAxleElement(unit, axle, axleSensors, axledivclasses) {

    let axleDiv = document.createElement('div');
    axleDiv.classList.add(...axledivclasses);

    //let unitId = unit.id;
    //let trailerId = 0;
    //if (unit.unitTrailers.length > 0) trailerId = unit.unitTrailers[0].id;
    //+ "<div class='axle axleactive axle1' onclick='openValuesOutOfRangeForm(" + unitId + ", " + trailerId + ", 1, " + JSON.stringify(selectedUnitSensorIds) + ")'></div >"
    axleDiv.onclick = function (e) {
        e.stopImmediatePropagation();
        if (axle >= 4) {
            if (unit.hasOwnProperty('trailers')) {
                if (unit.unitTrailers[0] != null) unit = unit.unitTrailers[0];
            }
        } //set trailer's axle properties
        openValuesOutOfRangeForm(unit, axleSensors);
    };

    return axleDiv;
}

function getAxleActiveStatus(axleStatusNotifications, unit, axle) {

    let notifications = axleStatusNotifications.notifications;
    for (let key in notifications) {
        if (!notifications.hasOwnProperty(key)) continue;
        let obj = notifications[key];
        if (obj.n.substring(0, obj.n.length - 9) === unit.name + "-P" + axle) {
            return true;
        }
    }
    return false;
}

function createTyreElement(unit, tyre, axleSensors, tyredivclasses) {

    let tyreDiv = document.createElement('div');

    if (axleSensors.length > 0)
        tyreDiv.id = 'tyre' + axleSensors[0].sensorName.slice(0, 1) + tyre;

    tyreDiv.classList.add(...tyredivclasses);

    let tyreArrayIndex = getTyreArrayIndex(tyre, axleSensors);

    if (tyreDiv.classList.contains('wheelinactive')) {
        tyreDiv.onclick = function (e) {
            e.stopImmediatePropagation();
            openNewSensorForm(unit, "9" + tyre);
        };
    } else {
        tyreDiv.onclick = function (e) {
            e.stopImmediatePropagation();
            openTyreContextMenu(unit, axleSensors[tyreArrayIndex]);
        };
    }

    return tyreDiv;
}

function createTyreInfoElement(axle, tyre, axleSensors) {

    let sensorvaluesdivclasses = ['wheel' + tyre, 'wheelinfohide'];
    //<div class='axle" + axleNo.toString() + "wheel wheel" + wheelNo + " wheelinfohide'>" + axleWheelValues[k].tableInfo + "</div>
    let sensorValuesDiv = document.createElement('div');
    sensorValuesDiv.classList.add(...sensorvaluesdivclasses);

    let tyreArrayIndex = getTyreArrayIndex(tyre, axleSensors);

    let tyreInfoAxle = "";
    axle === "S" ? tyreInfoAxle = axle : tyreInfoAxle = axleSensors[0].sensorName.slice(0, 1);
    //if (axle === "S") {
    //    tyreInfoAxle = axle;
    //} else {
    //    tyreInfoAxle = axleSensors[0].sensorName.slice(0, 1);
    //}
    let sensorNameDiv = createTyreNameElement(tyreInfoAxle, tyre);
    sensorValuesDiv.appendChild(sensorNameDiv);

    let tyreIdDiv = createTyreIdElement(tyreArrayIndex, axleSensors);
    sensorValuesDiv.appendChild(tyreIdDiv);

    let sensorId = axleSensors[tyreArrayIndex].sensorName.slice(2);
    let sensorIdDiv = createSensorIdElement(sensorId);
    sensorValuesDiv.appendChild(sensorIdDiv);

    let sensorType = axleSensors[tyreArrayIndex].sensorType;
    if (sensorType !== "wheelblue") {
        let sensorPressureDiv = createSensorPressureElement(tyreArrayIndex, axleSensors);
        sensorValuesDiv.appendChild(sensorPressureDiv);
        let sensorTemperatureDiv = createSensorTemperatureElement(tyreArrayIndex, axleSensors);
        sensorValuesDiv.appendChild(sensorTemperatureDiv);
        let sensorVoltageDiv = createSensorVoltageElement(tyreArrayIndex, axleSensors);
        sensorValuesDiv.appendChild(sensorVoltageDiv);
    } else {
        let sensorNoSignalDiv = createSensorNoSignalElement();
        sensorValuesDiv.appendChild(sensorNoSignalDiv);
    }

    return sensorValuesDiv;
}

//function createAxleInfoElement(unit, axle) {

//    let mrpValue = 0;
//    let pdaValue = 0;
//    let minPressureValue = 0;
//    let maxPressureValue = 0;
//    let maxTempValue = 0;
//    let minVoltageValue = 0;

//    for (let s = 0; s <= unit.sensors.length; s++) {
//        if (axle === parseInt(unit.sensors[s].sensorName.slice(0, 1))) {
//            mrpValue = unit.sensors[s].manufacturersRecommendedPressure;
//            pdaValue = unit.sensors[s].percentageDeviationAllowed;
//            minPressureValue = unit.sensors[s].minPressureValue;
//            maxPressureValue = unit.sensors[s].maxPressureValue;
//            maxTempValue = unit.sensors[s].maxTemperatureValue;
//            minVoltageValue = unit.sensors[s].minVoltageValue;
//            break;
//        }
//    }

//    let axleinfodivclasses = ['axleinfo', 'axleinfohide'];
//    let axleInfoDiv = document.createElement('div');
//    axleInfoDiv.classList.add(...axleinfodivclasses);

//    let mrpDiv = createManufacturersRecommendedPressureElement(mrpValue);
//    axleInfoDiv.appendChild(mrpDiv);

//    let pdaDiv = createPercentageDeviationAllowedElement(pdaValue);
//    axleInfoDiv.appendChild(pdaDiv);

//    let minPressureDiv = createMinPressureValueElement(minPressureValue);
//    axleInfoDiv.appendChild(minPressureDiv);

//    let maxPressureDiv = createMaxPressureValueElement(maxPressureValue);
//    axleInfoDiv.appendChild(maxPressureDiv);

//    let maxTempDiv = createMaxTemperatureValueElement(maxTempValue);
//    axleInfoDiv.appendChild(maxTempDiv);

//    let minVoltageDiv = createMinVoltageValueElement(minVoltageValue);
//    axleInfoDiv.appendChild(minVoltageDiv);

//    return axleInfoDiv;
//}

//function createPercentageDeviationAllowedElement(pdaValue) {

//    if (pdaValue == null || pdaValue == "null" || pdaValue == "") pdaValue = "--";

//    let pdaDiv = document.createElement('div');

//    let labelSpan = document.createElement('span');
//    let labelSpanText = document.createTextNode("Deviation (%): ");
//    labelSpan.appendChild(labelSpanText);

//    let valueSpan = document.createElement('span');
//    let valueSpanText = document.createTextNode(pdaValue);
//    valueSpan.className = 'boldtextmaxminvalue';
//    valueSpan.appendChild(valueSpanText);

//    let pdaSpan = document.createElement('span');
//    pdaSpan.appendChild(labelSpan);
//    pdaSpan.appendChild(valueSpan);

//    pdaDiv.appendChild(pdaSpan);

//    return pdaDiv;
//}

//function createMinPressureValueElement(minPressurevalue) {

//    if (minPressurevalue == null || minPressurevalue == "null" || minPressurevalue == "") minPressurevalue = "--";

//    let minPressureDiv = document.createElement('div');

//    let labelSpan = document.createElement('span');
//    let labelSpanText = document.createTextNode("Min Pressure (bar): ");
//    labelSpan.appendChild(labelSpanText);

//    let valueSpan = document.createElement('span');
//    let valueSpanText = document.createTextNode(minPressurevalue);
//    valueSpan.className = 'boldtextmaxminvalue pressurered';
//    valueSpan.appendChild(valueSpanText);

//    let minPressureSpan = document.createElement('span');
//    minPressureSpan.appendChild(labelSpan);
//    minPressureSpan.appendChild(valueSpan);

//    minPressureDiv.appendChild(minPressureSpan);

//    return minPressureDiv;
//}

//function createMaxPressureValueElement(maxPressureValue) {

//    if (maxPressureValue == null || maxPressureValue == "null" || maxPressureValue == "") maxPressureValue = "--";

//    let maxPressureDiv = document.createElement('div');

//    let labelSpan = document.createElement('span');
//    let labelSpanText = document.createTextNode("Max Pressure (bar): ");
//    labelSpan.appendChild(labelSpanText);

//    let valueSpan = document.createElement('span');
//    let valueSpanText = document.createTextNode(maxPressureValue); 
//    valueSpan.className = 'boldtextmaxminvalue pressurepurple';
//    valueSpan.appendChild(valueSpanText);

//    let maxPressureSpan = document.createElement('span');
//    maxPressureSpan.appendChild(labelSpan);
//    maxPressureSpan.appendChild(valueSpan);

//    maxPressureDiv.appendChild(maxPressureSpan);

//    return maxPressureDiv;
//}

async function openNotificationsForm(unitId) {

    document.body.style.cursor = 'wait';
    $('#container').overlayMask();
    let temp = global.selectedUnitId;
    let notificationsmodal = document.getElementById('notificationsmodal');

    let span = document.getElementById("closenotificationsmodal");
    span.onclick = function (e) {
        e.stopImmediatePropagation();
        notificationsmodal.style.display = "none";
    };

    //window.onclick = function (event) {
    //    event.stopImmediatePropagation();
    //    if (event.target == notificationsmodal) {
    //        notificationsmodal.style.display = "none";
    //    }
    //};

    let pressureMetricFound = false;
    let temperatureMetricFound = false;
    let voltageMetricFound = false;

    document.getElementById('nfUnitId').value = unitId;
    document.getElementById('nfPressureCheck').checked = pressureMetricFound;
    document.getElementById('nfTemperatureCheck').checked = temperatureMetricFound;
    document.getElementById('nfVoltageCheck').checked = voltageMetricFound;
    document.getElementById('nfTelegramChannelId').value = '';
    document.getElementById('nfEmailAddress1').value = '';
    document.getElementById('nfEmailAddress2').value = '';
    document.getElementById('nfEmailAddress3').value = '';
    
    gNotificationCounterCheck = 0;
    gNotificationsIds = [];
    let notificationCounterCheck = 0;
    let notificationsIds = [];
    let notificationMethodsFound = false;
    //let resourceNotifications = await getResourceNotifications();  
    //let allNotifications = resourceNotifications.notifications;

    let notificationData = await getNotificationData();
    for (const key in notificationData) {
        // skip loop if the property is from prototype
        //if (!allNotifications.hasOwnProperty(key)) continue;
        
        let obj = notificationData[key];

        if ((obj.un[0] === unitId)) {
            notificationCounterCheck++;
            notificationsIds.push(obj.id);
            if (!notificationMethodsFound) {
                let sensorMetric = obj.n.slice(-5, -4);
                let notificationMethods = obj.act;
                for (let m = 0; m < notificationMethods.length; m++) {
                    if (notificationMethods[m].t === "email") {
                        let emailAddressesStr = notificationMethods[m].p.email_to;
                        var emailAddresses = emailAddressesStr.split(';');
                        let emailCount = 0;
                        for (let e = 0; e < emailAddresses.length; e++) {
                            emailCount++;
                            if (emailCount <= 3) {
                                if (emailAddresses[e] !== null && emailAddresses[e] !== "") {
                                    let emailAddressElement = 'nfEmailAddress' + (emailCount).toString();
                                    document.getElementById(emailAddressElement).value = emailAddresses[e];
                                }
                                else emailCount--;
                            }
                        }
                        if (sensorMetric === 'P') pressureMetricFound = true;
                        if (sensorMetric === 'T') temperatureMetricFound = true;
                        if (sensorMetric === 'V') voltageMetricFound = true;
                    }
                    if (notificationMethods[m].t === "messenger_messages") {
                        document.getElementById('nfTelegramChannelId').value = notificationMethods[m].p.chat_id;
                        if (sensorMetric === 'P') pressureMetricFound = true;
                        if (sensorMetric === 'T') temperatureMetricFound = true;
                        if (sensorMetric === 'V') voltageMetricFound = true;
                    } 
                }
            }
            if (pressureMetricFound && temperatureMetricFound && voltageMetricFound) notificationMethodsFound = true;
        }        
    }

    gNotificationCounterCheck = notificationCounterCheck;
    gNotificationsIds = [...notificationsIds]

    if (pressureMetricFound) {
        document.getElementById('nfPressureCheck').checked = pressureMetricFound;
    }
    if (temperatureMetricFound) {
        document.getElementById('nfTemperatureCheck').checked = temperatureMetricFound;
    }
    if (voltageMetricFound) {
        document.getElementById('nfVoltageCheck').checked = voltageMetricFound;
    }

    let notificationsform = document.getElementById('notificationsform');
    notificationsform.addEventListener('submit', function (e) {
        try {
            submitNotificationsForm();
            e.preventDefault();
            e.stopImmediatePropagation();           
        } catch (err) {
            console.error(`wheelsdiagramJS - openNotificationsForm ${err}`);
            setMainInterval(global.units);
        }
    });

    $('#container').overlayMask('hide');
    document.body.style.cursor = 'default';
    document.getElementById("notificationsmodal").style.display = 'block';
}

function submitNotificationsForm() {

    document.getElementById("loadingBoxText").innerText = "Updating notification data... ";
    $("#loadingBox").modal({
        backdrop: "static", //remove ability to close modal with click
        keyboard: false, //remove option to close with keyboard
        show: true //Display loader!
    });    

    let notificationParams = {};
    notificationParams.id = document.getElementById('nfUnitId').value;
    notificationParams.pressureCheck = document.getElementById('nfPressureCheck').checked;
    notificationParams.temperatureCheck = document.getElementById('nfTemperatureCheck').checked;
    notificationParams.voltageCheck = document.getElementById('nfVoltageCheck').checked;

    notificationParams.telegramBotToken = "6509137951:AAFJF55C9Ly4bZzO9yB1u26RSdwn6pqOYaU";
    if (document.getElementById('nfTelegramChannelId').value !== null && document.getElementById('nfTelegramChannelId').value !== "") {
        notificationParams.telegramChannelId = document.getElementById('nfTelegramChannelId').value.trim();
    } else {
        notificationParams.telegramChannelId = "";
    }

    let emailAddresses = "";
    if (document.getElementById('nfEmailAddress1').value.trim()) {
        emailAddresses = document.getElementById('nfEmailAddress1').value.trim();
    }
    if (document.getElementById('nfEmailAddress2').value.trim()) {
        if (emailAddresses === "")
            emailAddresses = document.getElementById('nfEmailAddress2').value.trim();
        else
        emailAddresses = emailAddresses + ";" + document.getElementById('nfEmailAddress2').value.trim();
    }
    if (document.getElementById('nfEmailAddress3').value.trim()) {
        if (emailAddresses === "")
            emailAddresses = document.getElementById('nfEmailAddress3').value.trim();
            else
            emailAddresses = emailAddresses + ";" + document.getElementById('nfEmailAddress3').value.trim();
    }
    notificationParams.emailAddress = emailAddresses;

    const notificationsIds = gNotificationsIds;
    const notificationCounterCheck = gNotificationCounterCheck;
    updateUnitNotifications(notificationParams, notificationsIds, notificationCounterCheck);
            //let isUpdated = updateUnitNotifications(notificationParams);        
            //if (isUpdated) {
            //    $('#loadingBox').modal('hide');
            //    document.body.style.cursor = 'default';
            //}
    document.getElementById("notificationsmodal").style.display = 'none';
    return false;
}

async function openValuesOutOfRangeForm(unit, axleSensors) {

    onClickCloseModal('valuesoutofrangemodal', 'closevaluesoutofrangemodal');
    //const span = document.getElementById("closevaluesoutofrangemodal");
    //span.onclick = function (e) {
    //    e.stopImmediatePropagation();
    //    valuesoutofrangemodal.style.display = "none";
    //};
    //window.onclick = function (event) {
    //    event.stopImmediatePropagation();
    //    if (event.target == valuesoutofrangemodal) {
    //        valuesoutofrangemodal.style.display = "none";
    //    }
    //};

    const valuesOutOfRangeForm = document.getElementById('valuesoutofrangeform');
    valuesOutOfRangeForm.addEventListener('submit', function (e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        submitValuesOutOfRangeForm(unit);
    });

    //let session = global.session;
    //if (!session) session = wialon.core.Session.getInstance();
    //const wUnit = session.getItem(unit.id);    

    if (document.contains(document.getElementById("wheelsinfo"))) {
        document.getElementById("wheelsinfo").remove();
        const wheelsInfoDiv = document.createElement('div');
        wheelsInfoDiv.id = 'wheelsinfo';
        valuesoutofrangeform.appendChild(wheelsInfoDiv);
    } else {
        const wheelsInfoDiv = document.createElement('div');
        wheelsInfoDiv.id = 'wheelsinfo';
        valuesoutofrangeform.appendChild(wheelsInfoDiv);
    }

    document.getElementById('unitId').value = unit.id;
    //if (unit.unitLinkedToId)
    //    document.getElementById('unitLinkedToId').value = unit.unitLinkedToId;

    //document.getElementById("vormUnitImg").src = wUnit.getIconUrl(64);
    document.getElementById("vormUnitName").value = unit.name;
    const unitNumber = axleSensors[0].sensorName.slice(-1);
    const axle = axleSensors[0].sensorName.slice(1, 3);
    if (axle === SPARE_AXLE.toString())
        document.getElementById('axle').value = "Spare Axle";
    else
        document.getElementById('axle').value = "Axle " + axle;

    document.getElementById('minPressure').value = 2.4;
    document.getElementById('maxPressure').value = 3.6;
    //document.getElementById('maxTemperature').value = '';
    //document.getElementById('minVoltage').value = '';

    const unitNumberInput = document.createElement('input');
    unitNumberInput.type = 'hidden';
    unitNumberInput.id = 'unitnumber';
    unitNumberInput.value = unitNumber;
    wheelsinfo.appendChild(unitNumberInput);

    const wheelConfigInput = document.createElement('input');
    wheelConfigInput.type = 'hidden';
    wheelConfigInput.id = 'wheelconfig';

    let wheelConfig = null;
    if (unit && unit.locationSensors && axleSensors && axleSensors[0] && axleSensors[0].sensorName) {
        wheelConfig = unit.locationSensors.filter(s => s.axleUnit === axleSensors[0].sensorName.slice(1))[0]?.wheelConfig || null;
    }
    
    if (wheelConfig == null) {
        //assumption made that first axle will have wheelconfig = 0 
        if (parseInt(axleSensors[0].sensorName.slice(1, 3)) === 1) {
            wheelConfig = 0;
        } else {
            //assumption made that all axles after first axle will have the same wheelconfig
            const locationSensors = unit.locationSensors.filter(ls => parseInt(ls.axle) > 1);
            for (let s = 0; s < locationSensors.length; s++) {
                wheelConfig = unit.locationSensors[s]?.wheelConfig;
                if (wheelConfig >= 0) break;
            }
            if (wheelConfig == null) wheelConfig = 1; //fallback - can also check axleSensors.length for bettter fallback
        }
    }
    wheelConfigInput.value = wheelConfig;
    wheelsinfo.appendChild(wheelConfigInput);

    //let notificationId = 0;
    document.getElementById('notificationId').value = 0;
    let resourceNotifications = await getResourceNotifications();
    let allNotifications = resourceNotifications.notifications;
    for (let key in allNotifications) {
        //skip loop if the property is from prototype
        if (!allNotifications.hasOwnProperty(key)) continue;

        var obj = allNotifications[key];
        if (obj.un[0] === unit.id) {

            const notificationUnitNumber = obj.n.slice(-1);
            const notificationAxle = obj.n.slice(-3, -1);
            
            if (notificationUnitNumber === unitNumber && notificationAxle === axle) {
                document.getElementById('notificationId').value = obj.id;
                let sensorMetric = obj.n.slice(-5, -4);
                if (sensorMetric === 'P') {
                    document.getElementById('minPressure').value = obj.trg_p.lower_bound;
                    document.getElementById('maxPressure').value = obj.trg_p.upper_bound;
                }
                if (sensorMetric === 'T') {
                    document.getElementById('maxTemperature').value = obj.trg_p.upper_bound;                        
                }
                if (sensorMetric === 'V') {
                    document.getElementById('minVoltage').value = obj.trg_p.lower_bound;                    
                }
            }
        }
    }

    let manufacturersRecommendedPressure = document.getElementById('manufacturersRecommendedPressure');
    manufacturersRecommendedPressure.addEventListener('input', function (e) {
        e.preventDefault(
        );
        e.stopImmediatePropagation();
        calculateMinMaxPressure();
    });

    let percentageDeviationAllowed = document.getElementById('percentageDeviationAllowed');
    percentageDeviationAllowed.addEventListener('input', function (e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        calculateMinMaxPressure();
    });

    const axleInfoMrpValue = parseFloat(document.getElementById(`a${axle}${unitNumber}mrp`).textContent);
    const axleInfoPpdValue = parseFloat(document.getElementById(`a${axle}${unitNumber}ppd`).textContent);
    manufacturersRecommendedPressure.value = getFallbackValue(axleSensors, 'manufacturersRecommendedPressure', axleInfoMrpValue);
    percentageDeviationAllowed.value = getFallbackValue(axleSensors, 'percentageDeviationAllowed', axleInfoPpdValue);

    document.getElementById("valuesoutofrangemodal").style.display = "block";
}

function onClickCloseModal(modalToClose, modalCloseButton) {

        //const span = document.getElementById("closevaluesoutofrangemodal");
    //span.onclick = function (e) {
    //    e.stopImmediatePropagation();
    //    valuesoutofrangemodal.style.display = "none";
    //};

    const closeButton = document.getElementById(modalCloseButton);
    closeButton.onclick = function (e) {
        e.stopImmediatePropagation();
        document.getElementById(modalToClose).style.display = "none";
    };

}

function showCustomWait(info) {
    document.getElementById("loadingBoxText").innerText = info;
    $("#loadingBox").modal({
        backdrop: "static", //remove ability to close modal with click
        keyboard: false, //remove option to close with keyboard
        show: true //Display loader!
    });
}

function calculateMinMaxPressure(textbox) {

    let manufacturersRecommendedPressure = parseFloat(document.getElementById('manufacturersRecommendedPressure').value);
    let percentageDeviationAllowed = parseFloat(document.getElementById('percentageDeviationAllowed').value);
    if (manufacturersRecommendedPressure && percentageDeviationAllowed) {
        document.getElementById("minPressure").value = calculateMinPressure(parseFloat(manufacturersRecommendedPressure), parseFloat(percentageDeviationAllowed));
        document.getElementById("maxPressure").value = calculateMaxPressure(parseFloat(manufacturersRecommendedPressure), parseFloat(percentageDeviationAllowed));
    }

    return true;
}

function calculateMinPressure(manufacturersRecommendedPressure, percentageDeviationAllowed) {

    let minPressure = (manufacturersRecommendedPressure - (percentageDeviationAllowed / 100 * manufacturersRecommendedPressure));
    return Math.round(minPressure * 100) / 100;
}

function calculateMaxPressure(manufacturersRecommendedPressure, percentageDeviationAllowed) {

    let maxPressure = (manufacturersRecommendedPressure + (percentageDeviationAllowed / 100 * manufacturersRecommendedPressure));
    return Math.round(maxPressure * 100) / 100;
}

async function submitValuesOutOfRangeForm(unit) {

    manufacturersRecommendedPressureValidation();
    percentageDeviationAllowedValidation();

    //document.body.style.cursor = 'wait';
    //$('#container').overlayMask();
    showCustomWait("Updating 'sensor values out of range' data... ")
    //document.getElementById("loadingBoxText").innerText = "Updating 'sensor values out of range' data... ";
    //$("#loadingBox").modal({
    //    backdrop: "static", //remove ability to close modal with click
    //    keyboard: false, //remove option to close with keyboard
    //    show: true //Display loader!
    //});

    let valuesOutOfRangeParams = {};
    valuesOutOfRangeParams.notificationId = document.getElementById('notificationId').value;
    valuesOutOfRangeParams.id = document.getElementById('unitId').value;
    const unitNumber = document.getElementById('unitnumber').value;
    valuesOutOfRangeParams.unitNumber = unitNumber;
    //if (document.getElementById('unitLinkedToId').value)
    //    valuesOutOfRangeParams.unitLinkedToId = document.getElementById('unitLinkedToId').value;
    valuesOutOfRangeParams.name = document.getElementById("vormUnitName").value;
    let axleName = document.getElementById('axle').value;
    let axle = addLeadingZero(parseInt(document.getElementById('axle').value.slice(-2))); 
    if (axleName === "Spare Axle")
        axle = "17";
    valuesOutOfRangeParams.axle = axle;

    const wheelConfig = document.getElementById('wheelconfig').value;
    let wheels = [];
    if (wheelConfig === "0" && axle !== SPARE_AXLE.toString()) {
        wheels.push("1" + axle + valuesOutOfRangeParams.unitNumber);
        wheels.push("4" + axle + valuesOutOfRangeParams.unitNumber);
    }
    if (wheelConfig === "1" && axle !== SPARE_AXLE.toString()) {
        //valuesOutOfRangeParams.wheel1 = document.getElementById("wheel1").value;
        wheels.push("1" + axle + valuesOutOfRangeParams.unitNumber);
        wheels.push("2" + axle + valuesOutOfRangeParams.unitNumber);
        wheels.push("3" + axle + valuesOutOfRangeParams.unitNumber);
        wheels.push("4" + axle + valuesOutOfRangeParams.unitNumber);
    }
    if (axle === SPARE_AXLE.toString()) {
        wheels.push("1170");
        wheels.push("2170");
        wheels.push("3170");
        wheels.push("4170");
    } 

    valuesOutOfRangeParams.wheels = wheels;

    const manufacturersRecommendedPressure = parseFloat(document.getElementById("manufacturersRecommendedPressure").value);
    valuesOutOfRangeParams.manufacturersRecommendedPressure = manufacturersRecommendedPressure;
    const percentagePressureDeviation = parseFloat(document.getElementById("percentageDeviationAllowed").value);
    valuesOutOfRangeParams.percentageDeviationAllowed = percentagePressureDeviation;

    let minPressure = parseFloat(document.getElementById("minPressure").value);
    let maxPressure = parseFloat(document.getElementById("maxPressure").value);

    if ((minPressure == null || Number.isNaN(minPressure)) && (maxPressure == null || Number.isNaN(maxPressure))) {
        minPressure = 2.4;
        maxPressure = 3.6;
    }

    if ((minPressure == null || Number.isNaN(minPressure)) && maxPressure) {
        minPressure = maxPressure - 1.2;
    }

    if (minPressure && (maxPressure == null || Number.isNaN(maxPressure))) {
        maxPressure = minPressure + 1.2;
    }

    valuesOutOfRangeParams.minPressure = minPressure;
    valuesOutOfRangeParams.maxPressure = maxPressure;

    valuesOutOfRangeParams.minTemperature = -18;
    let maxTemperature = 65;
    if (document.getElementById('maxTemperature').value) {
        maxTemperature = parseInt(document.getElementById('maxTemperature').value);
        valuesOutOfRangeParams.maxTemperature = maxTemperature;
    }  else {
        valuesOutOfRangeParams.maxTemperature = 65;
    }

    let minVoltage = 2.65;
    if (document.getElementById('minVoltage').value) {
        minVoltage = parseFloat(document.getElementById('minVoltage').value);
        valuesOutOfRangeParams.minVoltage = minVoltage;
    } else {
        valuesOutOfRangeParams.minVoltage = 2.65;
    }
    valuesOutOfRangeParams.maxVoltage = 5.0;

    setUnitValuesOutOfRangeNotifications(valuesOutOfRangeParams);
    await setAxleSensorPressureMetricAttributes(valuesOutOfRangeParams);
    //setUnitValuesOutOfRangeNotifications(valuesOutOfRangeParams);

    //document.getElementById("loadingBox").modal("hide");
    //document.body.style.cursor = 'default';
    //$("#loadingBox").modal("hide");
    //await getUnitPressureMetricAttributes(unit);
    updateValuesOutOfRangeValue(`a${axle}${unitNumber}mrp`, manufacturersRecommendedPressure);
    updateValuesOutOfRangeValue(`a${axle}${unitNumber}ppd`, percentagePressureDeviation);
    updateValuesOutOfRangeValue(`a${axle}${unitNumber}minpressure`, minPressure);
    updateValuesOutOfRangeValue(`a${axle}${unitNumber}maxpressure`, maxPressure);
    updateValuesOutOfRangeValue(`a${axle}${unitNumber}maxtemperature`, maxTemperature);
    updateValuesOutOfRangeValue(`a${axle}${unitNumber}minvoltage`, minVoltage);

    if (axle === SPARE_AXLE.toString()) {
        unit.spareSensors.forEach(s => {
            if (wheels.includes(s.sensorName)) {
                s.manufacturersRecommendedPressure = manufacturersRecommendedPressure;
                s.percentageDeviationAllowed = percentagePressureDeviation;
                s.minPressureValue = minPressure;
                s.maxPressureValue = maxPressure;
                s.maxTemperatureValue = maxTemperature;
                s.minVoltageValue = minVoltage;
            }
        });
    } else {
        unit.wheelSensors.forEach(s => {
            if (wheels.includes(s.sensorName)) {
                s.manufacturersRecommendedPressure = manufacturersRecommendedPressure;
                s.percentageDeviationAllowed = percentagePressureDeviation;
                s.minPressureValue = minPressure;
                s.maxPressureValue = maxPressure;
                s.maxTemperatureValue = maxTemperature;
                s.minVoltageValue = minVoltage;
            }
        });
    }

    document.getElementById("valuesoutofrangemodal").style.display = "none"; //valuesoutofrangemodal
    await displayUpdatedData(unit);   

    return false;
}

function updateValuesOutOfRangeValue(valueSpanId, newValue) {
    let valueSpan = document.getElementById(valueSpanId);
    if (valueSpan) {
        valueSpan.textContent = newValue;
    }
}

function manufacturersRecommendedPressureValidation() {

    if (parseFloat(document.getElementById('manufacturersRecommendedPressure').value) < 1 || parseFloat(document.getElementById('manufacturersRecommendedPressure').value) > 9)
        document.getElementById('manufacturersRecommendedPressure').setCustomValidity("Please enter a value between 1.0 and 12.0 bar");

    return;
}

function percentageDeviationAllowedValidation() {
    if (parseFloat(document.getElementById('percentageDeviationAllowed').value) < 10 || parseFloat(document.getElementById('percentageDeviationAllowed').value) > 35)
        document.getElementById('percentageDeviationAllowed').setCustomValidity("Please enter a value not less than 10%");

    return;
}

async function setAxleSensorPressureMetricAttributes(valuesOutOfRangeParams) {

    try {

        global.pressureMetricAttributesUpdated = false;

        let session = global.session;
        session.loadLibrary("itemCustomFields");

        let flags = wialon.util.Number.or(wialon.item.Item.dataFlag.base, wialon.item.Item.dataFlag.customFields, wialon.item.Item.dataFlag.adminFields);

        let axleMRPValue = false;
        let axlePPDValue = false;

        return await new Promise((resolve, reject) => {
            session.updateDataFlags(
                [{ type: "type", data: "avl_unit", flags: flags, mode: 0 }],
                (error) => {

                    if (error) {
                        console.log("pressuretrackJS: setAxleSensorPressureMetricAttributes - API ERROR " + error + " (" + wialon.core.Errors.getErrorText(error)) + ")";
                        reject({ type: 'API_ERROR', error: error });
                        return;
                    }

                    let unit = session.getItem(valuesOutOfRangeParams.id);

                    let customFields = unit.getCustomFields();
                    //cur_unit.updateCustomField({ id: prop_id, n: name, v: value });
                    for (let cf in customFields) {  // construct select list

                        if (customFields[cf].n.trim() === valuesOutOfRangeParams.axle + valuesOutOfRangeParams.unitNumber + "MRP".trim()) {
                            unit.updateCustomField({ id: customFields[cf].id, n: customFields[cf].n, v: valuesOutOfRangeParams.manufacturersRecommendedPressure });
                            //axleSensorPressureMetricAttributes.manufacturersRecommendedPressure = customFields[cf].v;
                            axleMRPValue = true;
                        }

                        if (customFields[cf].n.trim() === valuesOutOfRangeParams.axle + valuesOutOfRangeParams.unitNumber +  "PPD".trim()) {
                            unit.updateCustomField({ id: customFields[cf].id, n: customFields[cf].n, v: valuesOutOfRangeParams.percentageDeviationAllowed });
                            axlePPDValue = true;
                        }
                    }

                    if (axleMRPValue && axlePPDValue)
                        resolve(true);
                }
            );
        });
    } catch (e) {
        console.error('unitschematicdiagramJS: setAxleSensorPressureMetricAttributes - ERROR: ', e);
        setMainInterval(global.units);
    }
}

export { openValuesOutOfRangeForm, openNotificationsForm, createUnitElement };

