import { formatDateTime } from "../utilities";
import { getUnitData } from "../data/getunitdata";

import { displayUnitInfoView, showUnitOnMap } from "./views/unitinfo";
import { displayUnitsList, getWheelProperties, getUnitPressureMetricAttributes } from "./views/units";
import { createDiagramView } from "./views/unitdiagrams/uds";

export async function displayUnitData(unit) {
    
    global.selectedUnitId = unit;

    await getUnitPressureMetricAttributes(unit);
    //const resource = await getResource();
    //const unitConfig = getUnitConfig(unitdata);
    //unit.sensorCount = countUnitSensors(unitConfig);
    //unit.unitConfig = unitConfig;
    //unit.unitLocation = (await getUnitLocation(unit.position))[0];
    //unit.trailers = await getUnitTrailers(resource, unit);
    //units = await getUnitConfigs(units);
    //units = getSensorValueFlags(units);
    //global.units = units;

    displayDetailPage(unit);

    if (global.detailIntervalId) {
        clearInterval(global.detailIntervalId);
        //console.log(`CLEAR Detail Interval Id: ${global.detailIntervalId}`);
    }

    global.detailIntervalId = setInterval(async () => {
        displayUpdatedData(unit);
    }, 35000);
}

export async function displayUpdatedData(unit) {

    const updatedUnit = await getUnitData(unit.id);
    const updatedWheelSensors = updatedUnit[0].wheelSensors;
    const updatedSpareSensors = updatedUnit[0].spareSensors;
    await getUnitPressureMetricAttributes(updatedUnit[0]);
    getWheelProperties(updatedWheelSensors);
    getWheelProperties(updatedSpareSensors);

        if (document.getElementById('unitinfodate')) {
            document.getElementById('unitinfodate').innerText = `Last Message: ${updatedUnit[0].lastMessage}`;
            document.getElementById('unitinfolocation').innerText = `Location: ${updatedUnit[0].location}`;
            document.getElementById('unitinfospeed').innerText = `Speed: ${updatedUnit[0].speed} km/h`;
            showUnitOnMap(updatedUnit[0].position);

        }
        if (document.getElementById('sensorvaluesdiagramdate')) {
            document.getElementById('sensorvaluesdiagramdate').innerText = updatedUnit[0].lastMessage;
        }

        let sensorsDiff = [];

        updatedWheelSensors.forEach(sensorUpdated => {            
            let wheelSensor = unit.wheelSensors.find(s => s.sensorName === sensorUpdated.sensorName);

            if (wheelSensor) {
                if (wheelSensor.sensorId !== sensorUpdated.sensorId ||
                    wheelSensor.pressureValue !== sensorUpdated.pressureValue ||
                    wheelSensor.maxPressureValue !== sensorUpdated.maxPressureValue ||
                    wheelSensor.temperatureValue !== sensorUpdated.temperatureValue ||
                    wheelSensor.maxTemperatureValue !== sensorUpdated.maxTemperatureValue ||
                    wheelSensor.voltageValue !== sensorUpdated.voltageValue ||
                    wheelSensor.minVoltageValue !== sensorUpdated.minVoltageValue ||
                    wheelSensor.pressureLabelColour !== sensorUpdated.pressureLabelColour ||
                    wheelSensor.sensorType !== sensorUpdated.sensorType) {
                        sensorsDiff.push(sensorUpdated);
                    }
            } else {
                sensorsDiff.push(sensorUpdated);
            }
        });

        updatedSpareSensors.forEach(spareUpdated => {
            // Find corresponding object in arrayA based on sensorN
            let spareSensor = unit.spareSensors.find(s => s.sensorName === spareUpdated.sensorName);

            if (spareSensor) {
                if (spareSensor.sensorId !== spareUpdated.sensorId ||
                    spareSensor.pressureValue !== spareUpdated.pressureValue ||
                    spareSensor.maxPressureValue !== spareUpdated.maxPressureValue ||
                    spareSensor.temperatureValue !== spareUpdated.temperatureValue ||
                    spareSensor.maxTemperatureValue !== spareUpdated.maxTemperatureValue ||
                    spareSensor.voltageValue !== spareUpdated.voltageValue ||
                    spareSensor.minVoltageValue !== spareUpdated.minVoltageValue ||
                    spareSensor.pressureLabelColour !== spareUpdated.pressureLabelColour ||
                    spareSensor.sensorType !== spareUpdated.sensorType) {

                    sensorsDiff.push(spareUpdated);
                }
            } else {
                sensorsDiff.push(spareUpdated);
            }
        });

        //unit.unitLocation = (await getUnitLocation(unit.position))[0];
        //unit.trailers = await getUnitTrailers(resource, units[u]);
        //units = await getUnitConfigs(units);
        //units = getSensorValueFlags(units);
        //global.units = units;

    if (sensorsDiff.length > 0) {
        unit.lastMessage = updatedUnit[0].lastMessage;
        unit.wheelSensors = updatedWheelSensors;
        unit.spareSensors = updatedSpareSensors;       

        for (let s = 0; s < sensorsDiff.length; s++) {
            if (document.getElementById(`wheel${sensorsDiff[s].sensorName}`)) {
                if (sensorsDiff[s].sensorType === '') sensorsDiff[s].sensorType = 'wheelcorrect';
                document.getElementById(`wheel${sensorsDiff[s].sensorName}`).className = `wheeldefault ${sensorsDiff[s].sensorType}`;
            }
            if (document.getElementById(`sv${sensorsDiff[s].sensorName}sensorid`)) {
                document.getElementById(`sv${sensorsDiff[s].sensorName}sensorid`).innerText = sensorsDiff[s].sensorId;
            }
            if (document.getElementById(`sv${sensorsDiff[s].sensorName}pressure`)) { 
                document.getElementById(`sv${sensorsDiff[s].sensorName}pressure`).innerText = `${sensorsDiff[s].pressureValue} bar`;
                document.getElementById(`sv${sensorsDiff[s].sensorName}pressure`).className = `sensorvalues ${sensorsDiff[s].pressureLabelColour}`;               
            }
            if (document.getElementById(`sv${sensorsDiff[s].sensorName}temperature`)) { 
                document.getElementById(`sv${sensorsDiff[s].sensorName}temperature`).innerText = `${sensorsDiff[s].temperatureValue} C`;
                document.getElementById(`sv${sensorsDiff[s].sensorName}temperature`).className = `sensorvalues ${sensorsDiff[s].temperatureLabelColour}`;
            }
            if (document.getElementById(`sv${sensorsDiff[s].sensorName}voltage`)) {
                document.getElementById(`sv${sensorsDiff[s].sensorName}voltage`).innerText = `${sensorsDiff[s].voltageValue} V`;
                document.getElementById(`sv${sensorsDiff[s].sensorName}voltage`).className = `sensorvalues ${sensorsDiff[s].voltageLabelColour}`;
            }
        }
    }
}

export function displayDetailPage(unit) {

    global.showMainPane = false;
    let units = global.units;

    document.body.style.cursor = 'wait';
    displayUnitsList(units, unit.id);
    displayUnitInfoView(units, unit.id);
    displayDiagramViews(unit);   
    
    document.getElementById('detailspane').style.display = 'block';
    removeMainPane();
    document.body.style.cursor = 'default';
    global.showMainPane = false;
}

function removeMainPane() {

    let unitsListMain = document.getElementById('unitslistmain');
    while (unitsListMain.hasChildNodes()) {
        unitsListMain.removeChild(unitsListMain.lastChild);
    }
    unitsListMain.style.display = "none";

    let mainPane = document.getElementById('mainpane');
    mainPane.style.display = "none";
}

export function displayDiagramViews(unit) {
    createDiagramView(unit, 'WHEELSDIAGRAM', 'rightpane');
    if (!global.sensorValuesDiagramDateSelected) {
        createDiagramView(unit, 'SENSORVALUESDIAGRAM', 'middlepane');
    }
}
