import { sensorValues } from "./data";
import { getUnitData } from "./getunitdata";
import { loadSensorValuesBatch } from "../components/views/units";


export async function getRepeaterData(unit) {   

    //if (unit.name === "Test Wialon IPS 2" || unit.name === "GalileoSky7x") {
        getLocationSensorValues(unit)
    //} else {
    //    unit.locationSensors = loadLocationSensorValues();
    //}


    const deviceData = await getUnitData("", unit.id);
    unit.deviceType = deviceData.deviceType;
    unit.imei = deviceData.imei;
    unit.phoneNumber = deviceData.phoneNumber;

    return unit;
}

async function getLocationSensorValues(unit) {

    let session = global.session;
    let timeTo = session.getServerTime();
    let timeFrom = timeTo - 2520;
    let msgLoadCount = 0xffffffff; //0xffffffff=4294967295 All messages

    let prms = [];
    let unitId = unit.id;
    let prmsObj = {
        "svc": "messages/load_interval",
        "params": {
            "itemId": unitId,
            "timeFrom": timeFrom,
            "timeTo": timeTo, "flags": 32, "flagsMask": 0, "loadCount": msgLoadCount
        }
    };
    prms.push(prmsObj);

    if (unit.unitTrailers.length > 0) {
        for (let t = 0; t < unit.unitTrailers.length; t++) {
            let prmsObj = {
                "svc": "messages/load_interval",
                "params": {
                    "itemId": unit.unitTrailers[t].id,
                    "timeFrom": timeFrom,
                    "timeTo": timeTo, "flags": 1, "flagsMask": 65281, "loadCount": msgLoadCount
                }
            };
            prms.push(prmsObj);
        }
    }

    let data = await loadSensorValuesBatch(prms);

    return await new Promise((resolve, reject) => {

        let sensorValues = [];

            for (let p = 0; p < prms.length; p++) {

                for (let i = 0; i < data[p].messages.length; i++) {

                    for (let property in data[p].messages[i].p) {
                        let sensorValue = {};
                        if (property.slice(0, 1) === "w" || property.slice(0, 2) === "vr" || property.slice(0, 2) === "vl") {
                            sensorValue.temperature = data[p].messages[i].p[property];
                            const tempi = i;
                            continue;
                        }

                        //if (property.slice(0, 1) === "p") {
                        //    let sensorValue = {};

                        //    sensorValue.id = prms[p].params.itemId;
                        //    sensorValue.unitNumber = property.slice(-1);
                        //    sensorValue.sensorName = property.slice(1);
                        //    sensorValue.axle = property.slice(2, 4);
                        //    sensorValue.wheelPos = property.slice(1, 2);
                        //    sensorValue.axleWheelPos = sensorValue.axle + sensorValue.wheelPos;

                        //    for (let s = 0; s < unit.sensors.length; s++) {
                        //        if (unit.sensors[s].sensorName.slice(1, 3) === sensorValue.axleWheel) {
                        //            sensorValue.minPressureValue = unit.sensors[s].minPressureValue;
                        //            sensorValue.maxPressureValue = unit.sensors[s].maxPressureValue;
                        //            sensorValue.maxTemperatureValue = unit.sensors[s].maxTemperatureValue;
                        //            sensorValue.minVoltageValue = unit.sensors[s].minVoltageValue;
                        //            break;
                        //        }
                        //    }

                        //    sensorValue.unixTime = data[p].messages[i].t;
                        //    sensorValue.time = convertUnixTime(data[p].messages[i].t);
                        //    sensorValue.posx = data[p].messages[i].pos?.x || 0;
                        //    const tempi = i;
                        //    sensorValue.posy = data[p].messages[i].pos?.y || 0;
                        //    sensorValue.pressure = (parseFloat(data[p].messages[i].p[property])).toFixed(2);
                        //    sensorValue.temperature = data[p].messages[i].p["t" + property.slice(1)];
                        //    sensorValue.voltage = data[p].messages[i].p["v" + property.slice(1)];

                        //    sensorValues.push(sensorValue);

                        //}
                    }
                }
            }


        if (sensorValues.length) {
            //if (unit.name === "Test Wialon IPS 2" || unit.name === "GalileoSky7x")
                sensorValues.sort((a, b) => a.unitNumber - b.unitNumber || a.axle - b.axle || a.wheelPos - b.wheelPos || a.unixTime - b.unixTime);
            //else
            //    sensorValues.sort((a, b) => a.id - b.id || a.axleWheel - b.axleWheel || a.unixTime - b.unixTime);

            //console.log('time2sort: [' + Date.now() + '] ' );
            //sensorValues = removeDataSpikes(sensorValues);

            //global.sensorValuesChartMessages = sensorValues;
            //global.isProcessingChartMessages = false;

            //console.log('time2: [' + Date.now() + '] ' + unitId + ' record count: ' + sensorValues.length);

            resolve(sensorValues);
        } else {
            reject(new Error(`Whoops!`));
        }

    }).catch(alert);
}

//function loadLocationSensorValues() {

//    let e6SensorValues = {};

//    for (let i = 0; i < sensorValues.messages.length; i++) {
//        if (sensorValues.messages[i].p.data_type === "E6") {
//            e6SensorValues = Object.assign(e6SensorValues, sensorValues.messages[i].p);
//        }
//    }

//    delete e6SensorValues.real_count;
//    delete e6SensorValues.total_count;
//    delete e6SensorValues.data_type;
//    Object.keys(e6SensorValues).forEach(function (key) {
//        key.indexOf("t") == 0 && delete e6SensorValues[key];
//    });

//    let locationSensors = [];
//    Object.keys(e6SensorValues).forEach(function (key) {
        
//        if (key.indexOf("SL") == 0 || key.indexOf("SR") == 0 || key.indexOf("VL") == 0 || key.indexOf("VR") == 0) {
//            const sensor = {}
//            sensor.name = key.slice(1);
//            if (key.indexOf("S") == 0) {
//                sensor.signalStrength = e6SensorValues[key];
//                if (sensor.signalStrength == null || parseFloat(sensor.signalStrength) < -50) {

//                    sensor.signalStrengthLabelColour = 'blue';
//                } else { sensor.signalStrengthLabelColour = '';}
//            }
//            if (key.indexOf("V") == 0) {
//                sensor.voltageValue = e6SensorValues[key];
//                if (sensor.voltageValue < 2.7) {
//                    sensor.voltageLabelColour = 'yellow';
//                } else { sensor.voltageLabelColour = ''; }
//            }
//            locationSensors.push(sensor);
//        }        
//    });

//    return mergeSensors(locationSensors);
//}

function mergeSensors(locationSensors) {
    const merged = [];
    const mergedKeys = new Set();

    locationSensors.forEach((obj) => {
        const key = obj.name;

        if (!mergedKeys.has(key)) {
            merged.push(obj);
            mergedKeys.add(key);
        } else {
            const existingObj = merged.find((item) => item.name === obj.name);
            Object.assign(existingObj, obj);
        }
    });

    return merged;
}