/// <reference path="../units.js" />
import { getToday, formatTime, convertUnixTime, convertToUnixTimeStamp, convertToStartOfDayUnixTimeStamp, convertToEndOfDayUnixTimeStamp, isDateInArray, removeSelectOptions} from "../../../utilities";
import { setMainInterval } from "../units";
import { loadIntervalAsync } from "../../../pressuretrack";
import { createDiagramView } from "./uds";

function openSelectSensorDiagramDateForm(unit) {

    let selectSensorDiagramDateModal = document.getElementById('selectsensordiagramdatemodal');

    let closeSelectSensorDiagramDateModal = document.getElementById("closeselectsensordiagramdatemodal");
    closeSelectSensorDiagramDateModal.onclick = function () {
        selectSensorDiagramDateModal.style.display = "none";
    };

    window.onclick = function (event) {
        if (event.target == selectSensorDiagramDateModal) {
            selectSensorDiagramDateModal.style.display = "none";
        }
    };

    let selectsensordiagramdateform = document.getElementById('selectsensordiagramdateform');
    selectsensordiagramdateform.addEventListener('submit', function (e) {
        e.preventDefault();
        e.stopPropagation();
        return;
    });

    document.getElementById('ssddUnitId').value = unit.id;    

    let selectSensorDiagramDate = document.getElementById('ssddSensorDiagramDate');
    selectSensorDiagramDate.value = "";
    let today = getToday();
    selectSensorDiagramDate.setAttribute("max", today);

    let selectSensorDiagramTime = document.getElementById('ssddSensorDiagramTime');
    removeSelectOptions(selectSensorDiagramTime);

    selectSensorDiagramDateModal.style.display = "block";

    selectSensorDiagramDate.oninput = async () => {

        let sensorValuesDiagramDate = selectSensorDiagramDate.value;        
        getUnitSensorValuesTimestamps(unit, sensorValuesDiagramDate);
    }

    let selectedDateBtn = document.getElementById("selectedDateBtn");
    selectedDateBtn.onclick = () => {
        createDiagramView(unit, 'SENSORVALUESDIAGRAM', 'middlepane');
        selectSensorDiagramDateModal.style.display = "none";

        return false;
    };
}

async function getUnitSensorValuesTimestamps(unit, sensorValuesDiagramDate) {

    try {
        document.body.style.cursor = 'wait';
        let timestamps = await getTimestamps(unit, sensorValuesDiagramDate);
        var distinctTimestamps = [];
        for (var i = 0; i < timestamps.length; i++) {
            if (!isDateInArray(timestamps[i], distinctTimestamps)) {
                distinctTimestamps.push(timestamps[i]);
            }
        }
        unit = await populateSensorValuesDiagramTimeOptions(unit, distinctTimestamps);
        document.body.style.cursor = 'default';
    } catch (e) {        
        console.error('sensorvaluesdiagramsJS: getUnitSensorValuesTimestamps - ERROR: ', e);
        setMainInterval(global.units);
    }    
}

async function populateSensorValuesDiagramTimeOptions(unit, timestamps) {

    let selectSensorDiagramTime = document.getElementById('ssddSensorDiagramTime');
    removeSelectOptions(selectSensorDiagramTime);
    //Object.keys(result).map((key) => selectSensorDiagramTime.add(new Option(result[key].name, JSON.stringify(result[key]))));
    timestamps.map((key) => selectSensorDiagramTime.add(new Option(formatTime(key), key)));

    if (selectSensorDiagramTime.options.length === 0) {
        selectSensorDiagramTime.options[0] = new Option("No data - select another date.");
        selectSensorDiagramTime.options[0].selected = true;
    } else {
        selectSensorDiagramTime.selectedIndex = -1;
        selectSensorDiagramTime.oninput = async () => {
            global.selectedDateTime = selectSensorDiagramTime.value;
            global.sensorValuesDiagramDateSelected = true;
            unit = await getUnitSensorValuesByTime(unit);
            
            return unit;
        }
    }
}

async function getTimestamps(unit, sensorValuesDiagramDate) {

    const session = global.session;

    const timeTo = convertToEndOfDayUnixTimeStamp(sensorValuesDiagramDate);
    const timeFrom = convertToStartOfDayUnixTimeStamp(sensorValuesDiagramDate);
    const ml = session.getMessagesLoader();

    let unitId = unit.id;

    let data = await loadIntervalAsync(ml, {
        unitId,
        timeFrom,
        timeTo,
        loadCount: 12500, //for a day
    });

    let timestamps = [];

    for (let m = 0; m < data.messages.length; m++) { // get last received message with values
        for (let property in data.messages[m].p) {
            if (property.includes("i")) { //sensorId
                timestamps.push(convertUnixTime(data.messages[m].t));
            }
        }
    }

    return timestamps;
}

async function getUnitSensorValuesByTime(unit) {

    let session = global.session;
    let selectedDateTime = global.selectedDateTime;

    let timeTo = convertToUnixTimeStamp(selectedDateTime);
    let timeFrom = timeTo - 360;
    let ml = session.getMessagesLoader();

    let unitId = unit.id;

    let sensorValuesdata = await loadIntervalAsync(ml, {
        unitId,
        timeFrom,
        timeTo,
        loadCount: 1500, //for a day
    });

    unit = processHistoryData(unit, sensorValuesdata);

    if (unit.unitTrailers.length > 0) {

        for (let t = 0; t < unit.unitTrailers.length; t++) {            

            let unitId = unit.unitTrailers[t].id;

            let sensorValuesdata = await loadIntervalAsync(ml, {
                unitId,
                timeFrom,
                timeTo,
                loadCount: 1500, //for a day
            });

            processHistoryData(unit.unitTrailers[t], sensorValuesdata);
        }
    }

    return unit;
}

function processHistoryData(unit, data) {

    for (let m = 0; m < data.messages.length; m++) {
            for (let property in data.messages[m].p) {

                for (let k = 0; k < unit.wheelSensors.length; k++) {
                    if (property.substring(1) === unit.wheelSensors[k].sensorName) {

                        if (property.includes("i")) 
                            unit.wheelSensors[k].selectedDateSensorId = parseFloat(data.messages[m].p[property]);

                        if (property.includes("p"))
                            unit.wheelSensors[k].selectedDatePressureValue = (parseFloat(data.messages[m].p[property])).toFixed(2); //* 0.0689475729

                        if (property.includes("t"))
                            unit.wheelSensors[k].selectedDateTemperatureValue = parseInt(data.messages[m].p[property]);

                        if (property.includes("v"))
                            unit.wheelSensors[k].selectedDateVoltageValue = parseFloat(data.messages[m].p[property]);
                    }
                }
            }
}

    unit = getAxleSelectedDateTyreProperties(unit);

    return unit;
}

function getAxleSelectedDateTyreProperties(unit) {

    if (unit.wheelSensors.length > 0) {

        for (let s = 0; s < unit.wheelSensors.length; s++) {
            unit.wheelSensors[s].selectedDateSensorType = "";
            unit.wheelSensors[s].selectedDatePressureLabelColour = "green";
            unit.wheelSensors[s].selectedDateTemperatureLabelColour = "green";
            unit.wheelSensors[s].selectedDateVoltageLabelColour = "green";
            unit.wheelSensors[s].selectedDateNoSignalLabelColour = "blue";

            if (unit.wheelSensors[s].selectedDatePressureValue == null && unit.wheelSensors[s].selectedDateTemperatureValue == null && unit.wheelSensors[s].selectedDateVoltageValue == null) {
                unit.wheelSensors[s].selectedDateSensorType = "wheelblue";
                unit.wheelSensors[s].selectedDateNoSignalLabelColour = "blue";
            }

            if (parseFloat(unit.wheelSensors[s].selectedDatePressureValue) > parseFloat(unit.wheelSensors[s].maxPressureValue)) {
                //assign flags here!!
                unit.wheelSensors[s].selectedDateSensorType = "wheelpurple";
                unit.wheelSensors[s].selectedDatePressureLabelColour = "purple";
            }

            if (parseFloat(unit.wheelSensors[s].selectedDatePressureValue) < parseFloat(unit.wheelSensors[s].minPressureValue)) {
                unit.wheelSensors[s].selectedDateSensorType = "wheelred";
                unit.wheelSensors[s].selectedDatePressureLabelColour = "red";
            }

            if (parseInt(unit.wheelSensors[s].selectedDateTemperatureValue) > parseInt(unit.wheelSensors[s].maxTemperatureValue)) {
                if (unit.wheelSensors[s].selectedDateSensorType !== "wheelpurple" && unit.wheelSensors[s].selectedDateSensorType !== "wheelred") {
                    unit.wheelSensors[s].selectedDateSensorType = "wheelorange";
                }
                unit.wheelSensors[s].selectedDateTemperatureLabelColour = "orange";
            }

            if (parseFloat(unit.wheelSensors[s].selectedDateVoltageValue) < parseFloat(unit.wheelSensors[s].minVoltageValue)) {
                if (unit.wheelSensors[s].selectedDateSensorType !== "wheelpurple" && unit.wheelSensors[s].selectedDateSensorType !== "wheelred" && unit.wheelSensors[s].selectedDateSensorType !== "wheelorange") {
                    unit.wheelSensors[s].selectedDateSensorType = "wheelyellow";
                }
                unit.wheelSensors[s].selectedDateVoltageLabelColour = "yellow";
            }
        }
    }

    return unit;
}

export { openSelectSensorDiagramDateForm };


//class Shape {
//    area() {
//        return 0;
//    }
//    toString() {
//        return Object.getPrototypeOf(this).constructor.sensorName;
//    }
//}

//class Circle extends Shape {
//    constructor(r) {
//        super();
//        this.radius = r;
//    }

//    area() {
//        return Math.PI * this.radius ** 2;
//    }
//}

//class Rectangle extends Shape {
//    constructor(w, h) {
//        super();
//        this.width = w;
//        this.height = h;
//    }

//    area() {
//        return this.width * this.height;
//    }
//}

//class Triangle extends Shape {
//    constructor(b, h) {
//        super();
//        this.base = b;
//        this.height = h;
//    }

//    area() {
//        return this.base * this.height / 2;
//    }
//}

//function cumulateShapes(shapes) {
//    return shapes.reduce((sum, shape) => {
//        if (shape instanceof Shape) {
//            console.log(`Shape: ${shape.toString()} - area: ${shape.area()}`);
//            return sum + shape.area();
//        }
//        throw Error('Bad argument shape.');
//    }, 0);
//}

//const shapes = [new Circle(3), new Rectangle(2, 3), new Triangle(3, 4), new Circle(2)];

