import { formatDateTime, convertUnixTime } from "../../../utilities";
import { getWheelProperties } from "../units";

export function displayStatusSummary(units) {

    let unitTotal = units.length;
    if (parseInt(unitTotal) === 0) {
        let totalUnitsSpan = document.getElementById('totalunits');
        totalUnitsSpan.innerText = '-';

        document.getElementById('lowvoltagemetrics').style.display = 'none';
        document.getElementById('underinflatedmetrics').style.display = 'inherit';
        document.getElementById('overinflatedmetrics').style.display = 'inherit';
        document.getElementById('hightemperaturemetrics').style.display = 'none';

        let underInflatedSpan = document.getElementById('underInflated');
        underInflatedSpan.innerText = '-';
        let percUnderInflatedSpan = document.getElementById('percentageUnderInflated');
        percUnderInflatedSpan.innerText = '- ';
        let overInflatedSpan = document.getElementById('overInflated');
        overInflatedSpan.innerText = '-';
        let percOverInflatedSpan = document.getElementById('percentageOverInflated');
        percOverInflatedSpan.innerText = '- ';
        document.getElementById('normalvoltagelabel').style.display = 'none';
        document.getElementById('normalpressurelabel').style.display = 'inherit';
        document.getElementById('normaltemperaturelabel').style.display = 'none';
        let normalSpan = document.getElementById('normal');
        normalSpan.innerText = '-';
        let percNormalSpan = document.getElementById('percentagenormal');
        percNormalSpan.innerText = '- ';

        let noSignalSpan = document.getElementById('noSignal');
        noSignalSpan.innerText = '-';
        let percNoSignalSpan = document.getElementById('percentagenosignal');
        percNoSignalSpan.innerText = '- ';
        let totalSensorValuesSpan = document.getElementById('totalSensorValues');
        totalSensorValuesSpan.innerText = '-';
    } else {
        let totalUnitsSpan = document.getElementById('totalunits');
        totalUnitsSpan.innerText = unitTotal;

        let totalSensorValues = 0;
        let yellowStatus = 0;
        let blinkStatus = 0;
        let redStatus = 0;
        let greenStatus = 0;
        let purpleStatus = 0;
        let orangeStatus = 0;
        let blueStatus = 0;

        for (let unit = 0; unit < units.length; unit++) {
            totalSensorValues = parseInt(totalSensorValues) + parseInt(units[unit].wheelSensors.length) + parseInt(units[unit].spareSensors.length);
            yellowStatus = parseInt(yellowStatus) + parseInt(units[unit].yellowFlags);
            blinkStatus = parseInt(blinkStatus) + parseInt(units[unit].blinkFlags);
            redStatus = parseInt(redStatus) + parseInt(units[unit].redFlags);
            purpleStatus = parseInt(purpleStatus) + parseInt(units[unit].purpleFlags);
            orangeStatus = parseInt(orangeStatus) + parseInt(units[unit].orangeFlags);
            blueStatus = parseInt(blueStatus) + parseInt(units[unit]?.blueFlags || 0);
        }

        if (document.getElementById('rbtnPressure').checked === true) {

            global.selectedMetric = "PRESSURE";

            document.getElementById('lowvoltagemetrics').style.display = 'none';
            document.getElementById('underinflatedmetrics').style.display = 'inherit';
            document.getElementById('overinflatedmetrics').style.display = 'inherit';
            document.getElementById('hightemperaturemetrics').style.display = 'none';

            let underInflatedSpan = document.getElementById('underInflated');
            underInflatedSpan.innerText = redStatus;
            let percUnderInflatedSpan = document.getElementById('percentageUnderInflated');
            percUnderInflatedSpan.innerText = Math.round(redStatus / totalSensorValues * 100);
            let overInflatedSpan = document.getElementById('overInflated');
            overInflatedSpan.innerText = purpleStatus;
            let percOverInflatedSpan = document.getElementById('percentageOverInflated');
            percOverInflatedSpan.innerText = Math.round(purpleStatus / totalSensorValues * 100);
            document.getElementById('normalvoltagelabel').style.display = 'none';
            document.getElementById('normalpressurelabel').style.display = 'inherit';
            document.getElementById('normaltemperaturelabel').style.display = 'none';
            let normalSpan = document.getElementById('normal');
            greenStatus = totalSensorValues - (redStatus + purpleStatus);
            normalSpan.innerText = greenStatus;
            let percNormalSpan = document.getElementById('percentagenormal');
            percNormalSpan.innerText = Math.round(greenStatus / totalSensorValues * 100);
        }

        if (document.getElementById('rbtnTemperature').checked === true) {

            global.selectedMetric = "TEMPERATURE";

            document.getElementById('lowvoltagemetrics').style.display = 'none';
            document.getElementById('underinflatedmetrics').style.display = 'none';
            document.getElementById('overinflatedmetrics').style.display = 'none';
            document.getElementById('hightemperaturemetrics').style.display = 'inherit';

            document.getElementById('normalvoltagelabel').style.display = 'none';
            document.getElementById('normalpressurelabel').style.display = 'none';
            document.getElementById('normaltemperaturelabel').style.display = 'inherit';
            let normalSpan = document.getElementById('normal');
            greenStatus = parseInt(greenStatus) + (parseInt(totalSensorValues) - parseInt((parseInt(orangeStatus) + parseInt(blueStatus))));
            normalSpan.innerText = greenStatus;
            let percNormalSpan = document.getElementById('percentagenormal');
            percNormalSpan.innerText = Math.round(greenStatus / totalSensorValues * 100);
            let highTemperatureSpan = document.getElementById('hightemperature');
            highTemperatureSpan.innerText = orangeStatus;
            let percentageHighTemperatureSpan = document.getElementById('percentagehightemperature');
            percentageHighTemperatureSpan.innerText = Math.round(orangeStatus / totalSensorValues * 100);
        }

        if (document.getElementById('rbtnVoltage').checked === true) {

            global.selectedMetric = "VOLTAGE";

            document.getElementById('lowvoltagemetrics').style.display = 'inherit';
            document.getElementById('underinflatedmetrics').style.display = 'none';
            document.getElementById('overinflatedmetrics').style.display = 'none';
            document.getElementById('hightemperaturemetrics').style.display = 'none';

            let lowVoltageSpan = document.getElementById('lowvoltage');
            lowVoltageSpan.innerText = yellowStatus;
            let percentageLowVoltageSpan = document.getElementById('percentagelowvoltage');
            percentageLowVoltageSpan.innerText = Math.round(yellowStatus / totalSensorValues * 100);
            document.getElementById('normalvoltagelabel').style.display = 'inherit';
            document.getElementById('normalpressurelabel').style.display = 'none';
            document.getElementById('normaltemperaturelabel').style.display = 'none';
            let normalSpan = document.getElementById('normal');
            greenStatus = parseInt(greenStatus) + (parseInt(totalSensorValues) - parseInt((parseInt(yellowStatus) + parseInt(blueStatus))));
            normalSpan.innerText = greenStatus;
            let percNormalSpan = document.getElementById('percentagenormal');
            percNormalSpan.innerText = Math.round(greenStatus / totalSensorValues * 100);
        }

        let noSignalSpan = document.getElementById('noSignal');
        noSignalSpan.innerText = blueStatus;
        let percNoSignalSpan = document.getElementById('percentagenosignal');
        percNoSignalSpan.innerText = Math.round(blueStatus / totalSensorValues * 100);
        let totalSensorValuesSpan = document.getElementById('totalSensorValues');
        totalSensorValuesSpan.innerText = totalSensorValues;
    }

    const lastUpdatedMain = document.getElementById('lastupdatedmain');

    const foundUnitLastMessage = units.reduce((latestUnit, currentUnit) => {

        const latestValid = latestUnit && isValidDate(latestUnit.lastMessage);
        const currentValid = currentUnit && isValidDate(currentUnit.lastMessage);

        if (currentValid && (!latestValid || new Date(currentUnit.lastMessage) > new Date(latestUnit.lastMessage))) {
            return currentUnit;
        }
        return latestUnit;
    }, null);

    if (foundUnitLastMessage && isValidDate(foundUnitLastMessage.lastMessage)) {
        lastUpdatedMain.innerText = " " + foundUnitLastMessage.lastMessage;
    } else {
        lastUpdatedMain.innerText = "Not Available";
    }
}

function isValidDate(dateStr) {
    // Check if the date string is not equal to the 'NaN-NaN-NaN NaN:NaN:NaN' and is a valid date
    return dateStr !== 'NaN-NaN-NaN NaN:NaN:NaN' && !isNaN(new Date(dateStr).getTime());
}

function loadUnitStatusDiagram(unit) {

    unit.wheelSensors = getWheelProperties(unit.wheelSensors);
    unit.spareSensors = getWheelProperties(unit.spareSensors);

    let unitStatusDiagramDiv = document.getElementById(`unitstatusdiagram${unit.id}`);
    if (unitStatusDiagramDiv != null) {
        while (unitStatusDiagramDiv.hasChildNodes()) {
            unitStatusDiagramDiv.removeChild(unitStatusDiagramDiv.lastChild);
        }
    } else {
        unitStatusDiagramDiv = document.createElement('div');
    }
    unitStatusDiagramDiv.id = `unitstatusdiagram${unit.id}`;
    unitStatusDiagramDiv.className = 'statusdiagram';

    switch (global.selectedMetric) {
        case 'PRESSURE':
            displayUnitPressureStatus(unitStatusDiagramDiv, unit);
            break;
        case 'TEMPERATURE':
            displayUnitTemperatureStatus(unitStatusDiagramDiv, unit);
            break;
        case 'VOLTAGE':
            displayUnitVoltageStatus(unitStatusDiagramDiv, unit);
            break;
        default:
    }

    const metricStatusOptions = document.querySelectorAll('input[name="optmetricstatus"]');
    for (const metricStatusOption of metricStatusOptions) {
        metricStatusOption.addEventListener('change', () => {

            if (unitStatusDiagramDiv != null) {
                while (unitStatusDiagramDiv.hasChildNodes()) {
                    unitStatusDiagramDiv.removeChild(unitStatusDiagramDiv.lastChild);
                }
            }
            switch (metricStatusOption.value) {
                case "Pressure":
                    global.selectedMetric = 'PRESSURE';
                    displayUnitPressureStatus(unitStatusDiagramDiv, unit);
                    break;
                case "Temperature":
                    global.selectedMetric = 'TEMPERATURE';
                    displayUnitTemperatureStatus(unitStatusDiagramDiv, unit);
                    break;
                case "Voltage":
                    global.selectedMetric = 'VOLTAGE';
                    displayUnitVoltageStatus(unitStatusDiagramDiv, unit);
                    break;
                default:
            }
        });
    }

    return unitStatusDiagramDiv;
}

function displayUnitPressureStatus(unitStatusDiagramDiv, unit) {

    const sensors = [...unit.wheelSensors, ...unit.spareSensors];

    sensors.sort(function (s1, s2) {
        if (parseFloat(s1.pressureValue) > parseFloat(s2.pressureValue)) return 1;
        if (parseFloat(s1.pressureValue) < parseFloat(s2.pressureValue)) return -1;
        return 0;
    });
  
    for (let s = 0; s < sensors.length; s++) {
        let sensorStatusDiv = createSensorPressureElement(sensors[s]);

            unitStatusDiagramDiv.appendChild(sensorStatusDiv);
    }

    createSensorNoSignalElements(unitStatusDiagramDiv, unit);        
}

function createSensorNoSignalElements(unitStatusDiagramDiv, unit) {

    const noSignalSensorCount = unit.wheelSensors.length < unit.sensorCount
        ? unit.sensorCount - unit.wheelSensors.length
        : 0;

    for (let ns = 0; ns < noSignalSensorCount; ns++) {
        let sensorNoSignalStatusDiv = createSensorNoSignalElement();
        unitStatusDiagramDiv.appendChild(sensorNoSignalStatusDiv);
    }
}

function displayUnitTemperatureStatus(unitStatusDiagramDiv, unit) {

    unit.wheelSensors.sort(function (s1, s2) {
        if (parseFloat(s1.temperatureValue) < parseFloat(s2.temperatureValue)) return 1;
        if (parseFloat(s1.temperatureValue) > parseFloat(s2.temperatureValue)) return -1;
        return 0;
    });

    for (let s = 0; s < unit.wheelSensors.length; s++) {
        let sensorStatusDiv = createSensorTemperatureElement(unit.wheelSensors[s]);
        unitStatusDiagramDiv.appendChild(sensorStatusDiv);
    }

    createSensorNoSignalElements(unitStatusDiagramDiv, unit);
}

function displayUnitVoltageStatus(unitStatusDiagramDiv, unit) {

    unit.wheelSensors.sort(function (s1, s2) {
        if (parseFloat(s1.voltageValue) > parseFloat(s2.voltageValue)) return 1;
        if (parseFloat(s1.voltageValue) < parseFloat(s2.voltageValue)) return -1;
        return 0;
    });

    for (let s = 0; s < unit.wheelSensors.length; s++) {
        let sensorStatusDiv = createSensorVoltageElement(unit.wheelSensors[s]);
        unitStatusDiagramDiv.appendChild(sensorStatusDiv);
    }

    createSensorNoSignalElements(unitStatusDiagramDiv, unit);
}

function createSensorNoSignalElement() {

    const sensorNoSignalDiv = document.createElement('div');
    let sensorvaluesdivclasses = ['sensorvaluesstatus', 'blue'];
    sensorNoSignalDiv.classList.add(...sensorvaluesdivclasses);
    sensorNoSignalDiv.innerText = "S";
    return sensorNoSignalDiv;
}

function createSensorPressureElement(sensor) {

    let sensorPressureDiv = document.createElement('div');
    let sensorvaluesdivclasses = ['sensorvaluesstatus', sensor.pressureLabelColour];
    let pressure = parseFloat(sensor.pressureValue).toFixed(1);  
    sensorPressureDiv.classList.add(...sensorvaluesdivclasses);
    sensorPressureDiv.innerText = pressure;
    return sensorPressureDiv;
}

function createSensorTemperatureElement(sensor) {

    let sensorTemperatureDiv = document.createElement('div');
    let sensorvaluesdivclasses = [];
    let temperature = parseInt(sensor.temperatureValue);
    if (sensor.sensorType === 'wheelblue') {
        sensorvaluesdivclasses = ['sensorvaluesstatus', 'blue'];
        temperature = "S";
    }
    else
        sensorvaluesdivclasses = ['sensorvaluesstatus', sensor.temperatureLabelColour];

    sensorTemperatureDiv.classList.add(...sensorvaluesdivclasses);
    sensorTemperatureDiv.innerHTML = temperature;
    return sensorTemperatureDiv;
}

function createSensorVoltageElement(sensor) {

    let sensorVoltageDiv = document.createElement('div');
    let sensorvaluesdivclasses = [];
    let voltage = parseFloat(sensor.voltageValue).toFixed(1);
    if (sensor.sensorType === 'wheelblue') {
        sensorvaluesdivclasses = ['sensorvaluesstatus', 'blue'];
        voltage = "S";
    }
    else
        sensorvaluesdivclasses = ['sensorvaluesstatus', sensor.voltageLabelColour];

    sensorVoltageDiv.classList.add(...sensorvaluesdivclasses);
    sensorVoltageDiv.innerHTML = voltage;
    return sensorVoltageDiv;
}

export { loadUnitStatusDiagram };

//class Shape {
//    area() {
//        return 0;
//    }
//    toString() {
//        return Object.getPrototypeOf(this).constructor.sensorName;
//    }
//}

//class Circle extends Shape {
//    constructor(r) {
//        super();
//        this.radius = r;
//    }

//    area() {
//        return Math.PI * this.radius ** 2;
//    }
//}

//class Rectangle extends Shape {
//    constructor(w, h) {
//        super();
//        this.width = w;
//        this.height = h;
//    }

//    area() {
//        return this.width * this.height;
//    }
//}

//class Triangle extends Shape {
//    constructor(b, h) {
//        super();
//        this.base = b;
//        this.height = h;
//    }

//    area() {
//        return this.base * this.height / 2;
//    }
//}

//function cumulateShapes(shapes) {
//    return shapes.reduce((sum, shape) => {
//        if (shape instanceof Shape) {
//            console.log(`Shape: ${shape.toString()} - area: ${shape.area()}`);
//            return sum + shape.area();
//        }
//        throw Error('Bad argument shape.');
//    }, 0);
//}

//const shapes = [new Circle(3), new Rectangle(2, 3), new Triangle(3, 4), new Circle(2)];

